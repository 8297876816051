.ck-editor__editable {min-height: 200px;}
.c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover,.c-header-nav-link:hover{
    border: none;
}
span.ck-file-dialog-button {
    display: none;
}
.CDataTable_arrow-position__3YLcJ {
    right: auto !important;
    margin-left: 5px;
}