@import "~react-image-gallery/styles/css/image-gallery.css";
body {
background-color: white !important;}
.c-video--live .c-video__icon:after,
body {
    font-family: Futura;
}/*
html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
}
body {
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.03188rem;
    background-color: #ededf0;
}
*,
:after,
:before {
    box-sizing: border-box;
}
/*a {
    border-bottom: 1px solid rgba(0, 82, 204, 0.2);
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    color: #0052cc;
    outline: none;
    cursor: pointer;
}
a:focus,
a:hover {
    background-color: rgba(0, 82, 204, 0.2);
}*/
b,
strong {
    font-weight: 700;
}
svg:not(:root) {
    overflow: hidden;
}
button,
input {
    overflow: visible;
}
textarea {
    overflow: auto;
}
select {
    font-size: 0.8125rem;
    padding: 3px 6px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
}
cite,
dfn,
em,
i {
    font-style: italic;
}
blockquote {
    color: #002e5d;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 2rem;
    overflow: hidden;
}
blockquote cite {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.9375rem;
    color: #808083;
    margin-top: 16px;
}
abbr,
acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}
ins,
mark {
    background: #eee;
    text-decoration: none;
}
small {
    font-size: 80%;
}
big {
    font-size: 125%;
}
[type="search"] {
    -webkit-appearance: textfield;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
button {
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
}
img {
    height: auto;
    max-width: 100%;
    transition: opacity 0.3s;
    opacity: 1;
}
img:not([src]) {
    visibility: hidden;
}
img.lazy[data-src] {
    opacity: 0;
}
img.lazy[data-src].visible {
    opacity: 1;
}
address,
button,
dd,
dl,
dt,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
optgroup,
select,
textarea {
    margin: 0;
    padding: 0;
}
fieldset {
    padding: 0;
    border: none;
}
legend {
    display: block;
}
iframe {
    margin: 0 auto;
    border: none;
}
table {
    border: none;
    border-top: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0 0 20px;
    width: 100%;
}
th {
    padding: 8px 0;
    text-align: left;
    border: none;
}
thead th {
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
}
td {
    padding: 8px;
    border: none;
}
tr {
    border-bottom: 1px solid #ccc;
}
p {
    margin: 28px 0;
    padding: 0;
}
p.narrow {
    margin: 10px 0;
}
ol,
ul {
    margin: 0;
    padding: 0 0 0 50px;
}
main {
    display: block;
}
article > * {
    margin-bottom: 27px;
}
aside > * {
    margin-bottom: 64px;
}
@media (min-width: 1024px) {
    aside > * {
        margin-bottom: 32px;
    }
}
article > p {
    margin-top: 0;
}
article li {
    margin-bottom: 17px;
}
article div[class*="fsk_splitbox"] {
    margin-bottom: 0;
}
article div[class*="fsk_splitbox"].sb-opened {
    margin-bottom: 32px;
}
#wpstats {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}
.embed-youtube {
    position: relative;
}
.embed-youtube:before {
    content: "";
    display: block;
    padding: 0 0 56.25%;
    width: 100%;
    height: 0;
}
.embed-youtube > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
}
.embed-youtube .youtube-player {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    transform: none;
}
.aligncenter {
    display: block;
    margin: 0 auto;
}

@font-face {
    font-family: Aljazeera-Regular;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("Aljazeera-Regular"), url(../fonts/Aljazeera-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: ArbFONTS-Aljazeera;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("ArbFONTS-Aljazeera"), url(../fonts/ArbFONTS-Aljazeera.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Aljazeera;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("Aljazeera"), url(../fonts/Aljazeera.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Abenda;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("Abenda"), url(../fonts/Abenda.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: MondaySans;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("MondaySans"), url(../fonts/MondaySans.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Khebrat-Musamim;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("Khebrat-Musamim"), url(../fonts/Khebrat-Musamim.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Al-Jazeera-Arabic;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("Al-Jazeera-Arabic"), url(../fonts/Al-Jazeera-Arabic.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Museo300-Regular;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("Museo300-Regular"), url(../fonts/Museo300-Regular.otf) format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: newstar;
    /* src: url(../fonts/newstar.otf) format("otf"); */
    src: local("newstar"), url(../fonts/newstar.otf) format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Amiri-Regular;
    src: url(../fonts/Amiri-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: AmiriQuran;
    src: url(../fonts/AmiriQuran.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: Chopin-Light;
    src: url(../fonts/Chopin-Light.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: Journal-du-Soir;
    src: url(../fonts/Journal-du-Soir.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: Kilograph;
    src: url(../fonts/Kilograph.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: proxima-nova;
    src: local("Proxima Nova"), url(../fonts/proximanova-regular-webfont.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: proxima-nova;
    src: local("Proxima Nova Bold"), url(../fonts/proximanova-bold-webfont.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: proxima-nova;
    src: local("Proxima Nova Semibold"), url(../fonts/proximanova-semibold-webfont.woff2) format("woff2");
   
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: proxima-nova;
    src: local("Proxima Nova Extrabold"), url(../fonts/proximanova-extrabold-webfont.woff2) format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: Futura;
    src: local("Futura"), url(../fonts/Futura-Book-font.ttf) format("ttf");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    font-weight: 400;
    font-style: normal
}
.o-container {
    max-width: initial;
    padding-left: 40px;
    padding-right: 40px;
}
.o-container--relative {
    position: relative;
}
@media (min-width: 1312px) {
    .o-container {
        margin: 0 auto;
    }
}

.c-ad {
  /*  background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png); */
    position: relative;
}
.c-ad__label {
    font-size: 0.6875rem;
    font-weight: 600;
    color: #4d7192;
    text-transform: uppercase;
    margin: 0 auto 4px;
    width: 300px;
    line-height: 1.25rem;
    text-align: center;
}
.c-ad--noBackground {
    background: none;
}
.c-ad__unit {
    position: relative;
    height: 0;
    margin: 0 auto;
}
.c-ad__unit--loaded {
    height: auto;
}
.c-ad--leaderboard,
.c-ad--leaderboardCombo {
    min-height: 50px;
}
.c-ad--leaderboard:after,
.c-ad--leaderboardCombo:after {
    display: none;
    content: "Ad";
    position: absolute;
    font-size: 0.5625rem;
    text-transform: uppercase;
    right: 0;
    bottom: 0;
    transform: translateX(18px) translateY(-16px);
}
.c-ad--bigbox,
.c-ad--bigboxCombo {
    padding: 8px 0;
    margin-left: -15px;
    margin-right: -15px;
}
.c-ad--bigbox:after,
.c-ad--bigboxCombo:after {
    top: 4px;
}
.c-ad--wallpaper {
    display: none;
}
.c-ad--bigbox .c-ad__unit,
.c-ad--bigboxCombo .c-ad__unit {
    width: 300px;
    min-height: 250px;
}
.c-ad--leaderboard .c-ad__label,
.c-ad--leaderboardCombo .c-ad__label {
    display: none;
}

.c-ad--house .c-ad__unit {
    width: 300px;
    min-height: 50px;
}
.c-ad--house {
    padding: 4px 0;
}
.c-ad--slide {
    margin-left: 0;
    margin-right: 0;
}
@media (min-width: 498px) {
    .c-ad--bigbox,
    .c-ad--bigboxCombo {
        margin-left: auto;
        margin-right: auto;
    }
    .c-ad--leaderboard:after,
    .c-ad--leaderboardCombo:after {
        display: block;
    }
}
@media (min-width: 768px) {
    .c-ad--leaderboard,
    .c-ad--leaderboardCombo {
        min-height: 90px;
        min-width: 728px;
    }
    .c-ad--leaderboard:after,
    .c-ad--leaderboardCombo:after {
        content: "Advertisement";
        font-size: 0.625rem;
        right: -4px;
        transform: rotate(-90deg) translateX(37px) translateY(51px);
    }
}
@media (min-width: 1024px) {
    .c-ad--left .c-ad__label {
        text-align: left;
        padding-left: 4px;
    }
}
.c-button {
    position: relative;
    padding: 6px 15px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    color: #fff;
    font-size: 0.75rem;
    text-align: center;
    background-color: #002e5d;
    border-radius: 2px;
    text-transform: uppercase;
    outline: none;
}
.c-button:focus,
.c-button:hover {
    border-bottom: 2px solid #ff287d;
    background-color: #002e5d;
}
@media (min-width: 768px) {
    .c-button {
        font-size: 0.875rem;
        font-weight: 700;
    }
}
.c-button--light {
    min-width: 100px;
    color: #002e5d;
}
.c-button--light,
.c-button--light:focus,
.c-button--light:hover {
    background-color: #fff;
}
.c-button--red,
.c-button--red:focus,
.c-button--red:hover {
    background-color: #ff287d;
}
.c-button--live {
    padding-right: 8px;
}
.c-button--live:before {
    left: 12px !important;
}
.c-button--live:after {
    left: 15px !important;
}
.c-button--narrow {
    padding: 6px 8px;
}
.c-button--tiny {
    font-size: 0.6875rem;
}
.c-button--buffer {
    margin-left: 24px;
}
.c-button--clear {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: transparent;
    border: none;
}
.c-button--clear:focus,
.c-button--clear:hover {
    background-color: #ededf0;
    border: none;
}
.c-button--clear .c-button__icon {
    height: 38px;
    width: 38px;
}
.c-button--small {
    position: relative;
    padding: 4px 8px;
    border: none;
    border-radius: 0;
    color: #fff;
    background-color: transparent;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    font-weight: 400;
}
.c-button--small:focus,
.c-button--small:hover {
    background-color: transparent;
    border-bottom: none;
    color: #fff;
    text-decoration: none;
}
.c-button--small:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    transition: opacity 0.1s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0);
}
.c-button--small:focus:after,
.c-button--small:hover:after {
    border: 2px solid #ff287d;
    background-color: hsla(0, 0%, 100%, 0.2);
}
.c-button--blue {
    padding: 6px 14px;
}
.c-button--blue,
.c-button--blue:focus,
.c-button--blue:hover {
    background-color: #002e5d;
}
@media (min-width: 768px) {
    .c-button--tiny {
        font-size: 0.6875rem;
    }
}
.c-byline {
    display: flex;
}
.c-byline__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    margin-top: 4px;
}
.c-byline__images {
    margin-right: 10px;
    flex-shrink: 0;
}
.c-byline__images--multiple,
.c-byline__images--single {
    display: none;
}
.c-byline__image {
    display: inline-block;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-right: 6px;
}
.c-byline__attribution {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0;
    margin-top: 2px;
}
.c-byline__attribution .c-byline__by {
    font-weight: 600;
    font-style: italic;
    padding-right: 2px;
}
.c-byline__dateToggle {
    align-self: flex-start;
    position: relative;
    padding: 0 4px;
}
.c-byline__date {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #4d7192;
    letter-spacing: 0.03125rem;
}
.c-byline__date--pubDate {
    display: flex;
}
.c-byline__link {
    color: #0052cc;
    position: relative;
    display: inline-block;
    border-bottom: none;
}
.c-byline__link:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid rgba(0, 82, 204, 0.2);
    opacity: 0;
    bottom: 0;
}
.c-byline__link:hover:before {
    opacity: 1;
}
.c-byline__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.c-byline__link:focus:after {
    opacity: 1;
}
.c-byline__link:focus,
.c-byline__link:hover {
    background: transparent;
}
.c-byline__source {
    display: inline-block;
}
.c-byline__source:before {
    display: inline-block;
    content: "\2022";
    padding: 0 6px 0 2px;
    font-weight: 400;
    font-size: 0.875rem;
    top: -1px;
    position: relative;
}
.c-byline__attributionUrl {
    position: relative;
    color: #000;
    display: inline-block;
    background-color: transparent;
    border-bottom: none;
}
.c-byline__attributionUrl:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #000;
    opacity: 1;
    bottom: 0;
}
.c-byline__attributionUrl:hover:before {
    opacity: 1;
}
.c-byline__attributionUrl:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.c-byline__attributionUrl:focus:after {
    opacity: 1;
}
@media (min-width: 340px) {
    .c-byline__images--single {
        display: block;
    }
    .c-byline__text {
        margin-top: 0;
    }
}
@media (min-width: 498px) {
    .c-byline__images--multiple {
        display: block;
    }
}
@media (min-width: 768px) {
    .c-byline__dates {
        display: flex;
    }
    .c-byline__dates .c-byline__date--modDate {
        visibility: visible;
        height: auto;
    }
    .c-byline__date--modDate:before {
        content: ".";
        line-height: 1.25rem;
        padding: 0 2px 0 8px;
        top: -4px;
        position: relative;
    }
}
.c-trendingTopics {
    display: flex;
    align-items: center;
    padding: 0 8px;
}
.c-trendingTopics--divider {
    padding: 0 0 32px;
    border-bottom: 1px solid #ededf0;
}
.c-trendingTopics__title {
    font-weight: 700;
    font-size: 1rem;
    color: #ff287d;
    text-transform: uppercase;
    margin-right: 32px;
    white-space: nowrap;
    flex-shrink: 0;
}
.c-trendingTopics__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    height: 24px;
    overflow: hidden;
}
.c-trendingTopics__item {
    display: inline-block;
}
.c-trendingTopics__item:not(:first-child) {
    margin-left: 20px;
}
.c-trendingTopics__link {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    border-bottom: none;
}
.c-trendingTopics__link:focus,
.c-trendingTopics__link:hover {
    background: none;
}
.c-trendingTopics__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.c-trendingTopics__link:focus:after {
    opacity: 1;
}
.c-trendingTopics__label {
    display: block;
    position: relative;
    text-transform: capitalize;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.c-trendingTopics__link:hover .c-trendingTopics__label:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 1;
    bottom: 0;
}
.c-trendingTopics__link:hover .c-trendingTopics__label:hover:before {
    opacity: 1;
}
.c-trendingTopics--header .c-trendingTopics__title {
    margin: 2px 0 0;
    font-size: 0.75rem;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 12px;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    font-weight: 400;
}
.c-trendingTopics--header .c-trendingTopics__list {
    height: 40px;
}
.c-trendingTopics--header .c-trendingTopics__item {
    margin-left: 32px;
}
.c-trendingTopics--header .c-trendingTopics__link {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.01563rem;
    font-weight: 600;
}
.c-trendingTopics--header .c-trendingTopics__link .c-trendingTopics__label {
    line-height: 2.5rem;
}
.c-caption {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.03125rem;
    color: #4d7192;
    margin-top: 8px;
}
.c-caption__cite {
    font-weight: 700;
    font-style: normal;
}
.c-figure__embed,
.c-figure__image {
    display: block;
}
.c-figure__embed {
    width: 100%;
}
.c-figure__inner {
    max-width: 100%;
}
.c-figure__image {
    width: 100%;
}
.c-figure__imageWrapper {
    margin: 0 -16px;
    position: relative;
}
.c-figure--contained .c-figure__imageWrapper {
    background-color: #000;
    position: relative;
}
.c-figure--contained .c-figure__imageWrapper:before {
    content: "";
    display: block;
    padding: 0 0 66.66667%;
    width: 100%;
    height: 0;
}
.c-figure--contained .c-figure__imageWrapper > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    height: 100%;
    max-width: none;
}
.c-figure--containedPortrait .c-figure__imageWrapper:before {
    padding: 0 0 100%;
}
.c-figure--graphic .c-figure__image {
    visibility: hidden;
}
.c-figure--slide:not(:last-child) {
    margin-bottom: 32px;
}
.c-figure__overlayContainer {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.c-figure__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}
.c-figure--graphic .c-figure__overlay--blur {
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    display: block;
}
.c-figure--graphic .c-figure__overlay--warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.625rem;
    font-weight: 700;
    z-index: 1000;
}
.c-figure--expandable .c-figure__expand {
    pointer-events: none;
    border: none;
}
.c-figure--expandable .c-figure__expand:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.c-figure--expandable .c-figure__expand:focus:after {
    opacity: 1;
}
.c-figure--expandable .c-figure__expand:focus,
.c-figure--expandable .c-figure__expand:hover {
    background: transparent;
}
.c-figure--expandable .c-figure__expand:after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
}
.c-figure--expandable .c-figure__maximizeBtn {
    display: none;
}
@media (min-width: 498px) {
    .c-figure__overlay--warning {
        font-size: 1.625rem;
        line-height: 2rem;
    }
    .c-figure__caption {
        margin-left: 0;
    }
    .c-figure__imageWrapper {
        margin: 0;
    }
}
@media (min-width: 1024px) {
    .c-figure--expandable .c-figure__expand {
        cursor: pointer;
        pointer-events: all;
        z-index: 100;
        display: block;
    }
    .c-figure--graphic .c-figure__expand {
        display: none;
    }
    .c-figure--expandable .c-figure__maximizeBtn {
        position: absolute;
        right: 16px;
        bottom: 16px;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
    }
    .c-figure--expandable .c-figure__expand:focus .c-figure__maximizeBtn,
    .c-figure--expandable .c-figure__expand:hover .c-figure__maximizeBtn {
        display: flex;
    }
    .c-figure--expandable .c-figure__icon {
        width: 24px;
        height: 24px;
    }
}
.c-heading {
    font-size: 1rem;
    font-weight: 700;
}
.c-heading--primary {
    padding: 16px 8px;
    font-size: 1.125rem;
    line-height: 1.5rem;
}
.c-heading--primary,
.c-heading--secondary {
    border-bottom: 1px solid #ededf0;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 600;
    font-weight: 800;
}
.c-heading--secondary {
    padding: 0 8px 4px;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
}
.c-heading--component {
    font-size: 1.125rem;
    line-height: 1.5rem;
}
.c-heading--component,
.c-heading--page {
    font-weight: 800;
    text-transform: uppercase;
}
.c-heading--page {
    height: 26px;
    font-size: 1.375rem;
    line-height: 1.625rem;
}
@media (min-width: 768px) {
    .c-heading--page {
        height: 50px;
        font-size: 2rem;
        line-height: 4rem;
        font-weight: 100;
        letter-spacing: 1px;
        color: #ff287d;
    }
}
.c-heading--overlap {
    position: absolute;
    background: #fff;
    transform: translateY(-50%);
    padding: 0 4px;
    margin-left: 6px;
    z-index: 1;
}
.c-heading--subhead {
    padding-bottom: 4px;
    color: #1d2d44;
    text-transform: uppercase;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    letter-spacing: 0.03125rem;
    font-weight: 600;
}
.c-heading--strikethrough {
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 600;
    font-weight: 800;
    color: #ff287d;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 0 !important;
    white-space: nowrap;
}
.c-heading--strikethrough:after {
    content: "";
    height: 2px;
    background: #ff287d;
    margin-top: 11px;
    width: 100%;
    margin-left: 8px;
}
.c-heading--bold {
    font-weight: 600;
}
.c-heading--underline {
    border-bottom: 1px solid #ededf0;
}
.c-heading--error,
.c-heading--notice {
    padding: 16px 8px;
    font-size: 1.25rem;
    line-height: 1.625rem;
    text-align: center;
}
.c-heading--notice {
    color: #808083;
}
.c-heading--error {
    color: #e41b23;
}
.c-heading--logo {
    min-width: 0;
    max-height: 32px;
}
@media (min-width: 498px) {
    .c-heading--region .c-heading__link:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: 2px solid #ff287d;
        opacity: 0;
        bottom: 0;
    }
    .c-heading--region .c-heading__link:hover:before {
        opacity: 1;
    }
    .c-heading--region .c-heading__link:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        border: 2px solid #000;
        opacity: 0;
        pointer-events: none;
    }
    .c-heading--region .c-heading__link:focus:after {
        opacity: 1;
    }
    .c-heading--component {
        font-size: 1.25rem;
        line-height: 1.5625rem;
        letter-spacing: 0.01563rem;
    }
}
.c-icon {
    pointer-events: none;
}
.c-icon--inverted {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}
.c-icon--arrowUp:before {
    border-width: 0 4px 4px;
}
.c-icon--arrowDown:before,
.c-icon--arrowUp:before {
    content: "";
    display: inline-block;
    position: relative;
    border-color: #808083 transparent;
    border-style: solid;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.c-icon--arrowDown:before {
    border-width: 4px 4px 0;
}
.c-link {
    border-bottom: none;
    color: #ff287d;
    display: inline-block;
    position: relative;
    margin-right: 8px;
}
.c-link:hover{
    color: #ff287d;
    text-decoration: none;
}
.c-link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.c-link:focus:after {
    opacity: 1;
}
.c-link:after {
    left: -8px;
}
.c-link__label {
    font-size: 0.6875rem;
    font-weight: 600;
    line-height: 0.875rem;
    letter-spacing: 0.03125rem;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
}
.c-link__label--lowercase {
    text-transform: none;
}
.c-link__label:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: -2px;
}
.c-link__label:hover:before {
    opacity: 1;
}
.c-link__label:focus:before,
.c-link__label:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
@-webkit-keyframes underlineSlide {
    0% {
        transform: scaleY(0);
    }
    to {
        transform: scaleX(1);
    }
}
.c-link__inner {
    display: flex;
    align-items: center;
}
.c-link__icon {
    display: inline-block;
    margin-left: 2px;
    transition: transform 0.1s ease-in-out;
    transform-origin: center;
}
.c-link__icon:after {
    content: "";
    display: inline-block;
    height: 7px;
    width: 7px;
    border-color: #ff287d;
    border-style: solid;
    border-width: 2px 2px 0 0;
    background-color: transparent;
    transform: rotate(45deg) translateY(-50%);
}
.c-link--medium .c-link__label {
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
}
.c-link--large .c-link__label {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    letter-spacing: 0.01563rem;
    font-weight: 700;
    text-transform: uppercase;
    font-weight: 800;
}
.c-link--xlarge .c-link__label {
    font-size: 1.75rem;
    line-height: 2rem;
    letter-spacing: 0.01563rem;
    font-weight: 700;
    font-weight: 800;
}
.c-link--large .c-link__icon {
    margin-left: 6px;
    margin-bottom: -5px;
}
.c-link--large .c-link__icon:after {
    content: "";
    display: inline-block;
    height: 9px;
    width: 9px;
    border-color: #ff287d;
    border-style: solid;
    border-width: 3px 3px 0 0;
    background-color: transparent;
    transform: rotate(45deg) translateY(-50%);
}
.c-link--xlarge .c-link__icon {
    margin-left: 6px;
    margin-bottom: -5px;
}
.c-link--xlarge .c-link__icon:after {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    border-color: #ff287d;
    border-style: solid;
    border-width: 4px 4px 0 0;
    background-color: transparent;
    transform: rotate(45deg) translateY(-50%);
}
.c-link--large .c-link__label:before,
.c-link--xlarge .c-link__label:before {
    bottom: 0;
}
.c-link--white .c-link__label {
    color: #fff;
}
.c-link--moreLess .c-link__inner {
    justify-content: space-between;
}
.c-link--moreLess .c-link__icon--more:after {
    transform: rotate(135deg) translate(-70%);
}
.c-link--moreLess .c-link__icon--less:after {
    transform: rotate(-45deg) translate(0);
}
.c-link--noIcon {
    margin-right: 0;
}
.c-link:focus,
.c-link:hover {
    background: none;
}
.c-link:focus .c-link__icon,
.c-link:hover .c-link__icon {
    transform: translate3d(8px, 0, 0);
}
.c-logo {
    display: flex;
    height: 100%;
    align-items: center;
    border: 1px solid transparent;
    transition: transform 0.1s ease-in;
}
.c-logo:hover {
    background-color: transparent;
    transform: scale3d(0.95, 0.95, 0.95);
}
.c-logo:focus {
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    background-color: rgba(0, 0, 0, 0.2);
    outline: none;
    transform: scale3d(1.1, 1.1, 1.1);
}
.c-logo--header {
    position: relative;
    padding: 0 8px;
}
.c-logo--header:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.c-logo--header:hover:before {
    opacity: 1;
}
.c-logo--header:focus:before,
.c-logo--header:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
.c-logo--header:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.c-logo--header:focus:after {
    opacity: 1;
}
.c-logo--header .c-logo__image {
    width: 60px;
  /*  height: 24px;*/
}
.c-logo--header.c-logo--header:focus,
.c-logo--header.c-logo--header:hover {
    background-color: transparent;
    border-color: transparent;
    transform: none;
}
@media (min-width: 768px) {
    .c-logo--header .c-logo__image {
        width: 145px;
        height: 28px;
    }
}
@media (min-width: 1024px) {
    .c-logo--header {
        width: 130px;
        height: 100%;
      /*  background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/logo-big.svg); */
        background-size: 114px 64px;
        background-repeat: no-repeat;
        background-position: 8px 12px;
    }
    .c-logo--header .c-logo__image {
        display: none;
    }
}
.c-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 72px;
    height: 72px;
    transform: translateX(-50%) translateY(-50%);
}
.c-loader:before {
  /*  background: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/loading.svg); */
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}
.c-loader--small {
    width: 40px;
    height: 40px;
}

.c-nav {
    list-style: none;
    padding: 0;
}
.c-nav__item {
    height: 100%;
    overflow: hidden;
}
.c-nav__item--live:before {
   /* content: "LIVE";*/
    position: relative;
    top: -2px;
    display: inline-block;
    line-height: 0.875rem;
    font-size: 0.6875rem;
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.2);
    padding: 4px 8px;
    margin-left: 16px;
}
.c-nav__link {
    color: #0d1321;
    border: none;
}
.c-nav__link:focus,
.c-nav__link:hover {
    background-color: transparent;
    background-color: initial;
    color: #0d1321;
    text-decoration: none;
}
.c-nav__item--live .c-nav__link {
    padding-left: 4px !important;
}
.c-nav__icon--menuDesk,
.c-nav__icon--searchDesk {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}
.c-nav--inverted .c-nav__link {
    color: #fff;
}
.c-nav--inverted .c-nav__link:hover:before {
    border-color: #fff;
}
.c-nav--main {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    font-size: 1.125rem;
}
.c-nav--main .c-nav__link {
    display: inline-block;
    position: relative;
    height: 100%;
    padding: 0 8px;
    outline: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 400;
    line-height: inherit;
}
.c-nav--main .c-nav__link:hover{
    text-decoration: none;
}
.c-nav--main .c-nav__link:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff297d;
    text-decoration: none;
    opacity: 0;
    bottom: 0;
}
.c-nav--main .c-nav__link:hover:before {
    opacity: 1;
}
.c-nav--main .c-nav__link:focus:before,
.c-nav--main .c-nav__link:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
.c-nav--main .c-nav__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.c-nav--main .c-nav__link:focus:after {
    opacity: 1;
}
.c-nav--main .c-nav__link--active:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 1;
    bottom: 0;
}
.c-nav--main .c-nav__link--active:hover:before {
    opacity: 1;
}
.c-nav__icon {
    position: relative;
}
.c-nav__icon--chevron {
    margin-left: 8px;
}
.c-nav__icon--chevron:after {
    content: "";
    display: inline-block;
    height: 7px;
    width: 7px;
    border-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 0 0;
    background-color: transparent;
    transform: rotate(135deg) translateX(-50%);
    transition: transform 0.2s cubic-bezier(0.22, 1, 0.36, 1);
    transform-origin: center;
}
.c-nav--main .c-nav__link--active .c-nav__icon--chevron:after {
    transform: rotate(-45deg);
}
.c-nav--main.c-nav--stuck .c-nav__link {
    color: #000;
}
.c-nav--main.c-nav--stuck .c-nav__link:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 16px;
}
.c-nav--main.c-nav--stuck .c-nav__link:hover:before {
    opacity: 1;
}
.c-nav--main.c-nav--stuck .c-nav__link:focus:before,
.c-nav--main.c-nav--stuck .c-nav__link:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
@media (min-width: 768px) {
    .c-nav--main {
        font-weight: 500;
    }
    .c-nav--main .c-nav__link {
        margin-right: 16px;
    }
}
.c-nav--buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.c-nav--buttons .c-nav__item {
    position: relative;
    flex-grow: 1;
    height: 100%;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
}
.c-nav--buttons .c-nav__link {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.c-nav--buttons .c-nav__link:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.c-nav--buttons .c-nav__link:hover:before {
    opacity: 1;
}
.c-nav--buttons .c-nav__link:focus:before,
.c-nav--buttons .c-nav__link:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
.c-nav--buttons .c-nav__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.c-nav--buttons .c-nav__link:focus:after {
    opacity: 1;
}
.c-nav--buttons button.c-nav__item:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.c-nav--buttons button.c-nav__item:hover:before {
    opacity: 1;
}
.c-nav--buttons button.c-nav__item:focus:before,
.c-nav--buttons button.c-nav__item:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
.c-nav--buttons button.c-nav__item:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.c-nav--buttons button.c-nav__item:focus:after {
    opacity: 1;
}
.c-nav--buttons .c-nav__item--active:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 1;
    bottom: 0;
}
.c-nav--buttons .c-nav__item--active:hover:before {
    opacity: 1;
}
.c-nav--buttons .c-nav__icon--latest {
    width: 26px;
    height: 26px;
}
.c-nav--buttons .c-nav__icon--trending {
    width: 26.545px;
    height: 25px;
}
.c-nav--buttons .c-nav__icon--radio {
    width: 30px;
    height: 30px;
}
.c-nav--buttons .c-nav__icon--watch {
    width: 28px;
    height: 24px;
    margin-bottom: 1px;
}
.c-nav--buttons .c-nav__icon--live {
    width: 28px;
    height: 28px;
    margin-bottom: -2px;
}
.c-nav--buttons .c-nav__icon--local {
    width: 20.857px;
    height: 24px;
    margin-bottom: 1px;
}
.c-nav--buttons .c-nav__icon--menu {
    width: 18px;
    height: 17px;
    margin-top: 3px;
    margin-bottom: 5px;
}
.c-nav--buttons .c-nav__icon--more {
    width: 18px;
    height: 17px;
}
.c-nav--buttons .c-nav__icon--menuDesk {
    width: 18px;
    height: 17px;
    margin: 4px 12px -2px;
}
.c-nav--buttons .c-nav__icon--searchDesk {
    width: 18px;
    height: 18px;
    margin: 6px 8px -2px;
}
.c-nav--buttons .c-nav__label {
    font-size: 0.75rem;
    line-height: 0.625rem;
}
.c-nav--buttons .c-nav__label--radio {
    position: relative;
    top: -2px;
}
.c-nav--buttons .c-nav__content {
    position: relative;
}
.c-nav--buttons .c-nav__content:after,
.c-nav--buttons .c-nav__content:before {
    left: auto;
    top: 5px;
}
.c-nav--buttons .c-nav__content:before {
    right: -3px;
}
.c-nav--buttons .c-nav__content:after {
    right: 0;
}
.c-nav--buttons.c-nav--stuck .c-nav__icon--menuDesk,
.c-nav--buttons.c-nav--stuck .c-nav__icon--searchDesk {
    -webkit-filter: none;
    filter: none;
}
.c-nav--buttons.c-nav--stuck .c-nav__item:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.c-nav--buttons.c-nav--stuck .c-nav__item:hover:before {
    opacity: 1;
}
.c-nav--buttons.c-nav--stuck .c-nav__item:focus:before,
.c-nav--buttons.c-nav--stuck .c-nav__item:hover:before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom;
}
@keyframes underlineSlide {
    0% {
        transform: scaleY(0);
    }
    to {
        transform: scaleX(1);
    }
}
@media (min-width: 768px) {
    .c-nav--buttons.c-nav--stuck .c-nav__item:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: 2px solid #ff287d;
        opacity: 0;
        bottom: 16px;
    }
    .c-nav--buttons.c-nav--stuck .c-nav__item:hover:before {
        opacity: 1;
    }
    .c-nav--buttons.c-nav--stuck .c-nav__item:focus:before,
    .c-nav--buttons.c-nav--stuck .c-nav__item:hover:before {
        -webkit-animation: underlineSlide 0.1s linear forwards;
        animation: underlineSlide 0.1s linear forwards;
        transform-origin: bottom;
    }
    @-webkit-keyframes underlineSlide {
        0% {
            transform: scaleY(0);
        }
        to {
            transform: scaleX(1);
        }
    }
    @keyframes underlineSlide {
        0% {
            transform: scaleY(0);
        }
        to {
            transform: scaleX(1);
        }
    }
}
.c-nav--subnav {
    padding: 8px 0;
    height: auto;
    font-weight: 400;
}
.c-nav--subnav .c-nav__item {
    width: 50%;
    flex-shrink: 0;
}
.c-nav--subnav .c-nav__link {
    color: #000 !important;
    display: block;
    position: relative;
    padding: 12px 8px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 600;
    font-weight: 400;
}
.c-nav--subnav .c-nav__link:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ededf0;
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-out;
}
.c-nav--subnav .c-nav__link:focus,
.c-nav--subnav .c-nav__link:hover {
    outline: none;
}
.c-nav--subnav .c-nav__link:focus:after,
.c-nav--subnav .c-nav__link:hover:after {
    opacity: 1;
}
.c-nav--subnav .c-nav__link:before {
    display: none;
}
.c-nav--subnavLight .c-nav__link:focus:after {
    z-index: 1;
    border-color: #000;
}
.c-nav--subnav.c-nav--inverted .c-nav__link:focus,
.c-nav--subnav.c-nav--inverted .c-nav__link:hover {
    background-color: #676769;
}
@media (min-width: 498px) {
    .c-nav--subnav .c-nav__item {
        width: 33.33%;
    }
}
@media (min-width: 1024px) {
    .c-nav--subnav {
        padding: 0;
        font-weight: 700;
        height: 54px;
    }
    .c-nav--subnav .c-nav__item {
        width: auto;
        padding: 0;
        margin-bottom: 0;
    }
    .c-nav--subnav .c-nav__link:after {
        border-radius: 0;
        background-color: transparent;
        transition: none;
    }
    .c-nav--subnav.c-nav--inverted .c-nav__link:after {
        background-color: transparent;
    }
    .c-nav--subnav .c-nav__link:before {
        display: block;
    }
}
.c-pulse:before {
    left: 6px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    border-radius: 6px;
    -webkit-animation: livepulse 3s infinite;
    animation: livepulse 3s infinite;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    will-change: transform, opacity;
}
.c-pulse:after,
.c-pulse:before {
    content: "";
    position: absolute;
    top: 50%;
    background-color: #ff287d;
}
.c-pulse:after {
    left: 9px;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    border-radius: 3px;
}
@-webkit-keyframes livepulse {
    0% {
        opacity: 1;
        transform: matrix(0.4, 0, 0, 0.4, 0, 0);
    }
    70% {
        opacity: 0;
        transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    }
    to {
        opacity: 0;
        transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    }
}
@keyframes livepulse {
    0% {
        opacity: 1;
        transform: matrix(0.4, 0, 0, 0.4, 0, 0);
    }
    70% {
        opacity: 0;
        transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    }
    to {
        opacity: 0;
        transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    }
}
.c-posts {
    padding: 0;
}
.c-posts__item {
    display: block;
    position: relative;
    margin-bottom: 0;
    padding: 5px 0 5px 0;
}
.c-posts__ad {
    margin: 0 -16px;
}
.c-posts__inner {
    display: flex;
    position: relative;
    color: #000;
    outline: none;
    padding: 10px 0;
    flex-wrap: nowrap;
    border: none;
    border-bottom: 1px solid #ededf0;
}
.c-posts__inner:after {
    content: "";
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: 0;
    left: -8px;
    border-radius: 0;
   /* border: 2px solid #000;*/
    opacity: 0;
    pointer-events: none;
}
.c-posts__inner:focus:after {
    opacity: 1;
}
.c-posts__inner:focus,
.c-posts__inner:hover {
    background-color: initial;
    background-color: transparent;
}
.c-posts__rank {
    display: none;
}
.c-posts__media {
    flex-shrink: 0;
    align-self: flex-start;
    width: 100px;
    border-bottom: none;
    overflow: hidden;
    position: relative;
    background: #ebedef;
}
.c-posts__media:before {
    content: "";
    display: block;
    padding: 0 0 56.25%;
    width: 100%;
    height: 0;
}
.c-posts__media > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* width: auto; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: none;
}
.c-posts__overlay {
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent 70%, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 0.7));
}
.c-posts__icon {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e41b23;
    width: auto;
    height: auto;
    padding: 5px;
    z-index: 50;
}
.c-posts__icon--live {
    background-color: #ffdb46;
    width: auto;
    white-space: nowrap;
}
.c-posts__icon--embedVideo:after,
.c-posts__icon--live:after {
    /*content: "LIVE";*/
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    padding: 0 6px 0 2px;
    color: #fff;
}
.c-posts__icon--live:after {
    color: #0d1321;
}

.c-posts__icon .c-posts__play {
    width: 14px;
    height: 14px;
}
.c-posts__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 16px;
    flex: 1 1 auto;
    color: #000;
    border-bottom: none;
    width: 100%;
}
.c-posts__details:focus,
.c-posts__details:hover {
    background-color: transparent;
}
.c-posts__headline {
    border-bottom: none;
}
.c-posts__headline:focus,
.c-posts__headline:hover {
    background-color: transparent;
}
.c-posts__flag {
    display: inline-block;
    text-transform: uppercase;
    background-color: #ff287d;
    color: #fff;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    padding: 4px 8px;
}
.c-posts__media .c-posts__flag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 16px;
    z-index: 100;
}
.c-posts__headlineText {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    letter-spacing: 0;
    font-weight: 600;
    display: block;
    color: #000;
}
.c-posts__headlineFlag {
    background-color: rgba(228, 27, 35, 0.12);
    color: #cd1820;
    position: relative;
    margin-bottom: 8px;
}
.c-posts__headlineFlag--live {
    background-color: #ffdb46;
    color: #0d1321;
    padding-left: 24px;
}
.c-posts__headlineFlag--live:after,
.c-posts__headlineFlag--live:before {
    background-color: #0d1321;
}
.c-posts__about {
    display: block;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    margin-top: 14px;
}
.c-posts__about--multiple .c-posts__info:not(:first-child):not(:empty):before {
    content: "|";
    display: inline-block;
    padding: 0 6px 0 3px;
}
.c-posts__info {
    color: #4d7192;
    display: inline-block;
}
.c-posts__info--highlight {
    color: #ff287d;
}
.c-posts--video .c-posts__media {
    position: relative;
}
.c-posts--video .c-posts__media:before {
    content: "";
    display: block;
    padding: 0 0 56.25%;
    width: 100%;
    height: 0;
}
.c-posts--video .c-posts__media > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
}
.c-posts__item--playing .c-posts__media {
    background-color: #000;
}
.c-posts__item--playing .c-posts__media:after {
    content: "NOW PLAYING";
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 4px;
    border: 2px solid #ff287d;
    color: #fff;
    font-size: 0.75rem;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    width: auto;
    height: auto;
    opacity: 1;
    background: transparent;
}
.c-posts__item--playing .c-posts__thumbnail {
    opacity: 0.5;
}
.c-posts__item--playing .c-posts__icon {
    display: none;
}
.c-posts--skeleton .c-posts__headline,
.c-posts--skeleton .c-posts__info,
.c-posts--skeleton .c-posts__media {
    background-color: #b5b5b8;
}
.c-posts--skeleton .c-posts__headline {
    width: 100%;
}
.c-posts--inverted .c-posts__inner:after {
    border-color: #fff;
}
.c-posts--inverted .c-posts__headlineText {
    color: #fff;
}
.c-posts--inverted .c-posts__info {
    font-weight: 600;
}
.c-posts--maxWidth .c-posts__thumbnail {
    width: 100% !important;
    height: auto !important;
}
.c-posts--anchored .c-posts__item:first-child .c-posts__inner {
    padding-top: 0;
}
.c-posts--anchored .c-posts__item:first-child .c-posts__inner:after {
    top: -8px;
}
.c-posts--noPadding .c-posts__item {
    padding: 0 !important;
}
.c-posts--infoFlipped .c-posts__headline {
    margin-top: 4px;
    margin-bottom: 0;
    font-weight: 600;
}
.c-posts__video {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 100;
}
.c-posts__item--active .c-posts__flag,
.c-posts__item--active .c-posts__icon {
    display: none !important;
}
.c-posts__item--active .c-posts__video {
    opacity: 1;
}
.c-posts__item--active .c-posts__media--video {
    background: #000;
}
.c-posts__item--active .c-posts__thumbnail {
    opacity: 0;
}
.c-posts__item--active .c-imageContainer__bg {
    display: none;
}
.c-posts__media--video .c-posts__icon {
    z-index: 100;
}
.c-posts__ad .c-posts__inner:focus .c-posts__media:after,
.c-posts__ad .c-posts__inner:hover .c-posts__media:after {
    display: none;
}
@media (min-width: 498px) {
    .c-posts__ad {
        margin: 0;
    }
    .c-posts__media .c-posts__flag {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 8px 16px;
    }
    .c-posts__about {
        margin-top: 16px;
    }
}
@media (min-width: 768px) {
    .c-posts__media:after {
        content: "";
        opacity: 0;
        background: #0052cc;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        z-index: 1;
        transition: opacity 0.2s ease-out;
    }
    .c-posts__inner:focus .c-posts__media:not(.c-posts__media--video):after,
    .c-posts__inner:hover .c-posts__media:not(.c-posts__media--video):after {
        opacity: 0.4;
    }
    .c-posts__inner:focus .c-posts__headlineText,
    .c-posts__inner:hover .c-posts__headlineText {
        text-decoration: underline;
    }
    .c-posts__item--playing .c-posts__inner:focus .c-posts__media:after,
    .c-posts__item--playing .c-posts__inner:hover .c-posts__media:after {
        opacity: 1;
    }
    .c-posts__item--playing .c-posts__inner:focus .c-posts__headlineText,
    .c-posts__item--playing .c-posts__inner:hover .c-posts__headlineText {
        text-decoration: none;
    }
    .c-posts--imageOnly .c-posts__details {
        margin: 0 !important;
        padding: 0 !important;
    }
    .c-posts--imageOnly .c-posts__about,
    .c-posts--imageOnly .c-posts__excerpt,
    .c-posts--imageOnly .c-posts__headline {
        display: none !important;
    }
    .c-posts--imageOnly .c-posts__inner:focus .c-posts__media:after,
    .c-posts--imageOnly .c-posts__inner:hover .c-posts__media:after {
        display: none;
    }
}
@media (min-width: 1024px) {
    .c-posts--narrow .c-posts__media {
        width: 80px !important;
    }
    .c-posts--narrow .c-posts__media:before {
        padding: 0 0 100%;
    }
}
/* .c-posts--highlightFirst .c-posts__item:first-child .c-posts__icon  */
.c-posts--highlightFirst .c-posts__item .c-posts__icon 
{
    margin: 16px;
    border-radius: 3px;
}
/* .c-posts--highlightFirst .c-posts__item:first-child .c-posts__icon .c-posts__play  */
.c-posts--highlightFirst .c-posts__item .c-posts__icon .c-posts__play 
{
    height: 22px;
    width: 22px;
}
/* .c-posts--highlightFirst .c-posts__item:first-child .c-posts__inner  */
.c-posts--highlightFirst .c-posts__item .c-posts__inner {
    flex-direction: column;
    padding: 0;
}
.c-posts--highlightFirst .c-posts__item .c-posts__media {
    width: 100%;
}
.c-posts--highlightFirst .c-posts__item .c-posts__details {
    padding: 16px 0;
}
.c-posts--highlightFirst .c-posts__item .c-posts__headlineText {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    letter-spacing: 0.01563rem;
}
.c-posts--highlightFirst .c-posts__item .c-posts__about {
    margin-top: 16px;
}
.c-posts--highlightFirst .c-posts__item--active .c-posts__media--video {
    overflow: visible;
}
.c-posts--highlightFirst .c-posts__item--active .c-posts__media--video .c-posts__thumbnail {
    display: none;
}
@media (min-width: 498px) {
    .c-posts--highlightFirst .c-posts__item .c-posts__headlineText {
        font-size: 1.75rem;
        line-height: 2rem;
        letter-spacing: 0.01563rem;
        font-weight: 700;
    }
}
@media (min-width: 768px) {
    .c-posts--highlightFirst .c-posts__item .c-posts__icon--embedVideo,
    .c-posts--highlightFirst .c-posts__item .c-posts__icon--live {
        padding: 5px 7px;
    }
    .c-posts--highlightFirst .c-posts__item .c-posts__icon--embedVideo:after,
    .c-posts--highlightFirst .c-posts__item .c-posts__icon--live:after {
        font-size: 0.75rem;
        letter-spacing: 0.03125rem;
        line-height: 1rem;
        padding: 0 6px;
    }
    .c-posts--highlightFirst .c-posts__item .c-posts__play {
        height: 28px;
        width: 28px;
    }
}
@media (min-width: 1312px) {
    .c-posts--highlightFirst .c-posts__item .c-posts__headlineText {
        font-size: 2.125rem;
        line-height: 2.5rem;
        font-weight: 800;
    }
}
.c-posts--tile .c-posts__inner {
    padding: 16px 0;
    border-bottom: 1px solid #ededf0;
    flex-direction: row-reverse;
}
.c-posts--tile .c-posts__inner:after {
    top: 0;
}
.c-posts--tile .c-posts__details {
    padding: 0;
    margin: 0 10px 0 0;
    width: 100%;
}
.c-posts--tile .c-posts__label {
    color: #e41b23;
}
.c-posts--tile .c-posts__label:before {
    content: "|";
    padding: 0 2px 0 6px;
    color: #808083;
}
@media (min-width: 498px) {
    .c-posts--tile .c-posts__headlineText {
        font-size: 1.0625rem;
        line-height: 1.3125rem;
        letter-spacing: 0;
        font-weight: 600;
    }
    .c-posts--tile .c-posts__details {
        order: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 0;
        margin: 0;
        width: auto;
    }
    .c-posts--tile .c-posts__inner {
        padding: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100%;
    }
    .c-posts--tile .c-posts__inner:after {
        top: -8px;
    }
    .c-posts--tile .c-posts__media {
        display: block;
        width: 100%;
        max-width: none;
        order: 1;
    }
}
@media (min-width: 768px) {
    .c-posts--tile .c-posts__icon {
        margin: 16px;
        border-radius: 3px;
    }
    .c-posts--tile .c-posts__play {
        height: 22px;
        width: 22px;
    }
}
.c-posts--grid .c-posts__item[data-ad-fallback="true"] {
    display: none;
}
@media (min-width: 498px) {
    /* .c-posts--grid {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
    } */
    .c-posts--grid .c-posts__item {
        display: inline-block;
        width: 33.33%;
        flex-shrink: 0;
        padding: 8px;
    }
    .c-posts--grid .c-posts__ad {
        padding: 0 0 8px;
    }
    .c-posts--grid .c-posts__item[data-ad-fallback="true"] {
        display: block;
    }
    .c-posts--grid.c-posts--compact .c-posts__item:nth-child(n + 4) {
        padding-top: 0;
    }
    .c-posts--grid.c-posts--compact .c-posts__item:nth-child(n + 4) .c-posts__media {
        display: none;
    }
    .c-posts--grid.c-posts--compact[data-ad-num-collapsed] .c-posts__item:nth-child(4) {
        padding-top: 8px;
    }
    .c-posts--grid.c-posts--compact[data-ad-num-collapsed] .c-posts__item:nth-child(4) .c-posts__media {
        display: block;
    }
}
@media (min-width: 1312px) {
    .c-posts--grid {
        margin: -16px;
    }
    .c-posts--grid .c-posts__item {
        padding: 16px;
    }
    .c-posts--grid .c-posts__ad {
        padding: 8px 8px 16px;
    }
    .c-posts--grid.c-posts--compact .c-posts__item:nth-child(n + 4) {
        margin-top: -16px;
    }
    .c-posts--grid.c-posts--compact[data-ad-num-collapsed] .c-posts__item:nth-child(4) {
        margin-top: 0;
        padding-top: 16px;
    }
}
.c-posts--gridMosaic .c-posts__headlineLink {
    position: relative;
    display: none;
}
.c-posts--gridMosaic .c-posts__headlineLink:after {
    content: "";
    position: absolute;
    top: 0;
    right: -8px;
    bottom: 0;
    left: -8px;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.c-posts--gridMosaic .c-posts__headlineLink:focus:after {
    opacity: 1;
}
.c-posts--gridMosaic .c-posts__subhead {
    width: 100%;
}
.c-posts--gridMosaic .c-posts__item {
    padding: 0;
}
.c-posts--gridMosaic .c-posts__details .c-posts__headline {
    display: block;
}
.c-posts--gridMosaic .c-posts__excerpt {
    display: none;
}
/* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__media  */
.c-posts--gridMosaic .c-posts__item .c-posts__media {
    order: 1;
    max-width: 100%;
    width: 100%;
}
/* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__details  */
.c-posts--gridMosaic .c-posts__item .c-posts__details {
    order: 3;
    justify-content: flex-start;
    margin: 0;
}
/* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__detailsLink  */
.c-posts--gridMosaic .c-posts__item .c-posts__detailsLink {
    position: relative;
    color: #000;
    border-bottom: none;
}
/* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__detailsLink:after  */
.c-posts--gridMosaic .c-posts__item .c-posts__detailsLink:after {
    content: "";
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
/* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__detailsLink:focus:after  */
.c-posts--gridMosaic .c-posts__item .c-posts__detailsLink:focus:after {
    opacity: 1;
}
/* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__detailsLink:focus,
.c-posts--gridMosaic .c-posts__item:first-child .c-posts__detailsLink:hover  */
.c-posts--gridMosaic .c-posts__item .c-posts__detailsLink:focus,
.c-posts--gridMosaic .c-posts__item .c-posts__detailsLink:hover {
    background-color: transparent;
}


.c-posts--gridMosaic .c-posts__inner:after {
    top: 0;
}
@media (min-width: 498px) {
    .c-posts--gridMosaic .c-posts__item {
        padding: 8px;
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__excerpt  */
    .c-posts--gridMosaic .c-posts__item .c-posts__excerpt  {
        font-size: 1.125rem;
        line-height: 1.34em;
        margin: 10px 0 0px;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    /* heeeeeeeeere .c-posts--gridMosaic .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp  */
    .c-posts--gridMosaic .c-posts__item .c-posts__excerpt > .u-lineClamp  {
        max-height: 3em;
        height: 3em;
        position: relative;
        overflow: hidden;
    }
    @supports (-webkit-overflow-scrolling: touch) {
        .c-posts--gridMosaic .c-posts__item .c-posts__excerpt > .u-lineClamp  {
            max-height: 2.7em !important; /* Safari-specific max-height */
        }
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp:after  */
    .c-posts--gridMosaic .c-posts__item .c-posts__excerpt > .u-lineClamp:after 
    {
        content: "";
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: 1.5em;
        width: 20%;
        min-width: 3em;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 50%);
    }
    @supports (-webkit-line-clamp: 1) {
        /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp:after  */
        .c-posts--gridMosaic .c-posts__item .c-posts__excerpt > .u-lineClamp:after 
        {
            display: none;
        }
        /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp  */
        .c-posts--gridMosaic .c-posts__item .c-posts__excerpt > .u-lineClamp 
        {
            height: auto;
        }
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__about  */
    .c-posts--gridMosaic .c-posts__item .c-posts__about 
    {
        margin-top: 0;
    }
    .c-posts--gridMosaic .c-posts__item {
        margin-bottom: 0;
        width: 100%;
    }
    /* .c-posts--gridMosaic .c-posts__item:nth-child(2),
    .c-posts--gridMosaic .c-posts__item:nth-child(3) {
        width: 50%;
    } */
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__headlineLink  */
    .c-posts--gridMosaic .c-posts__item .c-posts__headlineLink 
    {
        display: block;
        margin: 0 0 16px;
        border-bottom: none;
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__headlineLink:focus,
    .c-posts--gridMosaic .c-posts__item:first-child .c-posts__headlineLink:hover  */
    .c-posts--gridMosaic .c-posts__item .c-posts__headlineLink:focus,
    .c-posts--gridMosaic .c-posts__item .c-posts__headlineLink:hover {
        background: none;
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__details,
    .c-posts--gridMosaic .c-posts__item:first-child .c-posts__media  */
    .c-posts--gridMosaic .c-posts__item .c-posts__details,
    .c-posts--gridMosaic .c-posts__item .c-posts__media {
        margin: 0;
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child .c-posts__details .c-posts__headline  */
    .c-posts--gridMosaic .c-posts__item .c-posts__details .c-posts__headline 
    {
        display: none;
        margin: 0;
    }
    .c-posts--gridMosaic .c-posts__inner:after {
        top: -8px;
    }
}
@media (min-width: 768px) {
    .c-posts--gridMosaic {
        display: -ms-grid;
        display: grid;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(9, 1fr);
        margin: 0;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        -ms-grid-rows: auto 16px auto;
        grid-template-areas: "S1 S1 S1 S1 S1 S1 S2 S2 S2" "S1 S1 S1 S1 S1 S1 S3 S3 S3";
    }
    .c-posts--gridMosaic .c-posts__item {
        position: relative;
        width: 100%;
        padding: 0;
    }
    .c-posts--gridMosaic .c-posts__inner {
        margin: 0;
    }
    .c-posts--gridMosaic .c-posts__item {
        grid-area: S1;
    }
    /* .c-posts--gridMosaic .c-posts__item:nth-child(2) {
        grid-area: S2;
        width: 100%;
    } */
    /* .c-posts--gridMosaic .c-posts__item:nth-child(3) {
        grid-area: S3;
        width: 100%;
    } */
    /* .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--embedVideo .c-posts__inner:focus .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--embedVideo .c-posts__inner:hover .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--live .c-posts__inner:focus .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--live .c-posts__inner:hover .c-posts__headlineText  */
    
    .c-posts--gridMosaic .c-posts__item .c-posts__item--embedVideo .c-posts__inner:focus .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item .c-posts__item--embedVideo .c-posts__inner:hover .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item .c-posts__item--live .c-posts__inner:focus .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item .c-posts__item--live .c-posts__inner:hover .c-posts__headlineText {
        text-decoration: none;
    }
    /* .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--embedVideo .c-posts__headlineLink:focus .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--embedVideo .c-posts__headlineLink:hover .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--live .c-posts__headlineLink:focus .c-posts__headlineText,
    .c-posts--gridMosaic .c-posts__item:first-child.c-posts__item--live .c-posts__headlineLink:hover .c-posts__headlineText 
     */
     .c-posts--gridMosaic .c-posts__item .c-posts__item--embedVideo .c-posts__headlineLink:focus .c-posts__headlineText,
     .c-posts--gridMosaic .c-posts__item .c-posts__item--embedVideo .c-posts__headlineLink:hover .c-posts__headlineText,
     .c-posts--gridMosaic .c-posts__item .c-posts__item--live .c-posts__headlineLink:focus .c-posts__headlineText,
     .c-posts--gridMosaic .c-posts__item .c-posts__item--live .c-posts__headlineLink:hover .c-posts__headlineText 
     {
        text-decoration: underline;
    }
}
@media (min-width: 768px) {
    .c-posts--gridMosaic .c-posts__item {
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11;
        
    }
    /* .c-posts--gridMosaic .c-posts__item:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 13;
        -ms-grid-column-span: 5;
    } */
    /* .c-posts--gridMosaic .c-posts__item:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 13;
        -ms-grid-column-span: 5;
    } */
}
@media (min-width: 1312px) {
    .c-posts--gridMosaic {
        grid-column-gap: 32px;
        grid-row-gap: 16px;
    }
}
.c-posts--gridFeatured .c-posts__item {
    margin-top: 0;
    padding-bottom: 8px;
}
.c-posts--gridFeatured .c-posts__item:first-child .c-posts__inner {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 0;
}
.c-posts--gridFeatured .c-posts__item:first-child .c-posts__details {
    position: relative;
    padding-top: 16px;
    order: 2;
    width: 100%;
    margin-right: 0;
}
.c-posts--gridFeatured .c-posts__item:first-child .c-posts__details:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.c-posts--gridFeatured .c-posts__item:first-child .c-posts__details:focus:after {
    opacity: 1;
}
.c-posts--gridFeatured .c-posts__item:first-child .c-posts__details:after {
    right: -8px;
    left: -8px;
}
.c-posts--gridFeatured .c-posts__item:first-child .c-posts__media {
    width: 100%;
    max-width: 100%;
    order: 1;
}
.c-posts--gridFeatured .c-posts__excerpt {
    display: none;
}
.c-posts--gridFeatured .c-posts__ad:not(.c-posts__item) {
    margin: 24px 0 0;
    width: 100%;
}
.c-posts--gridFeaturedSkeleton {
    margin-top: 24px;
}
@media (min-width: 498px) {
    .c-posts--gridFeatured .c-posts__item {
        width: 100%;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__inner {
        flex-wrap: nowrap;
        border-bottom: none;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__inner:hover:after {
        top: 4px;
        height: calc(100% - 4px);
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__media {
        width: 54%;
        max-width: 54%;
        margin-right: 16px;
        order: 1;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__details {
        display: block;
        width: 100%;
        min-width: none;
        max-width: none;
        padding-left: 0;
        order: 2;
        border-bottom: 1px solid #ededf0;
    }
    .c-posts--gridFeatured .c-posts__item:nth-of-type(3n + 1) {
        margin-right: 0;
    }
    .c-posts--gridFeatured .c-posts__ad:not(.c-posts__item) {
        margin: 24px 0;
    }
}
@media (min-width: 768px) {
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt {
        margin: 16px 0;
        font-size: 1.125rem;
        line-height: 1.5rem;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp {
        max-height: 4.05em;
        height: 4.05em;
        position: relative;
        overflow: hidden;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp:after {
        content: "";
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: 1.5em;
        width: 20%;
        min-width: 3em;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 50%);
    }
    @supports (-webkit-line-clamp: 1) {
        .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp:after {
            display: none;
        }
        .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp {
            height: auto;
        }
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__inner {
        flex-direction: row;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__details {
        padding-top: 0;
    }
}
@media (min-width: 1312px) {
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__headlineText {
        font-size: 1.75rem;
        line-height: 2rem;
        letter-spacing: 0.01563rem;
        font-weight: 700;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt {
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp {
        max-height: 5.4em;
        height: 5.4em;
        position: relative;
        overflow: hidden;
    }
    .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp:after {
        content: "";
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: 1.5em;
        width: 20%;
        min-width: 3em;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 50%);
    }
    @supports (-webkit-line-clamp: 1) {
        .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp:after {
            display: none;
        }
        .c-posts--gridFeatured .c-posts__item:first-child .c-posts__excerpt > .u-lineClamp {
            height: auto;
        }
    }
}
.c-posts--text {
    list-style: none;
}
.c-posts--text .c-posts__media {
    display: none;
}
.c-posts--text .c-posts__details {
    padding-left: 0;
}
.c-posts--text .c-posts__inner {
    padding: 16px 0;
    border-bottom: 1px solid #ededf0;
}
.c-posts--text .c-posts__headlineText {
    font-weight: 400;
    margin-bottom: 0;
}
.c-posts--text .c-posts__about {
    margin-top: 16px;
    display: none;
}
.c-posts--text .c-posts__item--current .c-posts__headlineText {
    font-weight: 700;
}
@media (min-width: 498px) {
    .c-posts--textGrid {
        display: flex;
        margin: -16px;
    }
    .c-posts--textGrid .c-posts__item {
        display: inline-block;
        width: 33.33%;
        margin: 16px;
    }
    .c-posts--textGrid .c-posts__inner {
        padding: 16px 0;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    .c-posts--textGrid .c-posts__headlineText {
        font-weight: 700;
    }
    .c-posts--textGrid .c-posts__details {
        flex: none;
        display: block;
    }
    .c-posts--textGridMobile .c-posts__item {
        padding: 0;
    }
    .c-posts--textGridMobilePartial {
        display: none;
    }
}
.c-posts--textGridMobile .c-posts__item--featured .c-posts__headlineText {
    font-weight: 700;
}
@media (min-width: 498px) {
    .c-posts--textGrid .c-posts__headlineText {
        font-size: 1.0625rem;
        line-height: 1.3125rem;
        letter-spacing: 0;
        font-weight: 600;
    }
}
@media (min-width: 1312px) {
    .c-posts--textGridMobile {
        margin: 0;
        display: block;
    }
    .c-posts--textGridMobile .c-posts__item {
        padding: 0;
        width: 100%;
        margin: 0;
    }
    .c-posts--textGridMobile .c-posts__details {
        padding-left: 0;
        display: none;
    }
    .c-posts--textGridMobile .c-posts__inner {
        display: block;
        padding: 16px 0;
        border-bottom: 1px solid #ededf0;
    }
    .c-posts--textGridMobile .c-posts__item:first-child .c-posts__inner {
        padding: 0 0 16px;
    }
    .c-posts--textGridMobile .c-posts__headlineText {
        margin-bottom: 0;
        font-weight: 400;
    }
    .c-posts--textGridMobile .c-posts__item--featured .c-posts__headlineText {
        font-size: 1.625rem;
        font-weight: 800;
        line-height: 1.875rem;
        margin: 0 0 16px;
    }
    .c-posts--textGridMobile .c-posts__item--featured .c-posts__details,
    .c-posts--textGridMobilePartial {
        display: block;
    }
}
.c-schedule__show {
    display: inline-block;
    position: relative;
    width: 250px;
    height: 80px;
    flex-shrink: 0;
    background-color: hsla(0, 0%, 100%, 0.1);
    border-radius: 8px;
    color: #fff;
    -ms-grid-row-align: center;
    align-self: center;
}
.c-schedule__show--clickable:hover {
    background-color: hsla(0, 0%, 100%, 0.2);
}
.c-schedule__show--current {
    height: 100px;
    width: 100%;
    background-color: transparent;
}
.c-schedule__show--current:hover {
    background-color: initial;
}
.c-schedule__flag {
    display: block;
    color: #e73239;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;
    letter-spacing: 0.035rem;
    text-transform: uppercase;
}
.c-schedule__link {
    display: flex;
    border: 0;
    position: relative;
}
.c-schedule__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #fff;
    opacity: 0;
    pointer-events: none;
}
.c-schedule__link:focus:after {
    opacity: 1;
}
.c-schedule__link:focus,
.c-schedule__link:hover {
    background-color: initial;
}
.c-schedule__link:after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
}
.c-schedule__details {
    padding: 10px;
    flex-grow: 1;
    -ms-grid-row-align: center;
    align-self: center;
}
.c-schedule__media {
    height: 80px;
    width: 80px;
    flex-shrink: 0;
    border-radius: 8px 0 0 8px;
    overflow: hidden;
    z-index: 1;
}
.c-schedule__timer {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 112px;
    height: 112px;
}
.c-schedule__outline,
.c-schedule__played {
    stroke-width: 4%;
    fill: none;
}
.c-schedule__outline {
    stroke: #808083;
}
.c-schedule__played {
    stroke: #e41b23;
    stroke-dasharray: 314%;
    stroke-dashoffset: 314%;
    transform: rotate(58deg);
    transform-origin: center;
    -webkit-animation: animateShowTimer 0.8s ease-out 0s 1 forwards;
    animation: animateShowTimer 0.8s ease-out 0s 1 forwards;
}
.c-schedule__name {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    white-space: normal;
    margin-bottom: 4px;
    height: 40px;
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.c-schedule__name > .u-lineClamp {
    max-height: 2.7em;
    height: 2.7em;
    position: relative;
    overflow: hidden;
}
.c-schedule__name > .u-lineClamp:after {
    content: "";
    display: block;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 1.5em;
    width: 20%;
    min-width: 3em;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 50%);
}
@supports (-webkit-line-clamp: 1) {
    .c-schedule__name > .u-lineClamp:after {
        display: none;
    }
    .c-schedule__name > .u-lineClamp {
        height: auto;
    }
}
.c-schedule__time {
    display: block;
    color: #808083;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.035rem;
    line-height: 0.875rem;
}
.c-schedule__show--current .c-schedule__flag {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.75rem;
    letter-spacing: 0.035rem;
    margin-bottom: 4px;
}
.c-schedule__show--current .c-schedule__name {
    font-size: 1.375rem;
    line-height: 1.625rem;
    font-weight: 700;
    letter-spacing: 0.02125rem;
    margin-bottom: 4px;
    height: 50px;
}
.c-schedule__show--current .c-schedule__time {
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: 0.04063rem;
}
.c-schedule__show--current .c-schedule__media {
    border-radius: 50%;
    background-color: #fff;
    width: 100px;
    height: 100px;
}
.c-schedule__show--current .c-schedule__image {
    border-radius: 50%;
}
.c-schedule__show--current .c-schedule__details {
    padding: 10px 16px;
}
.c-schedule__show--flagged {
    border-top-right-radius: 0;
}
.c-schedule__show--flagged .c-schedule__flag {
    background: #000;
    position: absolute;
    top: -18px;
    right: 0;
    padding: 2px 8px;
    border-radius: 8px 8px 0 0;
}
.c-schedule--horizontal {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-flow: row;
}
.c-schedule--horizontal .c-schedule__show {
    margin: 0 16px 0 0;
}
@media (min-width: 498px) {
    .c-schedule__show {
        width: 298px;
        height: 100px;
    }
    .c-schedule__details {
        padding: 16px;
    }
    .c-schedule__media {
        height: 100px;
        width: 100px;
    }
    .c-schedule__timer {
        top: -8px;
        left: -8px;
        width: 146px;
        height: 146px;
    }
    .c-schedule__name {
        font-size: 1.125rem;
        line-height: 1.375rem;
        height: 46px;
    }
    .c-schedule__time {
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.035rem;
        line-height: 0.875rem;
        color: #808083;
    }
    .c-schedule__show--current {
        height: 130px;
        max-width: 500px;
        width: auto;
    }
    .c-schedule__show--current .c-schedule__flag {
        font-size: 0.875rem;
        line-height: 0.875rem;
        letter-spacing: 0.04063rem;
    }
    .c-schedule__show--current .c-schedule__name {
        font-size: 1.625rem;
        line-height: 1.875rem;
        letter-spacing: 0.025rem;
        height: 60px;
    }
    .c-schedule__show--current .c-schedule__time {
        font-size: 0.875rem;
        line-height: 0.875rem;
        letter-spacing: 0.04063rem;
    }
    .c-schedule__show--current .c-schedule__media {
        width: 130px;
        height: 130px;
    }
    .c-schedule--singular .c-schedule__show--current {
        max-width: none;
    }
}
@-webkit-keyframes animateShowTimer {
    0% {
        stroke-dashoffset: 314%;
    }
    to {
        stroke-dashoffset: calc(266% * (1 - var(--showPlayed)) + 48%);
    }
}
@keyframes animateShowTimer {
    0% {
        stroke-dashoffset: 314%;
    }
    to {
        stroke-dashoffset: calc(266% * (1 - var(--showPlayed)) + 48%);
    }
}
.c-stickyRail {
   /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png); */
    position: relative;
    padding-bottom: 8px;
}
.c-stickyRail--noBackground {
    background: none;
}
.c-stickyRail__boundry {
    position: absolute;
    height: 1px;
    width: 100%;
}
.c-stickyRail__widget {
    padding: 0;
}
.c-stickyRail--native .c-stickyRail__widget {
    position: -webkit-sticky;
    position: sticky;
    top: 54px;
    padding-bottom: 10px;
}
.c-stickyRail[data-sticky-rail-grow="true"] {
    margin-bottom: 0;
}
@media (min-width: 1024px) {
    .c-stickyRail {
        height: 1200px;
    }
    .c-stickyRail--long {
        height: 2800px;
    }
    .c-stickyRail--full {
        height: 100%;
    }
    .c-stickyRail--auto {
        height: auto;
        padding-bottom: 8px;
    }
    .c-stickyRail--offset {
        height: calc(100% - var(--offset));
    }
    .c-stickyRail[data-display="sticky"] .c-stickyRail__widget {
        position: fixed;
        top: 54px;
        margin-top: 12px;
        background: none;
        height: auto;
    }
    .c-stickyRail[data-display="anchored"] .c-stickyRail__widget {
        position: absolute;
        bottom: 10px;
    }
}
.c-ticker {
    margin: 0 0 32px;
}
.c-ticker__main {
    background: #ededf0;
    position: relative;
}
.c-ticker__inner {
    display: flex;
    justify-content: flex-end;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 8px;
    width: 100%;
}
.c-ticker__footer {
    background: #fff;
    display: flex;
    align-items: flex-end;
}
.c-ticker__footer .c-ticker__inner {
    padding: 0 16px;
}
.c-ticker__embed {
    width: 100%;
}
.c-ticker__loader {
    width: 40px;
    height: 40px;
    z-index: 1;
}
[data-loaded="true"] .c-ticker__loader {
    display: none;
}
@media (min-width: 768px) {
    .c-ticker__inner {
        padding: 0 16px;
    }
}
@media (min-width: 1312px) {
    .c-ticker__footer .c-ticker__inner,
    .c-ticker__inner {
        padding: 0;
    }
}
.c-topLabel {
    display: flex;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1rem;
}
.c-topLabel__link,
.c-topLabel__text {
    color: #ff287d;
    font-family: 'newstar';
    letter-spacing: 1px;
}
.c-topLabel__link:hover{
    color: #ff287d;
    text-decoration: none;
    letter-spacing: 1px;
}
.c-topLabel__text {
    margin-bottom: 2px;
}
.c-topLabel__link {
    position: relative;
    outline: none;
    border: none;
}
.c-topLabel__link:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.c-topLabel__link:hover:before {
    opacity: 1;
}
.c-topLabel__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.c-topLabel__link:focus:after {
    opacity: 1;
}
.c-topLabel__link:focus,
.c-topLabel__link:hover {
    background-color: initial;
}
.c-topLabel__pulse {
    position: relative;
    width: 14px;
    margin-right: 8px;
}
.c-topLabel__pulse:before {
    left: 0;
}
.c-topLabel__pulse:after {
    left: 3px;
}
.c-video {
    display: block;
    position: relative;
    width: 100%;
    border: none;
}
.c-video__inner {
    position: relative;
    overflow: hidden;
}
.c-video__inner:before {
    content: "";
    display: block;
    padding: 0 0 56.25%;
    width: 100%;
    height: 0;
}
.c-video__inner > span:first-of-type {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
}
.c-video__placeholder {
    display: block;
    position: relative;
    overflow: hidden;
}
.c-video__image {
    display: block;
    width: 100%;
    height: 100%;
}
.c-video__overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 16px 14px;
    background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.8));
}
.c-video__icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e41b23;
    border-radius: 3px;
    flex-shrink: 0;
}
.c-video__play {
    width: 30px;
    height: 30px;
    margin-left: 2px;
}
.c-video__data {
    display: block;
    min-width: 0;
    margin-left: 16px;
    line-height: 2.5rem;
    flex-basis: 95%;
}
.c-video__duration {
    font-size: 0.6875rem;
    font-weight: 700;
    color: #fff;
}
.c-video__title {
    display: none;
    font-size: 1.25rem;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.c-video__embed {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.c-video__sticky {
    max-width: 1280px;
}
.c-video br {
    display: none;
}
.c-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.c-video--stuck {
    position: static;
}
.c-video--stuck .c-video__placeholder {
    display: none;
}
.c-video--stuck .c-video__inner {
    overflow: visible;
}
.c-video:not(.c-video--stuck) .c-video__sticky {
    position: static;
}
.c-video:focus,
.c-video:hover {
    background-color: none;
}
.c-video:focus .c-video__placeholder,
.c-video:hover .c-video__placeholder {
    background-color: #000;
}
.c-video--live .c-video__icon {
    width: auto;
    padding: 0 6px;
    background-color: #ffdb46;
}
.c-video--live .c-video__icon:after {
    /*content: "LIVE"; */
    padding: 0 8px 0 2px;
    font-size: 1rem;
    font-weight: 700;
    color: #0d1321;
}
.c-video--live .c-video__play {
    width: 24px;
}
.c-video--contained {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c-video--contained:not([data-displayinline-init]) .c-video__inner {
    position: relative;
}
.c-video--contained:not([data-displayinline-init]) .c-video__inner:before {
    content: "";
    display: block;
    padding: 0 0 66.66667%;
    width: 100%;
    height: 0;
}
.c-video--contained:not([data-displayinline-init]) .c-video__inner > span:first-of-type {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
}
.c-video--contained.c-video--stuck {
    transform: none;
}
.c-video--contained .c-video__image {
    opacity: 0;
}
.c-video--sendToNews .c-video__inner:before {
    padding-top: 25px;
}
.c-video--sendToNews .c-video__player {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.c-video[data-displayinline-init="true"] {
    background-color: #000;
}
@media (min-width: 498px) {
    .c-video__overlay {
        padding: 18px 20px;
        background: linear-gradient(180deg, transparent 60%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.95));
    }
    .c-video__icon {
        width: 52px;
        height: 52px;
    }
    .c-video__play {
        width: 32px;
        height: 32px;
        margin-left: 3px;
    }
    .c-video--live .c-video__play {
        width: 28px;
    }
    .c-video__data {
        line-height: normal;
    }
    .c-video__duration {
        font-size: 1rem;
        color: #b4b4b4;
    }
    .c-video__title {
        display: block;
    }
    .c-video--small .c-video__icon {
        width: 40px;
        height: 40px;
        padding: 6px;
    }
    .c-video--small .c-video__duration {
        font-size: 0.875rem;
    }
    .c-video--small .c-video__title {
        font-size: 1.0625rem;
    }
    .c-video--live .c-video__icon {
        width: auto;
    }
}
@media (min-width: 1312px) {
    .c-video__sticky {
        margin: 0 auto;
    }
}
.c-videoPlay {
    cursor: pointer;
}
.c-videoPlay__icon {
    background-color: #ff287d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    pointer-events: none;
    color: #fff;
    text-transform: capitalize;
}
.c-videoPlay--live .c-videoPlay__icon,
.c-videoPlay__icon--live {
    background-color: #ffdb46;
}
.c-videoPlay__on {
    display: none;
}
.c-videoPlay:focus .c-videoPlay__icon,
.c-videoPlay:hover .c-videoPlay__icon {
    background-color: #fff;
    color: #ff287d;
}
.c-videoPlay:focus .c-videoPlay__on,
.c-videoPlay:hover .c-videoPlay__on {
    display: block;
}
.c-videoPlay:focus .c-videoPlay__off,
.c-videoPlay:hover .c-videoPlay__off {
    display: none;
}
.c-videoPlay:focus .c-videoPlay__icon:after,
.c-videoPlay:hover .c-videoPlay__icon:after {
    color: #ff287d;
}
.c-videoPlay--live:focus .c-videoPlay__icon:after,
.c-videoPlay--live:hover .c-videoPlay__icon:after {
    color: #0d1321;
}

.l-article__ad {
    padding-top: 8px;
    padding-bottom: 16px;
    margin-left: -15px;
    margin-right: -15px;
}
.l-article__label {
    margin-bottom: 8px;
}
.l-article__title {
    font-size: 1.75rem;
    line-height: 2rem;
    letter-spacing: 0.01563rem;
    font-weight: 700;
    font-weight: 800;
    padding: 0 0 16px;
    color: #000;
}
.l-article__byline,
.l-article__highlight {
    padding: 0 0 24px;
}
.l-article__featured {
    margin-bottom: 32px;
}
.l-article__highlight {
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 600;
    color: #4d7192;
}
.l-article__details {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}
.l-article__fontSizer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.l-article__text {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01563rem;
    word-break: break-word;
}
.l-article__story {
   /* max-width: 632px;*/
    margin: 0 auto;
}
.l-article__link {
    display: inline-block;
}
.l-article__blockquote {
    margin-left: 42px;
    margin-right: 42px;
    position: relative;
    overflow: visible;
}
.l-article__blockquoteContent {
    margin: 0 0 20px 24px;
}
.l-article__oembed {
    text-align: center;
}
.l-article__sectionAnchor {
    position: absolute;
    display: block;
    margin-top: -70px;
}
.l-article__sectionAnchor--bigShift,
.l-article__sectionAnchor--shift {
    margin-top: -140px;
}
.l-article__copyright {
    margin-bottom: 16px;
}
@media (min-width: 498px) {
    .l-article__ad {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) {
    .l-article__title {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 0.01563rem;
        font-weight: 800;
    }
    .l-article__sectionAnchor {
        margin-top: -100px;
    }
    .l-article__sectionAnchor--shift {
        margin-top: -180px;
    }
    .l-article__sectionAnchor--bigShift {
        margin-top: -240px;
    }
}
@media (min-width: 1024px) {
    .l-article__title {
        font-size: 2.125rem;
        line-height: 2.5rem;
    }
    .l-article__embed {
        max-width: 816px;
    }
    .l-article__embed--carousel {
        max-width: 624px;
        margin-right: auto;
        margin-left: auto;
    }
    .l-article--suppressSidebar .l-article__story {
        max-width: none;
    }
    .l-article--suppressSidebar .l-article__byline,
    .l-article--suppressSidebar .l-article__details,
    .l-article--suppressSidebar .l-article__label,
    .l-article--suppressSidebar .l-article__tags,
    .l-article--suppressSidebar .l-article__text > *,
    .l-article--suppressSidebar .l-article__title {
        max-width: 780px;
        margin-left: auto;
        margin-right: auto;
    }
    .l-article--suppressSidebar .l-article__part[data-shortcode="cp_embed"] {
        max-width: none;
    }
}
@media (min-width: 1312px) {
    .l-article__blockquote--left {
        width: 305px;
        float: left;
        margin-left: -104px;
        margin-right: 32px;
    }
    .l-article__blockquote--left .l-article__blockquoteContent {
        margin: 0 0 20px 32px;
    }
    .l-article__featured--carousel {
        max-width: 816px;
    }
}
.l-container {
    background-color: #fff;
}
.l-header {
    position: relative;
    z-index: 8000;
    padding: 0 10px 0 10px;
    color: #fff;
    background-color: #ffffff;
}
.l-header__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    /* max-width: 1280px; */
    overflow: hidden;
}
.l-header__skip {
    position: absolute;
    top: 0;
    left: -1000px;
    padding: 6px 8px;
    font-size: 0.75rem;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 100;
}
.l-header__skip:focus,
.l-header__skip:hover {
    left: 0;
    opacity: 1;
    background-color: #e41b23;
}
.l-header__trending {
    display: none;
}
.l-header__trendingInner {
    width: 100%;
}
.l-header__actions {
    flex: 1;
    order: -1;
}
.l-header__secondary {
    display: none;
}
.l-header__radioContainer {
    position: relative;
}
.l-header__radioWidget {
    display: none;
    position: absolute;
    width: 380px;
    right: 0;
    margin: 0;
    padding-top: 4px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
}
.l-header__logo {
    height: 100%;
    text-align: center;
}
.l-header__search {
    flex: 1;
    height: 20px;
    text-align: right;
}
.l-header__searchButton {
    position: relative;
    padding: 0 18px;
    outline: none;
}
.l-header__searchButton:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.l-header__searchButton:hover:before {
    opacity: 1;
}
.l-header__searchButton:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.l-header__searchButton:focus:after {
    opacity: 1;
}
.l-header__icon {
    width: 16px;
    height: 16px;
}
.l-header__choices {
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    margin-left: 8px;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: #fff;
    border-bottom: none;
}
.l-header__choices:hover{
    color: #fff;
    text-decoration: none;
}
.l-header__choices:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ff287d;
    opacity: 0;
    bottom: 0;
}
.l-header__choices:hover:before {
    opacity: 1;
    

}
.l-header__choices:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #ff287d;
    opacity: 0;
    pointer-events: none;
}
.l-header__choices:focus:after {
    opacity: 1;
}
.l-header__choices:focus,
.l-header__choices:hover {
    background-color: initial;
    background-color: transparent;
}
.l-header__choicesText {
    display: none;
}
.l-header__choicesLogo {
    width: 16px;
    height: 16px;
}
@media (min-width: 768px) {
    .l-header__inner {
        height: 33px;
    }
    .l-header__logo {
        display: none;
    }
    .l-header__trending {
        display: flex;
    }
    .l-header__actions {
        display: flex;
        /* justify-content: flex-end; */
        order: 0;
        min-width: 310px;
        /* margin-left: 48px; */
    }
    .l-header__button {
        display: flex;
        align-items: center;
    }
    .l-header__secondary {
        display: flex;
        margin-left: 24px;
    }
    .l-header__button--light:after {
        background-color: hsla(0, 0%, 100%, 0.15);
    }
    .l-header__button--light:focus:after,
    .l-header__button--light:hover:after {
        background-color: hsla(0, 0%, 100%, 0.05);
    }
    .l-header__live {
        padding-left: 22px;
    }
    .l-header__search {
        display: none;
    }
    .l-header__icon {
        width: 14px;
        height: 14px;
        margin-right: 8px;
    }
    .l-header__choices {
        height: 40px;
        min-width: 16px;
        margin-left: 24px;
        padding: 0 8px;
    }
    .l-header__choicesLogo {
        width: 12px;
        height: 12px;
        margin-left: 4px;
    }
}
@media (min-width: 1024px) {
    .l-header__choicesText {
        display: inline;
    }
}
@media (min-width: 1312px) {
    .l-header__inner {
        overflow: visible;
    }
    .l-header__radioWidget:hover {
        display: block;
    }
    .l-header__button:hover + .l-header__radioWidget {
        display: block;
        -webkit-animation: fade_in_radio 0.1s;
        animation: fade_in_radio 0.1s;
    }
    @-webkit-keyframes fade_in_radio {
        0% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fade_in_radio {
        0% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.l-headerAd__countdown {
    position: absolute;
    display: none;
    bottom: 0;
    font-size: 0.625rem;
}
.l-headerAd__close {
    cursor: pointer;
    display: none;
    font-weight: 700;
    font-size: 0.625rem;
    text-transform: uppercase;
    margin-left: 4px;
}
.l-headerAd__inner {
   /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 66px;
    background-color: #ededf0;
    padding: 15px 0;
}
.l-headerAd__container {
    width: 100%;
    z-index: 5000;
}
.l-headerAd__container--stuck {
    position: fixed;
    top: 52px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
    -webkit-animation-name: slide;
    animation-name: slide;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}
.l-headerAd__container--stuck .l-headerAd__close,
.l-headerAd__container--stuck .l-headerAd__countdown {
    display: inline-block;
}
.l-headerAd__container--detaching {
    position: fixed;
    top: 52px;
}
.l-headerAd__container--detaching .l-headerAd__inner {
    transform: translate3d(0, -100%, 0);
    -webkit-animation-name: slide;
    animation-name: slide;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    animation-direction: reverse;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}
.l-headerAd--anchored .l-headerAd__container--detaching,
.l-headerAd--anchored .l-headerAd__container--stuck {
    top: 0;
}
@media (min-width: 768px) {
    .l-headerAd__container__inner {
        padding: 20px 0;
    }
    .l-headerAd__container--detaching,
    .l-headerAd__container--stuck {
        top: 54px;
    }
    .l-headerAd__container__countdown {
        bottom: 3px;
    }
}
.l-main {
   /* margin-top: 16px;*/
   /*padding: 15px;*/
   background: #fff;
   min-height: 100vh;
}
.l-main__content {
    padding: 0 16px;
}
.l-main__sidebar {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin: 0 auto;
}
.l-main__section {
    margin-bottom: 32px;
}
.l-main__smartfeed {
    padding: 0;
}
.l-main__tagSection {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
}
.l-main__feedback {
    display: flex;
    justify-content: space-between;
    padding: 14px 0;
    border-top: 1px solid #ededf0;
    border-bottom: 1px solid #ededf0;
}
.l-main__feedbackInner {
    margin-left: -4px;
}
.l-main__feedbackLink {
    width: auto !important;
}
.l-main__feedbackLink:last-child {
    margin-bottom: 0;
}
.l-main__comments {
    border-left: 1px solid #ededf0;
    margin-left: 8px;
    padding-left: 8px;
}
.l-main__commentsButton {
    position: relative;
    padding: 6px 8px;
}
.l-main__commentsButton:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.l-main__commentsButton:focus:after {
    opacity: 1;
}
.l-main__commentsLabel {
    font-size: 0.6875rem;
    line-height: 0.875rem;
    letter-spacing: 0.03125rem;
    font-weight: 600;
    color: #1d2d44;
}
.l-main--suppressSidebar {
    margin-bottom: 32px;
}
.l-main--suppressSidebar .l-main__footer {
    padding: 0 16px;
}
.l-main--collapseTop {
    margin-top: 0;
}
.l-main {
    padding-top: 32px;
 }
@media (min-width: 498px) {
  
    .l-main--collapseTop {
        margin-top: 0;
    }
    .l-main__tagSection {
        flex-direction: row;
        justify-content: space-between;
    }
    .l-main__tags {
        margin-bottom: 0;
    }
    .l-main__feedbackInner {
        display: flex;
    }
    .l-main__feedbackLink:first-of-type {
        margin-bottom: 0;
    }
    .l-main__commentsButton {
        padding: 6px 24px;
    }
}
@media (min-width: 1024px) {
    .l-main__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "A A A A A A A A S S S S" "SP SP SP SP SP SP SP SP S S S S" "F F F F F F F F S S S S";
        -ms-grid-rows: max-content 0 auto;
        grid-template-rows: -webkit-max-content auto;
        grid-template-rows: max-content auto;
    }
    .l-main__article {
        grid-area: A;
    }
    .l-main__footer {
        grid-area: F;
    }
    .l-main__sponsor {
        grid-area: SP;
    }
    .l-main__sidebar {
        grid-area: S;
        padding: 0;
        margin: 0 16px;
        max-width: 380px;
    }
    .l-main--suppressSidebar .l-main__grid {
        grid-template-areas: ". A A A A A A A A A A ." "SP SP SP SP SP SP SP SP S S S S" "F F F F F F F F S S S S";
        -ms-grid-rows: auto max-content;
        grid-template-rows: auto -webkit-max-content;
        grid-template-rows: auto max-content;
        overflow-x: hidden;
    }
    .l-main--suppressSidebar .l-main__article {
        -ms-grid-column-span: 21 !important;
    }
    .l-main--suppressSidebar .l-main__footer {
        -ms-grid-row: 3 !important;
        -ms-grid-column-span: 15 !important;
    }
    .l-main--suppressSidebar .l-main__sidebar {
        -ms-grid-column: 17 !important;
        -ms-grid-column-span: 7 !important;
        -ms-grid-row-span: 5 !important;
    }
}
@media (min-width: 1312px) {
    .l-main__sidebar {
        margin: 0 16px 0 32px;
        width: calc(100% - 48px);
    }
    .l-main__content {
        padding: 0 16px 0 0;
    }
}
.l-overflow__arrows {
    display: none;
}
@media (min-width: 1024px) {
    .l-overflow {
        position: relative;
        overflow: hidden;
    }
    .l-overflow__list,
    .l-overflow__track {
        overflow: visible !important;
    }
    .l-overflow__list {
        display: flex;
        flex-flow: row nowrap !important;
    }
    .l-overflow--active .l-overflow__arrows {
        display: block;
    }
    .l-overflow__arrow {
        display: flex;
        position: absolute;
        top: 0;
        width: 64px;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .l-overflow__arrow--left {
        display: none;
        left: 0;
        background: linear-gradient(270deg, rgba(0, 82, 204, 0), #0052cc);
    }
    .l-overflow__arrow--right {
        right: 0;
        background: linear-gradient(90deg, rgba(0, 82, 204, 0), #0052cc);
    }
    .l-overflow__icon {
        height: 10px;
    }
    .l-overflow__icon--left:after {
        transform: rotate(-135deg) translateY(50%);
    }
    .l-overflow__icon--left:after,
    .l-overflow__icon--right:after {
        content: "";
        display: inline-block;
        height: 16px;
        width: 16px;
        border-color: #fff;
        border-style: solid;
        border-width: 5px 5px 0 0;
        background-color: transparent;
    }
    .l-overflow__icon--right:after {
        transform: rotate(45deg) translateY(-50%);
    }
    .l-overflow__icon--black:after {
        border-color: #000;
    }
    .l-overflow--scrolled .l-overflow__arrow--left {
        display: flex;
    }
    .l-overflow--anchoredLeft .l-overflow__arrow--left,
    .l-overflow--anchoredRight .l-overflow__arrow--right {
        display: none;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow {
        display: flex;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow .l-overflow__icon {
        background: #fff;
        height: 46px;
        width: 46px;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow .l-overflow__icon:after {
        border-color: #ff287d;
        position: relative;
        border-width: 4px 4px 0 0;
        width: 14px;
        height: 14px;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow--left {
        left: auto;
        right: 50px;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow:hover .l-overflow__icon {
        background: #002e5d;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow--right .l-overflow__icon:after {
        left: 8px;
        top: 16px;
    }
    .l-overflow__arrows--bundled .l-overflow__arrow--left .l-overflow__icon:after {
        left: 12px;
        top: 16px;
    }
    .l-overflow--anchoredLeft .l-overflow__arrows--bundled .l-overflow__arrow--left,
    .l-overflow--anchoredRight .l-overflow__arrows--bundled .l-overflow__arrow--right {
        opacity: 0.7;
        pointer-events: none;
    }
}
.l-panel {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 16px 16px;
    background-color: #fff;
    overflow-y: scroll;
    transform: matrix(1, 0, 0, 1, 0, -20);
    visibility: hidden;
    opacity: 0;
    scroll-behavior: smooth;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
}
.l-panel__inner {
    display: none;
}
.l-panel--init .l-panel__inner {
    display: block;
}
.l-panel__header {
    display: flex;
    justify-content: space-between;
}
.l-panel__icon {
    width: 16px;
    height: 16px;
    margin: 0 8px;
}
.l-panel__dark {
    background-color: #ededf0;
}
.l-panel--popup {
    position: relative;
    width: auto;
    overflow: auto;
    box-shadow: none;
}
.l-panel--popup .l-panel__header {
    border-bottom: 1px solid #ededf0;
    align-items: center;
}
.l-panel--popup .c-heading--primary {
    border-bottom: none;
}
.l-panel__button {
    display: block;
}
.l-panel__button--close {
    display: none;
}
.l-panel--single .l-panel__button--close {
    display: block;
}
.l-panel--single .l-panel__button--back {
    display: none;
}
.l-panel--single .l-panel__header {
    flex-direction: row-reverse;
}
@media (max-width: 767px) {
    .l-panel__firstHeading {
        display: none;
    }
    .l-panel--bottom {
        top: auto;
        bottom: 0;
        height: auto !important;
        max-height: none !important;
        padding-bottom: 64px !important;
        border-radius: 8px 8px 0 0;
        transform: matrix(1, 0, 0, 1, 0, 20);
    }
    .l-panel--bottom .l-panel__header {
        padding-bottom: 16px;
    }
}
@media (min-width: 768px) {
    .l-panel {
        position: absolute;
        top: auto;
    }
    .l-panel__header {
        display: none;
    }
    .l-panel--popup {
        position: relative;
    }
    .l-panel--popup .l-panel__header {
        display: flex;
    }
}
.l-navbar {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 6500;
}
.l-navbar__top {
    height: 48px;
    background-color: #fff;
    padding: 0 16px 0 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}
.l-navbar__logoSocial {
    display: flex;
    height: 52px;
    margin-top: 8px;
}
.l-navbar__blackIcon {
    display: none;
}
.l-navbar__inner {
    height: 100%;
    max-width: none;
    position: relative;
    overflow: hidden;
}
.l-navbar__standard {
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate3d(0, 24px, 0);
}
.l-navbar__standard,
.l-navbar__submenus {
    position: absolute;
    width: 100%;
}
.l-navbar__topics {
    width: 100%;
}
.l-navbar__list {
    display: none;
}
.l-navbar__buttons {
    background: #fff;
    height: 100%;
}
.l-navbar__toggles {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 20%;
    max-width: 60px;
    height: 100%;
}
.l-navbar__toggles--scrolling {
    width: 16.7%;
    display: flex;
}
.l-navbar__search {
    display: none;
}
.l-navbar__scrolling {
    display: flex;
    justify-content: space-between;
    position: static;
    background-color: #fff;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    visibility: visible;
    opacity: 1;
}
.l-navbar__scrollingLeft {
    display: none;
}
.l-navbar__scrollingRight {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    overflow: hidden;
}
.l-navbar__socialShare {
    width: calc(100% - 116px);
}
.l-navbar__title {
    margin-left: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.l-navbar__logo {
    flex-shrink: 0;
    display: none;
}
.l-navbar--menuOpen {
    z-index: 10000001;
}
.l-navbar--nonSticky {
    position: static;
}
.l-navbar--narrowMobile .l-navbar__top {
    transform: translate3d(0, -8px, 0);
}
.l-navbar--narrowMobile .l-navbar__mobileIcon {
    transform: translate3d(0, 3px, 0);
}
.l-navbar--narrowMobile .l-navbar__buttons .l-navbar__mobileIcon {
    transform: translate3d(0, 11px, 0);
}
.l-navbar--mobileSocial .l-navbar__standard {
    opacity: 1;
    z-index: 1;
    transform: translateZ(0);
}
.l-navbar__blueIcon--whatsapp {
    height: 28px !important;
    width: 28px !important;
}
.l-navbar__blueIcon--more {
    height: 30px !important;
    width: 30px !important;
}
@media (min-width: 768px) {
    .l-navbar__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0300b1;
        /* background: linear-gradient(90deg, rgba(5,0,177,1) 9%, rgba(255,41,125,1) 21%); */
    }
    .l-navbar__top {
        height: 79px;
        /*overflow: hidden;*/
    }
    .l-navbar__blackIcon {
        display: inline;
    }
    .l-navbar__blueIcon,
    .l-navbar__buttons,
    .l-navbar__logoSocial {
        display: none;
    }
    .l-navbar__inner {
        overflow: visible;
    }
    .l-navbar__standard {
        display: flex;
        justify-content: space-between;
        position: static;
        z-index: 1;
        line-height: 5.5rem;
        visibility: visible !important;
        opacity: 1 !important;
        transform: translateZ(0) !important;
    }
    .l-navbar__logo {
        display: flex;
        position: relative;
        width: 100px;
        /* height: 88px; */
        margin: 0 32px 0 8px;
        border: none;
    }
    .l-navbar__logo:focus,
    .l-navbar__logo:hover {
        background-color: transparent;
    }
    .l-navbar__logo:focus:before,
    .l-navbar__logo:hover:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: 2px solid #ff287d;
        opacity: 0;
        bottom: 0;
    }
    .l-navbar__logo:focus:hover:before,
    .l-navbar__logo:hover:hover:before {
        opacity: 1;
    }
    .l-navbar__logo:focus:after,
    .l-navbar__logo:hover:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        border: 2px solid #ff287d;
        opacity: 0;
        pointer-events: none;
    }
    .l-navbar__logo:focus:focus:after,
    .l-navbar__logo:hover:focus:after {
        opacity: 1;
    }
    .l-navbar__logoText {
        position: absolute;
        top: 10px;
        left: 0;
        width: 116px;
        height: 64px;
    }
    .l-navbar__morty {
        position: absolute;
        top: 12px;
        right: 0;
      /*  width: 33px;*/
        /* height: 60px; */
    }
    .l-navbar__track {
        width: 100%;
    }
    .l-navbar__list {
        display: flex;
        width: 100%;
        /* height: 88px; */
        overflow: hidden;
    }
    .l-navbar__topics {
        display: flex;
        width: 78%;
        max-width: calc(100% - 254px);
        transform: translateZ(0);
    }
    .l-navbar__scrolling {
        width: calc(100% + 40px);
        padding: 0 8px 0 0;
        line-height: 3.375rem;
    }
    .l-navbar__scrollingLeft {
        max-width: calc(82vw - 294px);
        display: flex;
        align-items: center;
    }
    .l-navbar__scrollingRight,
    .l-navbar__socialShare,
    .l-navbar__toggles--scrolling {
        width: auto;
    }
    .l-navbar__toggles {
        display: block;
        position: relative;
        width: auto;
        align-self: flex-start;
        height: 100%;
        padding-left: 22px;
        line-height: 5.5rem;
        max-width: unset;
    }
    .l-navbar__toggles:before {
        content: "";
        position: absolute;
        left: 0;
        top: 29.5%;
        height: 43%;
        opacity: 0.2;
        border-left: 1.5px solid #ededf0;
    }
    .l-navbar__search {
        display: block;
    }
    .l-navbar__title {
        margin-left: 8px;
    }
    .l-navbar__submenus {
        background: transparent;
        display: block;
        /*overflow: hidden;*/
        z-index: 5500;
       /* height: 600px;*/
    }
    .l-navbar__submenus:before {
        display: none;
        content: "";
        width: 100%;
        height: 20px;
        position: absolute;
        top: 0;
        z-index: 1000;
        box-shadow: inset 0 14px 14px -10px rgba(0, 0, 0, 0.08);
        pointer-events: none;
    }
    .l-navbar--menuOpen {
        z-index: 7500;
    }
    .l-navbar--menuOpen .l-navbar__top {
        box-shadow: none;
    }
    .l-navbar--menuOpen .l-navbar__submenus:before {
        display: block;
    }
    .l-navbar--menuOpen .l-navbar__submenus--menuComments:before {
        display: none;
    }
    .l-navbar--narrowMobile .l-navbar__mobileIcon,
    .l-navbar--narrowMobile .l-navbar__top {
        transform: none;
    }
    .l-navbar--morty .l-navbar__list {
        width: 100%;
    }
    .l-navbar__radio {
        display: block !important;
        position: absolute;
        right: 0;
        transform: translate3d(0, 34px, 0);
    }
    .l-navbar__radioBanner {
        display: none;
    }
    .l-navbar--stuck {
        transform: translate3d(0, -34px, 0);
    }
    .l-navbar--stuck .l-navbar__morty {
        transform: scale3d(0.64, 0.64, 1) translate3d(-125px, 35px, 0);
    }
    .l-navbar--stuck .l-navbar__logo {
        margin-right: 80px;
    }
    .l-navbar--stuck .l-navbar__logo:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        border: 2px solid #ff287d;
        opacity: 0;
        pointer-events: none;
    }
    .l-navbar--stuck .l-navbar__logo:focus:after {
        opacity: 1;
    }
    .l-navbar--stuck .l-navbar__logo:after {
        width: 45px;
    }
    .l-navbar--stuck .l-navbar__logo:hover:before {
        content: "";
        position: absolute;
        left: -2px;
        right: 74px;
        border-bottom: 2px solid #ff287d;
        opacity: 0;
        bottom: 0;
    }
    .l-navbar--stuck .l-navbar__logo:hover:hover:before {
        opacity: 1;
    }
    .l-navbar--stuck .l-navbar__list,
    .l-navbar--stuck .l-navbar__scrolling {
        width: calc(100% + 150px);
    }
    .l-navbar--stuck .l-navbar__topics {
        transform: translate3d(-142px, 16px, 0);
    }
    .l-navbar--stuck .l-navbar__toggles {
        transform: translate3d(0, 16px, 0);
    }
    .l-navbar--stuck .l-navbar__toggles:before {
        border-left: 1.5px solid #808083;
    }
    .l-navbar--titleAndSocial .l-navbar__track {
        transform: translate3d(0, -71px, 0);
    }
    .l-navbar--initRadio .l-navbar__radioBanner {
        display: block;
        visibility: visible;
    }
    .l-navbar--initRadio .l-navbar__radioFader {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #fff;
        transform-origin: left;
    }
    .l-navbar--initRadio .l-navbar__topics {
        margin-right: 276px;
    }
    .l-navbar--initRadio .l-navbar__toggles {
        background-color: #fff;
    }
    .l-navbar--showRadio .l-navbar__radioFader {
        transform: scaleX(0);
    }
    .l-navbar--showRadio .l-navbar__toggles {
        transform: translate3d(-276px, 16px, 0);
    }
}
@media (min-width: 1312px) {
    .l-navbar__scrollingLeft {
        max-width: 836px;
    }
}
.l-notification {
    z-index: 7000;
    overflow: hidden;
    display: none;
    top: 60px;
    position: -webkit-sticky;
    position: sticky;
    padding-bottom: 12px;
    margin-bottom: -12px;
}
.l-notification__container {
    position: relative;
    background: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}
.l-notification__inner {
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.l-notification__item {
    display: flex;
}
.l-notification__video {
    display: none;
    width: 176px;
    overflow: hidden;
    flex-shrink: 0;
    margin: 8px 16px 8px 0;
    border-bottom: none;
    position: relative;
}
.l-notification__video:before {
    content: "";
    display: block;
    padding: 0 0 56.25%;
    width: 100%;
    height: 0;
}
.l-notification__video > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
}
.l-notification__video:focus,
.l-notification__video:hover {
    border-bottom: none;
    background: none;
}
.l-notification__video video {
    width: 100%;
}
.l-notification__image {
    height: auto;
    width: 100%;
}
.l-notification__text {
    display: inline-block;
    -ms-grid-row-align: center;
    align-self: center;
    margin: 12px 0;
}
.l-notification__label {
    text-transform: uppercase;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    margin-bottom: 4px;
}
.l-notification__headline {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    letter-spacing: 0;
    font-weight: 600;
    color: inherit;
    border-bottom: none;
    position: relative;
}
.l-notification__headline:focus,
.l-notification__headline:hover {
    background-color: transparent;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.2);
}
.l-notification__button {
    display: flex;
    position: relative;
    border-radius: 2px;
    padding: 10px;
    border-bottom: 0;
}
.l-notification__icon {
    width: 11px;
    height: 11px;
}
.l-notification--active {
    display: block;
}
.l-notification--active .l-notification__wrapper {
    -webkit-animation-name: slide;
    animation-name: slide;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}
.l-notification--hiding {
    display: block;
}
.l-notification--hiding .l-notification__container {
    transform: translate3d(0, -100%, 0);
    -webkit-animation-name: slide;
    animation-name: slide;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    animation-direction: reverse;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}
.l-notification--breaking .l-notification__container {
    background: #ff287d;
    color: #fff;
}
.l-notification--special .l-notification__container {
    background: #808083;
    color: #fff;
}
.l-notification--listen .l-notification__container,
.l-notification--live .l-notification__container {
    background: #f99500;
    color: #000;
}
.l-notification--listen .l-notification__headline:focus,
.l-notification--listen .l-notification__headline:hover,
.l-notification--live .l-notification__headline:focus,
.l-notification--live .l-notification__headline:hover {
    border-color: rgba(0, 0, 0, 0.2);
}
.l-notification--shift {
    transform: translate3d(0, -8px, 0);
}
@media (min-width: 768px) {
    .l-notification {
        top: 54px;
    }
    .l-notification--shift {
        transform: none;
    }
    .l-notification__button {
        margin: 16px 16px 0 0;
    }
    .l-notification__button:focus,
    .l-notification__button:hover {
        background-color: rgba(0, 0, 0, 0.2);
        outline: none;
    }
    .l-notification__label {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .l-notification__headline {
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0;
        font-weight: 600;
    }
    .l-notification--video .l-notification__video {
        display: inline-block;
    }
}
@media (min-width: 1312px) {
    .l-notification__inner {
        padding-left: 0;
    }
    .l-notification__button {
        margin: 16px 0 0;
    }
}
.l-radioHeader {
   /* background: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/radio-header-bg.jpg) no-repeat 50% #000;*/
    background-size: cover;
    padding: 16px;
    position: relative;
    overflow: hidden;
    margin-bottom: -14px;
}
.l-radioHeader:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
}
.l-radioHeader__headingWrapper {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.6);
    padding-bottom: 16px;
    margin-bottom: 16px;
}
.l-radioHeader__headingWrapper .l-radioHeader__branding {
    display: flex;
}
.l-radioHeader__headingWrapper .l-radioHeader__heading {
    padding-bottom: 0;
    border-bottom: none;
    max-width: 120px;
    margin: 0;
    z-index: 1;
}
.l-radioHeader__headingWrapper .l-radioHeader__branding .l-radioHeader__logo {
    height: auto;
    max-height: none;
    width: 100%;
}
.l-radioHeader__headingWrapper .l-radioHeader__nav {
    border-bottom: none;
}
.l-radioHeader__headingWrapper .l-radioHeader__navList {
    display: flex;
    flex-flow: wrap;
}
.l-radioHeader__scheduleWrapper {
    margin: 16px -16px -16px 0;
}
.l-radioHeader__scheduleWrapper .l-radioHeader__schedule {
    padding: 18px 0;
}
.l-radioHeader__scheduleWrapper .l-radioHeader__show--current {
    display: none;
}
.l-radioHeader__show .l-radioHeader__play {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 64px;
    top: 62px;
    z-index: 1;
}
.l-radioHeader__show .l-radioHeader__icon {
    width: 24px;
    height: 24px;
    margin-left: 2px;
}
.l-radioHeader__navItem .l-radioHeader__link {
    color: #fff !important;
}
@media (min-width: 498px) {
    .l-radioHeader {
        padding: 24px 16px;
        margin-bottom: 0;
    }
    .l-radioHeader__headingWrapper {
        border-bottom: none;
        padding: 0;
        margin: 0;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__heading {
        padding-right: 20px;
        margin: 0 0 6px;
        max-width: none;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__branding {
        width: 22%;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navWrapper {
        width: 78%;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__expandable {
        visibility: visible;
        height: auto;
        overflow: visible;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__nav {
        border-top: 1px solid hsla(0, 0%, 100%, 0.6);
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.6);
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navList {
        padding: 0;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        flex-flow: row;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navItem {
        width: auto;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navItem .l-radioHeader__link:hover {
        background: transparent;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navItem .l-radioHeader__link:before {
        display: block;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navItem .l-radioHeader__link:hover:after {
        display: none;
    }
    .l-radioHeader__container {
        display: flex;
        width: 100%;
    }
    .l-radioHeader__scheduleWrapper {
        margin-top: 0;
    }
    .l-radioHeader__scheduleWrapper .l-radioHeader__show--current {
        padding: 0 16px;
        margin-right: 60px;
        display: block;
    }
    .l-radioHeader__show .l-radioHeader__play {
        left: 96px;
        top: 92px;
    }
}
@media (min-width: 1024px) {
    .l-radioHeader {
        padding: 0 16px;
    }
    .l-radioHeader__headingWrapper {
        padding: 32px 16px 0;
        border-bottom: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1000;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__heading {
        padding-right: 40px;
        margin: -6px 0 0;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__branding {
        width: 18%;
    }
    .l-radioHeader__headingWrapper .l-radioHeader__navWrapper {
        width: 82%;
    }
    .l-radioHeader__scheduleWrapper {
        padding: 120px 0 32px;
        margin: 0;
    }
    .l-radioHeader__scheduleWrapper:after,
    .l-radioHeader__scheduleWrapper:before {
        width: 100px;
    }
    .l-radioHeader__scheduleWrapper .l-radioHeader__schedule {
        padding: 0;
    }
    .l-radioHeader__scheduleWrapper .l-radioHeader__arrows {
        position: absolute;
        top: 186px;
        right: -10px;
    }
}
.l-section {
    position: relative;
    padding: 0 16px;
    /*margin: 64px 0;*/
    margin: 0px 0;
    overflow: hidden;
}
.l-section__grid {
    display: flex;
    flex-direction: column;
}
.l-section__sidebar {
    display: block;
    order: 3;
}
.l-section__sidebar--bottom {
    order: 7;
}
.l-section__sidebar .l-section__carousel {
    margin: 64px 0 32px;
}
.l-section__sidebar .l-section__widget {
    margin: 0 -16px;
}
.l-section__break {
    border-top: 4px solid #ff287d;
    padding-top: 28px;
}
.l-section__listenLive {
    order: 2;
    margin: 64px -16px 0;
    padding: 0;
}
.l-section__radioCarousel {
    margin: 32px auto 0;
}
.l-section__main .l-section__listenLive {
    margin-top: 32px;
}
.l-section__widget .l-section__widgetFooter {
    text-align: right;
    padding: 16px 0;
    border-bottom: 1px solid #ededf0;
}
.l-section__widget .l-section__widgetFooter--noBorder {
    border-bottom: none;
}
.l-section__widget .l-section__widgetFooter--listenLive {
    padding: 0;
    margin: 64px -16px 0;
}
.l-section__priority {
    grid-area: H;
}
.l-section__heading {
    grid-area: H;
    margin: 0 0 16px;
}
.l-section__heading--withMenu {
    display: flex;
    justify-content: space-between;
}
.l-section__headingLink {
    margin-left: 16px;
}
.l-section__carousel .l-section__heading {
    margin-bottom: 0;
}
.l-section__menu {
    display: flex;
    justify-content: space-between;
}
.l-section__menu .l-section__menuLink:not(:last-child) {
    margin-right: 32px;
}
.l-section__sponsorship {
    height: 52px;
    padding: 0 32px;
    margin: 0 -32px;
    background-color: #ededf0;
}
.l-section__main--radioCarousel {
    max-width: 100%;
}
.l-section--overflow {
    overflow: visible;
}
.l-section--anchored {
    margin-top: -24px !important;
}
.l-section--overlap {
    background-color: #ededf0;
    margin: 96px 0 64px;
    padding: 0 16px;
}
.l-section--overlap .l-section__widget {
    top: -32px;
    position: relative;
}
.l-section--overlap .l-section__heading {
    color: #fff;
    background-color: #ff287d;
    display: inline-block;
    padding: 12px;
    margin-bottom: 32px;
}
.l-section--overlap .c-posts__info--highlight {
    color: #ff287d;
}
.l-section--dark {
    background: #000;
    padding: 32px 16px;
}
.l-section--dark .l-section__widget .l-section__widgetFooter {
    border-bottom: 1px solid rgba(237, 237, 240, 0.2);
}
.l-section--dark .l-section__widget .l-section__widgetFooter--noBorder {
    border-bottom: none;
    margin-bottom: -32px;
}
.l-section--dark .l-section__heading,
.l-section--dark .l-section__main {
    color: #fff;
}
.l-section--dark .c-link {
    color: #ff287d;
}
.l-section--dark .c-link:after {
    border-color: #fff;
}
.l-section--dark .c-posts__info--highlight {
    color: #ff287d;
}
.l-section--gradient {
    background: linear-gradient(180deg, #ededf0, #fff 50%);
    padding: 24px 16px 0;
}
.l-section--pageHeader,
.l-section--withSubmenu {
    /* margin-bottom: -64px; */
}
.l-section--withSponsor {
    margin-bottom: -32px;
}
.l-section--withSubmenu .l-section__heading {
    justify-content: space-between;
}
.l-section--radioHeader {
    margin: 0 0 -32px;
    padding: 16px;
}
.l-section--archive .l-section__widget:not(:last-child) {
    margin-bottom: 32px;
}
.l-section--archive .l-section__listenLive {
    margin: 0 -16px;
}
.l-section__grid .l-section__main {
    order: 1;
}
.l-section__grid .l-section__main:empty {
    display: none;
}
.l-section__grid .l-section__main--bottom {
    order: 5;
}
.l-section--tablet {
    height: 0;
    margin-bottom: -32px;
}
.l-section--header {
    padding: 0;
    margin: 0;
}
.l-section:empty {
    display: none !important;
}
.l-section:first-of-type {
    margin-top: 0;
}
.l-section:last-of-type {
    padding-bottom: 30px;
    margin-bottom: 0;
}
@media (min-width: 498px) {
    .l-section--anchored {
        margin-top: 0 !important;
    }
    .l-section__subnav.is-expanded {
        margin-bottom: 32px;
    }
    .l-section__carousel .l-section__heading {
        margin-bottom: 16px;
    }
    .l-section__listenLive {
        margin: 64px 0 0;
    }
}
@media (min-width: 768px) {
    .l-section--dark {
        padding: 32px 16px;
    }
    .l-section--tablet {
        height: auto;
        margin-bottom: 32px;
    }
    .l-section__main--bottom {
        margin-top: 24px;
    }
}
@media (min-width: 1024px) {
    .l-section--withSubmenu {
        margin-bottom: -32px;
    }
    .l-section__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "H H H H H H H H H H H H" "H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2" "M1 M1 M1 M1 M1 M1 M1 M1 S S S S" "M2 M2 M2 M2 M2 M2 M2 M2 S S S S" "L L L L L L L L L L L L";
        -ms-grid-rows: auto 0 auto 0 max-content 0 min-content 0 auto;
        grid-template-rows: auto auto -webkit-max-content -webkit-min-content auto;
        grid-template-rows: auto auto max-content min-content auto;
    }
    .l-section__grid--wide .l-section__main {
        margin-right: 32px;
    }
    .l-section__grid .l-section__sidebar {
        grid-area: S;
        padding: 0;
    }
    .l-section__grid .l-section__listenLive {
        grid-area: L;
    }
    .l-section__main .l-section__listenLive {
        margin: 0;
    }
    .l-section__subnav {
        visibility: visible !important;
        height: auto !important;
    }
    .l-section__sidebar .l-section__widget {
        margin: 0 auto 32px;
    }
    .l-section__sidebar .l-section__widget:last-child {
        margin: 0;
    }
    .l-section__sponsorship {
        height: 52px;
        padding: 0;
        margin: 0;
        background-color: #fff;
    }
    .l-section__radioCarousel {
        margin: 32px auto 0;
        max-width: 656px;
    }
    .l-section__grid--wideSidebar .l-section__main {
        margin-right: 16px;
    }
    .l-section__grid .l-section__main {
        grid-area: M1;
    }
    .l-section__grid .l-section__main:not(:last-child) {
        margin-bottom: 0;
    }
    .l-section__grid .l-section__main--bottom {
        grid-area: M2;
    }
    .l-section--gradient .l-section__widget .l-section__widgetFooter {
        border-bottom: none;
    }
    .l-section--gradient .l-section__sidebar {
        margin-top: -8px;
    }
    .l-section--withWeather {
        overflow: visible;
    }
    .l-section--radioHeader {
        padding: 0 16px;
    }
    .l-section--archive .l-section__grid--wideSidebar .l-section__sidebar {
        margin-left: 16px;
    }
    .l-section__grid > .l-main__sidebar {
        -ms-grid-row: 5;
        -ms-grid-row-span: 3;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7;
    }
    .l-section__heading,
    .l-section__priority {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23;
    }
}
@media (min-width: 1024px) {
    .l-main__article {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
        padding: 0 16px;
    }
    .l-main--suppressSidebar .l-main__grid > .l-main__article {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 10;
    }
    .l-main__footer {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
    }
    .l-main--suppressSidebar .l-main__grid > .l-main__footer {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 8;
    }
    .l-main__sponsor {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
    }
    .l-main--suppressSidebar .l-main__grid > .l-main__sponsor {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 8;
    }
    .l-main__sidebar {
        -ms-grid-row: 1;
        -ms-grid-row-span: 5;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7;
    }
    .l-main--suppressSidebar .l-main__grid > .l-main__sidebar {
        -ms-grid-row: 2;
        -ms-grid-row-span: 2;
        -ms-grid-column: 9;
        -ms-grid-column-span: 4;
    }
    .l-section__grid .l-section__sidebar {
        -ms-grid-row: 1;
        -ms-grid-row-span: 5;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7;
    }
    .l-main--suppressSidebar .l-main__grid > .l-section__sidebar {
        -ms-grid-row: 2;
        -ms-grid-row-span: 2;
        -ms-grid-column: 9;
        -ms-grid-column-span: 4;
    }
    .l-section__grid > .l-section__sidebar {
        -ms-grid-row: 5;
        -ms-grid-row-span: 3;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7;
    }
    .l-section__grid .l-section__listenLive {
        -ms-grid-row: 9;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23;
    }
    .l-section__grid .l-section__main {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
    }
    .l-section__grid .l-section__main--bottom {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
    }
}
@media (min-width: 1312px) {
    .l-section__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "H H H H H H H H H H H H" "H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2" "M1 M1 M1 M1 M1 M1 M1 M1 M1 S S S" "M2 M2 M2 M2 M2 M2 M2 M2 M2 S S S" "L L L L L L L L L L L L";
        -ms-grid-rows: auto 0 auto 0 max-content 0 min-content 0 auto;
        grid-template-rows: auto auto -webkit-max-content -webkit-min-content auto;
        grid-template-rows: auto auto max-content min-content auto;
    }
    .l-section__grid--wideSidebar {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto;
        grid-template-areas: "H H H H H H H H H H H H" "H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2" "M1 M1 M1 M1 M1 M1 M1 M1 S S S S" "M2 M2 M2 M2 M2 M2 M2 M2 S S S S" "L L L L L L L L L L L L";
    }
    .l-section__grid--radioCarousel {
        grid-template-areas: "H H H H H H H H H H H H" "H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2 H2" "M1 M1 M1 M1 M1 M1 M1 M1 M1 S S S" "RC RC RC RC RC RC RC RC RC S S S" "M2 M2 M2 M2 M2 M2 M2 M2 M2 S S S" "L L L L L L L L L L L L";
        -ms-grid-rows: auto auto max-content max-content auto;
        grid-template-rows: auto auto -webkit-max-content -webkit-max-content auto;
        grid-template-rows: auto auto max-content max-content auto;
    }
    .l-section__grid .l-section__main {
        margin-right: 16px;
    }
    .l-section__grid--wideSidebar .l-section__main {
        margin-right: 32px;
    }
    .l-section__grid--wideSidebar .l-section__sidebar {
        margin-left: -16px;
    }
    .l-section__grid--wideSidebar .l-section__sidebar .l-section__widget--inset {
        padding-left: 16px;
        padding-right: 16px;
    }
    .l-section__radioCarousel {
        max-width: 940px;
    }
    .l-section__grid > .l-main__sidebar {
        -ms-grid-row: 5;
        -ms-grid-row-span: 3;
        -ms-grid-column: 19;
        -ms-grid-column-span: 5;
    }
    .l-section__grid--wideSidebar > .l-main__sidebar {
        -ms-grid-row: 5;
        -ms-grid-row-span: 3;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7;
    }
    .l-section__grid--radioCarousel > .l-main__sidebar {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 10;
        -ms-grid-column-span: 3;
    }
    .l-section__grid--wideSidebar > .l-section__priority,
    .l-section__grid > .l-section__priority {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23;
    }
    .l-section__grid--radioCarousel > .l-section__priority {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
    }
    .l-section__grid--wideSidebar > .l-section__heading,
    .l-section__grid > .l-section__heading {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23;
    }
    .l-section__grid--radioCarousel > .l-section__heading {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
    }
    .l-section__grid > .l-section__sidebar {
        -ms-grid-row: 5;
        -ms-grid-row-span: 3;
        -ms-grid-column: 19;
        -ms-grid-column-span: 5;
    }
    .l-section__grid--wideSidebar > .l-section__sidebar {
        -ms-grid-row: 5;
        -ms-grid-row-span: 3;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7;
    }
    .l-section__grid--radioCarousel > .l-section__sidebar {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 10;
        -ms-grid-column-span: 3;
    }
    .l-section__grid--wideSidebar > .l-section__listenLive,
    .l-section__grid > .l-section__listenLive {
        -ms-grid-row: 9;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23;
    }
    .l-section__grid--radioCarousel > .l-section__listenLive {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
    }
    .l-section__grid > .l-section__main {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 17;
    }
    .l-section__grid--wideSidebar > .l-section__main {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
    }
    .l-section__grid--radioCarousel > .l-section__main {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 9;
    }
    .l-section__grid > .l-section__main--bottom {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
        -ms-grid-column-span: 17;
    }
    .l-section__grid--wideSidebar > .l-section__main--bottom {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15;
    }
    .l-section__grid--radioCarousel > .l-section__main--bottom {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 9;
    }
}
.l-sidebar__ad {
    width: 100%;
    margin: 0;
}
.l-sidebar__widget--shiftUp {
    margin-bottom: 32px;
}
.l-sidebar__widget--sportsnet {
    margin-top: 32px;
    margin-bottom: 0;
}
.l-sidebar__widgetCaption {
    padding: 16px;
    font-size: 1rem;
    font-weight: 700;
    background-color: #ededf0;
}
.l-sidebar__widgetFooter {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    border-bottom: 1px solid #ededf0;
}
@media (min-width: 1024px) {
    .l-sidebar__widget--sportsnet {
        margin: 0;
    }
}
.l-stream__title {
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 24px;
}
.l-stream__section {
    padding-bottom: 64px;
}
.l-stream__loadMore {
    margin: 32px auto 0px;
    display: block;
    float: right;
}
.l-stream__sidebar {
   /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png);*/
    margin-bottom: 64px;
}
@media (min-width: 1024px) {
    .l-stream__loadMore {
        margin: 32px auto 0px;
        float: right;
    }
}
.l-subnav {
    border-top: 1px solid #ededf0;
    border-bottom: 1px solid #ededf0;
}
.l-subnav--framed {
    border-bottom: none;
}
.l-subnav.is-expanded {
    margin-bottom: 16px;
}
@media (min-width: 498px) {
    .l-subnav.is-expanded {
        margin-bottom: 0;
    }
}
@media (min-width: 1024px) {
    .l-subnav {
        visibility: visible !important;
        height: auto !important;
    }
}
.l-highImpact {
    margin: 0 -16px;
    position: relative;
}
.l-highImpact__inner {
    border-bottom: none;
    padding: 0;
}
.l-highImpact__inner:focus:after,
.l-highImpact__inner:hover:after {
    display: none;
}
.l-highImpact__header {
    position: absolute;
    width: 100%;
    bottom: 42px;
    line-height: 1.24em;
    letter-spacing: 0.006em;
    z-index: 1000;
    padding: 0 16px 8px;
}
.l-highImpact__headlineLink {
    border-bottom: none;
    position: relative;
}
.l-highImpact__headlineLink:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid #000;
    opacity: 0;
    bottom: 0;
}
.l-highImpact__headlineLink:hover:before {
    opacity: 1;
}
.l-highImpact__headlineLink:hover {
    background: none;
}
.l-highImpact__imageLink {
    border-bottom: none;
    position: relative;
    display: block;
}
.l-highImpact__imageLink:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none;
}
.l-highImpact__imageLink:focus:after {
    opacity: 1;
}
.l-highImpact__imageLink:hover {
    background: none;
}
.l-highImpact__imageLink:after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
}
.l-highImpact__headline {
    color: #fff;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 1.875rem;
}
.l-highImpact__flag {
    display: inline-block;
    background: #e41b23;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 4px 8px;
    margin-bottom: 8px;
    flex-shrink: 0;
}
.l-highImpact__headline .l-highImpact__headlineFlag {
    font-size: 1.2rem;
    font-weight: 800;
}
.l-highImpact__media {
    overflow: hidden;
    width: 100%;
    position: relative;
}
.l-highImpact__media:before {
    content: "";
    display: block;
    padding: 0 0 150%;
    width: 100%;
    height: 0;
}
.l-highImpact__media > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: auto;
    width: 100%;
    max-height: none;
}
.l-highImpact__media:after {
    content: "";
    display: block;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
    width: 100%;
    height: 420px;
    bottom: 0;
    position: absolute;
    z-index: 100;
}
.l-highImpact__media--desktop {
    display: none;
}
.l-highImpact__thumbnail {
    display: block;
    width: 100%;
    height: auto;
    max-width: none;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.l-highImpact__media--mobile .l-highImpact__thumbnail {
    width: auto;
    height: 100%;
}
.l-highImpact__item {
    position: relative;
}
.l-highImpact__details {
    position: absolute;
    padding: 0 16px 24px;
    bottom: 0;
    z-index: 1000;
}
.l-highImpact__bottom .l-highImpact__details {
    padding: 0 0 16px;
    position: relative;
    display: block;
}
.l-highImpact__bottom .l-highImpact__excerpt {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 16px;
}
.l-highImpact__bottom .l-highImpact__main,
.l-highImpact__item:nth-child(n + 4) {
    display: none;
}
.l-highImpact__bottom .l-highImpact__main .l-highImpact__item {
    border-bottom: 1px solid #ededf0;
}
@media (min-width: 498px) {
    .l-highImpact {
        margin: 0 0 24px;
    }
    .l-highImpact__bottom .l-highImpact__main {
        display: block;
    }
    .l-highImpact__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;
        bottom: auto;
        padding: 0;
        margin-bottom: 16px;
        z-index: 1;
    }
    .l-highImpact__flag {
        font-size: 1.125rem;
        padding: 40px 16px;
        margin-bottom: 0;
    }
    .l-highImpact__media {
        height: auto;
        overflow: hidden;
        position: relative;
    }
    .l-highImpact__media:before {
        content: "";
        display: block;
        padding: 0 0 56.25%;
        width: 100%;
        height: 0;
    }
    .l-highImpact__media > :first-child {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: auto;
        width: 100%;
        max-height: none;
    }
    .l-highImpact__media:after {
        display: none;
    }
    .l-highImpact__media--desktop {
        display: block;
    }
    .l-highImpact__media--mobile {
        display: none;
    }
    .l-highImpact__thumbnail {
        display: block;
        height: 100%;
        width: auto;
        max-width: none;
        position: relative;
    }
    .l-highImpact__headline {
        color: #000;
        font-size: 2.125rem;
        line-height: 2.5rem;
    }
    .l-highImpact__headlineLink {
        margin-left: 32px;
    }
    .l-highImpact__headline .l-highImpact__headlineFlag {
        font-size: 1.5rem;
    }
    .l-highImpact__details {
        display: none;
        z-index: 1;
    }
    .l-highImpact__info {
        white-space: nowrap;
    }
}
.l-topRelated__mainList .l-topRelated__item:first-child {
    display: none;
}
.l-topRelated__main .l-topRelated__inner {
    padding: 0;
}
.l-topRelated__list,
.l-topRelated__main .l-topRelated__inner:focus:after,
.l-topRelated__main .l-topRelated__inner:hover:after,
.l-topRelated__mainList .l-topRelated__details {
    display: none;
}
@media (min-width: 768px) {
    .l-topRelated {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(9, 1fr);
        -ms-grid-rows: auto 0 auto;
        grid-template-areas: "M M M M M M M M M" "L1 L1 L1 L1 L1 L1 L1 L1 L1";
    }
    .l-topRelated__main {
        grid-area: M;
    }
    .l-topRelated__main .l-topRelated__media {
        margin-bottom: 0;
        width: 50%;
    }
    .l-topRelated__main .l-topRelated__inner {
        padding: 0 0 24px;
        flex-wrap: nowrap;
    }
    .l-topRelated__mainList {
        grid-area: L1;
    }
    .l-topRelated__mainList .l-topRelated__details {
        display: flex;
    }
}
@media (min-width: 1312px) {
    .l-topRelated {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(9, 1fr);
        -ms-grid-rows: auto 0 auto;
        grid-template-areas: "M M M M M L1 L1 L1 L1" "L2 L2 L2 L2 L2 L2 L2 L2 L2";
    }
    .l-topRelated__main {
        grid-area: M;
    }
    .l-topRelated__list {
        grid-area: L2;
        display: flex;
    }
    .l-topRelated__main .l-topRelated__inner {
        padding: 0;
    }
    .l-topRelated__main .l-topRelated__details {
        display: none;
    }
    .l-topRelated__main .l-topRelated__media {
        width: 100%;
    }
    .l-topRelated__mainList {
        grid-area: L1;
    }
    .l-topRelated__mainList .l-topRelated__item:first-child {
        display: block;
    }
    .l-topRelated__mainList .l-topRelated__details {
        display: none;
    }
    .l-topRelated__main {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 9;
    }
    .l-topRelated__mainList {
        -ms-grid-row: 1;
        -ms-grid-column: 11;
        -ms-grid-column-span: 7;
    }
}
@media (min-width: 768px) {
    .l-topRelated__main {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 17;
    }
    .l-topRelated__mainList {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 17;
    }
}
@media (min-width: 1312px) {
    .l-topRelated__main {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 9;
    }
    .l-topRelated__list {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 17;
    }
    .l-topRelated__mainList {
        -ms-grid-row: 1;
        -ms-grid-column: 11;
        -ms-grid-column-span: 7;
    }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.l-navbar__radio {
    display: none;
}
.l-article__details {
    visibility: hidden;
    height: 106px;
}
.l-longform-header__right {
    display: none;
}
.is-slid-and-faded-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out;
}
.is-slid-and-faded-in {
    visibility: visible;
    transform: matrix(1, 0, 0, 1, 0, 0) !important;
    opacity: 1;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.is-faded-out {
    visibility: hidden;
    opacity: 0;
}
.is-faded-in {
    visibility: visible;
    opacity: 1 !important;
}
.is-animated {
    transition: transform 0.3s ease-out;
}
.is-animated-fast {
    transition: transform 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}
.is-animated-slow {
    transition: transform 0.4s ease-out;
}
.is-faded-out.is-animated {
    transition: visibility 0s 0.3s, opacity 0.3s ease-out;
}
.is-faded-in.is-animated {
    transition: opacity 0.3s ease-out;
}
.is-faded-out.is-animated-fast {
    transition: visibility 0s 0.2s, opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}
.is-faded-in.is-animated-fast {
    transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}
.is-slid-and-faded-out.is-animated-fast {
    transition: visibility 0s 0.2s, transform 0.2s, opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}
.is-slid-and-faded-in.is-animated-fast {
    transition: transform 0.2s, opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}
.is-hidden {
    display: none !important;
}
.is-reset {
    transform: translateZ(0) !important;
}
.is-disabled {
    opacity: 0.2;
}
.is-disabled[aria-expanded="true"] {
    opacity: 1;
}
.is-expandable {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: visibility 0s 0.3s, height 0.3s ease-out;
}
.is-expanded {
    visibility: visible;
    transition: visibility 0s, height 0.3s ease-out;
}
.is-full-width {
    width: 100% !important;
    max-width: none !important;
    margin-bottom: 5%;
    transition: width 0.4s ease-out;
}
.is-toggled .toggle-off,
.toggle-on,
.toggle-on--flex {
    display: none !important;
}
.is-toggled .toggle-on {
    display: inline-block !important;
    display: initial !important;
}
.is-toggled .toggle-on--flex {
    display: flex !important;
}
.is-open {
    visibility: visible !important;
    transition: visibility 0s !important;
}
.is-no-scroll {
    overflow: hidden;
}
.is-no-smooth-scroll {
    scroll-behavior: auto;
}
.is-compact {
    padding: 0 !important;
}
.is-uncollapsed {
    max-height: 1000px !important;
}
.is-static {
    position: static !important;
}
.u-show-desktop,
.u-show-mobile,
.u-show-small-mobile,
.u-show-tablet-landscape,
.u-show-tablet-portrait {
  /*  display: none !important;*/
}
.u-hide-desktop,
.u-hide-mobile,
.u-hide-small-mobile,
.u-hide-tablet-landscape,
.u-hide-tablet-portrait {
    display: block !important;
}
@media (min-width: 340px) {
    .u-show-small-mobile {
        display: block !important;
    }
    .u-hide-small-mobile {
        display: none !important;
    }
}
@media (min-width: 498px) {
    .u-show-mobile {
        display: block !important;
    }
    .u-hide-mobile {
        display: none !important;
    }
}
@media (min-width: 768px) {
    .u-show-tablet-portrait {
        display: block !important;
    }
    .u-hide-tablet-portrait {
        display: none !important;
    }
}
@media (min-width: 1024px) {
    .u-show-tablet-landscape {
        display: block !important;
    }
    .u-hide-tablet-landscape {
        display: none !important;
    }
}
@media (min-width: 1312px) {
    .u-show-desktop {
        display: block !important;
    }
    .u-hide-desktop {
        display: none !important;
    }
}
@media (min-width: 768px){
    .l-panel--more .l-panel__topics{
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11;
        z-index: 99;
    }
    .l-panel--more .l-panel__topics{
        grid-area: T;
    }
    .c-nav--dropdown--topics .c-nav__item{
        width: 33.33333%;
    }
}
.l-panel--more .l-panel__topics, .l-panel--more .l-panel__menu{
    margin-bottom: 24px;
}
.c-nav--dropdown{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 8px 0 0;
    font-size: 1.0625rem;
}
.c-nav--dropdown .c-nav__item{
    width: 100%;
}
.c-nav--dropdown .c-nav__link{
    display: block;
    position: relative;
    padding: 12px 8px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 600;
    font-weight: 400;
}
.c-nav--dropdown .c-nav__link::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ededf0;
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-out;
}
form input[type=text], form input[type=email], form input[type=tel], form input[type=url], form textarea, form.c-ageGate input[type=submit], form.contact-form input[type=submit], form.search-form input[type=submit], .c-newsletterSignup__input, .c-search {
    font-family: 'proxima-nova', Arial, 'Helvetica Neue', Helvetica, sans-serif
}

@-webkit-keyframes slide {
    from {
        transform: translate3d(0, -100%, 0)
    }
    to {
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slide {
    from {
        transform: translate3d(0, -100%, 0)
    }
    to {
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes fade {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fade {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotateZ(0)
    }
    100% {
        transform: rotateZ(360deg)
    }
}

@keyframes spin {
    0% {
        transform: rotateZ(0)
    }
    100% {
        transform: rotateZ(360deg)
    }
}

@-webkit-keyframes fadeWithScale {
    0% {
        opacity: 0;
        transform: scale(0.8)
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeWithScale {
    0% {
        opacity: 0;
        transform: scale(0.8)
    }
    100% {
        opacity: 1
    }
}

.animate-fadeIn {
    -webkit-animation-name: fade;
    animation-name: fade;
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none
}

.animate-fadeOut {
    opacity: 0;
    -webkit-animation-name: fade;
    animation-name: fade;
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    animation-direction: reverse;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none
}

.animate-move {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    transition: all 0.35s ease-out;
    transform-origin: top left
}

form label {
    font-weight: 600;
    display: block;
    font-size: 15px;
}

form label.small {
    font-weight: 400;
    font-size: 0.875rem
}

form input[type=text], form input[type=email], form input[type=tel], form input[type=url] {
   /* height: 52px */
}

form textarea {
    height: 160px
}

form input[type=text], form input[type=email], form input[type=tel], form input[type=password]{
  
    width: 100%;
    display: block;
    height: 35px;
    padding: 8px 10px;
    border: 1px solid #d1d3d5;
    border-radius: 5px;
}

form textarea {
    width: 100%;
    display: block;
    padding: 8px 10px;
    border: 1px solid #d1d3d5;
    border-radius: 5px;
}

form input[type=text]::-webkit-input-placeholder, form input[type=email]::-webkit-input-placeholder, form input[type=tel]::-webkit-input-placeholder, form input[type=url]::-webkit-input-placeholder, form textarea::-webkit-input-placeholder {
    color: #4d7192
}

form input[type=text]::-moz-placeholder, form input[type=email]::-moz-placeholder, form input[type=tel]::-moz-placeholder, form input[type=url]::-moz-placeholder, form textarea::-moz-placeholder {
    color: #4d7192
}

form input[type=text]:-ms-input-placeholder, form input[type=email]:-ms-input-placeholder, form input[type=tel]:-ms-input-placeholder, form input[type=url]:-ms-input-placeholder, form textarea:-ms-input-placeholder {
    color: #4d7192
}

form input[type=text]::-ms-input-placeholder, form input[type=email]::-ms-input-placeholder, form input[type=tel]::-ms-input-placeholder, form input[type=url]::-ms-input-placeholder, form textarea::-ms-input-placeholder {
    color: #4d7192
}

form input[type=text]::placeholder, form input[type=email]::placeholder, form input[type=tel]::placeholder, form input[type=url]::placeholder, form textarea::placeholder {
    color: #4d7192
}

form input[type=checkbox] {
    border-radius: 2px;
    border: 1px solid #808083;
    margin: 4px 14px 0 0;

   /* transform: scale(1.5, 1.5)*/
}

form select {
    max-width: 100%;
    padding: 5px 10px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 1rem
}

form.contact-form div.grunion-field-wrap {
    margin-bottom: 16px
}

form.contact-form input[type=text], form.contact-form input[type=email], form.contact-form input[type=tel], form.contact-form input[type=url] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px
}

form.contact-form label span {
    color: #4d7192;
    margin-left: 4px;
    font-weight: normal
}

form.contact-form .contact-submit {
    margin: 0
}

form.contact-form textarea {
    width: 100%;
    height: 160px;
    margin-bottom: 12px
}

form.contact-form button[type=submit], form.search-form button[type=submit] {
    position: relative;
    padding: 6px 24px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    color: #fff;
    font-size: 0.75rem;
    text-align: center;
    background-color: #002e5d;
    border-radius: 2px;
    text-transform: uppercase;
    outline: none
}

form.c-ageGate input[type=submit], form.contact-form input[type=submit], form.search-form input[type=submit] {
    border-right: 0;
    border-left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    padding: 6px 24px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    color: #fff;
    font-size: 0.75rem;
    text-align: center;
    background-color: #002e5d;
    border-radius: 2px;
    text-transform: uppercase;
    outline: none
}

form.c-ageGate input[type=submit]:hover, form.c-ageGate input[type=submit]:focus, form.contact-form input[type=submit]:hover, form.contact-form input[type=submit]:focus, form.search-form input[type=submit]:hover, form.search-form input[type=submit]:focus {
    border-bottom: 2px solid #e41b23;
    background-color: #002e5d
}

@media (min-width: 768px) {
    form.c-ageGate input[type=submit], form.contact-form input[type=submit], form.search-form input[type=submit] {
        font-size: 0.875rem;
        font-weight: 700
    }
}

form.is-attempted input:invalid, form.is-attempted select:invalid, form.is-attempted textarea:invalid {
    outline: 2px solid #e41b23
}

.fb_iframe_widget, .fb_iframe_widget > span, .fb_iframe_widget iframe {
    max-width: 100%
}

.c-ageGate {
    width: 400px;
    max-width: 100%
}

.c-ageGate__section--center {
    text-align: center
}

.c-ageGate__section:not(:last-child) {
    margin-bottom: 14px
}

.c-ageGate__row {
    display: inline-block
}

.c-ageGate__row:not(:last-child) {
    margin-bottom: 8px
}

.c-ageGate__field {
    display: flex
}

.c-ageGate__label {
    font-size: 0.75rem;
    font-weight: 400
}

.c-backToTop {
    z-index: 6000;
    position: fixed;
    bottom: 100px;
    right: 0;
    transform: translate3d(0, 120%, 0)
}

.c-backToTop--shift {
    bottom: 120px
}

.c-backToTop--bigShift {
    bottom: 228px
}

.c-backToTop__button {
    display: block;
    background-color: #002e5d;
    color: #fff;
    margin: 0 auto;
    font-size: 0.6875rem;
    text-transform: uppercase;
    padding: 12px;
    border-radius: 4px 0 0 4px;
    outline: none
}

.c-backToTop__buttonInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.c-backToTop__icon {
    width: 14px;
    height: 14px;
    margin-bottom: 4px
}

.c-blockquote::before {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    position: absolute;
    background: #e41b23
}

.c-blockquote__button {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 0.6875rem;
    padding: 0 10px;
    position: relative;
    border-bottom: none;
    text-transform: uppercase;
    margin-left: 14px;
    width: 120px
}

.c-blockquote__button:focus, .c-blockquote__button:hover {
    background: none
}

.c-blockquote__button::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-blockquote__button:focus::after {
    opacity: 1
}

.c-blockquote__buttonLabel {
    line-height: 1.25rem
}

.c-blockquote__button:hover .c-blockquote__buttonLabel {
    position: relative
}

.c-blockquote__button:hover .c-blockquote__buttonLabel::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
    opacity: 2px
}

.c-blockquote__button:hover .c-blockquote__buttonLabel::before {
    bottom: 0px
}

.c-blockquote__button:hover .c-blockquote__buttonLabel:hover::before {
    opacity: 1
}

.c-blockquote__Icon {
    max-width: 18px;
    max-height: 18px;
    -ms-grid-row-align: center;
    align-self: center;
    margin-right: 8px
}

.c-blockquote--noButton .c-blockquote__button {
    display: none
}

.c-carousel__trackContainer {
    position: relative
}

.c-carousel--active .c-carousel__track {
    margin-left: 0
}

.c-carousel__buttons, .c-carousel__prev, .c-carousel__next, .c-carousel__goto {
    display: none
}

.c-carousel__bullets {
    display: flex;
    justify-content: center;
    margin-top: 16px
}

.c-carousel__bullet {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #d8d8d8;
    margin-right: 8px
}

.c-carousel__bullet:focus {
    outline: none
}

@media (min-width: 600px) {
    .c-carousel__buttons {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none
    }

    .c-carousel__button {
        position: absolute;
        height: 100%;
        pointer-events: auto;
        outline: none
    }

    .c-carousel__buttonInner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff
    }

    .c-carousel__icon {
        width: 16px;
        height: 16px;
        margin: 5px 0 0 -12px
    }

    .c-carousel__next {
        display: block;
        right: 0;
        width: 19%
    }

    .c-carousel__next .c-carousel__buttonInner {
        right: 0
    }

    .c-carousel__prev .c-carousel__icon::before {
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        border-style: solid;
        border-color: #e41b23;
        border-width: 5px 5px 0 0;
        background-color: transparent;
        transform: rotate(-135deg) translateY(50%)
    }

    .c-carousel__next .c-carousel__icon::before {
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        border-style: solid;
        border-color: #e41b23;
        border-width: 5px 5px 0 0;
        background-color: transparent;
        transform: rotate(45deg) translateY(-50%)
    }

    .c-carousel__prev:hover, .c-carousel__prev:focus {
        background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35))
    }

    .c-carousel__next:hover, .c-carousel__next:focus {
        background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35))
    }

    .c-carousel--active .c-carousel__prev {
        display: block;
        width: 10%
    }

    .c-carousel--active .c-carousel__next {
        width: 11%
    }
}

.c-carousel--faded .c-carousel__buttons {
    display: none;
    top: 0;
    background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.4) 4%, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0) 92%, rgba(255, 255, 255, 0.4) 96%, #fff 100%)
}

.c-carousel--faded .c-carousel__button {
    display: block;
    opacity: 0.4;
    transition: opacity 0.3s ease
}

.c-carousel--faded .c-carousel__button:hover, .c-carousel--faded .c-carousel__button:focus {
    opacity: 1;
    background: none
}

.c-copyright {
    color: #4d7192;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.03125rem
}

.c-dataTracker {
    padding: 16px;
    position: relative
}

.c-dataTracker__loader {
    display: none
}

.c-dataTracker__title {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.375rem;
    min-height: 22px;
    color: #000
}

.c-dataTracker__lastUpdated, .c-dataTracker__source {
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #1d2d44
}

.c-dataTracker__entries {
    padding: 16px;
    margin: 8px 0;
    border-radius: 8px;
    background: #ededf0;
    display: flex;
    justify-content: space-between
}

.c-dataTracker__entry {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    flex-grow: 1;
    flex-basis: 50%
}

.c-dataTracker__entry:last-child {
    margin-right: 0
}

.c-dataTracker__label {
    color: #0d1321;
    font-size: 0.875rem;
    line-height: 1.0625rem
}

.c-dataTracker__value {
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.625rem
}

.c-dataTracker__diff, .c-dataTracker__pctChange {
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #1d2d44
}

.c-dataTracker__link {
    font-size: 0.875rem
}

.c-dataTracker__trend {
    margin-top: 5px
}

.c-dataTracker__svg {
    overflow: visible !important
}

.c-dataTracker__entry--deaths .c-dataTracker__value, .c-dataTracker__entry--deaths .c-dataTracker__diff, .c-dataTracker__entry--deaths .c-dataTracker__trend, .c-dataTracker__entry--deaths .c-dataTracker__pctChange {
    color: #bf245d
}

.c-dataTracker__title, .c-dataTracker__entries, .c-dataTracker__lastUpdated, .c-dataTracker__source, .c-dataTracker__link {
    opacity: 1;
    transition: opacity 0.35s ease-out
}

.c-dataTracker--loading .c-dataTracker__title, .c-dataTracker--loading .c-dataTracker__entries, .c-dataTracker--loading .c-dataTracker__lastUpdated, .c-dataTracker--loading .c-dataTracker__source, .c-dataTracker--loading .c-dataTracker__link {
    opacity: 0
}

.c-dataTracker--loading .c-dataTracker__loader {
    display: block
}

.c-dataTracker__marker {
    fill: currentColor
}

.c-dataTracker__line {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2
}

@media (min-width: 1024px) {
    .c-dataTracker {
        padding: 0
    }
}

.c-details__item {
    position: relative;
    margin-right: 4px
}

.c-details__item:last-child {
    margin-right: 0
}

.c-details__link {
    width: 40px;
    height: 40px;
    background-color: #ededf0;
    transition: 0.2s background-color ease-in
}

.c-details__title {
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    position: absolute;
    top: calc(-100% + 8px);
    right: 50%;
    transform: translateX(50%);
    display: none;
    font-weight: 400;
    color: #1d2d44;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
}

.c-details__title::after {
    content: '';
    border-width: 4px;
    border-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: #fff;
    position: absolute;
    bottom: -8px;
    right: calc(50% - 4px)
}

@media (min-width: 768px) {
    .c-details__item:hover .c-details__title {
        display: block
    }
}

.c-fbComments {
    margin: 28px 0;
    position: relative;
    min-height: 212px
}

.c-fbComments__placeholder {
    padding: 14px 0;
    border-top: 1px solid #b5b5b8;
    border-bottom: 1px solid #b5b5b8;
    background: #fff;
    position: absolute;
    top: 52px;
    width: 100%
}

.c-fbComments__dummyTitle {
    display: inline-block;
    width: 100%
}

.c-fbComments__dummyProfile {
    display: inline-block;
    background-color: #b5b5b8;
    width: 60px;
    height: 60px;
    position: absolute
}

.c-fbComments__dummyText {
    display: inline-block;
    background-color: #b5b5b8;
    width: 100%;
    height: 100px;
    margin-left: 70px
}

.c-flyers {
    position: relative
}

.c-flyers__header {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    text-transform: uppercase
}

.c-flyers__link {
    margin: 0 0 0 24px
}

.c-flyers__label {
    color: #e41b23
}

.c-flyers__icon::after {
    border-color: #808083
}

.c-flyers__skeleton {
    position: absolute;
    top: 56px;
    width: 100%
}

.c-flyers__skeletonBox {
    background-color: #b5b5b8;
    top: 0
}

.c-flyers__embed, .c-flyers__skeletonBox {
    min-height: 250px
}

.c-fontSizer {
    display: flex;
    flex-direction: row
}

.c-fontSizer__title {
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    position: absolute;
    top: calc(-100% + 8px);
    right: 50%;
    transform: translateX(50%);
    display: none;
    font-weight: 400;
    color: #1d2d44;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    min-width: 110px
}

.c-fontSizer__title::after {
    content: '';
    border-width: 4px;
    border-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: #fff;
    position: absolute;
    bottom: -8px;
    right: calc(50% - 4px)
}

.c-fontSizer__button {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0
}

.c-fontSizer__button::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-fontSizer__button:focus::after {
    opacity: 1
}

.c-fontSizer__button:hover, .c-fontSizer__button:focus {
    background-color: #b5b5c2
}

.c-fontSizer__letter {
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.0625rem
}

.c-fontSizer__symbol {
    font-family: Arial, sans-serif
}

.c-headingLink {
    display: flex;
    align-items: center
}

.c-headingLink__link, .c-headingLink__heading {
    display: inline-block;
}

.c-headingLink__link {
    -ms-grid-row-align: center;
    align-self: center;
    margin-left: 16px
}

.c-iconLink {
    position: relative;
    display: flex;
    align-items: center;
    width: 230px;
    padding: 2px 4px;
    border: none;
    margin-bottom: 20px;
    color: #000
}

.c-iconLink::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 1px dashed #002e5d;
    opacity: 0;
    pointer-events: none
}

.c-iconLink:focus::after {
    opacity: 1
}

.c-iconLink__icon--shield {
    margin-left: 2px
}

.c-iconLink__icon--active {
    display: none
}

.c-iconLink__text {
    font-size: 0.6875rem;
    line-height: 0.875rem;
    letter-spacing: 0.03125rem;
    font-weight: 600;
    position: relative;
    margin-left: 10px;
    color: #1d2d44
}

.c-iconLink--error::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 1px dashed #e41b23;
    opacity: 0;
    pointer-events: none
}

.c-iconLink--error:focus::after {
    opacity: 1
}

.c-iconLink:hover, .c-iconLink:focus {
    background-color: transparent;
    color: #002e5d
}

.c-iconLink--error:hover, .c-iconLink--error:focus {
    color: #e41b23
}

.c-iconLink:hover .c-iconLink__icon--initial, .c-iconLink:focus .c-iconLink__icon--initial {
    display: none
}

.c-iconLink:hover .c-iconLink__icon--active, .c-iconLink:focus .c-iconLink__icon--active {
    display: block
}

.c-iconLink:hover .c-iconLink__text::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid rgba(0, 46, 93, 0.4);
    opacity: 1
}

.c-iconLink:hover .c-iconLink__text::before {
    bottom: 0px
}

.c-iconLink:hover .c-iconLink__text:hover::before {
    opacity: 1
}

.c-iconLink--error:hover .c-iconLink__text::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid rgba(228, 27, 35, 0.4);
    opacity: 1
}

.c-iconLink--error:hover .c-iconLink__text::before {
    bottom: 0px
}

.c-iconLink--error:hover .c-iconLink__text:hover::before {
    opacity: 1
}

@media (min-width: 768px) {
    .c-iconLink {
        width: auto;
        margin: 0 64px 0 0
    }

    .c-iconLink__icon--shield {
        width: 18px;
        height: 22px;
        margin: 0
    }

    .c-iconLink__icon--alert {
        width: 24px;
        height: 22px
    }
}

.c-imageGrid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px
}

.c-imageGrid__item {
    display: flex;
    justify-content: center;
    height: 100%;
    margin-bottom: 16px
}

.c-imageGrid__link {
    position: relative;
    display: inline-block;
    border-bottom: 0;
    line-height: 0.5rem
}

.c-imageGrid__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-imageGrid__link:focus::after {
    opacity: 1
}

.c-imageGrid__link:hover, .c-imageGrid__link:focus {
    background-color: transparent
}

.c-imageGrid__link::before {
    content: '';
    opacity: 0;
    background: #0052cc;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: 1;
    transition: opacity 0.2s ease-out
}

.c-imageGrid__link:hover::before, .c-imageGrid__link:focus::before {
    opacity: 0.2
}

.c-imageGrid__image {
    display: block;
    margin: 0 auto;
    width: auto
}

@media (min-width: 498px) {
    .c-imageGrid {
        flex-direction: row
    }

    .c-imageGrid__item {
        margin-right: 16px
    }

    .c-imageGrid--col2 .c-imageGrid__item {
        width: calc(50% - 8px)
    }

    .c-imageGrid--col2 .c-imageGrid__item:nth-child(2n) {
        margin-right: 0
    }

    .c-imageGrid--col3 .c-imageGrid__item {
        width: calc(33.3% - 10.6px)
    }

    .c-imageGrid--col3 .c-imageGrid__item:nth-child(3n) {
        margin-right: 0
    }

    .c-imageGrid--col4 .c-imageGrid__item {
        width: calc(25% - 12px)
    }

    .c-imageGrid--col4 .c-imageGrid__item:nth-child(4n) {
        margin-right: 0
    }
}

.c-imageContainer {
    overflow: hidden;
    position: relative
}

.c-imageContainer__image {
    height: 100%;
    width: auto;
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1
}

.c-imageContainer__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(8px);
    filter: blur(8px);
    transform: translate3d(0, 0, 0) scale(2)
}

.c-imageContainer--fitWidth .c-imageContainer__image {
    width: 100% !important;
    height: auto !important
}

.c-imageContainer--fitHeight .c-imageContainer__image {
    height: 100% !important;
    width: auto !important
}

.c-imageViewer {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    transition: opacity 0.35s ease-out;
    z-index: 10000001;
    border-bottom: none
}

.c-imageViewer:hover, .c-imageViewer:focus {
    background: rgba(0, 0, 0, 0.75)
}

.c-imageViewer--animateIn {
    -webkit-animation-name: fadeWithScale;
    animation-name: fadeWithScale;
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none
}

.c-imageViewer--hidden {
    opacity: 0;
    pointer-events: none
}

.c-imageViewer__inner {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center
}

.c-imageViewer__image {
    max-height: 100%;
    opacity: 0;
    transition: opacity 0.35s ease-in-out
}

.c-imageViewer__image--portrait {
    width: auto
}

.c-imageViewer__loader {
    display: none;
    opacity: 0;
    transition: opacity 0.35s ease-in-out
}

.c-imageViewer--loading .c-imageViewer__loader {
    display: block;
    opacity: 1
}

.c-imageViewer:not(.c-imageViewer--loading) .c-imageViewer__image {
    opacity: 1
}

.c-infoBox {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin: 32px 0
}

.c-infoBox::before {
    display: block;
    content: '';
    width: 100%;
    height: 4px;
    background: #e41b23;
    position: absolute
}

.c-infoBox__inner {
    border: 1px solid #ededf0;
    padding: 26px 16px
}

.c-infoBox__title {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 600;
    color: #808083;
    margin-bottom: 16px
}

.c-infoBox__p {
    margin: 0
}

.c-infoBox__ul {
    margin: 0;
    padding: 0 0 0 14px;
    list-style: none
}

.c-infoBox__li {
    margin: 0
}

.c-infoBox__li:not(:last-child) {
    margin-bottom: 16px
}

.c-infoBox__li::before {
    content: '\2022';
    color: #e41b23;
    display: inline-block;
    width: 1em;
    margin-left: -1em
}

@media (min-width: 768px) {
    .c-infoBox::before {
        width: 8px;
        height: 100%
    }

    .c-infoBox__inner {
        padding: 26px
    }
}

.c-inline-sponsor {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 0 1em;
    text-align: center
}

.c-inline-sponsor__link {
    position: relative;
    border-bottom: none;
    margin-bottom: 0;
    line-height: 0.5rem
}

.c-inline-sponsor__link::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0052cc;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    top: 0
}

.c-inline-sponsor__link:hover, .c-inline-sponsor__link:focus {
    background-color: transparent;
    outline: none
}

.c-inline-sponsor__link:hover::after, .c-inline-sponsor__link:focus::after {
    opacity: 0.2
}

.c-inline-sponsor__image {
    margin-bottom: 0
}

.c-list {
    list-style: none;
    padding-left: 0
}

.c-list__link {
    border: none;
    color: #0d1321
}

.c-list__link:hover, .c-list__link:focus {
    text-decoration: underline;
    background: transparent
}

.c-list__item {
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    letter-spacing: 0;
    font-weight: 600;
    display: flex;
    margin-left: 22px
}

.c-list__item::before {
    display: inline-block;
    content: "\2022";
    color: #e41b23;
    margin-left: -22px;
    margin-top: -1px;
    padding-right: 16px
}

.c-list__item:not(:last-child) {
    margin-bottom: 16px
}

.c-list__item:last-child {
    margin-bottom: 0
}

.c-list--highlight {
    color: #4d7192
}

.c-list--highlight .c-list__item {
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 600
}

.c-list--highlight .c-list__item:not(:last-child) {
    margin-bottom: 12px
}

@media (min-width: 1024px) {
    .c-list--boxed {
        background: #ededf0;
        padding: 24px;
        position: relative
    }
}

.c-listenLive {
    border: 1px solid #002e5d;
    border-radius: 5px;
    max-height: 82px
}

.c-listenLive__info {
    margin-left: 10px;
    padding-right: 5px;
    overflow: hidden
}

.c-listenLive__link {
    display: flex;
    border-bottom: none
}

.c-listenLive__image {
    width: 80px;
    height: 80px;
    flex-shrink: 0
}

.c-listenLive__play {
    font-size: 0.75rem;
    color: #e41b23;
    height: 30px
}

.c-listenLive__play__icon {
    font-size: 1.25rem
}

.c-listenLive__play__text {
    font-size: 0.75rem;
    font-weight: 700;
    display: inline-block;
    vertical-align: top;
    margin-top: 7px
}

.c-listenLive__title {
    font-size: 1.125rem;
    color: #0d1321;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.c-listenLive__time {
    font-size: 0.75rem;
    color: #808083;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.c-logo--black {
    padding: 8px;
    flex-shrink: 0;
    max-width: 200px;
    margin: 0 auto;
    height: 84px;
    width: 136px;
    position: relative
}

.c-logo--black::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #000;
    opacity: 0
}

.c-logo--black::before {
    bottom: 0px
}

.c-logo--black:hover::before {
    opacity: 1
}

.c-logo--black:hover::before, .c-logo--black:focus::before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom
}

@-webkit-keyframes underlineSlide {
    0% {
        transform: scale3d(1, 0, 1)
    }
    100% {
        transform: scale3d(1, 1, 1)
    }
}

@keyframes underlineSlide {
    0% {
        transform: scale3d(1, 0, 1)
    }
    100% {
        transform: scale3d(1, 1, 1)
    }
}

.c-logo--black::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-logo--black:focus::after {
    opacity: 1
}

.c-logo--black .c-logo__image {
    height: 65px;
    width: 118px
}

.c-logo--black:hover::before {
    opacity: 0
}

.c-logo--black.c-logo--black:focus {
    background-color: transparent;
    border-color: transparent;
    transform: none
}

@media (min-width: 768px) {
    .c-logo--black {
        margin: 0
    }
}

.c-logoCorus {
    display: flex;
    padding: 8px;
    flex-shrink: 0;
    max-width: 200px;
    margin: 0 auto;
    height: 32px;
    width: 79px;
    position: relative;
    border: none
}

.c-logoCorus::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 1px solid #fff;
    opacity: 0
}

.c-logoCorus::before {
    bottom: 0px
}

.c-logoCorus:hover::before {
    opacity: 1
}

.c-logoCorus:hover::before, .c-logoCorus:focus::before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom
}

@keyframes underlineSlide {
    0% {
        transform: scale3d(1, 0, 1)
    }
    100% {
        transform: scale3d(1, 1, 1)
    }
}

.c-logoCorus::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #fff;
    opacity: 0;
    pointer-events: none
}

.c-logoCorus:focus::after {
    opacity: 1
}

.c-logoCorus__image {
    height: 14px;
    width: 61px
}

.c-logoCorus:hover, .c-logoCorus:focus {
    background-color: transparent
}

.c-logoCorus:hover::before {
    opacity: 0
}

@media (min-width: 768px) {
    .c-logoCorus {
        margin: 0;
        height: 28px;
        width: 62px
    }

    .c-logoCorus__image {
        height: 10px;
        width: 44px
    }
}

.c-nav--corus {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 0.875rem;
    color: #fff
}

.c-nav--corus .c-nav__item {
    position: relative;
    padding: 8px
}

.c-nav--corus .c-nav__item::before {
    content: '/';
    position: absolute;
    right: 0
}

.c-nav--corus .c-nav__item:last-of-type::before {
    content: '';
    padding-left: 0
}

.c-nav--corus .c-nav__link {
    position: relative;
    outline: none;
    color: #fff;
    margin: 0 8px;
    padding: 8px 0
}

.c-nav--corus .c-nav__link::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 1px solid #fff;
    opacity: 0
}

.c-nav--corus .c-nav__link::before {
    bottom: 0px
}

.c-nav--corus .c-nav__link:hover::before {
    opacity: 1
}

.c-nav--corus .c-nav__link:hover::before, .c-nav--corus .c-nav__link:focus::before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom
}

@keyframes underlineSlide {
    0% {
        transform: scale3d(1, 0, 1)
    }
    100% {
        transform: scale3d(1, 1, 1)
    }
}

.c-nav--corus .c-nav__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #fff;
    opacity: 0;
    pointer-events: none
}

.c-nav--corus .c-nav__link:focus::after {
    opacity: 1
}

.c-nav--corus .c-nav__link::after {
    right: -8px;
    left: -8px
}

@media (min-width: 768px) {
    .c-nav--corus {
        justify-content: initial
    }
}

.c-nav--dropdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 8px 0 0;
    font-size: 1.0625rem
}

.c-nav--dropdown .c-nav__link {
    display: block;
    position: relative;
    padding: 12px 8px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 600;
    font-weight: 400
}

.c-nav--dropdown .c-nav__link::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: #eef0ed; */
    border-radius: 4px;
    border: none;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-out
}

.c-nav--dropdown .c-nav__link:hover, .c-nav--dropdown .c-nav__link:focus {
    outline: none
}

.c-nav--dropdown .c-nav__link:hover::after, .c-nav--dropdown .c-nav__link:focus::after {
    opacity: 1
}

.c-nav--dropdown--generic .c-nav__link {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    letter-spacing: 0;
    font-weight: 600;
    font-weight: 400
}

.c-nav--dropdown--skeleton .c-nav__link {
    padding: 0;
    margin: 12px 8px;
    background-color: #ededf0
}

.c-nav--dropdown--skeleton .c-nav__link:hover, .c-nav--dropdown--skeleton .c-nav__link:focus {
    background-color: #ededf0;
    border-radius: 0
}

.c-nav--dropdown--inverted .c-nav__link:hover, .c-nav--dropdown--inverted .c-nav__link:focus {
    background-color: #fff
}

.c-nav--dropdown .c-nav__change {
    float: right;
    margin-top: -16px;
    padding: 16px 0;
    color: #e41b23
}

.c-nav--dropdown .c-nav__item {
    width: 100%
}

.c-nav--dropdown--generic .c-nav__item {
    width: 50%
}

.c-nav--dropdown .c-nav__icon {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    transform: translateY(3px)
}

@media (min-width: 768px) {
    .c-nav--dropdown .c-nav__change {
        display: none
    }

    .c-nav--dropdown--topics .c-nav__item {
        width: 33.33333%
    }

    .c-nav--dropdown--generic .c-nav__item {
        width: 100%
    }

    .c-nav--dropdown--generic .c-nav__link {
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0;
        font-weight: 600;
        font-weight: 400
    }

    .c-nav--dropdown--region {
        max-width: 854px
    }

    .c-nav--dropdown--region .c-nav__item, .c-nav--dropdown--regionStandalone .c-nav__item {
        width: calc(33.33333% - 12px)
    }
}

@media (min-width: 1024px) {
    .c-nav--dropdown--topics .c-nav__item {
        width: 50%
    }

    .c-nav--dropdown--region .c-nav__item, .c-nav--dropdown--regionStandalone .c-nav__item {
        width: calc(25% - 12px)
    }
}

@media (min-width: 1312px) {
    .c-nav--dropdown--topics .c-nav__item {
        width: 33.33333%
    }

    .c-nav--dropdown--region .c-nav__item, .c-nav--dropdown--regionStandalone .c-nav__item {
        width: calc(33.33333% - 12px)
    }
}

.c-nav--footer {
    height: auto
}

.c-nav--footer .c-nav__item {
    padding: 12px 8px;
    width: fit-content;
    margin-right: 0px;
    margin-left: 0px;
}

.c-nav--footer.c-nav--footer .c-nav__link {
    padding: 0;
    color: black;
    font-size: 14px;
    line-height: 1.3125rem
}

.c-nav--footer.c-nav--footer .c-nav__link::before {
    border-bottom-color: #0d1321
}

.c-nav--footer.c-nav--footer .c-nav__link::after {
    border-color: #000;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

@media (min-width: 768px) {
    .c-nav--footer {
        padding-top: 8px;
        justify-content: center;
    }

    .c-nav--footer.c-nav--footer .c-nav__item {
        width: auto;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding: 8px
    }
}

.c-newsletterSignup {
    display: flex;
    flex-direction: column
}

.c-newsletterSignup__image--mobile {
    width: 100%
}

.c-newsletterSignup__image--desktop {
    display: none
}

.c-newsletterSignup__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    border-right: 2px solid #ededf0;
    border-bottom: 2px solid #ededf0;
    border-left: 2px solid #ededf0;
    padding: 16px
}

.c-newsletterSignup__title {
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 100%;
    margin-bottom: 16px
}

.c-newsletterSignup__tagline {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 1.0625rem;
    line-height: 1.3em;
    max-width: 100%
}

.c-newsletterSignup__container {
    display: flex
}

.c-newsletterSignup__container--column {
    flex-direction: column
}

.c-newsletterSignup__input {
    border: 1px solid #ededf0;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    padding: 12px 16px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    width: 100%;
    max-width: calc(100% - 106px)
}

.c-newsletterSignup__input--error {
    border: 2px solid #e41b23;
    border-right: 0
}

.c-newsletterSignup__input::-webkit-input-placeholder {
    color: #000
}

.c-newsletterSignup__input::-moz-placeholder {
    color: #000
}

.c-newsletterSignup__input:-ms-input-placeholder {
    color: #000
}

.c-newsletterSignup__input::-ms-input-placeholder {
    color: #000
}

.c-newsletterSignup__input::placeholder {
    color: #000
}

.c-newsletterSignup__signupButton {
    border-radius: 0 5px 5px 0;
    flex-shrink: 0;
    font-size: 0.875rem
}

.c-newsletterSignup--noImage .c-newsletterSignup__image {
    display: none
}

.c-newsletterSignup--noImage .c-newsletterSignup__form {
    border: none;
    padding: 0 8px
}

.c-newsletterSignup--border {
    border: 2px solid #ededf0
}

.c-newsletterSignup--border .c-newsletterSignup__form {
    border: none
}

@media (min-width: 498px) {
    .c-newsletterSignup {
        flex-direction: row
    }

    .c-newsletterSignup__image--desktop {
        display: block;
        min-width: 170px
    }

    .c-newsletterSignup__image--mobile {
        display: none
    }

    .c-newsletterSignup__form {
        padding: 32px 24px;
        border-top: 2px solid #ededf0;
        border-left: none
    }
}

@media (min-width: 1024px) {
    .c-newsletterSignup {
        flex-direction: column
    }

    .c-newsletterSignup__image--desktop {
        display: none
    }

    .c-newsletterSignup__image--mobile {
        display: block
    }

    .c-newsletterSignup__form {
        border-top: none;
        border-left: 2px solid #ededf0;
        padding: 16px
    }
}

.c-omny {
    display: flex
}

.c-omny__show {
    list-style: none;
    overflow: hidden;
    padding: 4px
}

.c-omny__container {
    position: relative
}

.c-omny__container::before {
    content: '';
    display: block;
    padding: 0 0 100% 0;
    width: 100%;
    height: 0
}

.c-omny__container > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%
}

.c-omny__title {
    margin: 16px 0 8px 0
}

.c-omny__titleLink {
    position: relative;
    color: #000;
    font-weight: 600;
    border-bottom: none;
    line-height: 1.24em;
    letter-spacing: 0.01em
}

.c-omny__titleLink:hover {
    background-color: transparent
}

.c-omny__titleLink:hover::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #000;
    opacity: rgba(0, 0, 0, 0)
}

.c-omny__titleLink:hover::before {
    bottom: 0px
}

.c-omny__titleLink:hover:hover::before {
    opacity: 1
}

.c-omny__titleLink:hover::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-omny__titleLink:hover:focus::after {
    opacity: 1
}

@media (min-width: 498px) {
    .c-omny__titleLink {
        font-size: 1.0625rem
    }
}

@media (min-width: 1024px) {
    .c-omny {
        overflow-x: hidden !important;
        margin: 0 -8px !important
    }

    .c-omny__show {
        width: 25% !important;
        max-width: 25% !important;
        flex-shrink: 1 !important;
        padding: 8px;
        margin-right: 0 !important
    }
}

@media (min-width: 1312px) {
    .c-omny {
        margin: 0 -16px !important
    }

    .c-omny__show {
        padding: 16px
    }
}

.c-outbrain__skeletonBox {
    margin-top: 22px;
    height: 250px;
    background-color: #b5b5b8
}

.c-podcastButtons__link {
    border: none
}

.c-podcastButtons__link:hover, .c-podcastButtons__link:focus {
    background: none
}

.c-podcastButtons__image {
    height: 40px
}

.c-posts--column.c-posts--skeleton {
    margin-bottom: 32px
}

.c-posts--column .c-posts__ad {
    margin: 0 -16px;
    overflow: hidden
}

.c-posts--column .c-posts__excerpt {
    display: none
}

.c-posts--column .c-posts__details {
    padding: 0 10px 0 0
}

.c-posts--column .c-posts__inner {
    padding: 16px 0
}

.c-posts--column .c-posts__inner::after {
    top: 0
}

.c-posts--column .c-posts__media {
    max-width: 50%
}

@media (min-width: 768px) {
    .c-posts--column .c-posts__ad {
        margin: 0
    }

    .c-posts--column .c-posts__media {
        width: 250px
    }

    .c-posts--column .c-posts__headlineText {
        font-size: 1.5rem;
        font-weight: 100;
        line-height: 1.24em;
        letter-spacing: 0.7px;
    }

    .c-posts--column .c-posts__excerpt {
        font-size: 1.0625rem;
        line-height: 1.34em;
        max-height: 69px;
        flex-grow: 1;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: #000000;
        font-weight: 100;
    }

    .c-posts--column .c-posts__excerpt > .u-lineClamp {
        max-height: calc(1em * (1.5 - 0.15) * 3);
        height: calc(1em * (1.5 - 0.15) * 3);
        position: relative;
        overflow: hidden
    }

    .c-posts--column .c-posts__excerpt > .u-lineClamp::after {
        content: '';
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: calc(1em * 1.5);
        width: 20%;
        min-width: calc((1em * 1.5) * 2);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%)
    }

    @supports (-webkit-line-clamp: 1) {
        .c-posts--column .c-posts__excerpt > .u-lineClamp::after {
            display: none
        }

        .c-posts--column .c-posts__excerpt > .u-lineClamp {
            height: auto
        }
    }.c-posts--column .c-posts__details {
         padding: 0 16px 0 0
     }

    .c-posts--column .c-posts__icon {
        margin: 16px;
        border-radius: 3px
    }

    .c-posts--column .c-posts__play {
        height: 22px;
        width: 22px
    }
}

.c-posts--inline .c-posts__inner::after {
    top: 0
}

@media (min-width: 768px) {
    .c-posts--inline {
        display: flex;
        margin: -8px
    }

    .c-posts--inline .c-posts__item {
        width: 50%;
        margin: 8px
    }

    .c-posts--inline .c-posts__inner {
        border-bottom: none
    }

    .c-posts--inline .c-posts__media {
        width: 120px
    }
}

@media (min-width: 1024px) {
    .c-posts--inline {
        display: block;
        margin: 0
    }

    .c-posts--inline .c-posts__item {
        width: 100%;
        margin: 0
    }

    .c-posts--inline .c-posts__item .c-posts__inner {
        display: block;
        padding: 0;
        border-bottom: 1px solid #ededf0
    }

    .c-posts--inline .c-posts__item .c-posts__details {
        padding: 16px 0
    }

    .c-posts--inline .c-posts__item:first-child .c-posts__media {
        width: 100%
    }

    .c-posts--inline .c-posts__item:nth-child(n+2) .c-posts__media {
        display: none
    }
}

@media (min-width: 1024px) {
    .c-posts--nav .c-posts__item {
        width: 25%
    }

    .c-posts--nav .c-posts__item:last-child {
        display: none
    }
}

.c-posts {
    position: relative
}

.c-posts--numbered {
    padding: 5px;
    margin: 32px 0;
    border: 2px solid #e41b23
}

.c-posts--numbered .c-posts__inner {
    padding: 0px
}

.c-posts--numbered .c-posts__inner::after {
    top: 0
}

.c-posts--numbered .c-posts__media {
   /* display: none*/
}

.c-posts--numbered .c-posts__rank {
    display: block;
    margin-top: -4px
}

.c-posts--numbered .c-posts__rankIcon {
    height: 32px;
    width: 20px
}

.c-posts--numbered .c-posts__about {
    display: none
}

.c-posts--numbered .c-posts__item:last-child .c-posts__inner {
    border-bottom: none
}

.c-posts--numbered .c-posts__headlineText {
    font-size: 14px;
    line-height: 1.1rem;
    font-weight: 500;
}

@media (min-width: 1024px) {
    .c-posts--gridList {
        flex-wrap: wrap
    }

    .c-posts--gridList .c-posts__item {
        display: inline-block;
        padding: 0 8px;
        width: 100%
    }

    .c-posts--gridList .c-posts__item {
        padding-top: 8px
    }

    .c-posts--gridList .c-posts__item:not(:first-child) .c-posts__media {
        display: none
    }
}

@media (min-width: 1312px) {
    .c-posts--gridList .c-posts__item {
        padding: 0 16px
    }

    .c-posts--gridList .c-posts__item {
        padding-top: 16px
    }
}

@media (min-width: 498px) {
    .c-posts--gridRow .c-posts__item {
        width: 33.33%
    }

    .c-posts--gridRow .c-posts__item:nth-child(n+4) {
        display: none
    }
}

@media (min-width: 1024px) {
    .c-posts--gridRow .c-posts__item {
        width: 25%
    }

    .c-posts--gridRowNarrow .c-posts__item {
        width: 33.3%
    }

    .c-posts--gridRow .c-posts__inner {
        flex-direction: column
    }

    .c-posts--gridRow .c-posts__item:nth-child(n+4) {
        display: block
    }

    .c-posts--gridRow .c-posts__item:nth-child(n+5) {
        display: none
    }
}

.c-posts--horizontal {
    display: flex;
    overflow-x: auto;
    margin: 0 -16px 0 0
}

.c-posts--horizontal .c-posts__item {
    -ms-grid-row-align: stretch;
    align-self: stretch;
    flex-shrink: 0;
    width: 207px;
    max-width: 60%;
    height: auto;
    margin-right: 16px
}

.c-posts--horizontal .c-posts__inner {
    display: block;
    height: 100%;
    padding: 16px 0 0 0
}

.c-posts--horizontal .c-posts__inner::after {
    top: 0;
    right: -8px;
    bottom: 0;
    left: -8px
}

.c-posts--horizontal .c-posts__details {
    padding: 12px 0
}

.c-posts--horizontal .c-posts__about {
    margin-top: 8px
}

.c-posts--horizontal .c-posts__media {
    width: 100%;
    display: block
}

.c-posts--horizontal .c-posts__icon {
    margin: 16px;
    border-radius: 3px
}

.c-posts--horizontal .c-posts__trending {
    margin-bottom: 4px
}

.c-posts--horizontal.c-posts--inverted .c-posts__inner {
    border-bottom: none
}

@media (min-width: 1024px) {
    .c-posts--horizontal {
        margin: 0
    }

    .c-posts--horizontal .c-posts__inner::after {
        top: -8px;
        right: -8px;
        bottom: 0;
        left: -8px
    }
}

.c-posts--overImage .c-posts__details {
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 12px 0
}

.c-posts--overImage .c-posts__inner {
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0
}

.c-posts--overImage .c-posts__inner::after {
    top: 0;
    bottom: 0
}

.c-posts--overImage .c-posts__headlineText {
    white-space: normal
}

.c-posts--overImage .c-posts__about {
    margin: 0 0 8px 0
}

@media (min-width: 498px) {
    .c-posts--overImage .c-posts__headlineText {
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
    }

    .c-posts--overImage .c-posts__headlineText > .u-lineClamp {
        max-height: calc(1em * (1.5 - 0.15) * 2);
        height: calc(1em * (1.5 - 0.15) * 2);
        position: relative;
        overflow: hidden
    }

    .c-posts--overImage .c-posts__headlineText > .u-lineClamp::after {
        content: '';
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: calc(1em * 1.5);
        width: 20%;
        min-width: calc((1em * 1.5) * 2);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%)
    }

    @supports (-webkit-line-clamp: 1) {
        .c-posts--overImage .c-posts__headlineText > .u-lineClamp::after {
            display: none
        }

        .c-posts--overImage .c-posts__headlineText > .u-lineClamp {
            height: auto
        }
    }
}

@media (min-width: 1024px) {
    .c-posts--overImage .c-posts__details {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 16px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.8));
        z-index: 1
    }

    .c-posts--overImage .c-posts__headlineText {
        color: #fff;
        font-size: 1.25rem;
        line-height: 1.24em;
        letter-spacing: 0.006em;
        padding-bottom: 2px;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical
    }

    .c-posts--overImage .c-posts__headlineText > .u-lineClamp {
        max-height: calc(1em * (1.5 - 0.15) * 3);
        height: calc(1em * (1.5 - 0.15) * 3);
        position: relative;
        overflow: hidden
    }

    .c-posts--overImage .c-posts__headlineText > .u-lineClamp::after {
        content: '';
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: calc(1em * 1.5);
        width: 20%;
        min-width: calc((1em * 1.5) * 2);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%)
    }

    @supports (-webkit-line-clamp: 1) {
        .c-posts--overImage .c-posts__headlineText > .u-lineClamp::after {
            display: none
        }

        .c-posts--overImage .c-posts__headlineText > .u-lineClamp {
            height: auto
        }
    }.c-posts--overImage .c-posts__info {
         padding: 8px;
         font-weight: 600;
         color: #fff;
         background-color: #e41b23
     }

    .c-posts--overImage .c-posts__item {
        max-width: 508px
    }

    .c-posts--overImage.c-posts--maxWidth .c-posts__item {
        max-width: none
    }
}

.c-posts--skeleton .c-posts__headline, .c-posts--skeleton .c-posts__excerpt, .c-posts--skeleton .c-posts__about, .c-posts--skeleton .c-posts__trending, .c-posts--skeleton .c-posts__media {
    background: #ededf0;
    color: #ededf0
}

.c-posts--skeleton .c-posts__info {
    color: #ededf0
}

.c-posts--skeleton .c-posts__flag, .c-posts--skeleton .c-posts__headlineFlag {
    display: none
}

.c-posts--skeleton .c-posts__icon, .c-posts--skeleton .c-posts__media > * {
    visibility: hidden
}

.c-posts--skeleton .c-posts__excerpt, .c-posts--skeleton .c-posts__headline {
    flex-grow: 1
}

.c-posts--skeleton .c-posts__ad .c-posts__inner {
    visibility: hidden
}

.c-posts--tilePlayer .c-posts__inner {
    border-bottom: 1px solid rgba(237, 237, 240, 0.2)
}

.c-posts--tilePlayer .c-posts__inner::after {
    content: none
}

.c-posts--tilePlayer .c-posts__details {
    position: relative;
    width: auto;
    min-width: 30%;
    padding-right: 10px
}

.c-posts--tilePlayer .c-posts__details::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #fff;
    opacity: 0;
    pointer-events: none
}

.c-posts--tilePlayer .c-posts__details:focus::after {
    opacity: 1
}

.c-posts--tilePlayer .c-posts__details::after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

.c-posts--tilePlayer .c-posts__media {
    width: 152px
}

.c-posts--tilePlayer .c-posts__media:focus, .c-posts--tilePlayer .c-posts__media:hover, .c-posts--tilePlayer .c-posts__details:focus, .c-posts--tilePlayer .c-posts__details:hover {
    background-color: transparent
}

.c-posts--tilePlayer .c-posts__inner--expanded {
    flex-wrap: wrap-reverse
}

.c-posts--tilePlayer .c-posts__inner--expanded .c-posts__details {
    width: 100%;
    min-height: 0;
    padding-right: 0
}

.c-posts--tilePlayer .c-posts__headlineText {
    font-size: 1rem
}

.c-posts--tilePlayer .c-posts__about {
    order: 2
}

.c-posts--articleTrending .c-posts__item {
    display: flex;
    flex-direction: column
}

.c-posts--articleTrending .c-posts__inner {
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    flex: 1 1 auto;
    flex-direction: row-reverse;
    padding: 10px 0
}

.c-posts--articleTrending .c-posts__inner::after {
    top: 0
}

.c-posts--articleTrending .c-posts__ad:focus .c-posts--articleTrending .c-posts__ad__headlineText, .c-posts--articleTrending .c-posts__ad:hover .c-posts--articleTrending .c-posts__ad__headlineText {
    text-decoration: none
}

.c-posts--articleTrending .c-posts__details {
    order: 2;
    display: block
}

.c-posts--articleTrending .c-posts__headlineText {
    letter-spacing: inherit
}

.c-posts--articleTrending .c-posts__trending {
    display: block
}

.c-posts--articleTrending .c-posts__item:nth-child(n+6) {
    display: none
}

.c-posts--articleTrending[data-ad] .c-posts__item:last-child {
    display: block
}

.c-posts--articleTrending[data-ad-empty=false] .c-posts__item:nth-child(5) {
    display: none
}

.c-posts--articleTrending[data-ad-empty=true] .c-posts__item:last-child {
    display: none
}

@media (min-width: 498px) {
    .c-posts--articleTrending .c-posts__inner {
        flex-wrap: wrap;
        height: 100%;
        flex-direction: column
    }

    .c-posts--articleTrending .c-posts__details {
        flex-grow: 1
    }

    .c-posts--articleTrending .c-posts__ad .c-posts__details {
        padding-bottom: 0
    }

    .c-posts--articleTrending .c-posts__item:nth-child(n) {
        display: block
    }

    .c-posts--articleTrending .c-posts__item:nth-child(n+7) {
        display: none
    }

    .c-posts--articleTrending[data-ad] .c-posts__item:last-child {
        display: block
    }

    .c-posts--articleTrending[data-ad-empty=false] .c-posts__item:nth-child(6) {
        display: none
    }

    .c-posts--articleTrending[data-ad-empty=true] .c-posts__item:last-child {
        display: none
    }
}

@media (min-width: 1024px) {
    .c-posts--articleTrending {
        margin: 0
    }

    .c-posts--articleTrending .c-posts__item {
        width: 100%;
        padding: 0
    }

    .c-posts--articleTrending .c-posts__inner {
        margin: 0;
        flex-wrap: nowrap;
        flex-direction: row;
        padding: 10px 0
    }

    .c-posts--articleTrending .c-posts__details {
        display: block;
        padding: 0 0 0 16px
    }

    .c-posts--articleTrending .c-posts__about {
        margin-top: 4px
    }

    .c-posts--articleTrending .c-posts__media {
        width: 152px
    }

    .c-posts--articleTrending .c-posts__headlineText {
        font-size: 0.9375rem;
        line-height: 1.1875rem;
        letter-spacing: 0;
        font-weight: 600;
        margin-bottom: 0
    }

    .c-posts--articleTrending .c-posts__icon {
        margin: 0;
        border-radius: 0
    }

    .c-posts--articleTrending .c-posts__play {
        height: 14px;
        width: 14px
    }

    .c-posts--articleTrending .c-posts__item:nth-child(n) {
        display: block
    }

    .c-posts--articleTrending[data-ad] .c-posts__item:nth-child(n+6) {
        display: none
    }

    .c-posts--articleTrending[data-ad] .c-posts__item:last-child {
        display: block
    }

    .c-posts--articleTrending[data-ad-empty=false] .c-posts__item:nth-child(5) {
        display: none
    }

    .c-posts--articleTrending[data-ad-empty=true] .c-posts__item:last-child {
        display: none
    }
}

.c-promoBanners__item:not(:last-child) {
    margin-bottom: 8px
}

.c-promoBanners__imgContainer {
    display: flex;
    justify-content: space-around;
    position: relative
}

.c-promoBanners--main .c-promoBanners__item--hasLink .c-promoBanners__imgContainer {
    margin-bottom: 8px
}

.c-promoBanners__image {
    display: block;
    width: 100%;
    height: auto
}

.c-promoBanners__link {
    position: relative;
    display: block;
    border: none
}

.c-promoBanners__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-promoBanners__link:focus::after {
    opacity: 1
}

.c-promoBanners__link::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0052cc;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    top: 0
}

.c-promoBanners__link:hover::before, .c-promoBanners__link:hover::before {
    opacity: 0.2
}

.c-promoBanners__textLink {
    position: relative;
    font-weight: 600;
    margin-top: 8px
}

.c-promoBanners__textLink::after {
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: #e41b23;
    border-width: 2px 2px 0 0;
    background-color: transparent;
    transform: rotate(45deg) translateY(-50%)
}

.c-promoBanners__textLink::after {
    content: '';
    position: absolute;
    top: before;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-promoBanners__textLink:focus::after {
    opacity: 1
}

.c-promoBanners__textLink::after {
    transform: rotate(45deg) translate(-50%);
    margin-left: 8px
}

.c-promoBanners__pixel {
    position: absolute;
    left: 0;
    opacity: 0;
    height: 1px;
    width: 1px
}

.c-promoBanners--sidebar {
   /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png);*/
    padding: 16px 4px
}

.c-promoBanners--sidebar .c-promoBanners__image {
    width: auto;
    margin: 0 auto
}

.c-promoBanners--sidebar .c-promoBanners__textLink {
    display: none
}

.c-promoBanners--noBackground {
    background: none;
    padding: 0
}

.c-promoBanners--noBackground .c-promoBanners__image {
    width: 100%;
    max-width: none
}

.c-promoBanners--sidebar .c-promoBanners__link::after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

@media (min-width: 498px) {
    .c-promoBanners__textLink {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width: 1024px) {
    .c-promoBanners {
        background: none;
        padding: 0;
        margin-bottom: 8px
    }

    .c-promoBanners--main {
        margin-bottom: 24px
    }

    .c-promoBanners__item {
        margin-bottom: 6px
    }

    .c-promoBanners__item:last-child {
        margin-bottom: 0
    }

    .c-promoBanners__imgContainer {
       /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png) */
    }

    .c-promoBanners__image {
        max-height: none;
        width: 100%
    }

    .c-promoBanners--sidebar .c-promoBanners__link {
        padding: 4px
    }
}

.c-quickLinks {
    padding: 24px 0;
    background: #fff;
    border-bottom: 1px solid #ededf0;
    margin-bottom: 32px
}

.c-quickLinks__label {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 600;
    color: #808083;
    margin-bottom: 8px
}

.c-quickLinks__list {
    margin: 0;
    padding: 0;
    list-style: none
}

.c-quickLinks__item {
    margin: 4px 16px 0 0;
    display: inline-block
}

.c-quickLinks__link {
    font-size: 1rem;
    line-height: 0.875rem;
    border-bottom: none
}

@media (min-width: 1024px) {
    .c-quickLinks {
        padding: 24px;
        border: 1px solid #ededf0;
        margin: 0
    }
}

.c-radioBanner__header {
    width: 100%;
    padding: 0 16px
}

.c-radioBanner__headerInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end
}

.c-radioBanner__headerLogo {
    width: 117px;
    max-width: 117px;
    flex-shrink: 1;
    margin-bottom: 12px
}

.c-radioBanner__heading {
    margin: 0 0 16px 0
}

.c-radioBanner__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%
}

.c-radioBanner__inner {
    width: 100%;
    background-color: #ededf0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.c-radioBanner__left {
    display: flex;
    align-items: center
}

.c-radioBanner__logo {
    display: none;
    justify-content: center;
    align-items: center;
    margin-right: 64px;
    width: 100%;
    max-width: 160px;
    flex-shrink: 1
}

.c-radioBanner__logo:hover {
    transform: none
}

.c-radioBanner__logoImage {
    width: 100%
}

.c-radioBanner__personality {
    display: flex;
    position: relative;
    margin-right: 16px
}

.c-radioBanner__square, .c-radioBanner__squareImage {
    width: 52px
}

.c-radioBanner__squareImage {
    height: 52px;
    display: block
}

.c-radioBanner--hasShowImage .c-radioBanner__personality, .c-radioBanner--hasShowImage .c-radioBanner__personalityImage {
    width: 100px
}

.c-radioBanner--hasShowImage .c-radioBanner__personalityImage {
    height: 100px
}

.c-radioBanner__square {
    display: none;
    margin-right: 16px
}

.c-radioBanner:not(.c-radioBanner--hasShowImage) .c-radioBanner__square {
    display: block
}

.c-radioBanner__live {
    display: flex;
    width: 100%;
    max-width: calc(100% - 116px)
}

.c-radioBanner__liveText {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.c-radioBanner__link {
    border-bottom: 0
}

.c-radioBanner__link:hover, .c-radioBanner__link:focus {
    background-color: transparent
}

.c-radioBanner__play {
    border-radius: 50%;
    background-color: #e41b23;
    display: none;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-right: 16px;
    flex-shrink: 0
}

.c-radioBanner__play:hover {
    transform: scale(1.1);
    background-color: #e41b23
}

.c-radioBanner__play:focus {
    transform: none;
    background-color: #891015
}

.c-radioBanner__social {
    display: none
}

.c-radioBanner__link--social {
    position: relative;
    display: flex
}

.c-radioBanner__link--social::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-radioBanner__link--social:focus::after {
    opacity: 1
}

.c-radioBanner__link--social::after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

.c-radioBanner__link--social .c-radioBanner__icon {
    width: 32px;
    height: 32px;
    opacity: 0.5
}

.c-radioBanner__link--social .c-radioBanner__icon--twitter {
    width: 28px;
    height: 28px
}

.c-radioBanner__link--social:first-of-type {
    margin-right: 24px
}

.c-radioBanner__playSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px
}

.c-radioBanner__playSmall .c-radioBanner__icon {
    width: 14px;
    height: 14px
}

.c-radioBanner__play .c-radioBanner__icon {
    transition: all 0.2s ease-in-out
}

.c-radioBanner__link--social:hover .c-radioBanner__icon, .c-radioBanner__link--social:focus .c-radioBanner__icon {
    opacity: 1
}

.c-radioBanner__play:hover .c-radioBanner__icon {
    transform: scale(0.91)
}

.c-radioBanner__play:focus .c-radioBanner__icon {
    transform: none
}

.c-radioBanner__link--live {
    display: flex;
    position: relative;
    text-transform: uppercase;
    color: #cd1820;
    font-size: 0.625rem;
    font-weight: 700
}

.c-radioBanner__link--live::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #cd1820;
    opacity: 0
}

.c-radioBanner__link--live::before {
    bottom: 0px
}

.c-radioBanner__link--live:hover::before {
    opacity: 1
}

.c-radioBanner__link--live::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #cd1820;
    opacity: 0;
    pointer-events: none
}

.c-radioBanner__link--live:focus::after {
    opacity: 1
}

.c-radioBanner__programContainer {
    display: flex;
    max-width: 100%
}

.c-radioBanner__link--program {
    position: relative;
    font-size: 1.125rem;
    font-weight: 700;
    color: #000;
    text-align: left
}

.c-radioBanner__link--program::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #000;
    opacity: 0
}

.c-radioBanner__link--program::before {
    bottom: 0px
}

.c-radioBanner__link--program:hover::before {
    opacity: 1
}

.c-radioBanner__link--program::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-radioBanner__link--program:focus::after {
    opacity: 1
}

.c-radioBanner__time {
    font-size: 0.75rem;
    color: #1d2d44
}

.c-radioBanner__link--program, .c-radioBanner__time {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.c-radioBanner--narrow .c-radioBanner__inner {
    padding: 0 16px 0 0
}

.c-radioBanner--narrow .c-radioBanner__left {
    width: 100%
}

.c-radioBanner--narrow .c-radioBanner__live {
    width: calc(100% - 116px)
}

.c-radioBanner--narrow .c-radioBanner__liveText {
    width: 100%
}

.c-radioBanner--narrow:not(.c-radioBanner--hasShowImage) .c-radioBanner__container {
    min-height: auto
}

.c-radioBanner--narrow:not(.c-radioBanner--hasShowImage) .c-radioBanner__inner {
    padding: 24px
}

.c-radioBanner--narrow:not(.c-radioBanner--hasShowImage) .c-radioBanner__live {
    width: calc(100% - 68px)
}

.c-radioBanner:not(.c-radioBanner--hasShowImage) .c-radioBanner__link--program {
    line-height: 120%
}

@media (min-width: 498px) {
    .c-radioBanner__header {
        padding: 0
    }
}

.c-radioBanner--wide .c-radioBanner__logoInner {
    width: 160px
}

.c-radioBanner--wide .c-radioBanner__inner {
    padding: 0 16px 0 0
}

.c-radioBanner--wide:not(.c-radioBanner--hasShowImage) .c-radioBanner__inner {
    padding: 24px
}

.c-radioBanner--wide .c-radioBanner__left {
    align-items: center;
    max-width: 100%;
    width: 100%
}

.c-radioBanner--wide .c-radioBanner__play {
    width: 64px;
    height: 64px
}

.c-radioBanner--wide .c-radioBanner__play .c-radioBanner__icon {
    width: 32px;
    height: 32px;
    margin-left: 5px
}

.c-radioBanner--wide .c-radioBanner__square {
    display: none
}

.c-radioBanner--wide .c-radioBanner__live {
    max-width: calc(100% - 116px);
    width: 100%
}

.c-radioBanner--wide .c-radioBanner__liveText {
    width: 100%
}

@media (min-width: 498px) {
    .c-radioBanner--wide {
        margin: 0
    }

    .c-radioBanner--wide .c-radioBanner__left {
        max-width: calc(100% - 84px)
    }

    .c-radioBanner--wide .c-radioBanner__social {
        display: flex;
        align-items: center
    }

    .c-radioBanner--wide .c-radioBanner__inner {
        padding: 0 32px 0 0;
        border-radius: 8px
    }

    .c-radioBanner--wide:not(.c-radioBanner--hasShowImage) .c-radioBanner__inner {
        padding: 24px 32px
    }

    .c-radioBanner--wide .c-radioBanner__programContainer {
        max-width: 400px;
        width: 100%
    }
}

@media (min-width: 768px) {
    .c-radioBanner--wide .c-radioBanner__logo {
        display: flex
    }

    .c-radioBanner--wide .c-radioBanner__liveText {
        max-width: calc(100% - 80px)
    }

    .c-radioBanner--wide .c-radioBanner__live {
        width: 100%;
        max-width: calc(100% - 224px)
    }

    .c-radioBanner--wide .c-radioBanner__personality {
        display: none
    }

    .c-radioBanner--wide .c-radioBanner__link--live {
        font-size: 0.75rem
    }

    .c-radioBanner--wide .c-radioBanner__play {
        display: flex
    }

    .c-radioBanner--wide .c-radioBanner__playSmall {
        display: none
    }

    .c-radioBanner--wide .c-radioBanner__link--program {
        font-size: 1.375rem
    }

    .c-radioBanner--wide:not(.c-radioBanner--hasShowImage) .c-radioBanner__link--program {
        line-height: initial
    }

    .c-radioBanner--wide:not(.c-radioBanner--hasShowImage) .c-radioBanner__square {
        display: none
    }

    .c-radioBanner--wide .c-radioBanner__inner {
        padding: 24px 32px
    }
}

@media (min-width: 1312px) {
    .c-radioBanner--wide .c-radioBanner__container {
        min-height: 160px;
        height: 100px
    }

    .c-radioBanner--wide .c-radioBanner__inner {
        flex-wrap: nowrap
    }

    .c-radioBanner--wide .c-radioBanner__live {
        max-width: calc(100% - 448px)
    }

    .c-radioBanner--wide:not(.c-radioBanner--hasShowImage) .c-radioBanner__live {
        max-width: calc(100% - 224px)
    }

    .c-radioBanner--wide .c-radioBanner__personality {
        display: flex;
        height: 100%;
        margin-right: 64px
    }

    .c-radioBanner--wide .c-radioBanner__personalityImage {
        position: absolute;
        bottom: -24px;
        height: 160px
    }

    .c-radioBanner--wide .c-radioBanner__personality, .c-radioBanner--wide .c-radioBanner__personalityImage {
        width: 100%;
        max-width: 160px
    }
}

.c-radioBanner--mini {
    width: 260px;
    height: 54px;
    margin-left: 16px
}

.c-radioBanner--mini .c-radioBanner__inner {
    padding: 0 8px 0 0
}

.c-radioBanner--mini .c-radioBanner__personality {
    width: 54px;
    margin-right: 8px
}

.c-radioBanner--mini .c-radioBanner__personalityImage {
    width: 54px;
    height: 54px
}

.c-radioBanner--mini .c-radioBanner__play {
    display: flex;
    width: 24px;
    height: 24px;
    margin: 3px 8px 0 0
}

.c-radioBanner--mini .c-radioBanner__icon {
    width: 16px;
    height: 16px;
    margin-left: 2px
}

.c-radioBanner--mini .c-radioBanner__liveText {
    max-width: 150px;
    margin-right: 8px
}

.c-radioBanner--mini .c-radioBanner__link--live {
    display: none
}

.c-radioBanner--mini .c-radioBanner__link--program {
    font-size: 0.875rem;
    line-height: 1rem
}

.c-radioBanner--mini .c-radioBanner__time {
    font-size: 0.625rem;
    line-height: 0.875rem
}

.c-radioBanner--mini:not(.c-radioBanner--hasShowImage) .c-radioBanner__inner {
    padding: 8px
}

.c-radioBanner--mini:not(.c-radioBanner--hasShowImage) .c-radioBanner__square {
    width: 40px;
    flex-shrink: 0
}

.c-radioBanner--mini:not(.c-radioBanner--hasShowImage) .c-radioBanner__squareImage {
    width: 38px;
    height: 38px
}

.c-radioBanner--sticky {
    position: fixed;
    width: 100%;
    margin: 0;
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 1000
}

.c-radioBanner--sticky .c-radioBanner__live {
    max-width: none
}

.c-radioBanner--sticky .c-radioBanner__liveText {
    max-width: 240px
}

.c-radioBanner--sticky .c-radioBanner__personality {
    margin: -8px 8px 0 8px
}

.c-radioBanner--sticky .c-radioBanner__personalityImage {
    max-width: none;
    width: 62px;
    height: 62px
}

.c-radioBanner--sticky .c-radioBanner__play {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 0
}

.c-radioBanner--sticky .c-radioBanner__link--program {
    font-size: 0.875rem
}

.c-radioBanner--sticky .c-radioBanner__time {
    font-size: 0.75rem
}

.c-radioBanner--sticky.c-radioBanner--shift {
    bottom: 107px
}

.c-readmore__label {
    font-weight: 800;
    margin-right: 7px;
    text-transform: uppercase
}

.c-readmore__link {
    font-weight: 600
}

.c-regions {
    padding-bottom: 16px
}

.c-regions__heading {
    display: flex;
    align-items: center;
    padding: 16px 8px
}

@media (min-width: 768px) {
    .c-regions__icon {
        margin: 0 0 -8px 8px
    }

    .c-regions__icon--mobile {
        display: none
    }

    .c-regions__current {
        background-image: none
    }

    .c-regions--inBody .c-regions__selector {
        padding-left: 0
    }
}

.c-responsiveContainer {
    position: relative
}

.c-responsiveContainer__iframe {
    width: 100%
}

.c-responsiveContainer--keepRatio .c-responsiveContainer__aspectRatio {
    height: 0;
    width: 100%
}

.c-responsiveContainer--centered {
    margin: 0 auto
}

.c-responsiveContainer--keepRatio .c-responsiveContainer__iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%
}

.c-responsiveContainer--noMargin {
    width: auto;
    margin: 0 -16px
}

@media (min-width: 498px) {
    .c-responsiveContainer {
        width: auto;
        margin: 0 auto
    }
}

.c-search {
    position: relative
}

.c-search__input {
    width: 100%;
    height: 52px;
    padding: 16px 50px 16px 16px;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    letter-spacing: 0;
    font-weight: 600
}

.c-search__label {
    margin-bottom: 4px;
    display: inline-block;
    font-weight: 600
}

.c-search__submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 16px;
    opacity: 0.4;
    transition: opacity 0.3s
}

.c-search__submit:focus {
    opacity: 1
}

.c-search__icon {
    width: 18px;
    height: 18px;
    margin-top: 7px
}

.c-search__input:focus + .c-search__submit {
    opacity: 1
}

.c-sectionHeading--divider {
    text-align: center
}

.c-sectionHeading__title {
    font-size: 1.3125rem
}

.c-sectionHeading__divider {
    position: relative;
    display: inline-block;
    margin: 0 auto
}

.c-sectionHeading__icon {
    height: 10px;
    width: 10px
}

.c-sectionHeading__iconContainer {
    line-height: 1;
    min-height: 1px;
    margin-right: 62px;
    margin-left: 62px
}

.c-sectionHeading__iconContainer::before, .c-sectionHeading__iconContainer::after {
    content: '';
    position: absolute;
    top: 8px;
    height: 2px;
    width: 50px;
    background-color: #b4b4b4
}

.c-sectionHeading__iconContainer::before {
    left: 0
}

.c-sectionHeading__iconContainer::after {
    right: 0
}

.c-sectionHeading__iconContainer svg g {
    fill: #e41b23
}

.c-shimmer {
    position: relative;
    overflow: hidden
}

.c-shimmer::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 40px, rgba(255, 255, 255, 0) 80px);
    z-index: 1000;
    -webkit-animation: shine 1.7s ease-out infinite;
    animation: shine 1.7s ease-out infinite
}

@-webkit-keyframes shine {
    0% {
        transform: translate3d(-20%, 0, 0)
    }
    100% {
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes shine {
    0% {
        transform: translate3d(-20%, 0, 0)
    }
    100% {
        transform: translate3d(100%, 0, 0)
    }
}

.c-social {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.c-social__link {
    position: relative;
    width: auto;
    padding: 0 16px 12px;
    margin: 0 0 12px 0;
    border: none;
    outline: none
}

.c-social__link::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #e41b23;
    opacity: 0
}

.c-social__link::before {
    bottom: 0px
}

.c-social__link:hover::before {
    opacity: 1
}

.c-social__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #e41b23;
    opacity: 0;
    pointer-events: none
}

.c-social__link:focus::after {
    opacity: 1
}

.c-social__link:hover, .c-social__link:focus {
    background-color: initial
}

.c-social__link::after {
    top: -16px
}

.c-social__icon {
    width: 26px;
    height: 26px
}

.c-social__icon--facebook {
    width: 34px;
    height: 34px
}

.c-social__icon--twitter {
    width: 30px;
    height: 30px
}

.c-social__icon--youtube {
    width: 34.65px;
    height: 24.22px
}

.c-social--footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 20px
}

.c-social--footer .c-social__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    padding: 0;
    margin: 0 12px 0 0
}

.c-social--footer .c-social__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-social--footer .c-social__link:focus::after {
    opacity: 1
}

.c-social--footer .c-social__link:last-of-type {
    margin-right: 0
}

.c-social--footer .c-social__link::before {
    display: none
}

.c-social--footer .c-social__link:hover, .c-social--footer .c-social__link:focus {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px
}

@media (min-width: 768px) {
    .c-social__link {
        margin: 0 12px 0 0
    }

    .c-social__link:first-of-type {
        padding-left: 0;
        max-width: 42px
    }

    .c-social--footer {
        margin-top: 0;
        margin-bottom: 0
    }
}

.c-socialShare__copiedLink {
    opacity: 0;
    border-radius: 3px;
    margin: 0 auto;
    right: 0;
    left: 0;
    position: fixed;
    background-color: #808083;
    color: #fff;
    z-index: 10001000;
    top: 136px;
    padding: 16px;
    width: 123px
}

.c-socialShare__copiedLink--regular {
    position: -webkit-sticky;
    position: sticky
}

.c-socialShare__copiedLink--longform {
    top: 126px
}

.c-socialShare__copiedLink--gallery {
    top: 16px
}

.c-socialShare__list {
    display: flex;
    padding-left: 0
}

.c-socialShare__item {
    display: inline-block;
    outline: none;
    padding: 0;
    position: relative
}

.c-socialShare__item--hidden {
    display: none;
    cursor: default
}

.c-socialShare__link {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0.7rem;
    text-transform: capitalize;
    border: 0;
    transition: 0.2s background-color ease-in;
    outline: none
}

.c-socialShare__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-socialShare__link:focus::after {
    opacity: 1
}

.c-socialShare__link:hover {
    text-decoration: none;
    border: 0
}

.c-socialShare__link:hover, .c-socialShare__link:focus {
    background-color: transparent
}

.c-socialShare__icon {
    width: 22px;
    height: 22px
}

.c-socialShare__icon--comments {
    width: 30px;
    height: 30px
}

.c-socialShare__icon--email {
    width: 20px;
    height: 20px
}

.c-socialShare__icon--facebook {
    width: 28px;
    height: 28px
}

.c-socialShare__icon--more {
    width: 18px;
    height: 18px
}

.c-socialShare__icon--close {
    width: 18px;
    height: 17px
}

.c-socialShare--article .c-socialShare__item--twitter {
    display: none
}

.c-socialShare--article .c-socialShare__item:nth-child(5n) {
    display: none
}

.c-socialShare--article .c-socialShare__item--moreArticle {
    display: none
}

@media (min-width: 340px) {
    .c-socialShare--article .c-socialShare__item:nth-child(5n) {
        display: block
    }
}

@media (min-width: 768px) {
    .c-socialShare--article .c-socialShare__item--twitter {
        display: block
    }

    .c-socialShare--article .c-socialShare__item--whatsapp {
        display: none
    }

    .c-socialShare--article .c-socialShare__item--moreArticle {
        display: inline-block
    }

    .c-socialShare--article .c-socialShare__item--moreNav {
        display: none
    }
}

.c-socialShare--articleHover .c-socialShare__link:hover svg.c-icon--inverted, .c-socialShare--articleHover .c-socialShare__link:focus svg.c-icon--inverted {
    -webkit-filter: none;
    filter: none
}

.c-socialShare--articleHover .c-socialShare__link:hover svg:not(.c-icon--inverted), .c-socialShare--articleHover .c-socialShare__link:focus svg:not(.c-icon--inverted) {
    -webkit-filter: invert(100%);
    filter: invert(100%)
}

.c-socialShare--articleHover .c-socialShare__item--comments .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--comments .c-socialShare__link:focus {
    background-color: #e41b23
}

.c-socialShare--articleHover .c-socialShare__item--facebook .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--facebook .c-socialShare__link:focus {
    background-color: #3b5998
}

.c-socialShare--articleHover .c-socialShare__item--twitter .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--twitter .c-socialShare__link:focus {
    background-color: #1da1f2
}

.c-socialShare--articleHover .c-socialShare__item--email .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--email .c-socialShare__link:focus {
    background-color: #0072c6
}

.c-socialShare--articleHover .c-socialShare__item--whatsapp .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--whatsapp .c-socialShare__link:focus {
    background-color: #25d366
}

.c-socialShare--articleHover .c-socialShare__item--pinterest .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--pinterest .c-socialShare__link:focus {
    background-color: #bd081c
}

.c-socialShare--articleHover .c-socialShare__item--linkedin .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--linkedin .c-socialShare__link:focus {
    background-color: #0077b5
}

.c-socialShare--articleHover .c-socialShare__item--reddit .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--reddit .c-socialShare__link:focus {
    background-color: #ff4500
}

.c-socialShare--articleHover .c-socialShare__item--copy .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--copy .c-socialShare__link:focus {
    background-color: #002e5d
}

.c-socialShare--articleHover .c-socialShare__item--more .c-socialShare__link:hover, .c-socialShare--articleHover .c-socialShare__item--more .c-socialShare__link:focus {
    background-color: #002e5d
}

.c-socialShare--navbar .c-socialShare__list {
    justify-content: space-around
}

.c-socialShare--navbar .c-socialShare__item {
    width: 25%;
    max-width: 60px
}

.c-socialShare--navbar .c-socialShare__link {
    height: 60px;
    width: auto
}

.c-socialShare--navbar .c-socialShare__link::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #808083;
    opacity: 0
}

.c-socialShare--navbar .c-socialShare__link::before {
    bottom: 0px
}

.c-socialShare--navbar .c-socialShare__link:hover::before {
    opacity: 1
}

.c-socialShare--navbar .c-socialShare__link:focus {
    z-index: 1
}

.c-socialShare--navbar .c-socialShare__item--twitter, .c-socialShare--navbar .c-socialShare__item--email {
    display: none
}

@media (min-width: 768px) {
    .c-socialShare--navbar {
        width: auto
    }

    .c-socialShare--navbar .c-socialShare__list {
        flex-wrap: nowrap
    }

    .c-socialShare--navbar .c-socialShare__item {
        width: auto
    }

    .c-socialShare--navbar .c-socialShare__link {
        width: 48px;
        height: 54px
    }

    .c-socialShare--navbar .c-socialShare__link::before, .c-socialShare--navbar .c-socialShare__link::after {
        border-color: #e41b23
    }

    .c-socialShare--navbar .c-socialShare__item--facebook .c-socialShare__icon, .c-socialShare--navbar .c-socialShare__item--twitter .c-socialShare__icon, .c-socialShare--navbar .c-socialShare__item--more .c-socialShare__icon {
        -webkit-filter: none;
        filter: none
    }

    .c-socialShare--navbar .c-socialShare__item--twitter, .c-socialShare--navbar .c-socialShare__item--email {
        display: block
    }

    .c-socialShare--navbar .c-socialShare__item--whatsapp {
        display: none
    }
}

.c-socialShare--popup .c-socialShare__list {
    flex-wrap: wrap;
    margin: -4px
}

.c-socialShare--popup .c-socialShare__item {
    display: inline-block;
    width: calc(50% - 8px);
    margin: 4px
}

.c-socialShare--popup .c-socialShare__link {
    padding: 8px 16px;
    justify-content: flex-start;
    line-height: inherit;
    height: auto;
    width: auto
}

.c-socialShare--popup .c-socialShare__item--facebook .c-socialShare__link {
    background-color: #3b5998
}

.c-socialShare--popup .c-socialShare__item--twitter .c-socialShare__link {
    background-color: #1da1f2
}

.c-socialShare--popup .c-socialShare__item--email .c-socialShare__link {
    background-color: #0072c6
}

.c-socialShare--popup .c-socialShare__item--whatsapp .c-socialShare__link {
    background-color: #25d366
}

.c-socialShare--popup .c-socialShare__item--pinterest .c-socialShare__link {
    background-color: #bd081c
}

.c-socialShare--popup .c-socialShare__item--linkedin .c-socialShare__link {
    background-color: #0077b5
}

.c-socialShare--popup .c-socialShare__item--reddit .c-socialShare__link {
    background-color: #ff4500
}

.c-socialShare--popup .c-socialShare__item--copy .c-socialShare__link {
    background-color: #0052cc
}

.c-socialShare--popup .c-socialShare__item--facebook .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--facebook .c-socialShare__link:focus {
    background-color: #1e2e4f
}

.c-socialShare--popup .c-socialShare__item--twitter .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--twitter .c-socialShare__link:focus {
    background-color: #0967a0
}

.c-socialShare--popup .c-socialShare__item--email .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--email .c-socialShare__link:focus {
    background-color: #003760
}

.c-socialShare--popup .c-socialShare__item--whatsapp .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--whatsapp .c-socialShare__link:focus {
    background-color: #167c3c
}

.c-socialShare--popup .c-socialShare__item--pinterest .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--pinterest .c-socialShare__link:focus {
    background-color: #5b040e
}

.c-socialShare--popup .c-socialShare__item--linkedin .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--linkedin .c-socialShare__link:focus {
    background-color: #00344f
}

.c-socialShare--popup .c-socialShare__item--reddit .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--reddit .c-socialShare__link:focus {
    background-color: #992900
}

.c-socialShare--popup .c-socialShare__item--copy .c-socialShare__link:hover, .c-socialShare--popup .c-socialShare__item--copy .c-socialShare__link:focus {
    background-color: #002966
}

.c-socialShare--popup .c-socialShare__text {
    margin-left: 10px;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase
}

.c-socialShare--popup .c-socialShare__icon {
    width: 24px;
    height: 24px
}

@media (min-width: 340px) {
    .c-socialShare--popup .c-socialShare__link {
        font-size: 1.125rem
    }
}

.c-simpleVideo {
    position: relative;
    display: block;
    height: 100%;
    width: 100%
}

.c-simpleVideo video {
    height: 100%;
    width: auto;
    position: relative;
    transform: translateX(-50%);
    left: 50%
}

.c-simpleVideo .vjs-poster {
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.c-sponsor {
    display: flex;
    align-items: center
}

.c-sponsor__image {
    display: block;
    height: 72px;
    width: 72px
}

.c-sponsor__name {
    display: flex
}

.c-sponsor__label {
    display: block;
    font-size: 0.875rem;
    color: #4d7192
}

.c-sponsor__icon {
    cursor: pointer;
    top: 2px;
    position: relative
}

.c-sponsor__icon--question {
    height: 18px;
    width: 18px
}

.c-sponsor__button {
    position: relative
}

.c-sponsor__link {
    border: none;
    margin-right: 16px;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: #0052cc
}

.c-sponsor__link--image {
    position: relative;
    flex-shrink: 0;
    display: block
}

.c-sponsor__link--image::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.c-sponsor__link--image:focus::after {
    opacity: 1
}

.c-sponsor__link:hover, .c-sponsor__link:focus {
    text-decoration: underline
}

.c-sponsor--section .c-sponsor__label {
    font-size: 0.75rem;
    font-weight: 600
}

.c-sponsor--section .c-sponsor__link {
    margin: 0 0 0 16px
}

.c-sponsor--section .c-sponsor__link--image:hover, .c-sponsor--section .c-sponsor__link--image:focus {
    background-color: initial
}

.c-sponsor--section .c-sponsor__image {
    width: auto;
    max-height: 30px
}

.c-sponsor--heading {
    height: 52px;
    margin-bottom: 0
}

.c-sponsor--inverted .c-sponsor__label {
    color: #fff
}

.c-sponsor--inline .c-sponsor__details {
    display: none
}

.c-sponsor--inline .c-sponsor__image {
    height: 100%;
    width: auto
}

.c-sponsor--inline .c-sponsor__link {
    margin-right: 0;
    height: 30px
}

.c-stickToTop {
    position: -webkit-sticky;
    position: sticky;
    top: 178px;
    overflow: hidden;
    z-index: 1
}

@media (min-width: 1024px) {
    .c-stickToTop--shift {
        top: 152px
    }

    .c-stickToTop--bigShift {
        top: 198px
    }
}

.c-stickyVideo__info {
    display: none
}

.c-stickyVideo--stuck {
    position: fixed;
    top: 52px;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 5500
}

.c-stickyVideo--anchored {
    position: absolute
}

.c-stickyVideo--stuck .c-stickyVideo__inner {
    text-align: center;
    background-color: #000;
    font-size: 0;
    box-shadow: 0px 4px 12px 0 rgba(0, 0, 0, 0.16)
}

.c-stickyVideo--stuck .c-stickyVideo__info {
    display: flex;
    background-color: #fff;
    border-top: 1px solid #b5b5b8;
    border-right: 1px solid #b5b5b8;
    border-left: 1px solid #b5b5b8
}

.c-stickyVideo--stuck .c-stickyVideo__title {
    width: 90%;
    margin: 0 12px;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: #000;
    text-align: left
}

.c-stickyVideo--stuck .c-stickyVideo__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.c-stickyVideo--stuck .c-stickyVideo__close {
    position: relative;
    padding: 0 12px;
    pointer-events: all;
    height: 28px;
    outline: none;
    display: flex;
    align-items: center
}

.c-stickyVideo--stuck .c-stickyVideo__mute, .c-stickyVideo--stuck .c-stickyVideo__unmute {
    display: none
}

.c-stickyVideo--stuck .c-stickyVideo__icon {
    width: 12px;
    height: 12px
}

.c-stickyVideo--stuck .c-stickyVideo__embed {
    position: relative;
    width: 320px;
    height: 180px;
    pointer-events: all
}

.c-stickyVideo--stuck, .c-stickyVideo--detached .c-stickyVideo__embed {
    -webkit-animation: sticky-vid-fade 200ms ease-out;
    animation: sticky-vid-fade 200ms ease-out
}

.c-stickyVideo--stuck.c-stickyVideo--mini {
    top: auto;
    bottom: 0
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__innerContainer {
    display: flex
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__embed {
    order: 1;
    width: 192px;
    height: 108px
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__title {
    font-size: 0.75rem;
    line-height: 1rem;
    height: 84px;
    min-height: 84px;
    margin: 0 0 0 12px;
    -ms-grid-row-align: center;
    align-self: center;
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__title > .u-lineClamp {
    max-height: calc(1em * (1.5 - 0.15) * 5);
    height: calc(1em * (1.5 - 0.15) * 5);
    position: relative;
    overflow: hidden
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__title > .u-lineClamp::after {
    content: '';
    display: block;
    bottom: 0;
    right: 0;
    position: absolute;
    height: calc(1em * 1.5);
    width: 20%;
    min-width: calc((1em * 1.5) * 2);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%)
}

@supports (-webkit-line-clamp: 1) {
    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__title > .u-lineClamp::after {
        display: none
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__title > .u-lineClamp {
        height: auto
    }
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__text {
    white-space: normal
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__info {
    order: 2;
    pointer-events: all
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__buttons {
    display: flex;
    flex-direction: column
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__close, .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__mute, .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__unmute {
    padding: 12px
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__close .c-stickyVideo__icon {
    width: 14px;
    height: 14px
}

.c-stickyVideo--stuck.c-stickyVideo--mini:not(.c-stickyVideo--muted) .c-stickyVideo__mute, .c-stickyVideo--stuck.c-stickyVideo--mini.c-stickyVideo--muted .c-stickyVideo__unmute {
    display: block
}

.c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__mute .c-stickyVideo__icon, .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__unmute .c-stickyVideo__icon {
    height: 20px;
    width: 20px
}

.c-stickyVideo__loader {
    display: none
}

.c-stickyVideo--loading .c-stickyVideo__loader {
    display: block
}

.c-stickyVideo--customNav {
    top: 64px;
    z-index: 6500
}

@media (min-width: 498px) {
    .c-stickyVideo--stuck {
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        width: 384px
    }

    .c-stickyVideo--stuck .c-stickyVideo__inner {
        padding: 32px;
        background-color: transparent;
        box-shadow: none
    }

    .c-stickyVideo--stuck .c-stickyVideo__innerContainer {
        box-shadow: 0px 0px 12px 0 rgba(0, 0, 0, 0.16)
    }

    .c-stickyVideo--stuck .c-stickyVideo__close::before {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        border-bottom: 2px solid #e41b23;
        opacity: 0
    }

    .c-stickyVideo--stuck .c-stickyVideo__close::before {
        bottom: 0px
    }

    .c-stickyVideo--stuck .c-stickyVideo__close:hover::before {
        opacity: 1
    }

    .c-stickyVideo--stuck .c-stickyVideo__close::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        border: 2px solid #e41b23;
        opacity: 0;
        pointer-events: none
    }

    .c-stickyVideo--stuck .c-stickyVideo__close:focus::after {
        opacity: 1
    }

    .c-stickyVideo--customNav {
        top: auto;
        z-index: 5500
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__innerContainer {
        display: block
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__embed {
        width: 320px;
        height: 180px
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__title {
        display: block;
        font-size: 0.875rem;
        line-height: 1.75rem;
        height: 28px;
        min-height: auto
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 28px
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__close {
        padding: 0 12px;
        height: 28px
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__close .c-stickyVideo__icon {
        width: 12px;
        height: 12px
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__mute, .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__unmute {
        display: none !important
    }
}

@media (min-width: 1024px) {
    .c-stickyVideo--stuck {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "SV-A SV-A SV-A SV-A SV-A SV-A SV-A SV-A SV-B SV-B SV-B SV-B";
        width: 100%;
        padding-bottom: 32px
    }

    .c-stickyVideo--stuck .c-stickyVideo__spacer {
        grid-area: SV-A
    }

    .c-stickyVideo--stuck .c-stickyVideo__grid {
        grid-area: SV-B;
        width: calc(100% - 24px);
        max-width: 380px;
        margin: 0 12px
    }

    .c-stickyVideo--stuck .c-stickyVideo__inner {
        position: relative;
        height: 0;
        padding: 0 0 calc(56.25% + 29px) 0;
        overflow: hidden;
        box-shadow: 0px 0px 12px 0 rgba(0, 0, 0, 0.16)
    }

    .c-stickyVideo--stuck .c-stickyVideo__innerContainer {
        box-shadow: none
    }

    .c-stickyVideo--stuck .c-stickyVideo__embed {
        position: absolute;
        top: 29px;
        width: 100%;
        height: calc(100% - 29px);
        max-width: 100%;
        max-height: calc(100% - 29px)
    }

    .c-stickyVideo--stuck.c-stickyVideo--mini .c-stickyVideo__embed {
        position: absolute;
        top: 29px;
        width: 100%;
        height: calc(100% - 29px);
        max-width: 100%;
        max-height: calc(100% - 29px)
    }
}

@media (min-width: 1024px) {
    .c-stickyVideo--stuck .c-stickyVideo__spacer {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 15
    }

    .c-stickyVideo--stuck .c-stickyVideo__grid {
        -ms-grid-row: 1;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7
    }
}

@media (min-width: 1312px) {
    .c-stickyVideo--stuck {
        left: 0
    }

    .c-stickyVideo--stuck .c-stickyVideo__grid {
        margin: 0 16px 0 32px;
        width: calc(100% - 48px)
    }
}

@-webkit-keyframes sticky-vid-fade {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes sticky-vid-fade {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.c-surveyCta {
    margin: 32px 0 52px 0
}

.c-surveyCta__inner {
    display: flex;
    background: #ededf0
}

.c-surveyCta__content {
    background: #ededf0;
    flex-shrink: 1;
    padding: 16px 8px 16px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around
}

.c-surveyCta__message {
    margin: 8px 0;
    font-size: 0.875rem;
    line-height: 1.125rem
}

.c-surveyCta__button {
    border-radius: 20px;
    white-space: nowrap
}

.c-surveyCta__button:hover, .c-surveyCta__button:focus {
    background-color: #fff;
    border-color: #fff;
    color: #e41b23
}

.c-surveyCta__media {
    display: flex;
    flex-basis: 50%;
    align-items: flex-end;
    margin-top: -36px;
    margin-right: -10px;
    flex-shrink: 0
}

.c-surveyCta__image {
    width: 100%
}

.c-surveyCta--home {
    margin: 52px 16px 32px 16px
}

.c-surveyCta--clicked .c-surveyCta__button {
    display: none
}

.c-surveyCta--clicked .c-surveyCta__content {
    padding: 50px 8px 50px 0
}

.c-surveyCta--clicked .c-surveyCta__message {
    font-size: 1.125rem;
    line-height: 1.375rem
}

@media (min-width: 498px) {
    .c-surveyCta {
        margin-top: -32px
    }

    .c-surveyCta__inner {
        margin-left: 126px
    }

    .c-surveyCta__message {
        font-size: 1.125rem;
        line-height: 1.375rem
    }

    .c-surveyCta__content, .c-surveyCta--clicked .c-surveyCta__content {
        padding: 20px 32px
    }

    .c-surveyCta__media {
        margin-left: -126px;
        margin-bottom: -6px;
        flex-basis: 58%
    }

    .c-surveyCta--clicked {
        margin-top: 64px
    }

    .c-surveyCta--clicked .c-surveyCta__media {
        margin-bottom: -22px;
        margin-top: -56px
    }

    .c-surveyCta--home {
        margin: 52px 16px 32px 16px
    }
}

@media (min-width: 1024px) {
    .c-surveyCta--home {
        margin: 32px auto
    }

    .c-surveyCta--home .c-surveyCta__media {
        display: none
    }

    .c-surveyCta--home .c-surveyCta__content {
        padding: 32px;
        width: 100%
    }

    .c-surveyCta--home .c-surveyCta__inner {
        margin: 0
    }

    .c-surveyCta--home .c-surveyCta__logo {
        width: 156px
    }

    .c-surveyCta--home .c-surveyCta__message {
        font-size: 1.0625rem;
        line-height: 1.3em;
        width: 100%
    }

    .c-surveyCta--home.c-surveyCta--clicked {
        margin-top: 0
    }
}

@media (min-width: 1312px) {
    .c-surveyCta__content {
        padding: 26px 32px
    }

    .c-surveyCta__media {
        flex-shrink: 0;
        flex-basis: 50%;
        margin-bottom: -22px
    }

    .c-surveyCta--clicked {
        margin-top: 84px
    }
}

.c-tags {
    margin-top: -4px
}

.c-tags__body {
    display: flex;
    flex-wrap: wrap
}

.c-tags__link {
    background-color: #ededf0;
    border: 3px solid #ededf0;
    color: #0d1321;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    padding: 5px 16px;
    margin: 4px 4px 0 0;
    border-radius: 3px;
    display: inline-block
}

.c-tags__link:hover {
    background-color: #000;
    border: 3px solid #000;
    color: #fff
}

.c-tags__link:focus {
    border: 3px solid #002e5d;
    background-color: #ededf0;
    color: #000
}

.c-tags__link--more {
    background-color: #b5b5b8;
    border-color: #b5b5b8;
    color: #000;
    padding-right: 16px;
    padding-left: 16px
}

.c-tags__link--more:focus {
    background-color: #b5b5b8;
    color: #000
}

.c-toolTip {
    z-index: 100;
    position: absolute;
    visibility: hidden
}

.c-toolTip--mobile {
    width: 100%;
    left: 0
}

.c-toolTip--desktop {
    width: auto;
    left: 50%
}

.c-toolTip__title {
    font-weight: 700
}

.c-toolTip__content {
    font-weight: 400
}

.c-toolTip__title, .c-toolTip__content {
    color: #000;
    margin-bottom: 6px
}

.c-toolTip__inner {
    box-shadow: 0 0 3px 0 rgba(128, 128, 131, 0.3);
    background: #fff;
    padding: 20px;
    margin: 16px
}

.c-toolTip__pointer {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 16px;
    width: 16px;
    z-index: 100
}

.c-toolTip__pointer::before {
    content: '';
    position: absolute;
    top: 4px;
    box-sizing: border-box;
    border: 8px solid #000;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(135deg);
    box-shadow: -2px 2px 3px 0 rgba(128, 128, 131, 0.3)
}

.c-toolTip__button {
    position: absolute;
    top: 36px;
    right: 36px
}

.c-toolTip--desktop .c-toolTip__button {
    display: none
}

.c-toolTip--right .c-toolTip__inner {
    position: relative;
    left: -30%
}

.c-toolTip__icon {
    width: 14px;
    height: 14px
}

.c-toolTip--active.c-toolTip--mobile {
    visibility: visible
}

@media (min-width: 498px) {
    .c-toolTip {
        left: 50%;
        transform: translateX(-50%)
    }

    .c-toolTip--active.c-toolTip--desktop {
        visibility: visible
    }

    .c-toolTip--active.c-toolTip--mobile {
        display: none
    }

    .c-toolTip__pointer {
        display: block
    }

    .c-toolTip__inner {
        width: 300px;
        margin: 12px 0 0 0
    }

    .c-toolTip--flipped {
        transform: scaleY(-1) translateY(100%) translateX(-50%);
        margin-top: -20px
    }

    .c-toolTip--flipped .c-toolTip__inner {
        transform: scaleY(-1)
    }
}

.c-ad--storyStream, .c-ad--storyStream .c-ad__container {
    height: 100%
}

.c-ad--storyStream .c-ad__inner {
    margin: 0;
    padding: 24px 0;
    min-height: 164px;
    flex-direction: row
}

.c-ad--storyStream .c-ad__inner:hover .c-ad__media::after {
    display: block;
    opacity: 1
}

.c-ad--storyStream div[data-str-native-key] .c-ad__inner {
    flex-direction: row-reverse
}

.c-ad--storyStream .c-ad__details {
    padding: 0 0 0 16px;
    margin-right: 0
}

.c-ad--storyStream .c-ad__headline {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    letter-spacing: 0;
    font-weight: 600;
    display: block;
    color: #000;
    border: none;
    margin-bottom: 4px
}

.c-ad--storyStream .c-ad__headline:hover {
    background: none
}

.c-ad--storyStream .c-ad__excerpt {
    display: none
}

.c-ad--storyStream .c-ad__link {
    display: block;
    border-bottom: none
}

.c-ad--storyStream .c-ad__link:hover {
    background: none
}

.c-ad--storyStream .c-ad__placeholder {
   /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-greystripe.png); */
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0 16px
}

.c-ad--storyStream .c-ad__sponsor {
    display: flex;
    align-items: center;
    flex-wrap: nowrap
}

.c-ad--storyStream > .c-ad__sponsorLink {
    flex-shrink: 0
}

.c-ad--storyStream .c-ad__sponsorLink {
    padding: 0;
    border-bottom: none;
    display: inherit;
    color: #4d7192
}

.c-ad--storyStream .c-ad__sponsorLink[href=''] {
    pointer-events: none
}

.c-ad--storyStream .c-ad__sponsorLink:hover {
    background: none
}

.c-ad--storyStream .c-ad__sponsorLogo {
    height: 40px;
    width: 40px;
    background: #fff
}

.c-ad--storyStream .c-ad__sponsorInfo {
    margin-left: 8px
}

.c-ad--storyStream .c-ad__sponsorLabel {
    display: block;
    color: #676769;
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    margin-bottom: 4px
}

.c-ad--storyStream .c-ad__sponsorName {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: -4px;
    font-weight: 600;
    color: #0052cc
}

.c-ad--storyStream .c-ad__toolTip {
    margin-left: 10px
}

.c-ad--storyStream .c-ad__icon {
    display: flex
}

.c-ad--storyStream .c-ad__icon--question {
    width: 16px;
    height: 16px
}

.c-ad--storyStream .c-ad__unit--loaded, .c-ad--storyStream .c-ad__unit--loaded > div {
    height: 100%
}

.c-ad--storyStream.c-ad--dark .c-ad__placeholder {
   /* background-image: url(../../../../wp-content/themes/shaw-globalnews/assets/dist/images/pattern-darkstripe.png) */
}

.c-ad--storyStream.c-ad--dark .c-ad__headline, .c-ad--storyStream.c-ad--dark .c-ad__sponsorLink {
    color: #fff
}

@media (min-width: 498px) {
    .c-ad--storyStream .c-ad__placeholder {
        padding: 0
    }

    .c-ad--storyStream .c-ad__inner {
        padding: 16px 8px
    }

    .c-ad--storyStream .c-ad__excerpt {
        display: block;
        flex-grow: 1
    }

    .c-ad--storyStream .c-ad__details {
        padding: 0 20px
    }

    .c-ad--storyStream .c-ad__headline {
        font-size: 1.0625rem;
        line-height: 1.3125rem;
        letter-spacing: 0;
        font-weight: 600;
        margin-bottom: 16px
    }

    .c-ad--storyStream .c-ad__media::after {
        content: 'SPONSORED POST';
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.025rem;
        padding: 4px 8px;
        background: #fff;
        color: #0d1321;
        width: auto;
        height: auto;
        left: 0;
        top: 0;
        transition: none;
        opacity: 1;
        position: absolute
    }
}

.c-ad--storyStreamTile .c-ad__excerpt {
    display: none
}

.c-ad--storyStreamTile .c-ad__inner {
    flex-direction: row;
    border-bottom: 1px solid #ededf0
}

.c-ad--storyStreamTile div[data-str-native-key] .c-ad__inner {
    flex-direction: row
}

@media (min-width: 498px) {
    .c-ad--storyStreamTile .c-ad__inner {
        padding: 8px;
        flex-direction: column;
        border: none
    }

    .c-ad--storyStreamTile div[data-str-native-key] .c-ad__inner {
        flex-direction: column
    }

    .c-ad--storyStreamTile .c-ad__placeholder::before {
        display: none
    }

    .c-ad--storyStreamTile .c-ad__details {
        padding: 16px 0 8px 0
    }
}

.c-ad--mostPopular .c-ad__unit {
    height: 1px
}

.c-ad--mostPopular .c-ad__unit--loaded {
    height: 100%
}

.c-ad--mostPopular .c-ad__container {
    padding: 0 16px;
    height: 100%
}

.c-ad--mostPopular .c-ad__inner {
    flex-direction: row
}

.c-ad--mostPopular .c-ad__details {
    margin: 0 0 0 10px
}

.c-ad--mostPopular .c-ad__sponsor {
    display: flex;
    justify-content: space-between
}

.c-ad--mostPopular .c-ad__sponsorLabel {
    font-size: 0.625rem;
    font-weight: 600;
    color: #808083;
    -ms-grid-row-align: center;
    align-self: center
}

.c-ad--mostPopular .c-ad__sponsorLogo {
    width: 40px
}

.c-ad--mostPopular .c-ad__label {
    display: none
}

.c-ad--mostPopular .c-ad__headline {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    letter-spacing: 0;
    font-weight: 600;
    color: #000
}

@media (min-width: 498px) {
    .c-ad--mostPopular .c-ad__container {
        padding: 0
    }

    .c-ad--mostPopular .c-ad__details {
        margin: 0
    }

    .c-ad--mostPopular .c-ad__container .c-ad__inner {
        margin: 0;
        padding: 8px;
        flex-direction: column
    }
}

@media (min-width: 1024px) {
    .c-ad--mostPopular .c-ad__container {
        padding: 0
    }

    .c-ad--mostPopular .c-ad__container .c-ad__inner {
        flex-direction: row-reverse
    }

    .c-ad--mostPopular .c-ad__details {
        padding: 0 16px 0 0
    }
}

.l-article--featured {
    width: 100%
}

.l-article--featured .l-article__story {
    max-width: unset
}

.l-article--featured .l-article__featured {
    max-width: unset
}

.l-editorsPicks {
    margin-right: -16px
}

.l-editorsPicks__buttons {
    display: none
}

@media (min-width: 768px) {
    .l-editorsPicks__buttons {
        display: block
    }

    .l-editorsPicks__next {
        width: 15%
    }

    .l-editorsPicks__carousel {
        margin-right: 0
    }

    .l-editorsPicks--active .l-editorsPicks__next, .l-editorsPicks--active .l-editorsPicks__prev {
        width: 7%
    }

    .l-editorsPicks__slide {
        width: 516px
    }
}

@media (min-width: 1024px) {
    .l-editorsPicks {
        margin-right: 0
    }
}

.l-featuredCarousel__picker {
    display: none
}

@media (min-width: 600px) {
    .l-featuredCarousel__button {
        display: none !important
    }
}

@media (min-width: 768px) {
    .l-featuredCarousel__track {
        margin-left: 0;
        overflow: hidden
    }

    .l-featuredCarousel__slide {
        width: 516px
    }

    .l-featuredCarousel__media {
        position: relative;
        width: 100%
    }

    .l-featuredCarousel__media::before {
        content: '';
        display: block;
        padding: 0 0 54.6875% 0;
        width: 100%;
        height: 0
    }

    .l-featuredCarousel__media > :first-child {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%
    }

    .l-featuredCarousel__picker {
        display: flex;
        margin: 0 16px 24px;
        pointer-events: none
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item {
        width: 25%;
        margin: 0;
        pointer-events: none
    }

    .l-featuredCarousel__picker .l-featuredCarousel__inner {
        padding: 0 8px 20px;
        margin-right: 8px;
        pointer-events: auto
    }

    .l-featuredCarousel__picker .l-featuredCarousel__details {
        padding: 0
    }

    .l-featuredCarousel__picker .l-featuredCarousel__about {
        padding-top: 16px
    }

    .l-featuredCarousel__picker .l-featuredCarousel__headline {
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        font-weight: 700;
        letter-spacing: 0.0064em
    }

    .l-featuredCarousel__picker .l-featuredCarousel__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        pointer-events: none
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before {
        content: '';
        position: absolute;
        left: 8px;
        right: 18px;
        border-bottom: 4px solid #e41b23;
        opacity: 1
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before {
        top: 0px
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active:hover::before {
        opacity: 1
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before {
        -webkit-animation: underlineGrow 6s linear forwards;
        animation: underlineGrow 6s linear forwards;
        transform-origin: left
    }

    @-webkit-keyframes underlineGrow {
        0% {
            transform: scale3d(0, 1, 1)
        }
        100% {
            transform: scale3d(1, 1, 1)
        }
    }@keyframes underlineGrow {
         0% {
             transform: scale3d(0, 1, 1)
         }
         100% {
             transform: scale3d(1, 1, 1)
         }
     }.l-featuredCarousel__picker .l-featuredCarousel__item--active::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0;
          border: 2px solid #e41b23;
          opacity: 0;
          pointer-events: none
      }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active:focus::after {
        opacity: 1
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::after {
        content: '';
        position: absolute;
        left: 8px;
        right: 18px;
        top: 0;
        border-bottom: 4px solid rgba(0, 0, 0, 0.4)
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before, .l-featuredCarousel__picker .l-featuredCarousel__item--active .l-featuredCarousel__inner {
        z-index: 100
    }
}

@media (min-width: 1312px) {
    .l-featuredCarousel__track {
        overflow: visible
    }

    .l-featuredCarousel__slide::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: #fff;
        opacity: 0.6;
        z-index: 100;
        transition: opacity 0.3s ease-out
    }

    .l-featuredCarousel__slide--active::before {
        opacity: 0
    }

    .l-featuredCarousel__buttons {
        top: 0
    }

    .l-featuredCarousel__button, .l-featuredCarousel--active .l-featuredCarousel__button {
        display: block !important;
        width: calc((100% - 1280px) / 2)
    }

    .l-featuredCarousel__picker {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item {
        padding-top: 100px
    }

    .l-featuredCarousel__picker .l-featuredCarousel__inner {
        margin-right: 16px
    }

    .l-featuredCarousel__picker .l-featuredCarousel__about {
        padding-top: 0
    }

    .l-featuredCarousel__picker .l-featuredCarousel__headline {
        font-size: 1.5rem;
        line-height: 1.12em;
        letter-spacing: 0.005em;
        color: #fff
    }

    .l-featuredCarousel__picker .l-featuredCarousel__overlay {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.5) 50%)
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before {
        content: '';
        position: absolute;
        left: 0px;
        right: 18px;
        border-bottom: 4px solid #e41b23;
        opacity: 1
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before {
        bottom: 0px
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active:hover::before {
        opacity: 1
    }

    .l-featuredCarousel__picker .l-featuredCarousel__item--active::before {
        -webkit-animation: underlineGrow 6s linear forwards;
        animation: underlineGrow 6s linear forwards;
        transform-origin: left
    }

    @-webkit-keyframes underlineGrow {
        0% {
            transform: scale3d(0, 1, 1)
        }
        100% {
            transform: scale3d(1, 1, 1)
        }
    }@keyframes underlineGrow {
         0% {
             transform: scale3d(0, 1, 1)
         }
         100% {
             transform: scale3d(1, 1, 1)
         }
     }.l-featuredCarousel__picker .l-featuredCarousel__item--active::after {
          left: 0;
          bottom: 0;
          border-bottom: 4px solid rgba(255, 255, 255, 0.4)
      }
}

.l-footer {
    padding: 0 8px
}

.l-footer--corus {
    background-color: #000;
    padding-top: 16px;
    padding-bottom: 16px
}

.l-footer--main {
    /* background-color: #000000; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.l-footer__copyright {
    margin: auto;
    padding: 8px;
    max-width: 100%;
    text-align: center;
    color: #fff;
    font-size: 0.875rem;
    letter-spacing: 0.03125rem;
    line-height: 1.25rem
}

.l-footer__copyrightLink {
    display: inline-block;
    position: relative;
    color: inherit;
    border-bottom: 0;
    text-decoration: underline
}

.l-footer__copyrightLink::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.l-footer__copyrightLink:focus::after {
    opacity: 1
}

.l-footer__copyrightLink:focus {
    background-color: transparent;
    text-decoration: none
}

.l-footer__copyrightLink:hover {
    background-color: rgba(0, 0, 0, 0.2)
}

.l-footer__row {
    display: flex;
    flex-direction: column;
    align-items: normal;
    padding: 8px 0
}

.l-footer__row--top {
    justify-content: space-between
}

.l-footer__nav {
    width: 100%
}

@media (min-width: 768px) {
    .l-footer__row {
        flex-direction: row;
        align-items: center
    }

    .l-footer__copyright {
        padding: 0 8px;
        text-align: left
    }

    .l-footer--corus .l-footer__nav {
        width: auto;
        max-width: calc(100% - 79px)
    }
}

.l-navbar--custom {
    overflow: hidden
}

.l-navbar--custom .l-navbar__top {
    height: auto;
    min-height: 64px;
    color: #fff;
    background-color: #0052cc
}

.l-navbar--custom .l-navbar__banner {
    display: none
}

.l-navbar--custom .l-navbar__horizontal {
    display: flex;
    height: 100%;
    overflow-x: auto
}

.l-navbar--custom .l-navbar__label {
    display: flex;
    position: relative;
    flex-shrink: 0;
    min-height: 64px;
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.0625rem;
    font-weight: 700;
    align-items: center
}

.l-navbar--custom .l-navbar__label--withTabs {
    margin-right: 16px
}

.l-navbar--custom .l-navbar__label--withTabs::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin-left: 32px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: calc(100% - 34px);
    height: 100%
}

.l-navbar--custom .l-navbar__label--long {
    min-width: 185px;
    flex-shrink: 1;
    padding-right: 16px
}

.l-navbar--custom .l-navbar__labelImage {
    -ms-grid-row-align: center;
    align-self: center;
    max-width: 190px;
    height: auto
}

.l-navbar--custom .l-navbar__sponsor {
    background-color: #0052cc;
    display: flex;
    align-items: center;
    align-self: center;
    flex-shrink: 0;
    padding-left: 16px;
    position: relative
}

.l-navbar--custom .l-navbar__sponsor--inline {
    display: none
}

.l-navbar--custom .l-navbar__sponsor::before {
    content: '';
    display: block;
    background: rgba(255, 255, 255, 0.1);
    height: 100%;
    position: absolute;
    left: -32px;
    right: -16px;
    pointer-events: none
}

.l-navbar--custom.l-navbar--dark .l-navbar__sponsor::before {
    background: rgba(0, 0, 0, 0.1)
}

.l-navbar--custom .l-navbar__list {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin-left: 16px;
    line-height: 4rem;
    overflow: visible
}

.l-navbar--custom .l-navbar__item {
    flex-shrink: 0
}

.l-navbar--custom .l-navbar__item:last-child {
    padding-right: 32px
}

.l-navbar--custom .l-navbar__link {
    margin-right: 32px;
    color: #fff;
    font-size: 0.9375rem;
    letter-spacing: 0.0075rem
}

.l-navbar--custom .l-navbar__link::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    border-bottom: 2px solid #fff;
    opacity: 0
}

.l-navbar--custom .l-navbar__link::before {
    bottom: 0px
}

.l-navbar--custom .l-navbar__link:hover::before {
    opacity: 1
}

.l-navbar--custom .l-navbar__link:hover::before, .l-navbar--custom .l-navbar__link:focus::before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom
}

@keyframes underlineSlide {
    0% {
        transform: scale3d(1, 0, 1)
    }
    100% {
        transform: scale3d(1, 1, 1)
    }
}

.l-navbar--custom .l-navbar__labelLink {
    border-bottom: 0;
    line-height: 1.0625rem
}

.l-navbar--custom .l-navbar__labelLink::before {
    content: '';
    position: absolute;
    left: 0px;
    right: 32px;
    border-bottom: 2px solid #fff;
    opacity: 0
}

.l-navbar--custom .l-navbar__labelLink::before {
    bottom: 0px
}

.l-navbar--custom .l-navbar__labelLink:hover::before {
    opacity: 1
}

.l-navbar--custom .l-navbar__labelLink:hover::before, .l-navbar--custom .l-navbar__labelLink:focus::before {
    -webkit-animation: underlineSlide 0.1s linear forwards;
    animation: underlineSlide 0.1s linear forwards;
    transform-origin: bottom
}

@keyframes underlineSlide {
    0% {
        transform: scale3d(1, 0, 1)
    }
    100% {
        transform: scale3d(1, 1, 1)
    }
}

.l-navbar--custom .l-navbar__labelLink:focus::before {
    opacity: 1;
    border: 2px solid #e41b23;
    top: 0;
    bottom: 0;
    right: 0;
    left: -8px
}

.l-navbar--custom .l-navbar__label--graphic .l-navbar__labelLink {
    display: flex;
    margin-right: 16px
}

.l-navbar--custom .l-navbar__label--long .l-navbar__labelLink {
    margin-right: 0
}

.l-navbar--custom .l-navbar__link:hover, .l-navbar--custom .l-navbar__link:focus {
    background-color: initial
}

.l-navbar--custom .l-navbar__gradient {
    position: absolute;
    top: 0;
    width: 64px;
    height: 100%
}

.l-navbar--custom .l-navbar__gradient--left {
    left: 0;
    background: linear-gradient(to left, rgba(0, 82, 204, 0), #0052cc)
}

.l-navbar--custom .l-navbar__gradient--right {
    right: 0;
    background: linear-gradient(to right, rgba(0, 82, 204, 0), #0052cc)
}

.l-navbar--custom .l-navbar__tinter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25
}

@media (min-width: 498px) {
    .l-navbar--custom .l-navbar__link {
        letter-spacing: 0.03188rem;
        font-size: 1.0625rem
    }
}

@media (min-width: 768px) {
    .l-navbar--custom .l-navbar__list {
        margin-left: 8px
    }

    .l-navbar--custom .l-navbar__labelLink {
        line-height: initial
    }
}

@media (min-width: 1024px) {
    .l-navbar--custom .l-navbar__top {
        height: 54px;
        min-height: auto
    }

    .l-navbar--custom .l-navbar__banner {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%
    }

    .l-navbar--custom .l-navbar__container {
        display: flex;
        max-width: 1280px;
        margin: 0 auto;
        height: 100%
    }

    .l-navbar--custom .l-navbar__container .l-navbar__inner {
        flex-grow: 1
    }

    .l-navbar--custom .l-navbar__item:last-child {
        padding-right: 0
    }

    .l-navbar--custom .l-navbar__image {
        min-width: 1280px
    }

    .l-navbar--custom .l-navbar__gradient--mobile {
        display: none
    }

    .l-navbar--custom .l-navbar__horizontal {
        line-height: 3.375rem
    }

    .l-navbar--custom .l-navbar__label {
        flex-shrink: 0;
        flex-basis: auto;
        min-height: auto;
        padding: 0;
        margin-top: 0;
        border-right: none;
        font-size: 1.375rem;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 3.375rem
    }

    .l-navbar--custom .l-navbar__label::after {
        display: none;
        right: -20px
    }

    .l-navbar--custom .l-navbar__label--withTabs::after {
        display: inline-block;
        right: 0
    }

    .l-navbar--custom .l-navbar__label--graphic {
        height: 54px
    }

    .l-navbar--custom .l-navbar__labelLink {
        line-height: unset;
        white-space: nowrap
    }

    .l-navbar--custom .l-navbar__label--long .l-navbar__labelLink {
        margin-right: 16px
    }

    .l-navbar--custom .l-navbar__list {
        flex-flow: row wrap;
        line-height: 3.375rem;
        overflow: hidden
    }

    .l-navbar--custom .l-navbar__sponsor--inline {
        display: flex;
        height: 54px;
        background-color: transparent
    }

    .l-navbar--custom .l-navbar__sponsor::before {
        display: none
    }

    .l-navbar--customLegacy .l-navbar__image {
        min-width: 916px
    }

    .l-navbar--customHideTitle .l-navbar__label {
        display: none
    }

    .l-navbar--customHideTitle .l-navbar__banner {
        left: 0;
        right: auto
    }

    .l-navbar--customMenuRight .l-navbar__list {
        justify-content: flex-end
    }
}

.l-main--featured .l-main__sidebar--mobileOverlap {
    margin-top: -80px
}

@media (min-width: 1024px) {
    .l-main--featured .l-main__sidebar--desktopOverlap {
        margin-top: -170px
    }

    .l-main--featured .l-main__sidebar {
        grid-area: SB2
    }

    .l-main--featured .l-main__sidebar--top {
        grid-area: SB1
    }

    .l-main--featured .l-main__content {
        padding: 0 64px
    }

    .l-main--featured .l-main__feature {
        min-height: 800px
    }

    .l-main--featured .l-main__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "A A A A A A A A SB1 SB1 SB1 SB1" "SP SP SP SP SP SP SP SP SB2 SB2 SB2 SB2" "F F F F F F F F SB2 SB2 SB2 SB2";
        -ms-grid-rows: max-content 0 auto;
        grid-template-rows: -webkit-max-content auto;
        grid-template-rows: max-content auto
    }
}

@media (min-width: 1024px) {
    .l-main--featured .l-main__sidebar {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7
    }

    .l-main--featured .l-main__sidebar--top {
        -ms-grid-row: 1;
        -ms-grid-column: 17;
        -ms-grid-column-span: 7
    }
}

@media (min-width: 1024px) {
    .l-overflow--feathered::before {
        content: '';
        background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        height: 100%;
        width: 64px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none
    }

    .l-overflow--feathered::after {
        content: '';
        background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 80%);
        height: 100%;
        width: 64px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        opacity: 1
    }

    .l-overflow--feathered {
        box-shadow: 30px 0 20px 10px rgba(0, 0, 0, 0.5)
    }

    .l-overflow--feathered.l-overflow--scrolled:not(.l-overflow--anchoredLeft) {
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5)
    }

    .l-overflow--feathered.l-overflow--scrolled:not(.l-overflow--anchoredLeft)::before {
        opacity: 1
    }

    .l-overflow--feathered .l-overflow__arrows .l-overflow__arrow {
        z-index: 100;
        background: transparent
    }
}

.l-panel--comments {
    height: 100%;
    max-width: 100%;
    position: -webkit-sticky;
    position: sticky
}

@media (min-width: 768px) {
    .l-panel--comments {
        right: 0;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 20, 0, 0, 1);
        position: fixed;
        top: 0
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .l-panel--comments {
            top: initial;
            position: absolute
        }
    }.l-panel--comments .l-panel__header {
         display: flex
     }
}

.l-panel--more {
    z-index: 1;
    /*top:100px;*/
}

.l-panel--more .l-panel__search, .l-panel--more .l-panel__region {
    display: none
}

.l-panel--more .l-panel__topics, .l-panel--more .l-panel__menu {
    margin-bottom: 24px
}
@media(max-width: 768px){
    .l-navbar__toggles{
        display: none;
    }
}
@media (min-width: 768px) {
    .l-panel--more .l-panel__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(6, 1fr);
        -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto;
        grid-template-areas: "S S S S S S" "T T T T T T" "L L L L L L" "P P R R C C" "I I I I I I"
    }

    .l-panel--more {
        padding-top: 24px
    }

    .l-panel--more .l-panel__search {
        display: block;
        grid-area: S;
        margin-bottom: 24px
    }

    .l-panel--more .l-panel__topics {
        grid-area: T
    }

    .l-panel--more .l-panel__menu--programs {
        grid-area: P
    }

    .l-panel--more .l-panel__menu--radio {
        grid-area: R
    }

    .l-panel--more .l-panel__menu--connect {
        grid-area: C
    }

    .l-panel--more .l-panel__region {
        display: block;
        grid-area: L;
        border-top: 1px solid #ededf0;
        border-bottom: 1px solid #ededf0;
        margin: 0 8px 24px 0
    }

    .l-panel--more .l-panel__regionLabel {
        padding: 16px 0 0;
        margin-left: 8px
    }

    .l-panel--more .l-panel__social {
        grid-area: I;
        margin: 0 8px
    }

    .l-panel--more .l-panel__grid--noRadio .l-panel__menu--programs {
        -ms-grid-column: 1;
        -ms-grid-column-span: 5;
        grid-column: 1 / 4
    }

    .l-panel--more .l-panel__grid--noRadio .l-panel__menu--connect {
        -ms-grid-column: 7;
        -ms-grid-column-span: 5;
        grid-column: 4 / 7
    }
}

@media (min-width: 768px) {
    .l-panel--more .l-panel__search {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11
    }

    .l-panel--more .l-panel__topics {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11
    }

    .l-panel--more .l-panel__menu--programs {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3
    }

    .l-panel--more .l-panel__menu--radio {
        -ms-grid-row: 7;
        -ms-grid-column: 5;
        -ms-grid-column-span: 3
    }

    .l-panel--more .l-panel__menu--connect {
        -ms-grid-row: 7;
        -ms-grid-column: 9;
        -ms-grid-column-span: 3
    }

    .l-panel--more .l-panel__region {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11
    }

    .l-panel--more .l-panel__social {
        -ms-grid-row: 9;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11
    }
}

@media (min-width: 1024px) {
    .l-panel--more .l-panel__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        -ms-grid-rows: auto 0 auto 0 auto 0 auto;
        grid-template-areas: "S S S S S S S S S S S S" "T T T T T T P P R R C C" "L L L L L L L L L L L L" "I I I I I I I I I I I I"
    }

    .l-panel--more .l-panel__search {
        -ms-grid-column-span: 23 !important
    }

    .l-panel--more .l-panel__menu--programs, .l-panel--more .l-panel__menu--radio, .l-panel--more .l-panel__menu--connect {
        -ms-grid-row: 3 !important
    }

    .l-panel--more .l-panel__menu--programs {
        -ms-grid-column: 13 !important
    }

    .l-panel--more .l-panel__menu--radio {
        -ms-grid-column: 17 !important
    }

    .l-panel--more .l-panel__menu--connect {
        -ms-grid-column: 21 !important
    }

    .l-panel--more .l-panel__grid--noRadio .l-panel__menu--programs {
        -ms-grid-column: 13 !important;
        -ms-grid-column-span: 5;
        grid-column: 7 / 10
    }

    .l-panel--more .l-panel__grid--noRadio .l-panel__menu--connect {
        -ms-grid-column: 19 !important;
        -ms-grid-column-span: 5;
        grid-column: 10 / 13
    }

    .l-panel--more .l-panel__search {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23
    }

    .l-panel--more .l-panel__topics {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11
    }

    .l-panel--more .l-panel__menu--programs {
        -ms-grid-row: 3;
        -ms-grid-column: 13;
        -ms-grid-column-span: 3
    }

    .l-panel--more .l-panel__menu--radio {
        -ms-grid-row: 3;
        -ms-grid-column: 17;
        -ms-grid-column-span: 3
    }

    .l-panel--more .l-panel__menu--connect {
        -ms-grid-row: 3;
        -ms-grid-column: 21;
        -ms-grid-column-span: 3
    }

    .l-panel--more .l-panel__region {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23
    }

    .l-panel--more .l-panel__social {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23
    }
}

.l-panel--local .l-panel__firstHeading {
    border-bottom: 1px solid #ededf0
}

@media (min-width: 768px) {
    .l-panel--local {
        height: auto
    }
}

@media (max-width: 1311px) {
    .l-panel--local {
        margin-left: 0 !important
    }
}

@media (min-width: 1312px) {
    .l-panel--local {
        max-width: 664px;
        padding: 0 16px
    }
}

.l-panel--search .l-panel__subhead {
    padding: 32px 8px 4px 8px;
    border-bottom: 1px solid #ededf0
}

.l-panel--search .l-panel__suggested {
    display: block
}

.l-panel--search .l-panel__trending {
    display: none
}

@media (min-width: 768px) {
    .l-panel--search {
        padding-top: 24px
    }

    .l-panel--search .l-panel__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(6, 1fr);
        -ms-grid-rows: auto 0 auto 0 auto;
        grid-template-areas: "SS SS SS SS SS SS" "SR SR ST ST ST ST" "SR SR ST ST ST ST"
    }

    .l-panel--search .l-panel__subhead {
        padding: 24px 0 4px 0
    }

    .l-panel--search .l-panel__search {
        grid-area: SS
    }

    .l-panel--search .l-panel__suggested {
        grid-area: SR
    }

    .l-panel--search .l-panel__trending {
        display: block;
        grid-area: ST
    }

    .l-panel--search .l-panel__stories {
        padding-top: 8px
    }

    .l-panel--search .l-panel__story {
        width: 50%
    }
}

@media (min-width: 768px) {
    .l-panel--search .l-panel__search {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 11
    }

    .l-panel--search .l-panel__suggested {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3
    }

    .l-panel--search .l-panel__trending {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 5;
        -ms-grid-column-span: 7
    }
}

@media (min-width: 1024px) {
    .l-panel--search .l-panel__grid {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
        grid-template-columns: repeat(12, 1fr);
        -ms-grid-rows: auto 0 auto 0 auto;
        grid-template-areas: "SS SS SS SS SS SS SS SS SS SS SS SS" "SR SR SR ST ST ST ST ST ST ST ST ST" "SR SR SR ST ST ST ST ST ST ST ST ST"
    }

    .l-panel--search .l-panel__stories {
        padding-top: 16px
    }

    .l-panel--search .l-panel__story {
        width: 33.33333%
    }

    .l-panel--search .l-panel__search {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 23
    }

    .l-panel--search .l-panel__suggested {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 5
    }

    .l-panel--search .l-panel__trending {
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        -ms-grid-column: 7;
        -ms-grid-column-span: 17
    }
}

.l-panel--social {
    padding-bottom: 8px;
    z-index: 1;
    overflow: visible !important;
    height: 100vh
}

@media (min-width: 768px) {
    .l-panel--social {
        padding: 24px;
        width: 312px;
        right: 62px;
        height: auto
    }
}

@media (min-width: 1312px) {
    .l-panel--social {
        right: calc((100% - 1180px) / 2)
    }
}

.l-panel--socialOrphan {
    z-index: 100;
    position: absolute;
    top: 80px;
    left: 0;
    width: 310px;
    padding: 24px;
    overflow: visible;
    margin-top: 8px
}

.l-panel--social::before, .l-panel--socialOrphan::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -8px;
    box-sizing: border-box;
    border: 8px solid black;
    border-color: transparent transparent #fff #fff;
    transform: rotate(135deg);
    display: none;
    box-shadow: -6px 6px 12px 0 rgba(0, 0, 0, 0.08)
}

.l-panel--socialOrphan::before {
    left: calc(50% - 8px)
}

.l-panel--social::before {
    right: 36px
}

.l-panel--socialOrphanLongform {
    top: 60px;
    left: auto;
    right: 0;
    z-index: 5000
}

.l-panel--socialOrphanLongform::before {
    left: auto;
    right: 16px
}

.l-panel--socialOrphanArticle {
    top: 48px;
    left: 40px
}

@media (min-width: 768px) {
    .l-panel--socialOrphan {
        margin-top: 16px
    }

    .l-panel--social {
        right: 114px;
        margin-top: 20px
    }

    .l-panel--social::before, .l-panel--socialOrphan::before {
        display: block
    }
}

@media (min-width: 1312px) {
    .l-panel--social {
        right: calc((100% - 1076px) / 2)
    }
}

.l-sponsorshipBanner {
    height: 92px;
    background-color: #b5b5b8
}

.l-sponsorshipBanner--imageOnly {
    height: 46px
}

.l-sponsorshipBanner__inner {
    position: relative;
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    overflow-x: hidden
}

.l-sponsorshipBanner__content {
    position: relative;
    display: flex;
    align-items: center;
    height: 92px;
    padding: 16px
}

.l-sponsorshipBanner--imageOnly .l-sponsorshipBanner__content {
    height: 46px
}

.l-sponsorshipBanner__link {
    border-bottom: none
}

.l-sponsorshipBanner__link:hover, .l-sponsorshipBanner__link:focus {
    background-color: transparent
}

.l-sponsorshipBanner__logo {
    height: 60px;
    margin-right: 16px
}

.l-sponsorshipBanner__label {
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 1.375rem;
    color: #000
}

.l-sponsorshipBanner__background {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    max-width: none !important;
    width: auto !important
}

.l-sponsorshipBanner--imageOnly .l-sponsorshipBanner__background {
    display: block;
    left: 0;
    right: auto
}

.l-sponsorshipBanner__pixel {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 1px;
    visibility: hidden
}

@media (min-width: 1024px) {
    .l-sponsorshipBanner--imageOnly {
        height: 92px
    }

    .l-sponsorshipBanner--imageOnly .l-sponsorshipBanner__content {
        height: 92px
    }

    .l-sponsorshipBanner__background {
        display: block
    }
}

@media (min-width: 1312px) {
    .l-sponsorshipBanner__content {
        padding: 16px 0
    }
}

.l-topics {
    list-style: none;
    padding: 0
}

.l-topics__heading {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 16px 0
}

.l-topics__footer {
    padding: 16px 0;
    display: flex;
    justify-content: flex-end
}

.l-topics__footer--divider {
    border-bottom: 1px solid #ededf0
}

.l-topics__footer .l-topics__link {
    position: relative;
    right: 6px
}

.l-topics__item {
    margin: 24px 0
}

.l-topics__item:first-child {
    margin-top: 0
}

.l-topics__item:last-child {
    padding-bottom: 0;
    margin-bottom: 0
}

.l-topics__label {
    display: inline-block;
    font-size: 1.375rem;
    font-weight: 800;
    margin-right: 10px;
    text-transform: uppercase
}

.l-topics__icon {
    margin-left: 0
}

.l-topics__ad {
    display: block;
    margin: -24px 0 0 0
}

.l-topics--fullWidth .l-topics__item {
    border-top: 4px solid #e41b23;
    padding: 28px 0 0 0
}

.l-topics--fullWidth .l-topics__item:not(:last-child) {
    margin-bottom: 64px
}

@media (min-width: 498px) {
    .l-topics__footer {
        display: none
    }

    .l-topics__posts {
        margin: -8px
    }

    .l-topics__ad {
        margin: 0 -16px
    }
}

@media (min-width: 768px) {
    .l-topics__heading {
        flex-direction: row;
        justify-content: space-between
    }
}

@media (min-width: 1024px) {
    .l-topics {
        display: flex;
        flex-wrap: wrap;
        margin-right: -16px
    }

    .l-topics .l-topics__item {
        width: 33.33333%;
        flex-shrink: 0
    }

    .l-topics__ad {
        display: none
    }

    .l-topics__item {
        padding: 0 16px 32px 0;
        margin: 0
    }

    .l-topics--fullWidth {
        margin-right: 0
    }

    .l-topics--fullWidth .l-topics__item {
        width: 100%
    }
}

@media (min-width: 1312px) {
    .l-topics__item {
        padding: 0 32px 32px 0;
        margin: 0
    }

    .l-topics__posts {
        margin: -16px
    }

    .l-topics--fullWidth .l-topics__item {
        padding-right: 16px
    }
}

.l-popup {
    visibility: hidden
}

.l-popup--init {
    transition: visibility 0s 0.3s
}

.l-popup--locked::before, .l-popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 5500;
    overflow-y: scroll
}

.l-popup__dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: auto
}

.l-popup__content {
    position: relative
}

.l-popup__skeletonTemplate {
    display: none
}

.l-popup__skeleton {
    background-color: #b5b5b8
}

.l-popup--locked .l-popup__overlay {
    pointer-events: none;
    overflow: hidden
}

.l-popup--locked .l-popup__dialog {
    pointer-events: auto;
    overflow: hidden;
    top: 20%;
    left: 50%;
    transform: translateX(-50%)
}

.l-popup--locked::before {
    content: '';
    display: block;
    overflow: hidden
}

@media (min-width: 498px) {
    .l-popup__dialog {
        min-width: 450px;
        width: auto;
        top: 20%;
        left: 50%;
        transform: translateX(-50%)
    }
}

.l-radioCarousel {
    margin-bottom: 16px
}

.l-radioCarousel__track {
    margin-left: 0
}

.l-radioCarousel__slideLink {
    border-bottom: none
}

.l-radioCarousel__slideImage {
    display: block
}

@media (min-width: 600px) {
    .l-radioCarousel__slide {
        margin: 10px 0
    }

    .l-radioCarousel__slide--active {
        transform: scale(1.1);
        position: relative;
        z-index: 10
    }

    .l-radioCarousel__buttons {
        display: block
    }

    .l-radioCarousel__button {
        width: 11% !important
    }
}

@media (min-width: 1024px) {
    .l-radioCarousel__button {
        width: 13% !important
    }
}

.l-inlineStories--text .l-inlineStories__heading {
    margin-bottom: 16px
}

@media (min-width: 1024px) {
    .l-inlineStories__heading {
        display: inline-block;
        position: relative;
        margin-bottom: -36px
    }

    .l-inlineStories__heading::after {
        content: '';
        display: inline-block;
        border-color: #e41b23;
        border-width: 0 56px 47px 0;
        border-style: solid;
        border-right-color: transparent;
        background: transparent;
        position: absolute;
        width: auto;
        margin: 0;
        top: 0;
        right: -56px
    }

    .l-inlineStories__title {
        color: #fff;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0.03125rem;
        background: #e41b23;
        padding: 16px 16px 50px 24px;
        display: inline-block;
        position: relative
    }

    .l-inlineStories--tile {
        float: left;
        width: 292px;
        margin: 0 32px 24px 0
    }

    .l-inlineStories--text .l-inlineStories__heading {
        margin-bottom: -36px
    }
}

@media (min-width: 1312px) {
    .l-inlineStories__heading::before {
        content: '';
        display: inline-block;
        border-color: #e41b23;
        border-style: solid;
        border-width: 25px 0 0 25px;
        border-left-color: transparent;
        position: absolute;
        top: 82px;
        left: -24px
    }

    .l-inlineStories__title {
        margin-left: -24px
    }

    .l-inlineStories--tile {
        margin: 0 32px 24px -100px
    }
}

.l-playlist__main .l-playlist__link {
    border: none;
    color: #000;
    display: block;
    padding: 16px 0
}

.l-playlist__list .l-playlist__item .l-playlist__link {
    padding: 16px 0;
    border-bottom: 1px solid #ededf0
}

.l-playlist__list .l-playlist__item .l-playlist__link::after {
    top: 0
}

.l-playlist__about {
    margin-top: 4px
}

.l-playlist__details {
    padding-left: 0;
    padding-right: 16px
}

.l-playlist__list {
    display: flex;
    flex-direction: column
}

.l-playlist__list .l-playlist__media {
    width: 130px
}

.l-playlist__list .l-playlist__media .l-playlist__icon {
    height: 24px;
    width: 24px;
    padding: 6px;
    margin: 0;
    border-radius: 0
}

.l-videoCarousel__heading {
    margin-bottom: 12px
}

.l-videoCarousel__featured {
    padding-bottom: 12px
}

.l-videoCarousel__featured figcaption {
    display: none
}

.l-videoCarousel--singleVideo .l-videoCarousel__glide {
    display: none
}

@media (min-width: 498px) {
    .l-videoCarousel {
        margin-bottom: 24px
    }
}

.l-videoCarousel--standalone .l-videoCarousel__featured {
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(237, 237, 240, 0.2)
}

.l-videoCarousel--standalone.l-videoCarousel--singleVideo .l-videoCarousel__featured {
    border-bottom: 0;
    padding-bottom: 0
}

.l-videoCarousel--standalone .l-videoCarousel__title {
    display: none
}

.l-videoCarousel--standalone .l-videoCarousel__details {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    border-bottom: none
}

.l-videoCarousel--standalone .l-videoCarousel__details:hover, .l-videoCarousel--standalone .l-videoCarousel__details:focus {
    background-color: transparent
}

.l-videoCarousel--standalone .l-videoCarousel__featured .l-videoCarousel__details {
    position: relative
}

.l-videoCarousel--standalone .l-videoCarousel__featured .l-videoCarousel__details::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #fff;
    opacity: 0;
    pointer-events: none
}

.l-videoCarousel--standalone .l-videoCarousel__featured .l-videoCarousel__details:focus::after {
    opacity: 1
}

.l-videoCarousel--standalone .l-videoCarousel__featured .l-videoCarousel__details::after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

.l-videoCarousel--standalone .l-videoCarousel__info {
    order: 2;
    font-size: 0.75rem;
    color: #e73239;
    text-transform: uppercase
}

.l-videoCarousel--standalone .l-videoCarousel__fullTitle {
    margin-bottom: 14px;
    font-size: 1.125rem;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.006em;
    line-height: 1.24em
}

.l-videoCarousel--standalone .l-videoCarousel__description {
    display: none
}

.l-videoCarousel--standalone .l-videoCarousel__featured .l-videoCarousel__ad {
    display: none
}

.l-videoCarousel--standalone .l-videoCarousel__list .l-videoCarousel__ad {
    margin: 0 -16px
}

.l-videoCarousel--standalone .l-videoCarousel__link--page {
    padding: 4px
}

.l-videoCarousel--standalone .l-videoCarousel__link--page .l-videoCarousel__linkLabel {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.24em;
    text-align: center;
    display: flex;
    height: 100%;
    padding: 0 20%;
    align-items: center
}

@media (min-width: 498px) {
    .l-videoCarousel--standalone {
        margin-bottom: 0
    }

    .l-videoCarousel--standalone .l-videoCarousel__featured {
        display: flex;
        border-bottom-width: 2px;
        margin-bottom: 30px
    }

    .l-videoCarousel--standalone .l-videoCarousel__sidebar {
        width: 390px;
        padding-left: 24px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }

    .l-videoCarousel--standalone.l-videoCarousel--noDetails .l-videoCarousel__sidebar {
        display: none
    }

    .l-videoCarousel--standalone .l-videoCarousel__details {
        margin: 0
    }

    .l-videoCarousel--standalone .l-videoCarousel__list .l-videoCarousel__ad {
        display: none
    }

    .l-videoCarousel--standalone .l-videoCarousel__next {
        width: 15%
    }

    .l-videoCarousel--standalone.l-videoCarousel--active .l-videoCarousel__next, .l-videoCarousel--standalone.l-videoCarousel--active .l-videoCarousel__prev {
        width: 8%
    }
}

@media (min-width: 768px) {
    .l-videoCarousel--standalone .l-videoCarousel__featured {
        padding-bottom: 32px
    }

    .l-videoCarousel--standalone .l-videoCarousel__data {
        line-height: 3.75rem
    }

    .l-videoCarousel--standalone .l-videoCarousel__inner {
        flex-grow: 1;
        -ms-grid-row-align: center;
        align-self: center
    }

    .l-videoCarousel--standalone .l-videoCarousel__info {
        order: 0;
        margin-bottom: 6px
    }

    .l-videoCarousel--standalone .l-videoCarousel__fullTitle {
        font-size: 1.625rem;
        font-weight: 800;
        line-height: 1.12em;
        letter-spacing: 0.0048em
    }

    .l-videoCarousel--standalone .l-videoCarousel__description {
        font-size: 1.125rem;
        color: #fff;
        line-height: 1.34em;
        max-height: 4.02em;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical
    }

    .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp {
        max-height: calc(1em * (1.5 - 0.15) * 3);
        height: calc(1em * (1.5 - 0.15) * 3);
        position: relative;
        overflow: hidden
    }

    .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp::after {
        content: '';
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: calc(1em * 1.5);
        width: 20%;
        min-width: calc((1em * 1.5) * 2);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #000 50%)
    }

    @supports (-webkit-line-clamp: 1) {
        .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp::after {
            display: none
        }

        .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp {
            height: auto
        }
    }.l-videoCarousel--standalone .l-videoCarousel__details:hover .l-videoCarousel__fullTitle {
         text-decoration: underline
     }
}

@media (min-width: 1024px) {
    .l-videoCarousel--standalone.l-videoCarousel--noDetails .l-videoCarousel__featured .l-videoCarousel__ad {
        display: block;
        margin: 0
    }

    .l-videoCarousel--standalone.l-videoCarousel--noDetails .l-videoCarousel__sidebar {
        display: flex
    }

    .l-videoCarousel--standalone .l-videoCarousel__link::after {
        top: 0
    }
}

@media (min-width: 1312px) {
    .l-videoCarousel--standalone .l-videoCarousel__featured .l-videoCarousel__ad {
        display: block;
        margin: 20px 0 0 0
    }

    .l-videoCarousel--standalone .l-videoCarousel__description {
        display: -webkit-box;
        max-height: 2.68em;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
    }

    .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp {
        max-height: calc(1em * (1.5 - 0.15) * 2);
        height: calc(1em * (1.5 - 0.15) * 2);
        position: relative;
        overflow: hidden
    }

    .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp::after {
        content: '';
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: calc(1em * 1.5);
        width: 20%;
        min-width: calc((1em * 1.5) * 2);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #000 50%)
    }

    @supports (-webkit-line-clamp: 1) {
        .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp::after {
            display: none
        }

        .l-videoCarousel--standalone .l-videoCarousel__description > .u-lineClamp {
            height: auto
        }
    }.l-videoCarousel--standalone .l-videoCarousel__fullTitle {
         max-height: 4.48em;
         display: block;
         display: -webkit-box;
         overflow: hidden;
         -webkit-line-clamp: 4;
         -webkit-box-orient: vertical
     }

    .l-videoCarousel--standalone .l-videoCarousel__fullTitle > .u-lineClamp {
        max-height: calc(1em * (1.3 - 0.15) * 4);
        height: calc(1em * (1.3 - 0.15) * 4);
        position: relative;
        overflow: hidden
    }

    .l-videoCarousel--standalone .l-videoCarousel__fullTitle > .u-lineClamp::after {
        content: '';
        display: block;
        bottom: 0;
        right: 0;
        position: absolute;
        height: calc(1em * 1.3);
        width: 20%;
        min-width: calc((1em * 1.3) * 2);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #000 50%)
    }

    @supports (-webkit-line-clamp: 1) {
        .l-videoCarousel--standalone .l-videoCarousel__fullTitle > .u-lineClamp::after {
            display: none
        }

        .l-videoCarousel--standalone .l-videoCarousel__fullTitle > .u-lineClamp {
            height: auto
        }
    }
}

@media (min-width: 900px) {
    .l-videoCarousel--standalone .l-videoCarousel__next {
        width: 12%
    }

    .l-videoCarousel--standalone.l-videoCarousel--active .l-videoCarousel__next, .l-videoCarousel--standalone.l-videoCarousel--active .l-videoCarousel__prev {
        width: 6%
    }
}

@media (min-width: 1200px) {
    .l-videoCarousel--standalone .l-videoCarousel__next {
        width: 10%
    }

    .l-videoCarousel--standalone .l-videoCarousel__description {
        font-size: 1.125rem;
        color: #fff;
        line-height: 1.34em
    }

    .l-videoCarousel--standalone.l-videoCarousel--active .l-videoCarousel__next, .l-videoCarousel--standalone.l-videoCarousel--active .l-videoCarousel__prev {
        width: 5%
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .l-navbar {
        top: 46px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-navbar {
        top: 32px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .l-navbar--stuck, .admin-bar .l-navbar--custom {
        top: 46px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-navbar--stuck, .admin-bar .l-navbar--custom {
        top: 32px
    }
}

.admin-bar .l-panel--more, .admin-bar .l-panel--search {
    top: 46px
}

@media screen and (min-width: 768px) {
    .admin-bar .l-panel--more, .admin-bar .l-panel--search {
        top: 0
    }
}

.admin-bar .c-longform-chapters__panel--mobile {
    top: 0
}

@media (min-width: 783px) {
    .admin-bar .l-panel--comments.l-panel--aboveNav {
        top: 32px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .l-headerAd__container--stuck, .admin-bar .l-headerAd__container--detaching {
        top: 98px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-headerAd__container--stuck, .admin-bar .l-headerAd__container--detaching {
        top: 86px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .l-notification {
        top: 100px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-notification {
        top: 86px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .l-container--tallSticky .l-headerAd__container--stuck, .admin-bar .l-container--tallSticky .l-headerAd__container--detaching {
        top: 110px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-container--tallSticky .l-headerAd__container--stuck, .admin-bar .l-container--tallSticky .l-headerAd__container--detaching {
        top: 86px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .l-container--tallSticky .l-notification {
        top: 110px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-container--tallSticky .l-notification {
        top: 96px
    }
}

@media screen and (min-width: 1024px) {
    .admin-bar .l-container--tallSticky .l-notification {
        top: 86px
    }
}

.admin-bar .l-headerAd--anchored .l-headerAd__container--stuck, .admin-bar .l-headerAd--anchored .l-headerAd__container--detaching, .admin-bar .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--stuck, .admin-bar .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--detaching {
    top: 0
}

@media screen and (min-width: 601px) {
    .admin-bar .l-headerAd--anchored .l-headerAd__container--stuck, .admin-bar .l-headerAd--anchored .l-headerAd__container--detaching, .admin-bar .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--stuck, .admin-bar .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--detaching {
        top: 46px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-headerAd--anchored .l-headerAd__container--stuck, .admin-bar .l-headerAd--anchored .l-headerAd__container--detaching, .admin-bar .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--stuck, .admin-bar .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--detaching {
        top: 32px
    }
}

.admin-bar .c-stickyRail--native .c-stickyRail__widget, .admin-bar .c-stickyRail[data-display='sticky'] .c-stickyRail__widget {
    top: 86px
}

.admin-bar .c-stickToTop {
    top: 110px
}

@media (min-width: 1024px) {
    .admin-bar .c-stickToTop--shift {
        top: 184px
    }

    .admin-bar .c-stickToTop--bigShift {
        top: 230px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .liveblog-update-btn-container {
        top: 114px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .liveblog-update-btn-container {
        top: 100px
    }
}

.admin-bar .districtm-skin-b {
    transform: translate3d(0, 160px, 0)
}

.admin-bar .districtm-skin-b.is-sticky {
    transform: translate3d(0, 86px, 0)
}

.admin-bar .l-longform-header {
    top: 0
}

@media screen and (min-width: 601px) {
    .admin-bar .l-longform-header {
        top: 46px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .l-longform-header {
        top: 32px
    }
}

.admin-bar .c-longform-chapters {
    top: 0
}

@media screen and (min-width: 601px) {
    .admin-bar .c-longform-chapters {
        top: 46px
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .c-longform-chapters {
        top: 32px
    }
}

@media screen and (min-width: 601px) {
    .admin-bar .c-longform-chapters--shifted {
        top: 46px
    }

    .admin-bar .c-longform-chapters--shifted .c-longform-chapters__pagination {
        transform: translateY(54px) !important
    }

    .admin-bar .c-longform-chapters--shifted .c-longform-chapters__panel {
        transform: translateY(54px) !important
    }
}

@media screen and (min-width: 783px) {
    .admin-bar .c-longform-chapters--shifted {
        top: 32px
    }

    .admin-bar .c-longform-chapters--shifted .c-longform-chapters__pagination {
        transform: translateY(54px) !important
    }

    .admin-bar .c-longform-chapters--shifted .c-longform-chapters__panel {
        transform: translateY(54px) !important
    }
}

.admin-bar .c-longform-hero {
    height: calc(100vh - 46px)
}

@media (min-width: 601px) {
    .admin-bar .l-longform-panel-comments {
        padding-top: 46px
    }
}

@media (min-width: 783px) {
    .admin-bar .l-longform-panel-comments {
        padding-top: 32px
    }
}

.admin-bar .l-panel--comments {
    top: 46px
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .admin-bar .l-panel--comments {
        top: initial
    }
}

@media (min-width: 783px) {
    .admin-bar .l-panel--comments {
        top: 32px
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .admin-bar .l-panel--comments {
            top: initial
        }
    }
}

#wpadminbar {
    z-index: 99999 !important
}

_:-ms-fullscreen, :root .admin-bar .l-navbar {
    top: 0
}

.fb_iframe_widget_fluid_desktop iframe {
    width: 100% !important
}

.liveblog-entry .liveblog-entry-content {
    padding-left: 0
}

.liveblog-entry {
    display: block
}

.liveblog-entry .liveblog-entry-aside {
    margin-bottom: 16px
}

.liveblog-meta-time {
    flex-basis: 100px;
    display: block;
    position: relative;
    border-bottom: none
}

.liveblog-meta-time::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.liveblog-meta-time:focus::after {
    opacity: 1
}

.liveblog-meta-time span {
    font-size: 0.75rem !important
}

.liveblog-meta-time span:last-child::after {
    content: " EST"
}

.liveblog-meta-time:hover, .liveblog-meta-time:focus {
    text-decoration: underline;
    background-color: transparent
}

@media (min-width: 498px) {
    .liveblog-meta-time {
        flex-basis: 140px
    }

    .liveblog-meta-time span {
        font-size: 0.875rem
    }
}

.liveblog-meta-author-avatar img {
    display: none
}

.liveblog-meta-author-avatar img[src^="https://"] {
    display: inline
}

.liveblog-pagination-pages {
    white-space: nowrap
}

.liveblog-update-btn-container {
    top: 60px;
    margin-bottom: 8px
}

.liveblog-update-btn {
    padding: 6px 24px
}

@media (min-width: 498px) {
    .liveblog-entry {
        display: flex
    }

    .liveblog-entry .liveblog-entry-main {
        max-width: calc(100% - 85px)
    }
}

.l-article__details {
    visibility: visible;
    height: auto
}

.l-longform-header__right {
    display: flex
}

.l-article__oembed > *:first-child {
    margin-right: auto !important;
    margin-left: auto !important;
    min-width: auto !important
}

.instagram-media {
    margin: 0 auto !important
}

.responsive-embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%
}

.responsive-embed-container iframe, .responsive-embed-container embed, .responsive-embed-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.responsive-embed-container-no-ratio iframe {
    max-width: 100%
}

.tiktok-embed iframe {
    max-height: 810px !important;
    visibility: visible !important
}

.l-article__oembed iframe {
    left: 0
}

#onesignal-slidedown-dialog {
    outline: none !important
}

#onesignal-slidedown-dialog .slidedown-button {
    position: relative
}

#onesignal-slidedown-dialog .slidedown-button::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

#onesignal-slidedown-dialog .slidedown-button:focus::after {
    opacity: 1
}

.one-signal-hidden-button {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important
}

.ob_what a {
    position: relative;
    border-bottom: none;
    display: block
}

.ob_what a:hover, .ob_what a:focus {
    background-color: transparent
}

.ob_what a::before {
    content: '';
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    border: 2px solid #000
}

.ob_what a:hover::before, .ob_what a:focus::before {
    opacity: 1
}

.AR_14 {
    border-bottom: 1px solid #ededf0
}

.CRMB_8 .ob-rec-image-container, .CRMB_24 .ob-rec-image-container {
    max-width: 152px
}

.CRMB_24.ob-widget .ob-rec-image-container .ob-rec-image.ob-show {
    position: absolute;
    top: 0
}

.OUTBRAIN .ob-widget .ob-widget-items-container .ob-unit.ob-rec-source {
    color: #4d7192
}

.AR_8 .ob-rec-image-container::after {
    content: 'SPONSORED POST';
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.025rem;
    padding: 4px 8px;
    background: #fff;
    color: #0d1321;
    width: auto;
    height: auto;
    left: 0;
    top: 0;
    position: absolute
}

.AR_9.ob-widget .ob-dynamic-rec-link, .SFD_STP_9.ob-widget .ob-dynamic-rec-link, .SFD_STP_10.ob-widget .ob-dynamic-rec-link, .AR_5.ob-grid-layout .ob-dynamic-rec-link, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link, .SFD_STP_15.ob-widget .ob-dynamic-rec-link, .AR_5.ob-widget .ob-dynamic-rec-link {
    position: relative;
    display: block;
    border-bottom: 0
}

.AR_9.ob-widget .ob-dynamic-rec-link::after, .SFD_STP_9.ob-widget .ob-dynamic-rec-link::after, .SFD_STP_10.ob-widget .ob-dynamic-rec-link::after, .AR_5.ob-grid-layout .ob-dynamic-rec-link::after, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link::after, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link::after, .SFD_STP_15.ob-widget .ob-dynamic-rec-link::after, .AR_5.ob-widget .ob-dynamic-rec-link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 2px solid #000;
    opacity: 0;
    pointer-events: none
}

.AR_9.ob-widget .ob-dynamic-rec-link:focus::after, .SFD_STP_9.ob-widget .ob-dynamic-rec-link:focus::after, .SFD_STP_10.ob-widget .ob-dynamic-rec-link:focus::after, .AR_5.ob-grid-layout .ob-dynamic-rec-link:focus::after, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link:focus::after, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link:focus::after, .SFD_STP_15.ob-widget .ob-dynamic-rec-link:focus::after, .AR_5.ob-widget .ob-dynamic-rec-link:focus::after {
    opacity: 1
}

.AR_9.ob-widget .ob-dynamic-rec-link:hover, .AR_9.ob-widget .ob-dynamic-rec-link:focus, .SFD_STP_9.ob-widget .ob-dynamic-rec-link:hover, .SFD_STP_9.ob-widget .ob-dynamic-rec-link:focus, .SFD_STP_10.ob-widget .ob-dynamic-rec-link:hover, .SFD_STP_10.ob-widget .ob-dynamic-rec-link:focus, .AR_5.ob-grid-layout .ob-dynamic-rec-link:hover, .AR_5.ob-grid-layout .ob-dynamic-rec-link:focus, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link:hover, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link:focus, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link:hover, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link:focus, .SFD_STP_15.ob-widget .ob-dynamic-rec-link:hover, .SFD_STP_15.ob-widget .ob-dynamic-rec-link:focus, .AR_5.ob-widget .ob-dynamic-rec-link:hover, .AR_5.ob-widget .ob-dynamic-rec-link:focus {
    background-color: transparent
}

.AR_9.ob-widget .ob-dynamic-rec-link::after, .SFD_STP_9.ob-widget .ob-dynamic-rec-link::after, .SFD_STP_10.ob-widget .ob-dynamic-rec-link::after, .AR_5.ob-grid-layout .ob-dynamic-rec-link::after, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link::after, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link::after, .SFD_STP_15.ob-widget .ob-dynamic-rec-link::after, .AR_5.ob-widget .ob-dynamic-rec-link::after {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

.AR_9.ob-widget .ob-dynamic-rec-link:hover .ob-rec-text, .AR_9.ob-widget .ob-dynamic-rec-link:focus .ob-rec-text, .SFD_STP_9.ob-widget .ob-dynamic-rec-link:hover .ob-rec-text, .SFD_STP_9.ob-widget .ob-dynamic-rec-link:focus .ob-rec-text, .SFD_STP_10.ob-widget .ob-dynamic-rec-link:hover .ob-rec-text, .SFD_STP_10.ob-widget .ob-dynamic-rec-link:focus .ob-rec-text, .AR_5.ob-grid-layout .ob-dynamic-rec-link:hover .ob-rec-text, .AR_5.ob-grid-layout .ob-dynamic-rec-link:focus .ob-rec-text, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link:hover .ob-rec-text, .SFD_STP_14.ob-grid-layout .ob-dynamic-rec-link:focus .ob-rec-text, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link:hover .ob-rec-text, .SFD_STP_15.ob-grid-layout .ob-dynamic-rec-link:focus .ob-rec-text, .SFD_STP_15.ob-widget .ob-dynamic-rec-link:hover .ob-rec-text, .SFD_STP_15.ob-widget .ob-dynamic-rec-link:focus .ob-rec-text, .AR_5.ob-widget .ob-dynamic-rec-link:hover .ob-rec-text, .AR_5.ob-widget .ob-dynamic-rec-link:focus .ob-rec-text {
    text-decoration: underline
}

.l-container--tallSticky .l-notification {
    transform: translate3d(0, 0, 0)
}

.l-container--tallSticky .l-notification, .l-container--tallSticky .l-headerAd__container--stuck, .l-container--tallSticky .l-headerAd__container--detaching {
    top: 64px
}

@media (min-width: 1024px) {
    .l-container--tallSticky .l-notification, .l-container--tallSticky .l-headerAd__container--stuck, .l-container--tallSticky .l-headerAd__container--detaching {
        top: 54px
    }
}

.l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--stuck, .l-container--tallSticky .l-headerAd--anchored .l-headerAd__container--detaching {
    top: 0
}

.has-staticWallpaper:not(.has-districtM) .l-content__inner {
    background: #fff;
    margin: 0 auto;
    max-width: 1312px
}

.has-staticWallpaper:not(.has-districtM) .l-headerAd__container--detaching, .has-staticWallpaper:not(.has-districtM) .l-headerAd__container--stuck {
    max-width: 1312px
}

@media screen and (min-width: 1730px) {
    .has-districtM .l-content__inner {
        background: #fff;
        margin: 0 auto;
        max-width: 1312px
    }

    .has-districtM .l-headerAd__container--detaching, .has-districtM .l-headerAd__container--stuck {
        max-width: 1312px
    }
}

.l-flexitiveGuide.contain {
    width: 1312px;
    margin: 0 auto;
    max-width: 100%
}

.twitter-tweet-error {
    margin-left: 42px;
    margin-right: 42px;
    position: relative;
    overflow: visible;
    text-align: left
}

.twitter-tweet-error::before {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    position: absolute;
    background: #e41b23
}

.twitter-tweet-error p {
    margin: 0 0 20px 24px
}

@media (max-width: 497px) {
    body.radio-footer [id^="districtm-touch-container-"] {
        bottom: 56px !important
    }
}

.glide {
    position: relative;
    width: 100%;
    box-sizing: border-box
}

.glide * {
    box-sizing: inherit
}

.glide__track {
    overflow: hidden
}

.glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform
}

.glide__slides--dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent
}

.glide__slide a {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.glide__arrows {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.glide__bullet--active {
    background-color: #808083
}

.districtm-skin-b {
    box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.08);
    transform: translate3d(0, 128px, 0);
    transition: transform 0.3s
}

.districtm-skin-b.is-sticky {
    transform: translate3d(0, 54px, 0)
}

.s2nFloat .s2nFloatBox {
    width: 100% !important;
    max-width: 1312px !important;
    margin: 0 auto !important;
    left: 0 !important
}

.s2nFloat .s2nFloatParams {
    float: right;
    margin-bottom: 32px !important;
    margin-right: 16px !important;
    max-width: 384px
}

.not-visible{
    display: none;
}
is-visible{
    display: block;
}

ul,li {
     /* list-style:auto;  */
    padding:10px 0 10px 0;
    font-size:16px;
    text-decoration:none;
    }
    .ck-content li {
        list-style: auto;
    }
     
    nav ul {
    padding:0;
    margin:0;
    }
     
    nav ul li {
    list-style: none;
    line-height:25px;
    float:left;
   /* background-color:#64abfb;*/
    }

     nav ul.dropdown{
            /* min-width: 200px; */
            list-style: none;
            border-radius: 5px;
            padding: 0 28px 10px 2px;
            color: white !important;
            margin-left: 0px !important;
            margin-top: -15px !important;
            /* background: rgb(4 24 75 / 92%); */
            background: rgb(21 18 212 / 92%);
            border: solid 1px #020153;
            /* box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
     }
     nav ul.dropdown li{
        padding: 8px 0 0px 0 !important;

     }

    nav ul.dropdown li a {
    color: white;
    padding: 10px 0 0 2px;
    margin-left: 6px;
    font-size:16px;
    text-transform: capitalize;
    text-decoration:none;
    }
     
    li a:hover {
    border-bottom:2px solid #ff287d;
    color: #0d1321;
    text-decoration: none;
    }


    .black{
        color: #0d1321;
        position: relative;
        flex-grow: 1;
        height: 100%;
        text-align: center;
        cursor: pointer;
        outline: none;
    }

.black:hover{
    border-bottom: 2px solid #e41b23;
}
.l-errorPage{
    background: #fff;
    padding-bottom: 64px;
}
.l-errorPage__container{
    max-width: 960px;
    margin: 0 auto;
}
.l-errorPage__inner{
    padding: 24px 16px;
}
.l-errorPage__title{
    font-size: 2rem;
    color: #000;
}
.l-errorPage__errorCode{
    display: block;
    font-size: 4rem;
    line-height: 3.75rem;
    margin-top: 18px;
}
.btn-primary{
    color: #fff;
    background-color: #024890;
    border-color: #024890;
}
.btn-primary:hover, .btn-primary:after{
    background-color: #002e5d;
    box-shadow: none;
}
.btn-primary:focus, .btn-primary:disabled{
  
      outline: 0;
      box-shadow: none;
      background-color: #024890;
   
}
.b-comments--el-tab-controls{
    border-bottom: 1px solid #CCC;
}
.b-comments--el-tab.active{
    color: #222;
    border-bottom: 2px solid #222;
    padding-bottom: 10px;
}
.b-comments--el-tab{
    border-bottom: 2px solid #CCC;
    margin-bottom: -2px;
    position: relative;
    padding-left: 22px;
    display: inline-block;
    vertical-align: top;
    margin-right: -4px;
    text-decoration: none !important;
    outline: none !important;
}
.b-post-comment--el-title{

    font-size: 16px;
    margin-top: 32px;
    padding-bottom: 11px;
    border-bottom: 2px solid #222222;
    margin-bottom: 20px;
}
.comments{
    max-height: 400px;
    overflow-x: overlay;
}
.weather{
    background: linear-gradient(90deg, rgba(56,167,206,1) 1%, rgba(40,135,169,1) 100%);
}
.search-bar-weather, .search-bar-weather:focus{
    background: transparent;
    color: #B5DEF4;
    padding: 5px;
    border-bottom: 2px solid;
    border-right: none;
    border-left: none;
    border-top: none;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}
.search-bar-weather::placeholder{
    color: #B5DEF4;
}
.error-weather{
    color: #ffe064;
    padding: 5px;
}
.error{
    display: block;
    color: #e55353;
    font-size: 100%;
}
.ck-editor__editable {min-height: 200px;}
.c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover,.c-header-nav-link:hover{
    border: none;
}
.sector-number{
    margin-right: 20px;
    color: #002e5d;
    font-weight: 600;
    max-width: 5%;
    flex: 0 0 5%;
    margin-left: 15px;
    font-size: 14px;
    line-height: 2em;
}
.sector{
    font-weight: 600;
    color: #002e5d;
    line-height: 2em;
    font-size: 14px;
    max-width: 75%;
    flex: 0 0 75%;
}
.sector:hover{
    color: #002e5d;
    text-decoration: none;
}
.tab-content > .active{
    display: block;
    background: white;
    border-radius: 6px;
    padding: 20px;
    margin-top: 20px;
   
   
}
.tabpane-ad >.active{
     -webkit-box-shadow: 7px 6px 25px -18px rgba(0,0,0,0.4);
    -moz-box-shadow: 7px 6px 25px -18px rgba(0,0,0,0.4);
    box-shadow: 7px 6px 25px -18px rgba(0,0,0,0.4);
}
.nav-tabs{
    border-bottom: none;
}
.nav-tabs .nav-item a{
    padding: 10px;
    background-color: #f8f8f8;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -1px 4px 0px inset;  
    color: #002e5d;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}
.nav-tabs .nav-link:hover{
    color: #002e5d;
    /* background-color: #ebedef; */
    border-color: transparent;
    border-bottom: 3px solid #002e5d;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    color: #002e5d;
    background-color: #ebedef;
    border-color: transparent;
    border-bottom: 4px solid #ff287d;
}
.a-icon{
    max-height: 120px;
}
.search{
    position:absolute;
  margin:auto 0;
  top:0;
  left:0;
  bottom:0;
  right: 0;
  height:50%;
 
}
.profile-avatar{
    width: 70px;
    height: auto;
}
.icon-brand{
    width: 25px;
}
.title-section{
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}
.text-blue-primary{
    color: #002e5d;
}
.text-blue-secondary{
    color:#408bbb;
}
.quick-sort{
    border: 1px solid #b3b3b3;
    border-radius: 4px;
    padding: 5px;
}
.text-bold{
    font-weight: 600;
}
.sort-tab tr,.sort-tab{
    border:none;
    margin: 0px;

}
.campany-logo{
    width: 100px;
}
.bordered{
    border: 1px solid #d8dbe0a4;
    border-radius: 6px;
    padding: 20px;
    margin: 10px 0;
    min-height: 290px;
    -webkit-box-shadow: 7px 6px 20px -18px rgba(0,0,0,0.3);
    -moz-box-shadow: 7px 6px 20px -18px rgba(0,0,0,0.3);
    box-shadow: 7px 6px 20px -18px rgba(0,0,0,0.3);
}
.job-title{
    color: #002e5d;
    font-weight: 600;
    border-bottom: 1px solid #d8dbe0;
    line-height: 2em;
    margin-bottom: 10px;
}
.btn-job{
    border-radius: 20px;
}
.table th{
    color: #002e5d;
    font-weight: 600;
}
.table td{
    vertical-align: baseline;
}
.overflow{
    height: 3.2em;
   
    white-space: nowrap;
    overflow: hidden;
}
.ellipsis{
    text-overflow: ellipsis;
}


.link-job-title{
    font-size: 16px;
    color: #408bbb;
    font-weight: 600;
}
.link-job-title:hover{
    font-size: 16px;
    text-decoration: none;
    color: #408bbb;
}
.vehicle-search{
    background: #ffffff;
    padding-bottom:100px
}
.vehicule-navLink{
    background: rgb(255 255 255 / 80%);
    padding:5px 20px;
    border-radius: 3px;
    margin: 0 2px 2px 0;

}
.vehicule-tabs .vehicule-navLink.active, .vehicule-tabs .vehicule-tabs.show .vehicule-navLink
{
    color: #002e5d;
    background-color: #f8f8f8;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;  
 
}
.vehicule-tabs .vehicule-navLink:hover{
    color: #002e5d;
    background-color: #dedede;
    border-color: transparent;
    /* border-bottom: none; */
    border-bottom: 4px solid #ff287d;
}
.vehicule-tabs{
    
    padding: 40px 0px 0px 0px;
}
.vehicule-navItem{
    padding: 0px;
    width: 25%;
}

.vehicule-tabContent > .active{
    display: block;
    background: #f8f8f8;
    border-radius: 4px;
    padding: 20px;
    margin-top: 0px;
   
}

.btn-search 
{
    width: 100%;
    background: #ff287d;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
 .btn-search:hover{
    width: 100%;
    background: #fff;
    border: 1px solid #ff287d;
    color: #ff287d;
    font-size: 16px;
    font-weight: 600;
}
.icon-vehicle{
    width: 45px;
}
.btn:focus
 {
  outline: 0;
  box-shadow: none ;
}
@media (max-width: 990px){
    .container-vehicle {
    width: 100%;
    max-width: 100%;
}
}
.form-group label:before {
    padding: 10px; 
}

  .form-control{
      border:solid .4px #9ea5b1;
  }
  .similar-job{
      border-bottom: 1px solid #ededf0;
  }
  .text-white{
      color: #ffffff;
  }
  .modal{
      z-index: 9999999;
      top: 20%;
  }
  @media (min-width: 576px){
      .modal-dialog {
          max-width: 700px;
      }
  }
  .information-title >h2:before {
    position: absolute;
    top: 0px;
    right: 100%;
    width: 5px;
    height: 70px;
    margin-right: 11px;
    content: '';
    background-color: #ff287d;
}

.information-title > h2 {
    text-transform: capitalize;
    font-size: 22px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 70px;
    /* margin-bottom: 30px; */
    padding: 15px 5px 15px 5px;
    /* color: #333333; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.displayed-file{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 600px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: #f3f3f3;
}
.file-name{
    margin-right: 50%;

}
@media (max-width: 990px) {
    .file-name{
        margin-right: 50px;
    
    }
}
.vehicle-make{
    margin:10%
}
.vehicle-item{
    position: relative;
    border: 1px solid #d8dbe0a4;
    border-radius: 5px;
    padding: 0px;
    margin: 10px 0;
    min-height: 200px;
    -webkit-box-shadow: 7px 6px 20px -18px rgba(0,0,0,0.3);
    -moz-box-shadow: 7px 6px 20px -18px rgba(0,0,0,0.3);
    box-shadow: 7px 6px 20px -18px rgba(0,0,0,0.3);
}
input:placeholder-shown:focus, select:placeholder-shown:focus{
    outline: 0;
}
/**************************** VEHICLE *****************************/
.occasion-item {
    position: relative
}

.occasion-item>a,.occasion-item>div {
    display: block;
    color: #333
}

.occasion-item .details-container {
    position: relative
}

.occasion-item .details-wrapper {
    position: relative
}

.occasion-item h2 {
    text-transform: uppercase;
    line-height: 17px;
    font-weight: 600;
    font-size: 1.25rem;
    vertical-align: middle;
    display: table-cell
}

.occasion-item h2 span {
    max-height: 34px;
    display: block;
    overflow: hidden
}

.occasion-item .thumb {
    max-width: 265px;
    max-height: 199px;
    position: relative
}
.thumbWraperResultItem {
    background: black;
    width: 265px;
    height: 199px;
}
.thumbWraperResultItem img{
    margin: auto;
    display: block;
}

.occasion-item .thumb-caption {
    vertical-align: top;
    padding-top: 20px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 20px;
    min-height: 40px;
    display: table;
    width: 100%
}

.occasion-item .thumb-caption:before {
    display: table;
    content: " "
}

.occasion-item .thumb-caption:after {
    clear: both
}

.occasion-item p {
    display: none
}

.occasion-item div.specs-preview {
    display: flex;
    position: absolute;
    right: 10px;
    left: 270px;
    top: 70px;
}

.occasion-item div.specs-preview li {
    flex-grow: 1;
    text-align: center;
   /* border-right: 1px solid #dedede;*/
    display: flex;
}

.occasion-item div.specs-preview li:last-child {
    border-right: 0
}

.occasion-item div.specs-preview li .value {
    color: #3d3d3d;
    font-weight: 600;
    font-size: .9rem;
    margin-left: 5px;
}

.occasion-item div.specs-preview li .name {
    color: #888;
    text-transform: uppercase;
    font-size: 11px;
    display: flex;
    position: relative;
    margin-left: 8px
}
.occasion-item .seller {
    position: absolute;
    right: 2px;
    top: 2px
}

.occasion-item .seller img {
    max-width: 70px;
    max-height: 40px;
    opacity: .7
}

@media screen and (min-width: 576px) {
    .occasion-item .seller img {
        max-width:50px
    }
}

@media screen and (min-width: 768px) {
    .occasion-item .seller img {
        max-width:70px
    }
}

@media screen and (min-width: 920px) {
    .occasion-item .seller img {
        max-width:90px
    }
}

@media screen and (min-width: 1200px) {
    .occasion-item .seller img {
        max-width:110px
    }
}

.occasion-item .seller.seller-das-weltauto img {
    opacity: 1
}

.occasion-item .powerups li {
    display: inline-block
}

.occasion-item .powerups li a {
    border: 1px solid #888;
    z-index: 1;
    position: relative;
    color: #333;
    padding: .5em 1em;
    border-radius: 20px;
    margin: 0 8px;
    text-transform: uppercase;
    font-size: 11px;
    height: 16px;
    display: inline-block
}

.occasion-item .powerups li a:hover {
    border-color: #d90000;
    transition: all ease-in 200ms
}

@media screen and (min-width: 768px) {
    .occasion-item .powerups li a {
        margin:0
    }
}

.occasion-item .powerups li a.compare:before {
    color: #d90000;
    content: '\e912';
    font-family: "stm-icon";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
    position: relative;
    top: 2px;
    opacity: .7
}

.occasion-item .powerups li a.pro:before {
    color: #d90000;
    content: '\e901';
    font-family: "stm-icon";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    position: relative;
    top: 2px;
    opacity: .7
}

.occasion-item .powerups li a.leasing:before {
    color: #d90000;
    content: '\e80a';
    font-family: "stm-icon";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    position: relative;
    top: 2px;
    opacity: .7
}

.layout-thumb .occasion-item div.specs-preview {
    margin-top: .5em;
    margin-bottom: 1.5em
}

.layout-thumb .occasion-item div.specs-preview li:nth-child(3) {
    display: none
}

.layout-thumb .occasion-item div.specs-preview li:nth-child(4) {
    border-right: 0
}

.layout-thumb .occasion-item div.specs-preview li:nth-child(1n+5) {
    display: none
}

.layout-thumb .occasion-item .price {
   
    color: #c5165c;
    padding: 10px 6px 10px 4px;
    font-weight: 600;
    font-size: 16px;
    z-index: 5;
    position: relative;
    float: right;
    white-space: nowrap
}



.layout-thumb .occasion-item h2 span {
   /* text-shadow: 0 1px #000;color: white;*/
    margin-left: .5em;
    
    font-size: .95rem;
    padding-right: 2px
}

.layout-thumb .occasion-item .powerups {
    display: none
}

.layout-thumb .occasion-item .thumb-caption {
    background: linear-gradient(to right,rgba(0,0,0,0.77) 0,rgba(0,0,0,0.5) 30%,rgba(125,185,232,0) 54%);
    padding: 0;
    position: absolute;
    bottom: 0
}

@media screen and (max-width: 767px) {
    .occasion-item ul.specs-preview {
        margin-top:.5em;
        margin-bottom: 1.5em
    }

    .occasion-item ul.specs-preview li:nth-child(3) {
        display: none
    }

    .occasion-item ul.specs-preview li:nth-child(4) {
        border-right: 0
    }

    .occasion-item ul.specs-preview li:nth-child(1n+5) {
        display: none
    }

    .occasion-item .price {
     
        color: #c5165c;
        padding: 10px 6px 10px 4px;
        font-weight: 600;
        font-size: 16px;
        z-index: 5;
        position: relative;
        float: right;
        white-space: nowrap
    }

    

    .occasion-item h2 span {
       /* text-shadow: 0 1px #000;
        color: white;*/
        margin-left: .5em;
        font-size: .95rem;
        padding-right: 2px
    }

    .occasion-item .powerups {
        display: none
    }

    .occasion-item .thumb-caption {
      /*  background: linear-gradient(to right,rgba(0,0,0,0.77) 0,rgba(0,0,0,0.5) 30%,rgba(125,185,232,0) 54%);*/
        padding: 0;
        position: relative;
        bottom: 0
    }
}

@media screen and (max-width: 575px) {
    .occasion-item .thumb {
        width:calc(100% + (2 * 10px));
        margin: 0 -2px;
        max-width: unset;
        max-height: unset
    }
    .thumbWraperResultItem {width:calc(100% + (2 * 10px));}
}

@media screen and (min-width: 576px) and (max-width:767px) {
    /* .occasion-item .thumb {
        max-height:100%;
        max-width: 100%;
        border-radius: 6px 6px 0px 0px;
    } */
    /* .occasion-item div.specs-preview{
        position: initial;
    } */
}

@media screen and (max-width: 575px) {
    .occasion-item {
        width:100%;
        /*margin: 0 -10px*/
    }
    .occasion-item div.specs-preview{
        position:initial;
    }
    body.c-site.a-index .occasion-item {
        width: auto;
        margin: auto
    }

    .occasion-item .thumb , .thumbWraperResultItem {
        max-width: 100%;
         /*width: 100%; */
        margin: 0;
        border-radius: 6px 6px 0px 0px;
        /* width: 200px; */
        height: auto;
    }

    .occasion-item ul.specs-preview {
        width: 100%
    }

    .occasion-item ul.specs-preview li:nth-child(1n+5) {
        display: initial;
        border-left: 1px solid #dedede
    }
}

@media screen and (min-width: 576px) and (max-width:767px) {
    .articles>div {
        display:inline-block;
        width: 49%;
        margin: 2px;
    }
}

@media screen and (min-width: 576px) {
    .layout-thumb .articles>div {
        display:inline-block;
        width: 49%
    }

    .layout-thumb .occasion-item {
        margin: 1em
    }

    .layout-thumb .occasion-item .thumb-caption,.layout-thumb .occasion-item .thumb ,.thumbWraperResultItem {
        max-width: 100%
    }

    .layout-thumb .occasion-item .thumb {
        height: 220px;
        object-fit: cover;
        width: 100%
    }
}

@media screen and (min-width: 768px) {
    .layout-thumb .articles>div {
        width:33%
    }

    .layout-thumb img.thumb {
        height: 199px;
        object-fit: cover;
        width: 100%;
        background-color: #e6e6e6
    }

    .layout-thumb .occasion-item {
        margin: 1em
    }

    .layout-list p {
        display: block;
        font-size: .9rem;
        max-height: 59px;
        overflow: hidden;
        position: absolute;
        top: 32px;
        margin-left: 275px
    }
    .fav_page_item .itemCard1H2P p {
        position: initial !important;
    }

    .layout-list .occasion-item .powerups {
        margin-bottom: 2em;
        margin-top: -36px;
        text-align: right
    }

    .layout-list .occasion-item .powerups li {
        display: inline-block;
        margin: 0 .25em
    }

    .layout-list .occasion-item .powerups li a {
        display: inline-block;
        width: auto
    }

    .layout-list .occasion-item ul.specs-preview {
        position: absolute;
        left: 275px;
        right: 0;
        bottom: 30px
    }

    .layout-list .occasion-item ul.specs-preview li:nth-child(4) {
        display: initial;
        border-right: 1px solid #dedede
    }

    .layout-list .occasion-item ul.specs-preview li:nth-child(5) {
        display: initial;
        border-right: 0
    }



    .layout-list .occasion-item .seller {
        right: auto;
        left: 2px
    }

    .layout-list .seller {
        top: unset;
        bottom: -4px
    }
}

@media screen and (min-width: 920px) {
    .occasions-results {
      /*  float:right;
        width: 75%*/
    }
}

.occasions-results ul.inline-filters {
    margin-top: 34px
}

.occasions-results ul.inline-filters li {
    position: relative;
    float: left;
    padding: 4px 26px 6px 13px;
    margin: 0 15px 8px 0;
    font-size: 13px;
    border-radius: 3px;
    color: #555;
    line-height: 28px;
    background: #fff;
    border: 1px solid #d90000
}

.occasions-results ul.inline-filters li span {
    text-transform: uppercase;
    margin-right: 6px;
    display: inline-block
}

.occasions-results ul.inline-filters li span:after {
    content: ", ";
    text-transform: lowercase;
    color: #d90000
}

.occasions-results ul.inline-filters li span:nth-last-child(2) {
    margin-right: 0
}

.occasions-results ul.inline-filters li span:nth-last-child(2):after {
    content: ""
}

.occasions-results ul.inline-filters li .reset-filter {
    position: absolute;
    top: 0;
    right: 0;
    width: 29px;
    height: 100%;
    line-height: 36px;
    text-align: center;
    font-size: 10px;
    color: #aaa;
    cursor: pointer
}

.occasions-results .mobile-filter {
    position: fixed;
    bottom: 10%;
    z-index: 510;
    left: 0;
    opacity: .9;
    right: 0;
    width: 100%;
    text-align: center
}

.occasions-results .mobile-filter .btn {
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -o-border-radius: 20px !important;
    border-radius: 20px !important;
    font-size: 16px
}

@media screen and (min-width: 920px) {
    .occasions-results .mobile-filter {
        display:none
    }
}

.occasions-results h1 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 17px
}

.occasions-results h1 span.count {
    color: #d90000
}

.occasions-results h1 span.make {
    text-transform: uppercase
}

.occasion-buttons {
    text-align: right
}

.occasion-buttons a {
    font-size: .7rem
}

.layout-thumb .occasion-buttons {
    text-align: center
}

.layout-thumb .occasion-buttons a {
    font-size: .6rem;
    padding: .3rem
}

.c-account.a-my-cars .occasions-results {
    float: none;
    width: 100%
}

@media screen and (min-width: 575px) {
    
    .layout-list .occasion-item .thumb-caption {
        display: inline-block;
        width: auto;
        padding: 0;
        border-bottom: 0
    }

    .layout-list .occasion-item .thumb-caption h2 {
        line-height: 30px;
        padding-left: .25em
    }

    .layout-list .occasion-item .thumb-caption .price {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        color: #c5165c;
        font-weight: 700;
        font-size: 1.5rem
    }

    .layout-list .occasion-item .thumb-caption {
        display:flex;
        position: absolute;
        right: 10px;
        left: 270px;
        top: 20px;
        border-bottom: 1px solid #dcdcdc;
    }

    .layout-list .occasion-item .thumb-caption h2 {
        display: inline-flex;
        flex-grow: 1;
        line-height: 22px
    }

    .layout-list .occasion-item .thumb-caption h2 span {
        max-height: 42px
    }

    .layout-list .occasion-item .thumb-caption .price {
        display: table-cell;
        white-space: nowrap;
        position: initial;
        margin-top: -5px;
    }
}


@media screen and (min-width: 768px) and (max-width:919px) {
    .layout-list .occasion-item .powerups li {
        margin:0
    }
}


.push-neuf-occasion-widget,.similar-models-widget {
    margin-bottom: 15px;
    overflow: hidden;
    margin-top: 19px
}

.push-neuf-occasion-widget .versions-item,.similar-models-widget .versions-item {
    width: 100%;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px
}



.occasions-filter {
    display: none;
    line-height: 15px;
    margin-bottom: 15px;
    border-bottom: 1px #d1d1d1;
    background: #f8f8f8
}

@media screen and (min-width: 920px) {
    .occasions-filter {
        width:24%;
        float: left;
        display: inline-block;
        margin-top: 33px
    }
}

.occasions-filter :focus {
    outline: #ccc auto 3px
}

.occasions-filter .btn-reset {
    margin-top: 0;
    opacity: .6
}

@media screen and (max-width: 919px) {
    .occasions-filter .btn-reset {
        margin-bottom:12em
    }
}

.occasions-filter .btn-reset:hover {
    opacity: 1
}

.occasions-filter.seller .button-container {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px
}

.occasions-filter.seller.queen-cars News-pic {
    background: #000;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.queen-cars .profile-pic {
    border-bottom: 0
}

.occasions-filter.seller.queen-cars .button-container {
    background: black
}

.occasions-filter.seller.queen-cars p {
    color: white
}

.occasions-filter.seller.queen-cars p.phone:before {
    color: #c7a15b
}

.occasions-filter.seller.queen-cars News-pic {
    background: #000;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.hbib-auto News-pic {
    background: #fdee25;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.top-autos News-pic {
    background: #333233;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.maaroufi-auto News-pic {
    background: #000;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.aicha-city-cars News-pic {
    background: #000;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.makeba-motors News-pic {
    background: #000;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.tunisie-auto-prix News-pic {
    background: #fff;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.autostorede News-pic {
    background: #fff;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter.seller.podium-auto-center News-pic {
    background: #174ea6;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada
}

.occasions-filter h1 {
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: .5px;
    font-weight: 600;
    margin: 20px 0
}

.occasions-filter p {
    position: relative;
    text-align: left
}

.occasions-filter p:before {
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    left: 0;
    color: #333
}

.occasions-filter p.phone {
    font-weight: 600;
    padding-left: 20px;
    color: #d90000;
    letter-spacing: 3px;
    font-size: 16px;
    padding-top: 10px
}

.occasions-filter p.phone:before {
    content: "\f095"
}

.occasions-filter p.address {
    font-size: 14px;
    line-height: 17px
}

.occasions-filter form {
    margin: 0 10px 5px
}

@media screen and (max-width: 919px) {
    .occasions-filter form {
        height:calc(100vh)
    }
}

.occasions-filter fieldset {
    margin-bottom: 0
}

.occasions-filter legend {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #3d3d3d;
    margin-bottom: 15px;
    margin-left: 0;
    cursor: pointer
}

.occasions-filter legend:before {
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px
}

.occasions-filter legend.open:before {
    content: "\f106"
}

.occasions-filter span.label {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 9px;
    margin-bottom: 9px;
    display: block;
    cursor: pointer;
    padding-top: 0 !important
}

@media screen and (min-width: 920px) {
    .occasions-filter span.label {
        margin-top:5px;
        margin-bottom: 5px
    }
}

.occasions-filter span.label:before {
    font-family: FontAwesome;
    content: "\f068";
    color: #d90000;
    display: inline-block;
    font-size: 12px;
    opacity: .9;
    margin-left: -4px;
    margin-right: 4px
}

.occasions-filter .field {
    position: relative;
    clear: both
}

.occasions-filter .field .count {
    color: #333;
    font-size: 11px
}

.occasions-filter .field .count:before {
    content: "("
}

.occasions-filter .field .count:after {
    content: ")"
}

.occasions-filter .field .clear-selection {
    opacity: 0;
    display: none;
    position: absolute;
    right: 0;
    top: 2px;
    cursor: pointer
}

.occasions-filter .field.have-selection .clear-selection {
    opacity: .1;
    display: initial
}

.occasions-filter .field.have-selection .clear-selection:focus {
    opacity: .5
}

.occasions-filter .field.have-selection:hover .clear-selection {
    opacity: .5;
    display: initial
}

.occasions-filter .field .selection {
    display: none;
    margin-bottom: 10px;
    margin-top: 0;
    width: auto
}

.occasions-filter .field .selection .eq {
    font-size: 14px;
    font-weight: 600
}

.occasions-filter .field .selection .fa,.occasions-filter .field .selection .eq {
    color: #d90000
}

.occasions-filter .field .selection li {
    display: inline;
    text-transform: uppercase
}

.occasions-filter .field .selection li:after {
    content: " OU ";
    color: #d90000;
    font-size: 9px;
    font-weight: 400
}

.occasions-filter .field .selection li:last-child:after {
    content: ""
}

.occasions-filter .field.off span.label:before {
    content: "\f067"
}

.occasions-filter .field.off .selection {
    display: block
}

.occasions-filter .field.attr-m .selection li:after {
    content: " OU "
}

.occasions-filter .field.attr-m .selection li:last-child:after {
    content: ""
}

.occasions-filter .field.attr-f .selection li:after {
    content: " ET "
}

.occasions-filter .field.attr-f .selection li:last-child:after {
    content: ""
}

.occasions-filter .bm-select .bm:before {
    content: "ou";
    display: block;
    height: 22px;
    width: 100%;
    color: gray;
    text-align: center;
    clear: both
}

.occasions-filter .bm-select .bm:first-child:before {
    content: "";
    height: 0
}

.occasions-filter .bm-select.off .selects {
    display: none
}

.occasions-filter .bm-select label {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    cursor: pointer;
    padding-top: 0 !important
}

.occasions-filter .bm-select .add-row {
    text-transform: none;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 20px
}

.occasions-filter .bm-select .select {
    width: 100%;
    margin: 0 10px 8px 0;
    min-width: 155px
}

.occasions-filter .bm-select .select select {
    background: white;
    border: 1px solid #ccc;
    padding: 0 15px;
    min-width: 100%;
    margin: 0;
    height: 34px
}

.occasions-filter .bm-select .select:after {
    width: 27px;
    height: 34px;
    line-height: 32px
}

.occasions-filter .range-slider span.label {
    margin-top: 10px;
    margin-bottom: 10px
}

.occasions-filter .range-slider input {
    width: 50px;
    text-align: right;
    color: #d90000;
    font-weight: 600;
    border: 1px solid #c5c5c5;
    margin-top: -2px;
    height: 17px
}

.occasions-filter .range-slider input:placeholder-shown {
    background: transparent;
    border-color: transparent;
    text-align: center;
    cursor: pointer
}

.occasions-filter .range-slider input:placeholder-shown::placeholder {
    color: #d90000
}

.occasions-filter .range-slider input:placeholder-shown:focus {
    background: white;
    border: 1px solid #c5c5c5;
    text-align: right
}

.occasions-filter .range-slider .slider-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px
}

.occasions-filter .range-slider .range {
    font-weight: normal;
    font-size: 11px;
    margin-bottom: 5px;
    margin-top: 0
}

.occasions-filter .range-slider .slider {
    width: 90%;
    margin-right: auto;
    margin-left: auto
}

.occasions-filter .range-slider.off .slider-wrapper {
    display: none
}

.occasions-filter .ui-slider .ui-slider-handle {
    top: -0.3em;
    border: 1px solid #999;
    background-color: #dadada;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.occasions-filter .ui-slider .ui-slider-handle:before,.occasions-filter .ui-slider .ui-slider-handle:after {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    content: "";
    width: 1px;
    height: 9px;
    position: absolute;
    background-color: #a5a5a5;
    z-index: 2;
    left: 5px;
    top: 3px;
    font-size: 7px
}

.occasions-filter .ui-slider .ui-slider-handle:after {
    left: 8px
}

.occasions-filter .text.off .form-group {
    display: none
}

.occasions-filter .cb .form-group {
    padding-left: 5px;
    padding-bottom: 10px
}

.occasions-filter .cb .form-group label {
    display: inline-block;
    width: 90% !important;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 3px;
    position: relative;
    padding-left: 20px
}

.occasions-filter .cb .form-group label.no-results {
    color: #888
}

.occasions-filter .cb .form-group label.no-results .count {
    color: #888
}

.occasions-filter .cb .form-group label.no-results:before {
    border-color: #888
}

.occasions-filter .cb .form-group label:before {
    margin-top: 0;
    margin-bottom: -17px;
    content: "";
    display: block;
    width: 13px;
    height: 13px;
    border: 1px solid #333;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .6;
    -webkit-transition: all .12s,border-color .08s;
    transition: all .12s,border-color .08s
}

.occasions-filter .cb .form-group label input {
    display: initial
}

.occasions-filter .cb .form-group input {
    display: none
}

.occasions-filter .cb .form-group input:checked+label:before {
    border-color: #d90000;
    border-top-color: transparent;
    border-left-color: transparent;
    background-color: transparent !important;
    margin-bottom: 0;
    top: -1px;
    width: 7px;
    left: 4px;
    opacity: 1
}

.occasions-filter .cb.off .form-group {
    display: none
}

.occasions-filter [type="submit"] {
    width: 100%;
    margin-left: 0;
    margin-top: 10px
}

.occasions-filter [type="submit"]:disabled {
    background: dimgrey
}

.button-container {
    background: #f8f8f8;
    z-index: 3;
    margin: -5px -10px;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px
}


@media screen and (max-width: 919px) {
    .occasions-filter {
        position:fixed;
        z-index: 1000;
        width: 100%;
        margin-bottom: 0 !important;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        margin-left: -10px
    }

    body.filters-visible #onesignal-bell-container {
        display: none !important
    }
}

@media screen and (min-width: 768px) and (max-width:919px) {
    .occasions-filter .cb .form-group label {
        width:30% !important
    }

    .occasions-filter {
        margin-left: -15px
    }
}

.mobile-show-results {
    text-align: center;
    width: calc(100% + 20px);
    margin: 0 -10px;
    bottom: 10%;
    z-index: 10;
    opacity: .9;
    padding-bottom: 0;
    position: fixed;
    padding-top: 1em
}

.mobile-show-results .btn {
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -o-border-radius: 20px !important;
    border-radius: 20px !important;
    font-size: 16px
}

@media screen and (min-width: 920px) {
    .mobile-show-results {
        display:none
    }
}

.c-account.a-my-cars .occasions-filter {
    display: none
}

.version-details .buttons,.occasion-details .buttons {
    margin-top: 30px;
    position: relative;
    text-align: left;
    clear: both
}

.version-details .buttons>div:first-child,.occasion-details .buttons>div:first-child {
    display: none
}

.version-details .buttons>div:first-child sup,.occasion-details .buttons>div:first-child sup {
    font-size: 10px
}

.version-details .buttons>div:first-child span.price-ht,.occasion-details .buttons>div:first-child span.price-ht {
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding-top: 5px
}

@media screen and (min-width: 1200px) {
    .version-details .buttons>div:first-child,.occasion-details .buttons>div:first-child {
        display:inline-block;
        font-size: 11px;
        color: #FFF;
        background-color: #d90000;
        float: right;
        width: calc(20% - 20px);
        text-transform: uppercase;
        height: 40px;
        border-top: 1px solid #e2e2e2;
        padding: 29px 10px 31px;
        text-align: right
    }

    .version-details .buttons>div:first-child span,.occasion-details .buttons>div:first-child span {
        font-weight: 600;
        font-size: 23px
    }

    .version-details .buttons>div:first-child sup,.occasion-details .buttons>div:first-child sup {
        font-size: 10px
    }
}

.version-details .buttons a,.occasion-details .buttons a {
    text-transform: uppercase;
    color: #222;
    padding: 25px 17px;
    font-size: 12px;
    line-height: 26px;
    height: 50px;
    position: relative;
    display: block;
    border-top: 1px solid #e2e2e2;
    font-weight: normal;
    text-decoration: none
}

@media screen and (min-width: 576px) {
    .layout-list .occasion-item img.thumb {
        /*  min-width: 265px;*/
          min-height: 199px;
          background-color: #e6e6e6;
          object-fit: cover;
          border-radius: 6px 0px 0px 6px;
      }
    .version-details .buttons a,.occasion-details .buttons a {
        padding:25px 0;
        display: inline-block;
        width: 49%
    }
}

@media screen and (min-width: 920px) {
    .version-details .buttons a,.occasion-details .buttons a {
        color:#fff;
        background: #222;
        width: calc(25% - 51px);
        margin-right: 1px;
        float: left;
        padding: 25px
    }

    .version-details .buttons a:hover,.occasion-details .buttons a:hover {
        background: #d90000
    }
}

@media screen and (min-width: 1200px) {
    .version-details .buttons a,.occasion-details .buttons a {
        width:calc(20% - 51px)
    }
}

.version-details .buttons a:before,.occasion-details .buttons a:before {
    font-family: "fontello";
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    width: auto;
    margin-right: .1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0;
    font-weight: 500;
    color: #222;
    position: absolute;
    top: 22px;
    left: 9px;
    font-size: 55px
}





.version-details .buttons a.zitouna:before,.occasion-details .buttons a.zitouna:before {
    content: ' ';
   /* background: url(/images/zitouna-bw.png);*/
    height: 55px;
    width: 55px;
    background-size: contain;
    background-repeat: no-repeat
}

@media screen and (max-width: 919px) {
    .version-details .buttons a.zitouna:before,.occasion-details .buttons a.zitouna:before {
        filter:invert(1)
    }
}

.version-details .buttons a.add-alert:before,.occasion-details .buttons a.add-alert:before {
    content: '\e800'
}

.version-details .buttons a.contact-seller:before,.occasion-details .buttons a.contact-seller:before {
    content: '\e806'
}

.version-details .buttons a.favorite:before,.occasion-details .buttons a.favorite:before {
    content: '\e801'
}

.version-details .buttons a.specs:before,.occasion-details .buttons a.specs:before {
    content: '\e900';
    font-family: "stm-icon";
    font-size: 48px;
    top: 27px
}

.version-details .buttons a span,.occasion-details .buttons a span {
    padding-left: 65px;
    display: block;
    white-space: nowrap
}

@media screen and (min-width: 576px) {
    .version-details .buttons a span,.occasion-details .buttons a span {
        padding-left:70px
    }
}

@media screen and (min-width: 920px) {
    .version-details .buttons a span,.occasion-details .buttons a span {
        padding-left:53px
    }
}

.version-details .buttons a b,.occasion-details .buttons a b {
    font-size: 20px;
    font-weight: 600
}

@media screen and (max-width: 1199px) {
    .version-details .buttons>div:first-child,.occasion-details .buttons>div:first-child {
        display:block;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        color: #222;
        text-align: center;
        box-sizing: border-box;
        height: inherit;
        border: 0;
        text-transform: uppercase
    }

    .version-details .buttons>div:first-child span,.occasion-details .buttons>div:first-child span {
        font-weight: 600;
        font-size: 23px
    }
}



.carousel-occasions-widget {
    text-align: left;
    margin: 0 auto
}

@media screen and (min-width: 576px) {
    .carousel-occasions-widget {
        overflow:hidden
    }

    .carousel-occasions-widget img {
        max-height: 164px;
        object-fit: cover
    }
}

.carousel-occasions-widget .owl-nav {
    text-align: center
}

@media screen and (min-width: 576px) {
    .carousel-occasions-widget .items {
        overflow:hidden
    }
}

.carousel-occasions-widget.layout-thumb .occasion-item ul.specs-preview li:nth-child(3) {
    display: initial
}

@media screen and (max-width: 575px) {
    .carousel-occasions-widget.layout-thumb .occasion-item .thumb-caption {
        width:100%;
        margin: 0
    }
}


.home-search-widget .tabs-content .occasions {
    padding-top: 10px;
    color: #3d3d3d
}

.home-search-widget .tabs-content .occasions .chosen-container {
    width: 100% !important
}



.home-search-widget .tabs-content .occasions .search-buttons {
    padding-top: 55px
}


body.c-account.a-my-ads .occasions-filter {
    display: none !important
}

body.c-account.a-my-ads .occasions-results {
    width: 100%
}



form.split .form-group.attr-select,form.split .form-group.field-occasionform-governorate_id,form.split .form-group.field-occasionform-phone,form.split .form-group.field-occasionform-duration,form.split .form-group.field-occasionform-mileage,form.split .form-group.field-occasionform-price,form.split .form-group.field-occasion-brand,form.split .form-group.field-occasionform-model_id,form.split .form-group.field-occasionform-year,form.split .form-group.field-occasionform-month {
    width: 25%
}

@media screen and (max-width: 575px) {
    form.split .form-group.attr-select,form.split .form-group.field-occasionform-governorate_id,form.split .form-group.field-occasionform-phone,form.split .form-group.field-occasionform-duration,form.split .form-group.field-occasionform-mileage,form.split .form-group.field-occasionform-price,form.split .form-group.field-occasion-brand,form.split .form-group.field-occasionform-model_id,form.split .form-group.field-occasionform-year,form.split .form-group.field-occasionform-month {
        width:50%;
        float: left
    }
}

.field-occasionform-finition {
    margin-bottom: 4px;
    display: inline-block
}


.occasion-details .hide {
    visibility: hidden
}

.occasion-details .edit-occasion {
    float: right
}

.occasion-details .floating-infos {
    display: none
}

@media screen and (max-width: 575px) {
    .occasion-details .back-to-results span {
        display:none
    }

    .occasion-details .floating-infos {
        background: white;
        position: sticky;
        top: 70px;
        margin: -10px;
        padding: 10px 10px;
        z-index: 10;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        box-shadow: 1px 1px 4px #ccc
    }

    .occasion-details .floating-infos div {
        font-weight: 500;
        font-size: 1.1rem;
        text-transform: uppercase
    }
}

.occasion-details .wrapper img {
    height: auto;
    width: calc(100% + (2 * 10px));
    max-width: unset;
    position: relative
}

@media screen and (max-width: 575px) {
    .occasion-details .wrapper img {
        margin:0 -10px
    }
}

@media screen and (min-width: 768px) {
    .occasion-details .wrapper img {
        width:50%;
        max-height: 440px;
        object-fit: cover
    }
}

.occasion-details .wrapper .infos {
    line-height: 22px;
    overflow: hidden;
    padding-top: 15px;
    padding-right: 15px
}

@media screen and (min-width: 768px) {
    .occasion-details .wrapper .infos {
        float:right;
        width: 44%;
        padding-top: 0
    }
}

.occasion-details .wrapper .infos .small-price {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px
}

@media screen and (min-width: 920px) {
    .occasion-details .wrapper .infos .small-price {
        display:none
    }
}

.occasion-details .wrapper ul {
    overflow: hidden;
    margin-bottom: 15px;
    font-size: 14px;
    color: #3d3d3d
}

.occasion-details .wrapper ul li {
    width: 49%;
    display: inline-block;
    padding-bottom: 8px;
    vertical-align: top
}

@media print {
    .occasion-details .wrapper ul li {
        width: 32%
    }
}

.occasion-details .wrapper ul li label , .autrinfolable {  
    display: block;
    font-size: 14px;
    color: #878787;
    margin-bottom: -5px;

}
.occasion-details .wrapper ul li span {
    font-size: 18px;
    color: black;
}

.details{
    color: #3d3d3d;
    font-weight: 500;
    text-transform: capitalize;
}

.occasion-details .wrapper .description {
    margin-bottom: 20px;
    text-align: left;
    border-top: solid 1px #f0f0f0;
    padding: 10px 0 0;
    margin-top: 15px;
    clear: both;
    font-size: 14px;
    color: #3d3d3d
}

.occasion-details .wrapper .description p {
    white-space: pre-wrap;
    line-height: 15px
}

.occasion-details .wrapper .description p b {
    display: block
}

.occasion-details .technical-details {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 1px;
    clear: both
}

@media screen and (min-width: 920px) {
    .occasion-details .technical-details {
        margin:50px
    }
}

.occasion-details .technical-details table {
    margin-top: 20px;
    width: 100%
}

@media screen and (min-width: 576px) {
    .occasion-details .technical-details table {
        width:33%;
        display: inline-table;
        vertical-align: top
    }

    .occasion-details .technical-details table:nth-child(n+8) {
        width: 100%
    }
}

@media screen and (min-width: 768px) {
    .occasion-details .technical-details table:nth-child(n+8) {
        width:33%
    }
}

@media print {
    .occasion-details .technical-details table {
        width: 33%;
        display: inline-table;
        vertical-align: top
    }
}

.occasion-details .technical-details table thead {
    color: #3d3d3d
}

.occasion-details .technical-details table thead th {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase
}

.occasion-details .technical-details table tbody th {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px
}

.occasion-details .technical-details table th,.occasion-details .technical-details table td {
    padding: 5px;
    border-bottom: 1px solid #eaeaea
}

.occasion-details .technical-details table td {
    color: #777;
    padding-left: 15px;
    font-size: 14px
}

.occasion-details .technical-details table tr:last-child td {
    border-bottom: 0
}

.occasion-details .technical-details .attribute-suffix {
    font-size: 10px
}

.occasion-details .gallery-widget #right-side iframe {
    margin-top: 0
}

@media print {
    .occasion-details .wrapper {
        text-align: center
    }

    .occasion-details .wrapper img {
        width: 80%;
        float: none;
        padding-bottom: 30px
    }

    .occasion-details .wrapper ul,.occasion-details .wrapper .infos {
        width: 80%;
        margin: 0 auto
    }

    .occasion-details .wrapper .mobile-only {
        display: block !important;
        text-align: center !important;
        padding-bottom: 55px !important;
        font-size: 35px !important
    }

    .member-details-widget {
        page-break-inside: avoid
    }

    .technical-details {
        page-break-before: always
    }

    .technical-details table {
        page-break-inside: auto
    }

    .technical-details tr {
        page-break-inside: avoid;
        page-break-after: auto
    }
}

@media screen and (max-width: 575px) {
    .gallery-widget {
        display:flex;
        flex-direction: column
    }

    body.c-versions.a-versions .gallery-widget {
        flex-direction: column-reverse
    }

    .c-occasions.a-occasion .gallery-widget {
        flex-direction: column-reverse
    }

    .gallery-widget #right-side {
        margin-top: 2em;
        margin-left: 0;
        text-align: center
    }

    .occasion-details .buttons {
        margin-top: 20px;
        position: relative
    }

    .occasion-details .buttons a {
        padding: 15px 10px;
        font-size: 11px;
        line-height: 19px;
        height: 36px
    }

    .occasion-details .buttons a:before {
        top: 6px;
        font-size: 48px
    }
}
.icon-option-vehicle{
    width: 20px;
    vertical-align: top;
}
.l-main__article2 .icon-option-vehicle{
    width: 35px;
    height: 35px;
    vertical-align: text-top;
    margin-top: 3px;
}
.price, .price:hover{
    color: #c5165c;
    font-weight: 600;
    font-size: 1.2rem;
    
}
.priceCard {
    float: right;
}

/******************* IMAGES GALLERY ***********************/
.image-gallery-icon {
    color: #fff;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a)
}

@media (hover: hover) and (pointer:fine) {
    .image-gallery-icon:hover {
        color:#337ab7
    }

    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1)
    }
}

.image-gallery-icon:focus {
    outline: 2px solid #337ab7
}

.image-gallery-using-mouse .image-gallery-icon:focus {
    outline: 0
}

.image-gallery-fullscreen-button,.image-gallery-play-button {
    bottom: 0;
    padding: 20px
}

.image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px
}

@media (max-width: 768px) {
    .image-gallery-fullscreen-button,.image-gallery-play-button {
        padding:15px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px
    }
}

@media (max-width: 480px) {
    .image-gallery-fullscreen-button,.image-gallery-play-button {
        padding:10px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px
    }
}

.image-gallery-fullscreen-button {
    right: 0
}

.image-gallery-play-button {
    left: 0
}

.image-gallery-left-nav,.image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px
}

@media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg {
        height:72px;
        width: 36px
    }
}

@media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg {
        height:48px;
        width: 24px
    }
}

.image-gallery-left-nav[disabled],.image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none
}

.image-gallery-left-nav {
    left: 0
}

.image-gallery-right-nav {
    right: 0
}

.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    position: relative
}

.image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5
}

.image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0
}

.image-gallery-content.fullscreen {
    background: #000;
    z-index: 999999999;
    overflow: hidden;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px)
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,.image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh
}

.image-gallery-slide-wrapper {
    position: relative
}

.image-gallery-slide-wrapper.left,.image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px)
}

@media (max-width: 768px) {
    .image-gallery-slide-wrapper.left,.image-gallery-slide-wrapper.right {
        width:calc(100% - 87px)
    }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.image-gallery-slide.center {
    position: relative
}

.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain
}

.image-gallery-slide .image-gallery-description {
    background: rgba(0,0,0,.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal
}

@media (max-width: 768px) {
    .image-gallery-slide .image-gallery-description {
        bottom:45px;
        font-size: .8em;
        padding: 8px 15px
    }
}

.image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4
}

.image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center
}

.image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: 0;
    padding: 5px;
    transition: all .2s ease-out
}

@media (max-width: 768px) {
    .image-gallery-bullets .image-gallery-bullet {
        margin:0 3px;
        padding: 3px
    }
}

@media (max-width: 480px) {
    .image-gallery-bullets .image-gallery-bullet {
        padding:2.7px
    }
}

.image-gallery-bullets .image-gallery-bullet:focus {
    transform: scale(1.2);
    background: #337ab7;
    border: 1px solid #337ab7
}

.image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2);
    border: 1px solid #fff;
    background: #fff
}

@media (hover: hover) and (pointer:fine) {
    .image-gallery-bullets .image-gallery-bullet:hover {
        background:#337ab7;
        border: 1px solid #337ab7
    }

    .image-gallery-bullets .image-gallery-bullet.active:hover {
        background: #337ab7
    }
}

.image-gallery-thumbnails-wrapper {
    position: relative
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl
}

.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px
}

@media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right {
        width:81px
    }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px
}

.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right {
    margin: 0 5px
}

@media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right {
        margin:0 3px
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0
}

@media (max-width: 768px) {
    .image-gallery-thumbnails {
        padding:3px 0
    }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .3s ease-out;
    white-space: nowrap
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px;
    background: 0 0;
    padding: 0
}

@media (max-width: 768px) {
    .image-gallery-thumbnail {
        border:3px solid transparent;
        width: 81px
    }
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 2px
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0
}

.image-gallery-thumbnail.active,.image-gallery-thumbnail:focus {
    outline: 0;
    border: 4px solid #337ab7;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .image-gallery-thumbnail.active,.image-gallery-thumbnail:focus {
        border:3px solid #337ab7;
        border-radius: 10px;
    }
}

@media (hover: hover) and (pointer:fine) {
    .image-gallery-thumbnail:hover {
        outline:0;
        border: 4px solid #337ab7;
        border-radius: 10px;
    }
}

@media (hover: hover) and (pointer:fine) and (max-width:768px) {
    .image-gallery-thumbnail:hover {
        border:3px solid #337ab7;
        border-radius: 10px;
    }
}

.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: #fff;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 0 2px 2px #1a1a1a;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%
}

@media (max-width: 768px) {
    .image-gallery-thumbnail-label {
        font-size:.8em;
        line-height: .8em
    }
}

.image-gallery-index {
    background: rgba(0,0,0,.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4
}

@media (max-width: 768px) {
    .image-gallery-index {
        font-size:.8em;
        padding: 5px 10px
    }
}




.gallery-container {
    margin-top: 50px
}

.app-header {
    letter-spacing: 1px;
    text-transform: uppercase
}

.play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: rgba(0,0,0,.7);
    border-radius: 5px
}

.play-button:hover {
    background-color: rgba(0,0,0,.9)
}

.play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent #fff
}

.close-video::before {
    content: '✖';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1;
    line-height: .7;
    display: block;
    color: #fff
}

.video-wrapper {
    position: relative;
    padding: 33.35% 0;
    height: 0
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.app .image-gallery,.app-sandbox {
    margin: 0 auto;
    width: 65%
}

@media (max-width: 1320px) {
    .app-sandbox-content {
        padding:0 20px
    }
}

.app-sandbox {
    margin: 40px auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none
}

.app-buttons li {
    display: block
}

@media (max-width: 768px) {
    .app-header {
        font-size:20px
    }

    .app-buttons li {
        display: block;
        margin: 10px 0
    }

    .app-buttons li+li {
        padding: 0
    }

    .play-button {
        height: 40px;
        width: 65px
    }

    .play-button:after {
        top: 11px;
        left: 27px;
        border-width: 8.5px 0 8.5px 12px
    }

    .close-video::before {
        font-size: 16px;
        padding: 15px
    }
}

@media (max-width: 1024px) {
    .app .image-gallery,.app-sandbox {
        width:100%
    }
}

.app-interval-input-group {
    display: table
}

.app-interval-label {
    display: table-cell;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 3px solid #ccc;
    border-right: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.app-interval-input {
    -webkit-appearance: none;
    display: table-cell;
    margin: 0;
    padding: 9px;
    border-radius: 5px;
    font-size: 14px;
    border: 3px solid #ccc;
    background: #fff;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

input.app-interval-input {
    width: 65px
}

.app-checkboxes {
    margin-top: 10px
}

.app-checkboxes li {
    display: block
}
/****************** END GALLERY IMAGES **************/
.title-feature{
    color: #7e7e7e;
    text-transform: capitalize;
    margin: 0px;
    padding-left: 5px;
    display: inline-block;
    font-weight: 500;
    
}
.title-feature1{
    padding-left: 39px;
    font-size: 18px;
    color: black;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: -22px;
}

.features-vehicle {
    border: 1px solid #d8dbe0a4;
    border-radius: 6px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 7px 6px 20px -18px rgb(0 0 0 / 30%);
    font-weight: 600;
}
.similar-car{
    border-radius: 6px 6px 0px 0px;
    width: 100%;

    background-color: #e6e6e6;

}
.call-phone{
    display: inline-block;
    height: 32px;
    border-radius: 5px;
    background: #c5175c;
    padding: 5px 10px;
    color: #ffffff;
    font-weight: 600;
    margin: 5px;
    vertical-align: -webkit-baseline-middle;
    min-width: max-content;
}
.call-phone svg{
    color: white;
    width: 15px;
    height: 15px;
    vertical-align: text-top;
}
.call-phone:hover{
    text-decoration: none;
    color: gainsboro;
}
.btn-vehicle{
    border-radius: 20px;
    background: none;
    border: none;
    font-weight: 600;
    width: 35px;
    margin-left: 10px;
}
/********** Range slider ************/
.min-max{
    border: 1px solid #9ea5b1;
    background:#ffffff;
    padding: 4px;
    color: #ff287d;
    font-size: 10px;
    min-width: 50px;
}
.rc-slider-handle ,.rc-slider-handle-click-focused {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -5px;
    cursor: grab;
    /* border-radius: 50%; */
    border: solid 2px #ff287d;
    background-color: #fff;
    touch-action: pan-x;
}

.rc-slider-track {
    position: absolute;
    left: 0;
    height: 6px;
    background-color: #ff287d;
}
.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 6px;
    border-radius: 0px;
}
.rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-top: 5px;
}
.rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
}

.filter{
    background: #f3f3f3;
}
.vehicle-data-table{
    background: #002144;
}

.articles>.position-relative .table-responsive>td{
    border: 1px solid;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #d90000;
    border-color: #d90000;
}
.page-item.disabled .page-link {
    pointer-events: none;
    cursor: auto;
    color: #d90000;
    background-color: #fff;
    border-color: #d90000;
}
.page-link, .page-link:hover {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid;
    color: #d90000;
    background-color: #fff;
    border-color: #d90000;
    margin: 5px;
}
.page-link:hover {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid;
    color: #d90000;
    background-color: #ededf0;
    border-color: #d90000;
    margin: 5px;
}
.page-link:focus{
    outline: none;
    box-shadow: none;
}
.filter-title-field{
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    position: relative;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding: 0px 5px 5px 5px;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 5px;
}

.filter-title-field:before {
    display: inline-block;
    margin-left: -4px;
    position: absolute;
    top: 0px;
    right: 100%;
    width: 2px;
    height: 20px;
    margin-right: 18px;
    content: '';
    background-color: #ff287d;
}
.articles>.table th, .table td, .table tr{
    border-top-color: transparent;
    padding: 0px;
    border-bottom: transparent;
}
/**************** Vehicle tab form ****************/
.vehicule-form-navItem {
    padding: 0px;
    width: 20%;
}
.immobilierIcon{
    width: 14.25%;

}
  
.articleIcon{
    width: 11.1%;;

}
.vehicule-form-tabContent{
    margin: -20px;
}

.vehicule-form-tabs .vehicule-form-navLink.active, .vehicule-form-tabs .vehicule-form-tabs.show .vehicule-form-navLink
{
    color: #002e5d;
    background-color: #f8f8f8;
    border-bottom: 4px solid #ff287d;
   
 
}
.vehicule-form-tabs .vehicule-form-navLink:hover{
    color: #002e5d;
    background-color: #dedede;
    border-bottom: 4px solid #ff287d;
}

.tabpane-vehicle-form{
    box-shadow : none;
   
}
.favorite, .favorite:hover{
    color: #ff287d;
    font-weight: 600;
    /* position: absolute;
    top: 100px;
    right: 0;
    text-decoration: none; 
    align-items: center; */
    display: inline;
    margin-top: -8px;
    margin-left: 10px;
}
.icon-favorite{
    display: inline-block;
    width: 15px;
    height: 15px;
    
}
.tabpane-vehicle-form  > .active{
    box-shadow: none;
}

.vehicle-thumbnail {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 50em;
    width: 90px;
   /* height: 90px;*/
   height: 130px;
    font-size: 14.4px;
}

.artdeco-carousel__item-container{
    border-bottom: 1px solid #e6e8eb;

    padding: 10px;
    min-height: 200px;
}

.vehicle-media{
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    width: 100%;
    border-bottom: none;
    overflow: hidden;
    position: relative;
    
    
}
.bike-size-container{
    background: #ffffff;
    margin-top: 40px;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 7px 6px 35px -18px rgb(0 0 0 / 50%);
}
#dd{
    padding: 0px 0px 7px 3px !important;
    /* height: 45px; */
    /* width: fit-content; */
    width: 100px;
}
#dd li {
    height: 100%;
}
#dd li a{
    height: 100% !important;
    display: block;
    padding: 0px;
    width: fit-content;
}

#dd > li,
#dd > li li {
    position: relative;
    display:inline-block;
    /* width: 125px; */
    width: 101%;
    padding: 6px 15px;
 
}
#dd > li li { background: transparent none;    width:max-content; padding-top: 5px !important; }
#dd > li li a {
    width: fit-content;
    margin-left: 10px;
    color: white;
    padding-top: 3px;
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;

}
#dd ul li:last-child {
    padding-bottom: 10px !important;
}
#dd > li li:hover {

    color: #000000;
}

#dd>li:last-child {
    border-right:0;
    border-left: 1px #000000;
    border-radius: 0 8px 8px 0 ;
    width: 101% !important;
}
#dd ul {
    position: absolute;
    top: 0; 
    left:95px;
    max-height:0em;
    margin:0; padding:0;
    border-radius: 8px;
    /* background: rgb(4 24 75 / 92%); */
    background: rgb(21 18 212 / 92%);
    /* border: solid 1px #020153; */
   /* background-color:#ddd;
    background-image: linear-gradient(#fff,#ddd);*/
    overflow:hidden;
    transition: 0s max-height 0s;
    z-index: 1000;
    width: 160%;
}

#dd>li:hover ul {
    /* need an adaptation, lower is better, but see it large ;) */
    max-height:50em;
}
@media (max-width: 720px) {
#dd{
    display: none;
}
}

html:not([dir="rtl"]) .alert-dismissible{
    padding-right: 1rem;
}

.animatedText{
    position: absolute;
    width: max-content;
    margin: 0;
    font-weight: 400;
    text-align: left;
    transform: translateX(100%);
    animation: textAnimation  linear infinite;
     animation-duration:var(--animation-duration); 
     animation-direction:var(--animation-direction); 
   /* animation: 3s ease-in 1s infinite reverse both running slidein;*/
   white-space: nowrap; /* Prevent text from wrapping */
   display: inline-block;
   overflow: hidden;
    
}
@keyframes textAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

.text-update .horizontal-news{
    position: absolute;
    width: 150%;
    margin: 0;
    font-weight: 700;
    text-align: left;
    transform: translateX(100%);
    animation: moving 25s linear infinite;
    
}

.text-update{
    line-height: 28px;
    height: 40px;
    border-bottom: solid 1px;
    background: none;
    overflow: hidden;
    position: relative;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;        
}

@media (min-width: 352px) and (max-width: 429px) {

    .text-update{
        font-size: 12px;
        
    }
}
@media (min-width: 300px) and (max-width: 352px)  {

    .text-update{     
        font-size: 10px;        
    }
}
@media (max-width: 300px)   {

    .text-update{
        font-size: 9px;        
    }
}


@keyframes moving{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }

  
}

#btn-disabled{
    background-color: #828f9b;
}
.icon-vehicle2{
    height: 93%;
    width: 93%;
}
.bonplanIcon {
    width: 20%;
    height: 60px;
}
.animalIcon {
    width: 16.66%;
    height: 60px;
}
.bonplanIcon a , .animalIcon a {
    width: 99%;
    height: 99%;
}
.vehicule-tabContent {
    margin-top: 3px;
}
.vehicule-navItem{
    height: 60px;  
}
.vehicule-navItem a {
    width: 99%;
    height: 99%;  
}
.vehicule-navItem a img {
    width: 99%;
    height: 99%;  
}
.mainlabel {display:block;
     font-size:24px ;
      font-weight:600;
      color:black;
      margin-top: -15px;
    }
.tab-pane form {
    /* padding-top: 10px; */
}
.compteradio {
    display: inline-block;
}
.compteradio input{
    margin-right: 5px;
}
.applymessage{
    display: block;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
}
.loadingnow {
    font-size:30px;
    color:#0500b1;
    font-weight:900;
    text-align:center;
    margin-top:50px;
}
.showPassRow input{
    margin-right: 5px !important;
    vertical-align: top;
    margin-bottom: 15px !important;
}
.showPassRow label {
    font-size: 14px;
    font-weight: 500;
}
.mainTabWithSub{
    cursor: pointer;
    margin: auto;
}
.itemsRow .col-md-3 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
}
.mx-auto{
    width: 100%;
    margin-top: -8px;
}

.btn-vehicle {
    padding: 0.1rem 0.3rem !important;
    border-radius: 6px !important;
} 
.price .suffix {
    font-size: 0.7rem;
}
.vehicle-item h4 {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: black;
    text-transform: capitalize;
    line-height: 1.1em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 6px;
    height: 1.4rem;
}

.vehicle-item .pl-4 {
    padding-bottom: 0px !important;
}
.pl-4, .px-4 {
    padding: 5px 8px 8px 5px !important;
}
.slogan {
    line-height: 10px;
    color: white;
    font-weight: 600;
    font-size: 12px;
}
.sloganP1{
    margin-bottom: 0px !important;
    margin-top: 22px;
    letter-spacing: 0.5px;
    width: max-content;
}
.sloganP2{
    width: max-content;
    margin-top: 10px;
    letter-spacing: 1.4px;
}
/*.mainBanner{
    height: 400px;*/
    /* background-image: url(assets/images/banniére-principale-TL.jpg);
    background-repeat: no-repeat;
    background-position: center; */
/*}
.mainBanner img {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}*/
.typeLabel{
    margin-left: 15px;
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: black;
    padding-bottom: 10px;
    text-transform: capitalize;
}
.mainBannerText {
    text-align: center;
    display: block;
    font-size: 30px;
    height: 200px;
    margin-top: 130px;
}
.center_row{
    margin: auto;
}
.loadingGif{
    width: 100%;
}
.loadingGif img {
    display: block;
    margin: 30px auto;
    max-width: 120px;
}
.l-sidebar__widget .loadingGif img {
    margin: 50px auto 20px;
    max-width: 100px;
}
.itemCardSubtitle {
    margin: 0px 0px 4px !important;
    color: black;
    text-transform: capitalize;
    line-height: 1.1em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
    height: 1.1rem;
}
.itemCardTitle{
height: 45px;
}
.add_page li a{
    height: 60px;
    padding: 8px !important;
}
.add_page li {
padding: 1px;
}
.add_page li a img , .add_page li a svg {  
      width: 90% !important;
    height: 90% !important;
    vertical-align: text-top;
}
.nav-tabs .bonplanIcon a{
    padding: 5px;
}

.l-footer__row2 .c-logo {
    display: inline-block;
  width: auto !important;
  height: auto !important;
}
.l-footer__row2 .c-logo__image {
    height: 90px !important;
    width: 155px !important;
}
.footer_col1{
width:20%;
display: inline-block;
}
.footer_col2{
    width: 59%;
    display: inline-block;
    vertical-align: text-bottom;
    padding-bottom: 2px;
    }
 .footer_col3{
  
        width: 20%;
        display: inline-block;
        }
.footer_social img {
        width: 35px;
    }
    .footer_social li {
        padding: 0px !important;
    }
    .footer_social a {
        margin-right: 10px !important;
    }
    .footer_social {
        margin-left: 15px;
    }
    .footer_social ul {
       float: right;
    }
    .l-footer__nav2 ul li {
        margin: auto;
    }
.l-footer__row2{
    position: relative;
}
.l-footer__row22{
    color: black;
    text-align: center;
    width: inherit;
    font-size: 16px;
    padding-bottom: 18px;
    line-height: 4px;
 }       
 .similar-car::before {
    content: "";
    display: block;
    padding-top: 56%; /* Set the height as a percentage of the width */
  }
  .navtabs2 .nav-item a {
background:none !important;
  }      
  .lastChild{
width: fit-content !important;
  }
  .lastChild ul{
    width: 175px !important;
      }
     .loading_logo img {
        margin: auto;
        display: block;
        width: auto;
        height: 25vh;
        }
    .loading_logo p {
        text-align: center;
        font-size: 24px;
        color: #0500b1;
        font-weight: 600;
    }
    .toutesOptions {
        margin-bottom: 10px;
    }
    .itemCardLink:hover{
       text-decoration: none; 
    }
    .detail_result_head, .detail_result_head a , .detail_result_head button , .detail_result_head .price{
        display:inline-block !important;

    }
    .detail_result_head .price_div {
        float: right;
    }
    .favIconClass svg{
        width: 20px;
        height: 20px;
    }
    .fav_page_item .favIconClass{
      display: none !important;  
    }
    .l-main__article2 .row .mt-4 h3{
      vertical-align: bottom;
      display: inline-block;
      color: black;
    }
    .appelez_le{
        display: block;
        margin-bottom: 5px;
    }
   button.removefromfav{
    vertical-align: top;
    margin-top: 10px;
    float: right;
   }
   
  .removefromfav svg  {
        width: 40px;
    }
    .favItemwithRemove .vehicle-item{
        width: 90%;
        display: inline-block;
    }
    .filterWhiteIcon img{
        filter: invert(1);
    }
    .title-feature13 {
        display: inline-block;
        margin-left: 7px;
        font-size: 18px;
        color: black;
        text-transform: capitalize;
        font-weight: 500;
    }
    .title-feature12 {
        padding-left: 5px;
        margin-top: 0px;
        font-size: 18px;
        color: black;
        text-transform: capitalize;
        font-weight: 500;
    }
    .placeInfoCol{
padding-left: 49px;
    }
    .placeInfoRow {
        margin-top: -20px;
    }
    .placeInfoRow1 .title-feature12 {
        margin-top: -5px;
    }
    .twoColsList ul {
        column-count: 2;
    } 
    .logInButton button{
        width: -webkit-fill-available;
    }
    .sideImgWrapper {
        padding-top: 40px;
    }
    .min-max2 .form-group span {
        display: block;
    }
    .min-max2 .form-group input {
display: inline-block;
    }

    .sticky_logo{
        width: 30px !important;
    }
    .sticky_logo:hover:before{
        border-bottom: none !important;
    }
    .react-datetime-picker{
      width: 100% !important;  
    }
    .datepickersearch .react-date-picker__wrapper {
        border-color: #9ea5b1;
        border-radius: 4px;
        background-color: white;
        height: 35px;
    }
    .datepickersearch .react-date-picker{
width: 100%;
    }
    .react-datetime-picker__wrapper{
        border-color: #9ea5b1 !important;
        border-radius: 4px;
        background-color: white;
        height: 35px; 
    }
    .ticketsprices {
        margin-bottom: -20px;
    }
    .sectionClass img{
width: 50%;
    }
    
span.react-time-picker__inputGroup__leadingZero {
    margin-left: 13px;
    position: absolute;
    top: 9px;
}
.react-time-picker__inputGroup__input--hasLeadingZero{
    padding-left: 20px !important;
}
    .react-time-picker__inputGroup__input {
        width: 32%!important;
        height: 35px;
        padding: 8px 10px;
        border: 1px solid #d1d3d5;
        border-radius: 5px;
    }
    .react-time-picker__inputGroup{
display: inline-block;
width: 100%;
    }
  
    .react-time-picker__clock{
        display: none;
      }
      .react-time-picker__clock-button {
        display: none;
      }
      .react-time-picker__clear-button {
        display: none;
      }
      .ql-container {
        height: 200px !important;
      }
.homeNewsSection li a .c-posts__media  {
    background: none !important;
}
.homeNewsSection li a .c-posts__media img {
height: auto !important;
}
.annonceSection .c-posts__media:before {
    padding: 0 0 95%;
}
.infoPratiques{
    background: linear-gradient(90deg,#38a7ce 1%,#2887a9);
    padding: 20px;
    border-radius: 5px;
}
.infoPratiques ul {
    list-style: none;
}
.infoPratiques  .c-heading{
  font-size: 15px;  
  color: #B5DEF4;
  border-color: #B5DEF4;
}
.infoPratiques a {
    color: #B5DEF4 !important;
    border-color: #B5DEF4;
}
.articleFrontClass{
    margin-bottom: 35px;
}
.articleFrontClass img {
    margin: 15px 0px;
}
.articleFrontClass ul li{
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  
}
.articleFrontClass ul , .articleFrontClass li {
    padding: 0px;
}
.articleFrontClass > * {
    margin-bottom: 12px;
}
.card-body .position-relative table tbody tr td{
    padding-left: 7px;
}
.card-body .position-relative table tbody tr:nth-child(4n+1),.newsDashboard table tbody tr:nth-child(4n+2) {
    background-color: #9a9aa214 !important;
    -webkit-background-color: #9a9aa214 !important;
    -moz-background-color: #9a9aa214 !important;
    -ms-background-color: #9a9aa214 !important;
}
.card-body .position-relative table tbody tr:nth-child(4n+1):hover,.newsDashboard table tbody tr:nth-child(4n+2):hover {
    background-color: rgba(0, 0, 21, 0.075) !important;
    -webkit-background-color: (0, 0, 21, 0.075) !important;
    -moz-background-color: (0, 0, 21, 0.075) !important;
    -ms-background-color: (0, 0, 21, 0.075) !important;
}
.sectionClassDashboard img{
width: 50%;
display: block;
}
.card-accent-info {
    border-top: 2px solid #d8dbe0 !important;
}
.card-header button p{
    font-size: 28px;
    font-weight: 900;
    line-height: 20px;
    display: inline-block;
    margin: 0px;
    vertical-align: bottom;
}
.card-header svg {
    fill:#e75353;
}
/* .card-header svg:hover {
    fill:white;
} */
.btn-outline-danger:hover svg {
    fill:white;
}
.sectionEndImage{
    cursor: pointer;
    background: #2eb85c;
    padding: 4px;
    border-radius: 5px;
    color: white;
    width: fit-content;
}
.removeButton  {
    float: right;
    border: solid 1px white;
    padding: 3px 6px;
    border-radius: 4px;
    margin-bottom: 5px;
    color: red;
}
.newsGroup {
    border: solid 1px #ebedef;
    border-radius: 4px;
    background: #ebedef;
}
.newsGroup label:first-child{
    font-size: 22px;
    color: black;
    width: fit-content;
    margin-top: 15px;
}
.upIcon {
    width: fit-content;
background-color: #0500b1;
border-radius: 40px;
padding: 10px;
position: fixed;
bottom: 10px;
right: 10px;
cursor: pointer;
z-index: 99;
}
.upIcon svg {
    fill: white;
    font-weight: bold;
    font-size: 47px;
    width: 35px;
}
.mainSearchBox {
    width: 280px;
}
.searchWrapper {
    position:relative;
    width: 100%;
  }
  
  .searchWrapper .searchIcon {
    padding: 3.6px 6px;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #0300b1;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px;
  }
  .searchWrapper .searchIcon svg {
    height: 1.1rem;
    width: 1.1rem;
    vertical-align: sub;
  }
  .searchWrapper .input {
    height: 30px;
    border: none;
    padding-right: 2rem;
    padding-left: 0.3rem;
    border-radius: 3px;
    width: 450px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .searchResultList {
    position: absolute;
    top: 30px;
    width: 100%;
    background: #fafafa;
    color: black;
    border-radius: 3px;
    padding: 1rem 0.3rem;
  }
  .searchResultItem2 , .searchResultItem1{
display: inline-block;
  }
  .searchResultItem2 {float: right;}
 .voirResultButton {
    display: block;
    width: fit-content;
    color: white;
    margin: auto;
    margin-top: 15px;
    padding: 5px;
    background: #ff297d;
    border-radius: 3px;
    cursor: pointer;
}
.voirResultButton:hover {
    color: #dad8d8;
    text-decoration: none;
} 
.l-header__actions a .c-avatar-img {
    width: 30px;
}
.twoPartsResultPage1 {
    width: 70%;
    display: inline-block;
    padding-right: 25px;
    margin-bottom: 2rem;
}
.twoPartsResultPage1 .col-md-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
}
.twoPartsResultPage2 {
    width: 30%;
    display: inline-block;
    padding-left: 25px;
    margin-bottom: 2rem;
    vertical-align: top;
}
.searchResultPage .c-heading--component {
    font-size: 1rem;
}
.searchResultPage h6 {
    font-size: 1.2rem !important;
    color: black;
    border-bottom: solid #aeaeae 1px;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.searchResultItemUL{
    padding: 0px;
    text-align: left;
}
.searchResultItemUL li {
    padding-bottom: 3px;
    padding-top: 3px;
}
.searchResultItemUL a {
    color: black !important;
}
.profilePageAll {
    margin: 0px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
.profileSideBar .profile-avatar {
display: block;
margin: 18px auto 8px;
}
.profileSideBar {
    background: #f7f7f7;
    padding-right: 0px;
    padding-left: 5px;
}
.profileSideBarName{
    display: block;
    text-align: center;
    text-transform: capitalize;
    color: black;
    font-size: 17px;
}

.profileSideBar li , .profileSideBar a{
border: none !important;
color: black;
margin-bottom: 10px;
}
a.mainTabWithSub {
    padding: 0.75rem ;
    margin: 0px 0px 10px;
    border-bottom: solid #d4d4d4 1px !important;
    width: 100%;
    display: block;
}
.profileSideBar li:hover , .profileSideBar a:hover{
    width: auto;
    border-bottom: none !important;
    text-decoration: none;
    background-color: white;
    color: black;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    }
 
    .list-group-item {
        padding: 0.75rem ;
    }
.profileSideBar li {
    border-bottom: solid #d4d4d4 1px !important;
    } 
.profileSideBarLogout {
    border-bottom: none !important;
    margin-top: 20px;
}
.profileSideBar .subTab {
    border: none !important;
    display: block;
    padding: 0.75rem 1.25rem;
     width: 140px; 
    /* width: auto; */
    padding-left: 5px;
    margin-left: 30px;
    border-bottom: solid #d4d4d4  1px !important;
    color: black;
}
.profilePageAll .card {
    border:none;
}
.profilePageAll thead {
    display: none;
}

.profilePageAll .table-outline, .profilePageAll .table-outline th, .profilePageAll .table-outline td {
    border: none;
}
.profilePageAll .table-outline tr .vehicle-thumbnail {
    width: 150px !important;
    height: 150px !important;
}
.profilePageAll .table-outline tr td:last-child {
    vertical-align: middle;
}
.profilePageAll .table-outline tr td:last-child svg,.profilePageAll .table-outline tr td:last-child img {
    width: 1.3rem;
    height: 1.3rem;
    color: black;
  }
  .profilePageAll .table-outline tr td:last-child button {
    display: block;
  }

.actionsTdInMyProfile {
    vertical-align: middle;
}
.actionsTdInMyProfile svg , .actionsTdInMyProfile img {
    width: 1.3rem;
    height: 1.3rem;
    color: black;
  }
  .actionsTdInMyProfile button {
    display: block;
  }

.profilePageAll .card-body .position-relative table tbody tr:nth-child(4n+1), table tbody tr:nth-child(4n+2) {
    background-color: white !important;}
.profilePageAll .table-outline tr {
    border-bottom: solid 1px #cfcfcf ;
}
.tableWithHead thead {
    display: table-header-group;
    background-color: #f7f7f7;
}
.tableWithHead thead tr {
    border-bottom: none !important;
}
.profileAnnonceCardDetails {
    display: inline-block;
    padding-left: 15px;
    line-height: 33px;
    letter-spacing: 1px;
    vertical-align: top;
    padding-top: 1.5rem;
}
.profilePageAll .table-outline tr td:last-child a {
    display: block;
    margin-bottom: 0px;
  }
.noItemsText {text-align: center;font-size: 18px;}
.noItemsText button {font-size: 20px;}
.profileSideBarSelected {
    background-color: white;
    width: auto !important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.itemDetailsProfilePage aside {
    display: none;
}
.itemDetailsProfilePage .l-main__grid {
    display: block;
    padding: 0px;
}
.itemDetailsProfilePage:first-child{
    width: 70%;
    margin: 0px;
    display: inline-block;
}
.itemDetailsProfilePage .favIconClass {
    display: none;
}
.itemDetailsProfilePageMain{
    margin-right: -15px;
}
.itemReportSection  {
    color: black;
    display: inline-block;
    width: 30%;
    vertical-align: top;
    padding: 32px 10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
.annonceActionsInfo {
    text-align: center;
    margin-top: 40px;
}
.annonceActionsInfo p {
    margin: 10px 0px;
    font-size: 15px;
}
.annonceActionsInfo strong {
    font-size: 22px;
}
.annonceActionsInfo img , .annonceActionsInfo svg {
    width: 25px;
    vertical-align: text-top;
}
.beating {
    cursor: default !important;
    animation: beatAnimation 0.5s infinite alternate;
  }
  
  @keyframes beatAnimation {
    to {
      transform: scale(1.2);
    }
  }
  .fav_page_item .itemsRow .col-md-3 {
    position: relative;
    flex: 0 0 33% !important;
    max-width: 33% !important;
}
.remove_button {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 9;
}
.remove_button svg {
    background: #ffffffd1;
    padding: 3px;
    border-radius: inherit;
    width: 25px;
    height: 25px;
}
.remove_buttonSpinner {
    top: 14px;
    right: 19px;
}
.profilePageAll .table-outline tr .vehicle-thumbnail img {
    max-width: 100%;
    max-height: 100%;
}
.add_pageVichcule .vehicule-form-navItem {
width: 25%;
}
.mesFavoris svg {
    width: 20px;
    vertical-align: bottom;
}
.profileDropDown {
    position: relative;
}
.profileDropDown ul {
    width: max-content;
    list-style: none;
    border-radius: 5px;
    padding: 0 28px 10px 2px;
    color: white !important;
    margin-left: 0px !important;
    margin-top: -5px !important;
    background: rgb(21 18 212 / 92%);
}
.profileDropDown ul li {
    padding: 10px 0 0px 0 !important;
}
.profileDropDown ul li a {
    color: white;
    padding: 10px 0 0 2px;
    margin-left: 6px;
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;
}
.stickyFloatRight .profileDropDown ul{
    padding: 0 10px 10px 2px;
    margin-left: -30px !important;
}
.registerNow {
    text-align: center;
    font-size: 18px;
    margin-top: 15px ;
}
.sticky_logo img {
    width: 28px;
    top: auto;
}
.mysticky .c-avatar-img {
    margin-top: -3px;
}
.mysticky .profileDropDown {
    top: 0px;
}
.stickyFloatRight .userNameSpan {
    display: none;
}
.stickyFloatRight .l-header__actions {
   min-width: fit-content;
}
.mysticky .l-navbar__list {
justify-content: center;
}
.userNameSpan {
    text-transform: capitalize;
    vertical-align: sub;
}
.slogan_logo{
    display: flex;
}
.avatarLink {   
     display: inline-block;
    width: 30px;
}

.mobNav .c-logo__image {
    width: 40px;
}
/* .mobNav .searchWrapper {
    width: auto;
}
.mobNav .searchWrapper .searchIcon {
    top: 15px;
    right: auto;
} */
.mobProfileSetting {
    display: none;
}
.realEstateDetailsExtra .title-feature12{
    display: inline-block;
}
.features-realEstate .placeInfoRow1 .title-feature12{
    display: block;
}
.formAgeSection1 {display:flex;}
.lastColJobsTable {
    text-align: center;}
.jobsTableAll th { text-align: center;}
.stickyFloatRight .mesFavoris svg {
    vertical-align: middle;
}
.stickyFloatRight {
    margin-top: 15px;
}
.vehicle_itemSearchResult .specs-preview ul {
    column-count: 3;
    width: 100%;
}
.filter .filter-title-field:before {
    display: none;
}
.vehicle-item .mx-auto time {
    vertical-align: -webkit-baseline-middle;
}
.vehicle-item .mx-auto {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 4px;
    color: black;
}
.linkTitle {
    padding-bottom: 2px;
}
.currentSection .linkTitle{ 
    border-bottom: 3px solid white; 
    /* padding-right: 6px;
    padding-left: 5px;
    background: white;
    color: black; 
    border-radius: 4px; */
}

.place-icon-itemCard {
    width: 13px;
    vertical-align: baseline;
}
.mx-auto .place-icon-itemCard {
    vertical-align: -webkit-baseline-middle;
}

.itemsRow .vehicle-item button {
    position: absolute;
    z-index: 9;
    top: 10px !important;
    right: 5px;
    padding: 0px !important;
}
.l-sidebar__widget .vehicle-item button {
    position: absolute;
    z-index: 9;
    top: 10px !important;
    right: 5px;
    padding: 0px !important;
}
.itemsRow .vehicle-item .favIconClass svg {
    width: 22px;
    height: 22px;
}
.u-lineClampSub {
    line-height: 1.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.u-lineClampSub p {
   margin: 0px; 
   margin-top: 10px;
}
.homeNewsSectionFourSubUl .u-lineClampSub strong {
    font-weight: 300;
}
.u-lineClampMain { 
    font-weight: 600;
}
.realEstateDetailsExtra p {
    margin:1px 0px;
    text-transform: capitalize;
}
.l-header .c-avatar-img { 
    margin-top: 3px;
}

.homeNewsSectionTwoMain .c-posts {
    display: flex;
    justify-content: space-between;
}
.homeNewsSectionTwoMain  .c-posts--gridMosaic .c-posts__item {
    width: 46%;
}
.homeNewsSectionFourSub .c-posts {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.homeNewsSectionFourSub  .c-posts--gridMosaic .c-posts__item {
    width: 22%;
}
.homeNewsSectionFourSub {
    margin-top: 30px;
    margin-bottom: 30px;
}
.c-posts--highlightFirst .c-posts__item .c-posts__headlineText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 6px;
    height: 5.1rem;
}
.homeNewsSectionFourSub .u-lineClamp.u-lineClampMain {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
    height: 3rem !important;
    line-height: 1.5rem;
}
.titleTdNewsTableDashboard {
    position: relative;

}

.annonceTableDashboard table {
    text-align: center;
}
.annonceTableDashboard table th {
    text-align: center;
}
.annonceTableDashboard table tr {
    padding: 5px;
}
.titleTdNewsTableDashboard1 {
    text-align: left;
}
.autoSavedLabel{
    margin: 0px;
    left: 0px;
    top: -2px;
    position: absolute;
    color: #fd8c05;
    font-size: 10px;
    border: solid 1px #fd8c05;
    border-radius: 5px;
}
.itemsRowJobOffer  .col-md-3 {
    padding: 10px 20px;
}
.itemsRowJobOffer .c-posts__media:before {
    padding: 0px 0 80%;
}
.itemsRowJobOffer .c-posts__media > :first-child {
    object-fit: fill;
}
.jobDetailsPage .shareJobButton {display: none;}
.itemDetailsProfilePageMain .applyOrShareButton {display: none;}
.itemDetailsProfilePageMain .shareJobButton {display: block !important;}
.candidatsTable tr td {padding:20px 5px;}
.candidatureInfoSection {
    border-top: solid 1px #c7c7c7;
    margin-top: 18px;
}
.candidatureInfoSection p {
    margin: 18px 0;
}
.candidatureInfoPage {
    font-size: 16px;
}
.closedAnnonceNote {text-align: center;
    font-size: 18px;}
 .inputWithSuffix {
    display: flex;
    align-items: center;
    background-color: white;
    padding-right: 2px;
    border-radius: 5px;
    border: solid 1px #9ea5b2;
 }   
 .inputWithSuffix .form-control {
    border: 0px;
 }
 .inputWithSuffix  .suf {
    margin-left: 3px;
    margin-right: 2px;
    color: #8b93a3;
    font-size: 12px;
 }
 

 .highlight-container, .highlight {
    position: relative;
  }
  
  .highlight-container {
    display: inline-block;
  }
  .highlight-container:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #ffd500;
    transform: rotate(1deg);
    top: -4px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 19px 3px 9px 10px;
  }
  
 
 .advInfoSzctionCard {   
    background: #f8f8f8;
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px;
    margin-bottom: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.advInfoSzctionCardHeadTitle {
    font-size: 19px;
    display: block;
}
.advInfoSzctionCard img {
    width: 35px;
    margin-right: 10px;
    margin-bottom: 20px;
    vertical-align: -webkit-baseline-middle;
}
.advInfoSzctionCard label {
    font-weight: 600;
}
.advInfoSzctionCard svg{
    width: 32px;
    height: 32px;
    margin-right: 5px;
}
.advInfoSzctionCardHead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize
}
.advInfoSzctionCardBottom {
    text-align: right;
}
.advInfoSzctionCardBottom strong {
   font-size: 18px;
}
.advInfoSzctionCardBottomA {
    font-size: 18px;
}
.information-title.advInfoSzctionCardHead {
    margin-bottom: 10px;
}
.subRow {
    padding-left: 15px;
    padding-bottom: 25px;
}
html {
    scroll-behavior: smooth;
  }
.roller{
    cursor: pointer;
    height: 2.125rem;
    line-height: 4rem;
    position: relative;
    overflow: hidden; 
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    
    color: #1D3557;
  }
  
  
  #spare-time{
    font-size: 1rem;
    font-style: italic;
    letter-spacing: 1rem;
    margin-top: 0;
    color: #A8DADC;
    
  }
  
  .roller #rolltext {
    position: absolute;
    top: 0;
    animation: slide 5s infinite;  
    right: 15px;
  }
  
  @keyframes slide {
    0%{
      top:0;
    }
    25%{
      top: -4rem;    
    }
    50%{
      top: -8rem;
    }
    72.5%{
      top: -12.25rem;
    }
  }
  
  @media screen and (max-width: 600px){
    h1{
    text-align:center;
    text-transform: uppercase;
    font-size: 2.125rem;
  }
    
    .roller{
    height: 2.6rem; 
    line-height: 2.125rem;  
    }
    
    #spare-time {
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }
    
    .roller #rolltext {  
    animation: slide-mob 5s infinite;  
  }
    
    @keyframes slide-mob {
    0%{
      top:0;
    }
    25%{
      top: -2.125rem;    
    }
    50%{
      top: -4.25rem;
    }
    72.5%{
      top: -6.375rem;
    }
  }
  }
  .dzu-previewContainer {
    padding: 20px 2% !important;
}
.advInfoSzctionCard img {
    margin-bottom: 10px;
}
.img-thumbnail-others img {
    width: 100px;
}
.loading-bar-fill{    
    height: -webkit-fill-available;
    background: #ffd500;
    border-radius: 4px;
    color: white;
    text-align: center;
}
.loading-bar{
    border: solid 1px #d6d6d6;
    border-radius: 4px;
    height: 18px;
    width: 50%;
}
.ticker-wrapper {
    background: #0300b1;
}
.filterOptions {
    position: relative;
    display: inline-block;
    width: 100%;
}
.clearFilterOption {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    border: none;
    cursor: pointer;
}
.clearFilterOptionButton {
    border: 1px solid #cacaca;
    padding: 0px 5px;
    border-radius: 5px;
}
/* .searchPage select option:first-child {
    color: grey !important;
} */
.searchPage select , .searchPage select option {
    color: black !important ;
}
.react-date-picker__calendar {
    z-index: 9999 !important;
}
button.react-date-picker__clear-button.react-date-picker__button {
    display: none !important;
}
.filter .react-date-picker {
    width: 92% !important;
}
.filter .react-date-picker__wrapper {
    border: none !important;
}
.clearFilterInputDate {
    font-size: 22px;
    line-height: 20px;
}
.filtersS {
    font-size: 19px;
    font-weight: 600;
    color: black;
}
.l-header__inner .mesFavoris svg path {
    fill: #ff287d;
}
.l-header__inner  .text-white ,.c-avatar .userNameSpan {
    color: #0300b1 !important;
}
.footer_social svg {
    width: 30px;
    height: 30px;
}
.searchFormWrapper {box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.vehicule-navItem a img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(66%) saturate(6448%) hue-rotate(245deg) brightness(65%) contrast(136%);
}
.add_page .nav-item a img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(66%) saturate(6448%) hue-rotate(245deg) brightness(65%) contrast(136%);
}
.add_page .nav-item a path , .add_page .nav-item a polygon{
    fill: #0000b7;
}
.subSubMenu {
    border: 1px solid black;
}

.annoncesCount {
    background: white;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    float: right;
    line-height: 28px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgb(106 108 109 / 35%) 0px -2px 6px 0px inset;
    text-align: center;
    display: inline-block;
}
.editPage form {
    padding: 15px 15px 15px 0px;
}
.dzu-previewContainer img {
    width: auto;
}
.newsByCatPage .c-posts__excerpt p {
    margin: 0px;
}

.c-posts__headline {
    margin-bottom: 15px;
}
.articleFrontClass p {
    margin-top: 12px;
    margin-bottom: 0px;
}
.newsPage .l-article__title ,.newsPage .c-posts__headlineText {
    font-family: 'newstar';
    letter-spacing: 0.5px;
}
.newsPage .l-article__title {
    font-weight: 100;
    letter-spacing: 1px;
}
.newsPage .l-article__story {
    color: black;
    font-family: 'proxima-nova';
}
.newsPage .c-posts__headlineText {
    font-weight: 100;
}
.newsByCatPage .c-posts__headline  {
    font-family: 'newstar';
}
.newsByCatPage .c-posts__excerpt {   font-family: 'proxima-nova';
}
.dashBoardDetails .call-phone {
color: white;
}
.dashBoardDetails .l-main__grid.o-container {
    display: block;
}
.actionButDet {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
    font-size: 15px;
}

.dashBoardDetails textarea {
    width: 95%;
    margin: auto;
    display: block;
    height: 80px;
}
.dashBoardDetails h3 {
    display: inline-block;
    margin-right: 10px;
}
.dashBoardDetails .appelez_le {
    display: inline-block;
    margin-bottom: 0px;
}
.annonceTableDashboard table .badge{
    width: 85%;
    vertical-align: middle;
    }
   .h4MemberTitle {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .typeCompte{
        min-width: 102px;
      }      
.badge {
    padding: 0.35em 0.4em;
    font-size: 90%;
    text-transform: capitalize;
    font-weight: 300;
}
.badge-warning {
    color: #ffffff;
    background-color: #f78601;
}
svg.itemResultSvg {
    width: 16px;
    height: 16px;
    margin: auto;
}
.editPage .information-title > h2 {
    width: fit-content;
    display: inline-block;
}
.callTOActionSuccess  {
    text-align: center;
}
.callTOActionSuccess .btn {
    margin-right: 10px;
    font-size: 16px;
    width: 240px;
}
.successIcon img{
    width: 100px;
    height: auto;

}
.bigBadge {
    display: block;
    width: 100%;
    font-size: 100%;
    text-transform: uppercase;
    line-height: normal;
    padding-top: 8px;
    padding-bottom: 8px;
}

.btn:disabled {
    cursor: default;
}

.dashBoardDetails .features2 {
    height: 250px;
    padding: 0px;
}
.features2 .row {
    padding-left: 15px;
    padding-right: 15px;
}
.annoncePreview {
    padding-left: 15px;
    padding-right: 15px;
}
.annoncePreview .features-vehicle {
    height: 100%;
    padding-bottom: 0px;
}
.annoncePreview .col-md-10 {
margin: auto;
}
.dashBoardDetailsTop .col-md-9 {
    padding-right: 10px;
}
.dashBoardDetailsTop .col-md-3 {
    padding-left: 0px;
}
.actionButCon {
    width: 73%;
    margin: auto;
}
.actionButConA {
    width: 95% !important;
    margin: auto;
 }
.sectionHeadingB {
    background: #f2f3f4ab;
    padding-top: 8px;
    margin-bottom: 12px;
}
.sectionHeadingB label{
    font-size: 20px;
    text-align: center;
    width: 100%;
    font-weight: 500;
    margin-bottom: 5px;
    color: black;
}
.editsBadge {
    display: block;
    margin-top: 3px;
}
.badge-halfWarning {
    color: white;
    background: linear-gradient(to right, #f78601 50% , #6b6b6b 50%);
}
.c-sidebar-nav-dropdown-items a { display: inline-block;}
.c-sidebar-nav-dropdown-items svg{
    margin-left: -10px !important;
    margin-right: 7px;
}
.annonceDetailsPage .row .mt-4 h3 {
    vertical-align: baseline;
}
.carouselItem .c-posts__media {
width: -webkit-fill-available;
}
.carouselItem {
    padding: 0px 5px;
}

.news-ticker {
    overflow: hidden;
    white-space: nowrap; /* Prevent line breaks */
    margin: 0;
    padding: 0;
    display: flex; /* Wrap all news items */
}

.news-item {
    display: inline-block;
    padding: 0 10px; /* Adjust padding as needed */
    animation: ticker-scroll calc(100vw / var(--ticker-speed))s linear infinite; /* Calculate duration based on speed */
}

.ticker__element {
    width: max-content;
    max-width: max-content;
}
@keyframes ticker-scroll {
    from { transform: translateX(100%); } /* Start from right side */
    to { transform: translateX(-100%); } /* Move to left side */
}
.homeMain {
    padding-top: 12px;
}
.annonceDetailsPage .mt-4 {
    margin-top: 0.8rem !important;
}
.snSpan{
    z-index: 9;
    display: block;
    color: #696666;
    font-size: 12px;
    margin-bottom: 5px;
    background: #e6e8ebe6;
    width: fit-content;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 5px;
}
.snSpanImg {
    position: absolute;
}
.itemDetailsProfilePageMain .call-phone {
    display: none;
  }
  .itemDetailsProfilePageMain .appelez_le {
    display: none;
  }
  .image-gallery img {
border-radius: 5px;
  }


.itemReportSection .spinner-border-sm {
    vertical-align: baseline;
}
.image-gallery {
    width: 100%;
}
.image-gallery-slide {
    background: black;
    border-radius: 5px;
}
.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    max-height: 500px;
    height: 500px;
}

.fav_page_item .itemCard1H2P p {
    position: initial !important;
}
.c-posts--grid {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}
.ticker-container2 {
    line-height: 40px;
    height: 40px;
    border-bottom: solid 1px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 500;
}
.logoInInfobar {
    width: 18px;
    margin-left: 2px;
    margin-right: 2px;
    vertical-align: -7px;
}


.footerAnnoncesRow {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}
.footerAnnoncesRow li{
font-size: 14px;
}
.footerAnnoncesLinksSpan {
    font-size: 16px;
    font-weight: 600;
    color: #ff287d;
}
.footerAnnoncesLinks ul {
    column-count: 3;
    column-gap: 25px;
}
.footerAnnoncesLinks li {
    list-style: none;
}
.footerAnnoncesLinks a {
  color: black;
}
.l-footer .o-container{
    padding-left: 25px;
    padding-right: 25px;
}
.footerAnnoncesLinksLi1 {
    padding-bottom: 0px;
    margin-bottom: 1px !important;
}
.footerAnnoncesLinksLi12 {
    padding-top: 7px;
}
.mobNewsSection {
    display: none;
    overflow: visible;
} 
.mobNewsSection .c-videoPlay__icon {
    background-color: #0000008c;
}
.mobNewsSection videoPlay:hover .c-videoPlay__icon {
    background-color: #0000008c;
    color: #ff287d;
} 
.breadcrumb {
    margin-bottom: -10px;
    padding: 8px 16px 0px;
    list-style: none;
    width: fit-content;
    border: none;
  }
  
  .breadcrumb-item {
    display: inline-block;
    font-size: 14px !important;
    text-transform: capitalize;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
    padding: 0 2px;
    color: #ccc;
  }
  .mobMenu .c-nav__link{
    font-size: 16px !important;
  }
  .filtersMobButton , .filtersCountMob{
    display: none;
        }
        .cookisMsgContainer {
            position: absolute;
            z-index: 9999999;
        
          }
          
          .cookisMsg {         
            color: white;
            width: 100%;
            position: fixed;
            bottom: 0px;
    text-align: center;
    background-color: #000000d1;
    padding: 10px;
    padding-top: 15px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
          
          }
          .cookisMsg div {
            display: inline-block;
          }
          .cookisMsg svg{
            width: 40px;
            height: 40px;
            margin-right: 8px;
            filter: invert(1);
            display: inline-block;
        }
        .cookisMsg h4 {
            margin-bottom: 10px;
            display: inline-block;
        }
          .cookisMsg button {
            border: none;
            padding: 8px 16px;
            border-radius: 5px;
            cursor: pointer;
            display: block;
            margin: auto;
            margin-top: 12px;
            transition: background-color 0.3s ease;
          }

     .mainBanner img {height:420px; margin: auto;
        display: block;}

    .termsText {
        margin: 30px 60px;
        color: black;
        font-size: 1.2em;
    }
    .termsText h2 {
        text-align: center;
        margin-bottom: 30px;
    }
    .termsText p {
        margin: 10px 0;
    }
    .underLined {
        text-decoration: underline;
    }
    .termsStrong {
        display: block;
        margin: 15px auto;
    }
    .termsMargined {
        margin-left: 40px !important;
    }
    .termsPink{color: #ff287d;}
    .termsText a {
        color: #0300b1;
    }
  .snSpan.snSpanE {
        display: inline-block;
        margin-left: 10px;
    }
    .arabicFontClass {
        text-align: right;
        direction: rtl;
        font-family: 'ArbFONTS-Aljazeera' !important;
    }

    .arabicFontClass .l-article__story  ,   .arabicFontClass .c-posts__excerpt , .arabicFontClass .l-article__title , .arabicFontClass .c-posts__headlineText{
        font-family: 'ArbFONTS-Aljazeera' !important;
    }
    .arabicFontClass .u-lineClampMain   {
        font-size: 125%;
    }
    .arabicFontClass .u-lineClampSub{
        line-height: 1.8rem;
    }
 .ticker-container2.arabicFontClass {
        font-weight: 600;
    }
   .loginPageClass .invalid-feedback {
        font-size: 100%;
    }
   .membersTableDashboard .companyDetails {
        width: max-content;
        margin: auto;
        display: block;
        text-align: left;
        padding-top: 25px;
      }
      .membersTableDashboard .companyDetails div{
        padding-bottom: 12px;
      }
      .membersTableDashboard .companyDetails span{
        width: 250px;
        display: inline-block;
        }
        .filtersCount {
            margin-left: 7px;
            font-size: 12px;
        }
        .filtersCount button {
            display: inline-block;
            font-size: 11px;
            padding: 3px 7px;
            background-color: #d6ecfff0;
            color: black;
            border-color: aliceblue;
        }
        .filtersCount button:hover {
            background-color: #97cffff0 !important;
        }
        .filtersCount svg {
            width: 15px;
            height: 15px;
          }
         .filtersOrder select {
            display: inline-block;
            width: max-content;
            height: 27px;
            padding: 0px 10px;
            margin-left: 10px;
            margin-bottom: 15px;
          }
          .resultSearchItem ul {
            padding-top: 0px;
          }
          .resultSearchItem ul li {
            padding: 8px 0 8px 0;
          }
          .dateInfoRow .title-feature1 {
            font-size: 15px;
          }
          .annoncerPageSection {   
            background: #f8f8f8;
            padding: 15px 25px;
            border-radius: 5px;
            border: 1px;
            margin-bottom: 50px;
            /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
            box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        }
        .annoncerPageSection h4 {
            margin-bottom: 15px;
        }
        .annoncerPageSection h4 span{
           font-size: 13px;
        }
        .title-feature.appelez_le a {
            color: #373737;
        }
        img.icon-vehicle.widthAuto {
            width: auto !important;
        }
        .nocookie {
            cursor: pointer;           
    color: white;
    position: fixed;
    bottom: 10px;
    right: 10px;
        }
        .nocookie:hover {
            text-decoration: underline;
        }
        .annoncerInfoSection h3 {
         
        margin-bottom: 8px;}
        .annoncerPage .vehicule-form-tabs {
            justify-content: center;
        }
        .annoncerPage .nav-tabs .nav-item {
            flex: 1 0 auto;
        }
        .annoncerInfoSection span {
            display: block;
            margin-bottom: 5px;
        }
        .annoncerInfoSection a {
            color: #3c4c64;
        }
        .annoncerInfoSection .icon-item {
            width: 18px;
        }
        .annoncerInfoSection .c-avatar-img {
            width: 130px;
            margin-bottom: 25px;
            margin-right: 30px;
        }
        .annoncerInfoSection {
            margin-bottom: 30px;
        }
        .annoncerInfoSection1 {
            display: inline-block;
            vertical-align: text-top;
        }
        .annoncerInfoSection  .col-10 {
            margin: auto;
            padding-bottom: 12px;

        }
        /* .authPage .grecaptcha-badge {
            display: block !important;
        } */
        .memberCard .similar-car{
     border-radius: 50%;
        }
        .memberCard h4 {
            margin-bottom: 15px;
            margin-top: 10px;
        }
        .memberCard .c-posts__media {
            background: #fff;
            border-radius: 30px;
          }
          .memberCard {
            border: 1px solid #fff !important;
            border-radius: 30px !important;
            text-align: center;
            padding-bottom: 6px;
          }
          .searchResultPage .row.itemsRow {
            margin-top: 15px;
          }
          .separatorLine {
            border-bottom: solid #ededf0 1px;
            margin: 25px 0px;
          }

          .memberCard .mx-auto {
            font-size: 11px;
            color: #444;
             }
             .memberCard .place-icon-itemCard {
                width: 11px;
             }
             
/* my responsive starts*/
@media (min-width: 1050px) {
    .multicolumn  li {
        margin-bottom: 10px; /* Adjust spacing between list items */
        break-inside: avoid; /* Prevent items from breaking inside columns */
    }

    /* Select the first 6 items to keep in the first column */
    .multicolumn  li:nth-child(-n+6) {
        break-before: avoid; /* Force items to start in a new column */
    }

    /* Select items 7 to 10 to keep in the second column */
    .multicolumn li:nth-child(n+7):nth-child(-n+11) {
        break-before: auto; /* Force items to start in a new column */
    }

 
    .multicolumn  li:nth-child(n+12):nth-child(-n+14) {
        break-before: avoid; /* Force items to start in a new column */
    }
    }
@media (min-width: 1224px) {
.footerAnnoncesLinks1 {
    margin-right: 30px;
}
}
@media (min-width: 1160px) and (max-width: 1200px) {
    nav #dd {margin-left: -100px !important;}
    nav #dd .lastChild ul {
        left: -12px !important;
    }
  }
@media (max-width: 1160px) {
    .slogan {
        font-size: 9px;
    }
    .l-navbar__logo {
        width: 70px;
        margin: 0 10px 0 5px;
    }
    .l-navbar__morty {
        top: 22px;
    }
    .c-nav--main .c-nav__link {
        padding: 0px 5px;
        font-size: 14px;
        margin-right: 8px;
    }
    .c-nav__icon--chevron {
        margin-left: 4px;
    }
}

@media (max-width: 991px) {
    .itemsRow .col-md-3 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
}

@media (min-width: 1050px) and (max-width: 1200px) {
    .footerAnnoncesRow li {
        font-size: 13px;
    }
    .l-footer .o-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .footerAnnoncesLinks ul {
        column-gap: 15px;
    }
}
@media (min-width: 900px) and (max-width: 1051px){
    .footerAnnoncesLinks1 {
        margin-right: 35px;
    }
}

@media (min-width: 768px) and (max-width: 1051px) {
    .l-footer .o-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .footerAnnoncesLinks ul {
        column-count: 2;
        column-gap: 15px;
    }


}
@media (min-width: 768px) and (max-width: 900px){
    .footerAnnoncesRow {
          display: contents;
       width: 100%;
   }
   
   }
@media (min-width: 768px) and (max-width: 991px) {
    .l-footer .o-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .footerAnnoncesLinks ul {
        column-count: 2;
        column-gap: 15px;
    }
    nav #dd {margin-left: -110px !important;}
    nav #dd .lastChild ul {
        left: -70px !important;
    }
  }
@media (max-width: 900px) {
    .homeNewsSectionFourSub .c-posts--gridMosaic .c-posts__item {
        width: 48%;
    }
    .l-navbar__morty {
        		    top: 14px;
        }
        
        .slogan_logo {
        		    display: flex;
        		    flex-direction: column-reverse;
                    margin-right: 8px;
        }
        .slogan {
        		    margin-top: 30px;
        		    line-height: 1px;
        		    font-size: 7px;
        }
        
        .sloganP1 {
        		    font-size: 6.4px;
        		    margin-bottom: 0px !important;
        		    margin-top: 20px;
        }
        
}

@media (max-width: 813px) {
    .c-nav--main .c-nav__link {
        padding: 0px 3px;
        margin-right: 6px;
    }
    .slogan_logo {
        margin-right: 0px;
}
}
@media (min-width: 768px){ 

    nav ul li ul { display:none; } /* Rend le menu déroulant caché par défaut */
     
    nav ul li:hover ul.dropdown { /* Affiche la dropNav au survol de la souris avec la class .drop */
    z-index:99999;
    display:list-item !important;
    position:absolute;
    margin-top:2px;
    margin-left:-10px;
    }

    #dd li:hover ul { /* Affiche la dropNav au survol de la souris avec la class .drop */
        z-index:99999;
        display:list-item !important;
        position:absolute;
        border: 1px solid black;
        width: fit-content;
        padding-right: 10px;
        }
     
    nav ul li:hover ul li {
    float:none;
    }
    .bigMenuButton{
        display: none;
    }
    .profilePageAll  .col-sm-3 {
            flex: 0 0 25%;
            max-width: 25%;
        
    }
    .profilePageAll  .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
        padding-bottom: 20px;
    }
   

    /* .filter::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
      }
      
      .filter::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #cecece;
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
      }
    .filter {
        position: fixed;
        width: 20%;
        height: 70vh;
        overflow-y: scroll;
    } */
     

}  
@media (max-width: 767px) {

    .mainBanner img {height:auto;}
    .l-section:last-of-type {
        padding-bottom: 15px;
    }
    .jobsSection {
        padding-top: 25px;
    }
    .filtersSectionMob {
        pointer-events: none;
        position: absolute;
        width: 98%;
        top: -70px;
        z-index: 9999;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.5s ease, height 0.5s ease;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 9px 9px -20px, rgba(0, 0, 0, 0.3) 0px 30px 15px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }
    .visibleFiltersmob{
        pointer-events: all;
        opacity: 1;
        height: auto;

    }
    .filtersMobButton svg path {
fill: white
    }
    .filtersCountMob {
        display: inline-block;
    }
    .filtersMobButton{
display: inline-block;
padding: 5px 10px;
    background: #0300b1;
    border-radius: 5px;
    color: white;
    width: fit-content;
    margin-bottom: 15px;
    font-size: 16px;
    letter-spacing: 1px;
    }

    .filtersMobButtonx {
        position: absolute;
        right: 10px;
        font-size: 20px;
        padding: 5px;
        line-height: 14px;
    }
    .filtersMobButtonG {
        margin: auto;
        margin-top: 15px;
    }


    .c-nav--main {
        justify-content: center;
    }
/* 
    .l-footer__row2 {
        display: flex;
        flex-wrap: wrap;
    }
    .footer_col1 {
        order: 2;
    }
    .footer_col2 {
        order: 1;
    }
    .footer_col3 {
        order: 3;
    }
    .l-footer__row22 {
        order: 4;
        margin: auto;
    } */
    .l-footer__row.l-footer__row--top {
        text-align: center;
    }
    .searchResultListOut{
        justify-content: center;
        display: flex;
    }
    .searchResultList {
        padding-top: 5px;
        z-index: 99;
        position: fixed;
        width: 75vw;
        border: solid 1px #d1d2d7;
        background: #f1f1f1;
        top: 83px;
    }
    .searchResultList .close{
        height: 15px !important;
        width: 15px !important;
    }
    .searchResultItemUL li {
        width: 100%;
    }
    .voirResultButton {
        display: inline-block;
    }
    .footerAnnoncesRow {
        display: contents;
    }
    .footerAnnoncesLinks1 ul{
column-count: 2;
    }
    .desktopNewsSection {
        display: none;
    }
    .mobNewsSection {
        display: block;
    }
    #dd {
        width: 100%;
        border: none;
    }
    .loading-bar{
        width: 99%;
    }
  
    .table-responsive {
        width: 100% !important;
    }
    /* .vehicle-item .specs-preview {text-align: center;}
    .vehicle-item  .specs-preview {
        display: inline-block !important;
    }
    .vehicle-item  .specs-preview ul {
        display: inline-block;
    } */
    nav ul li ul { /* Affiche la dropNav au survol de la souris avec la class .drop */
        z-index:99999;
        display:list-item !important;
        position:absolute;
        margin-top:2px;
        margin-left:-10px;
        }
        nav ul li ul li {
            float:none;
            }
    .itemDetailsProfilePage:first-child , .itemReportSection {      
        width: 100%;
        margin-bottom: 10px;
    }
    .itemReportSection {
        padding: 10px 25px 10px
    }
    .itemDetailsProfilePageMain {
        margin-right: 0px;
        display: flex;
    flex-direction: column-reverse;
    }
   .itemReportSection h5 {
        text-align: center;
    }
    .annonceActionsInfo {
        margin-top: 10px;
    }
    .itemReportSection .annonceActionsInfo p {
        margin: 0px;
        display: inline-block;
    }
    .annonceActionsInfo img, .annonceActionsInfo svg {
        vertical-align: sub;
    }
    .row {
        margin-right: 0px;
        margin-left: 0px;
    }
    .nav-tabs .nav-item a {
        padding: 5px !important;
    }
    .nav-tabs .nav-item {
        margin-bottom: 0px;
    }
    .mainlabel {
        font-size: inherit;
        margin-top: inherit;
    }
   .occasion-item .detail_result_head .price {
        position: unset;
        float: unset;
    }
    .occasion-item .detail_result_head .price_div {
        float: right;
    }
    .occasion-item .detail_result_head h2 { 
        display: inline-block;
    }
    .mobMenuFooter {
        margin-top: 8px;
        margin-bottom: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    #menuMore .l-navbar__logo {
        display: block;
        padding-top: 15px;
    }
    #menuMore .slogan_logo {
        flex-direction: row;
    }
    #menuMore .l-navbar__morty {vertical-align: inherit;}
    #menuMore .slogan {
        margin-top: auto;
        font-size: 10px;
    }
    #menuMore .sloganP1 {
        font-size: 10px;
        margin-top: 0px;
    }
    #menuMore .l-navbar__logo {
        width: 85px;
    }
    .loginLogoutMobMenu{
        float: right;
        margin-top: -3px;
    }
    .mobMenu .l-navbar__list {
        display: block;
    }
   .mobMenu ul.dropdown ,.mobMenu #dd ul{
        margin-top: 0px !important;
        position: unset;
        background: unset;
        padding-left: 15px;
        padding-bottom: 0px;
    }
    .mobMenu ul.dropdown li a {
        margin-left: 0px;
    }
    .mobMenu #dd {
        display: block !important;
        height: auto;
        padding-left: 15px !important;
        padding-bottom: 0px !important;
    }
    .mobMenu #dd ul {
        width: 100% !important;
    }
    .mobMenu .hideSubMenu ul li ul { display:none; }
    .mobMenu #dd > li {
        width: 100%;
        position: unset;
        border-bottom: solid 1px #ffffff33;
    }
    .mobMenu #dd > li li {
        display: block;
    }
    .mobMenu .c-nav__item {
        width: 100%;
        padding-bottom: 2px;
        border-bottom: solid 1px #ffffff33;
    }
    .c-nav__local22 {
        border-bottom: none !important;
    }
    .l-panel--more {
        padding-top: 0px;
        top: 0px;
        width: 50%;
        background: #04184bfa;
        height: 100%;
        z-index: 9999;
    }
    /* .scrolledMob {
        top: 40px;  
    } */
    .searchWrapperOutter {display: none;}
    .searchWrapperOutterMob {
        padding: 0px 20px;
    }
    .searchWrapper .searchIcon svg {
        height: 1rem;
        width: 1rem;
        vertical-align: text-top;
    }
    .searchWrapper {
        top: 9px;
    }
    .searchWrapper .input {
        padding: 3px;
        background: #81475d0f;
        width: 100%;
    }
    .mobNav .black {
        flex-grow: unset;
    }
    .stickyMob{
        position: fixed;
        width: 100%;
    }
    .c-nav--buttons .c-nav__item {
        text-align: initial;
    }
    .itemsRow .col-md-3 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .itemsRow .col-md-3 .price {
            font-size: 0.9rem;
    }
    .l-main__content {
        padding: 0 0px;
    }
    .o-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .profileSideBar .profile-avatar {
   margin: auto;
   display: block;
    }

.profileSideBarName {
    margin: 5px 0;
}
.mobProfileSetting {
    display: block;
    font-size: 18px;
    color: black;
    text-transform: capitalize;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
}
.profilePageAll  .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;   
    padding-top: 15px;
    padding-bottom: 15px; 
}
.profilePageAll  .col-sm-9 {
flex: 0 0 100%;
max-width: 100%;
padding-top: 20px;
padding-bottom: 20px;
}
.profilePageAll .table-outline tr td:last-child {
vertical-align: top;
padding-top: 15px;
}
.profileSideBar li:hover , .profileSideBar a:hover , .profileSideBarSelected{
border-radius: 15px;
}
.profilePageAll  .col-sm-3 ul {
text-align: center;
margin-left: 15px;
margin-right: 15px;
}
.profileSideBar .subTab {
width: 100%;
padding: 0.75rem 0;
 margin: 0px; 
}
.hideProfileDropDown{
display: none;
}
.showProfileDropDown {
display: block;
}
.footer_col1 , .footer_col2 , .footer_col3 {
width: 100%;
text-align: center;
}
.l-footer__nav2 .c-nav__link {
    margin-right: 0px;
}
.footer_social ul {
float: none;
padding-top: 15px;
justify-content: center;
display: flex;
}
.footer_social ul li {
padding: 0px !important;
margin: 0px;
width: fit-content !important;
}
.footer_social {
margin-left: 0px;
}
}
/* max width 768 ends */
@media (max-width: 576px) {
 
   .cookisMsg svg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    } 
    .nocookie {
        bottom: 5px;
    }
    .cookisMsg button {
        padding: 8px 10px;
    }
   .addingPage {
        padding-right: 12px !important;
        padding-left: 12px !important;
    }
    .advInfoSzctionCardHead svg ,  .advInfoSzctionCardHead img {
        margin-left: 8px;
    }
    .advInfoSzctionCard {
        padding: 15px 5px;
    }
    .addingPage .tab-content > .active {
        padding: 20px 5px;
    }
    .newsByCatPage .c-posts--column .c-posts__media {
        height: fit-content;
    }
    .vehicle_itemSearchResult .specs-preview ul {
        column-count: 2;
    }
   
   .filteringPage form .col-6 , .findJobPage .col-5 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
   .filteringPage form .row {
        margin-bottom: 0px;
    }
    .articleIcon {
        width: 20%;
    }
    #form-add .row .col label {
        width: 100% !important; 
   }
   #form-add  .form-check  label {
    width: 100% !important;
    display: inline-block;
}
    ul.vehicule-tabs , ul.add_page{
        justify-content: center;
    }
    #form-add .col-6 , #form-add .col-4 ,#form-add .col-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    #form-add  .col-6 .form-group .input-group {
        width: 60%;
        display: inline-flex;
    }
    #form-add .col-6 .form-group .input-group, #form-add .col-4 .form-group .input-group , #form-add .col-md-3 .form-group .input-group {
        width: 60%;
        display: inline-flex;
    }
    #form-add  .form-control {
        width: 60%;
        display: inline-block;
        font-size: 13px;
    }
    #form-add  label  {
        width: 40% ;
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
    }
    #form-add .stateCheckBoxForm .col label {
        width: 40% !important;
    }
    .MyProfileForm  #form-add  .form-control  , .MyProfileForm  #form-add  label
    {width: 100%;}
    .MyProfileForm {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .MyProfileForm .p-4.card-body {
        padding: 0px !important;
    }
    .MyProfileForm .mx-4.card {
        margin: 0px !important;
    }
    .MyProfileForm .row {display: block;}
    .registerMemberForm label {
        width: 100% !important;
    }
    .ck.ck-voice-label {
        display: none !important;
    }
    .dzu-inputLabel {
        width: 100% !important;
        display: flex !important;
        text-align: center;
    }
    
    .formPhoneNumber label { width: 100% !important ;}
    #form-add .row .col .formTypeSelect label { width: 40% !important ;}
    #form-add .row .formAgeSection label { width: 40% !important ;}
    #form-add .row .formAgeSection1  { width: 60% !important ; display: inline-flex;}
    .jobFormClass  .form-control {
        width: 100% !important; 
    }
    .jobFormClass label  {
        width: 100% !important;
    }
    .jobFormClass .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    #form-add .offset-9 {
        margin-left: 0px;
    }
    .jobDetailsPage  .similar-job img { max-width: 100px;}
    .loginPageClass .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .loginPageClass  .text-right {
        text-align: center !important;
        margin-top: 15px;
    }
    .loginPageClass .card-body {
        padding: 1rem;
    }
}
@media (min-width: 498px) {
    .multicolumn  li {
    margin-bottom: 10px; /* Adjust spacing between list items */
    break-inside: avoid; /* Prevent items from breaking inside columns */
}

/* Select the first 6 items to keep in the first column */
.multicolumn  li:nth-child(-n+8) {
    break-before: avoid; /* Force items to start in a new column */
}
}
@media (max-width: 498px) {
    .l-footer__row22 {
        padding-bottom: 20px;
        line-height: 15px;
    }
    .footerAnnoncesRow {
        text-align: center;
    }
    .footerAnnoncesLinks1 ul{
        column-count: 1;
            }
     .newsUlFooter2 ul {
        column-count: 2;  
     }       
    .vehicle-item .detail_result_head .price_div {
        float: none;
        display: block !important;
        margin-left: 0.6em;
    }
    .l-panel--more {
        width: 60%;
    }
   .jobAndCompanyRow {
        flex-direction: column-reverse;
    }
    .jobDetailsPage .col-4 , .jobDetailsPage .col-8  {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .jobDetailsPage .bordered {
        min-height: fit-content;
    }

    .features-vehicle .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .placeInfoRow1 .col-6.col-md-6 {
        max-width: 50%;
    }
    .addresInfoCol  {
        max-width: 100% !important;
    }
    .addresInfoCol .title-feature12 {
        margin-bottom: 10px;

    }
    .dateInfoCol{
        max-width: 100% !important;
        padding-left: 49px;
    }
.c-posts--tile .c-posts__inner{
    flex-direction: row;
}
.itemCardSubtitle {
    font-size: 0.6rem;
    line-height: 0.8rem;
    height: 0.8rem;
}
.annonceSection, .mobNewsSection {
    padding-right: 0px;
    padding-left: 0px;
}
.itemsRow .col-md-3{
    padding-right: 3px;
    padding-left: 3px;
}
.itemsRow .vehicle-item .favIconClass svg {
    width: 18px;
    height: 18px;
}
.itemCard1H2P.col-md-12 {
    padding: 0px;
}
.vehicle-item h4 {
    font-size: 0.7rem !important;
    line-height: 0.8rem;
    height: 0.8rem;
    margin-bottom: 2px;
}
.vehicle-item .mx-auto {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
}
.vehicle-item .mx-auto .price {
    font-size: inherit;
}
.place-icon-itemCard {
    width: 10px;}

.c-posts__media{
    align-self: auto;
    margin-right: 10px;
}
.container {
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
} 
}

.consultTextMob {
    display: none;
}

    @media  (max-width: 767px) {

        .vehicle-item .detail_result_head button {
            position: absolute;
            top: 4px;
            right: -4px;
        }
        .occasion-item .details-wrapper {
            /* display: flex; */
            border-bottom: solid 1px #e6e8eb;
        }
    }

    @media  (max-width: 575px) {
        .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
            max-height: 300px;
            height: 300px;
        }
        .occasions-results .occasion-item .price {
        white-space: unset;
    }
    .occasions-results .occasion-item h2 span {
        max-height: initial;
    }
    .occasions-results .occasion-item .thumb-caption {
        display: inline-block !important;
        width: auto;
        position: initial;
        padding-top: 12px;
        border: none;
    }
 
    }
@media (max-width: 430px) {
    .cookisMsg{
        padding-bottom: 30px;
    }
    .nocookie {
        right: 30%;
    }
    #form-add label , #form-add .form-control ,     #form-add .col-6 .form-group .input-group, #form-add .col-4 .form-group .input-group, #form-add .col-md-3 .form-group .input-group {
        width: 100%;
    }
    #form-add .input-group .form-control {
        width: auto;
    }
    .c-nav--footer .c-nav__item {
        padding: 12px 6px;
    }
    .l-footer .o-container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .l-panel--more {
        width: 70%;
    }
    .lastColJobsTable  .c-button {
        padding: 3px 5px;
        font-size: 0.65rem;
    }
    .lastColJobsTable time {
        font-size: 0.75rem;
    }
    .campany-logoTd {
        width: 70px !important;
        display: inline-block;
    }
    .jobsTableAll  .campany-logo {
        width: 65px;
    }
    .jobsTableAll thead tr th:first-child { 
        width: 80px !important;
        max-width: 80px !important;
        padding-left: 0px;
        font-size: 12px;
    }
    #form-add .row .formAgeSection label { width: 17% !important ;}
    #form-add .row .formAgeSection1  { width: 83% !important ; display: inline-flex;}
    .input-group-text{
        padding-left: 1px;
        padding-right: 1px;
        font-size: 0.75rem;
    }
    ul.vehicule-tabs , ul.add_page {
        justify-content: center;
    }
    .immobilierIcon {
        width: 25%;
    }
}
@media (min-width: 498px) and (max-width: 1400px) {
    .container {
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    } 
}
/* @media (max-width: 375px) {
    .homeNewsSectionTwoMain .c-posts--gridMosaic .c-posts__item {
        width: 100%;
    }
    .homeNewsSectionFourSub .c-posts--gridMosaic .c-posts__item {
        width: 100%;
    }  
} */
@media (max-width: 354px) {
    .filtersMobButton {
        font-size: 14px;
        padding: 5px 5px;
      }
      .filtersMobButton svg {
        width: 20px;}
        .filtersCount {
            margin-left: 5px;
        }
        .filtersCount button {
            padding: 3px 3px;
        }
    .vehicle_itemSearchResult .specs-preview ul {
        column-count: 1;
    }
    .l-panel--more {
        width: 74%;
    }
    #form-add .stateCheckBoxForm .col label  {
        width: 30% !important;
    }
    .ck.ck-voice-label {
        display: none !important;
    }
    .dzu-inputLabel {
        width: 100% !important;
        display: flex !important;
        text-align: center;
    }
}

.newsPage .arabicFontClass .l-article__text {
    font-size: 22px;
    font-weight: 100 !important;
}
.newsPage .arabicFontClass .l-article__text  ul, .newsPage .arabicFontClass .l-article__text  li {
    font-size: 19px;
}
.newsPage .arabicFontClass .l-article__text p {
    font-weight: 100;
}
.arabicFontClass .postCategory {
    font-weight: 600;
}
.homeNewsSectionTwoMainUl .u-lineClampSub {
    line-height: 1.2rem;
}
.homeNewsSectionTwoMainUl .arabicFontClass .u-lineClampSub {
    line-height: 1.5rem;
}
.desktopNewsSection  .u-lineClampSub h2 , .desktopNewsSection  .u-lineClampSub h3 , .desktopNewsSection  .u-lineClampSub h4 , .desktopNewsSection  .u-lineClampSub h5{
font-size: 17.5px;
}
.desktopNewsSection .u-lineClampSub p {
    margin-top: 2px;
 }
 .desktopNewsSection .u-lineClamp.u-lineClampMain {
    min-height: 50px;
}
.desktopNewsSection  .u-lineClampSub P , .desktopNewsSection  .u-lineClampSub h2 , .desktopNewsSection  .u-lineClampSub h3 , .desktopNewsSection  .u-lineClampSub h4 , .desktopNewsSection  .u-lineClampSub h5 
{    line-height: 26px;
}
.arabicFontClass .c-posts__info--highlight {
    font-weight: 600;
}
.arabicFontClass .postCreatedAT {
    font-size: 16px;
}
.addnewbanner {
    background: #3c4c6412;
    padding: 10px;
    border-radius: 5px;
}
.consultText {
    max-width: 85%;
}
.consultText p{
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
    text-align: justify;
}
.consultText strong{
    font-size: 18px;
}
.consultText ul{
    padding-top: 0px;
    list-style: none;
}
.consultText ul li {
    padding: 3px 0 3px 0;
    font-size: 16px;
}
.consultText img{
    width: 300px;
    margin: auto;
    display: block;
}
.consultText h3 {
    font-weight: 600;
    color: black;
    font-size: 22px;
    text-transform: uppercase;
}
.consultForm {max-width: 1300px;}
.consultForm form{
    background: #f8f8f8;
    border-radius: 4px;
    display: block;
    margin-top: 0;
    padding: 20px;
    box-shadow: 0 9px 9px -20px #32325d40, 0 30px 15px -30px #0000004d, inset 0 -2px 6px 0 #0a254059;
}
.consultText2 p {
    display: flex;
        flex-wrap: wrap;
        justify-content: center;
}
.consultText h3 span {
    font-size: 25px;
}
@media  (max-width: 767px) {
    .consultTextDesktop {
        display: none;
    }

    .consultTextMob {
        display: flex;
    }
    .consultTextMob .col-8{
        padding-right: 0px !important;
    }
    .consultText {
        max-width: 96%;
    }

    .consultText2 {
        max-width: 100%;
    }
    .consultText p {
        font-size: inherit;
    }
    .consultForm form .col-6 { 
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .consultTextMob .col-12{ 
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .consultForm form label ,  .consultForm form input ,  .consultForm form select ,  .consultForm form textarea{
        width: 100% !important;
        display: block !important;
    }
    .consultForm .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .consultText img {
        max-width: 70%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .consultText h3 {
        font-size: 20px;
    }
    .consultText h3 span {
        font-size: 23px;
    }
    
}
@media  (max-width: 354px) {
    .consultText h3 {
        font-size: 18px;
    }
    .consultText h3 span {
        font-size: 21px;
    }
    .consultText img {
        max-width: 90%;
    }
}