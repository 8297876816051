.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #0300b1;
    padding: 6px;
    z-index: 999;
  }
  .mysticky .l-navbar__top{
    background: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    height: fit-content !important;
  }
 .mysticky nav ul.dropdown {
    margin-top: 1px !important;
  }
  @media (max-width: 768px) {
   .mysticky {
       display: none;
   }
}
.l-navbar__topics{
    display: contents;
}
.stickyFloatRight{
    margin-right: 15px;
    color: white;
    text-transform: capitalize;
    line-height: 1rem;
}
.stickyFloatRight a {
    display: inline-block;
}
.c-nav__item , header li {
    list-style:none !important;
}